import { styled } from '@mui/material/styles';
import { Typography, Box } from 'common/components';
import React from 'react';

const AnimatedLine = styled('div')({
    '@keyframes moving': {
        from: {
            left: 0,
        },
        to: {
            left: 'calc(100% - 10px)',
        },
    },
    '@keyframes blinking': {
        '0%': {
            opacity: 0,
        },
        '50%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
    width: 10,
    height: 4,
    backgroundColor: 'black',
    borderRadius: 2,
    position: 'relative',
    animationName: 'moving, blinking',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationTimingFunction: 'linear',
});

type LoadingLineTypes = {
    className?: string;
    showText?: boolean;
    sx?: any;
};

const StyledLoadingLine = styled('div')({
    margin: 'auto',
});

const LoadingLine: React.FC<LoadingLineTypes> = ({ showText, className, sx }) => {
    return (
        <StyledLoadingLine sx={sx} className={className}>
            <Box sx={{ width: 100, margin: 'auto' }}>
                {showText && <Typography sx={{ fontWeight: 500 }}>Loading...</Typography>}
                <AnimatedLine />
            </Box>
        </StyledLoadingLine>
    );
};

export default LoadingLine;
