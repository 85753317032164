import React from 'react';
import { ImageIcon } from 'common/icons';
import { styled, SxProps } from '@mui/material';
import { Box, Typography } from 'common/components';
import { useTeam } from 'entities/teams/teams.hooks';
import AvatarCircle from 'common/components/AvatarCircle';
import { useOrganization } from 'entities/organizations/organizations.hooks';

const Root = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

export type TeamInfoProps = {
    teamId: string;
    organizationId: string;
    sx?: SxProps;
};

const TeamInfo: React.FC<TeamInfoProps> = ({ teamId, organizationId, ...rest }) => {
    const team = useTeam(organizationId, teamId);
    const organization = useOrganization(organizationId);

    if (!team || !organization) {
        return null;
    }

    return (
        <Root {...rest}>
            <AvatarCircle
                size={32}
                sx
                DefaultIconComponent={ImageIcon}
                filePath={organization.logoPath}
                alt={organization?.name || 'Organization logo'}
            />
            <Typography sx={{ ml: 1 }}>{team.name}</Typography>
        </Root>
    );
};

export default TeamInfo;
