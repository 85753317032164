import React from 'react';
import { PhysicalIcon } from 'common/icons';
import { Guide, MainBlock } from './CommonGuideComponents';
import { VideoTutorial } from 'common/components/VideoPlayBtn/VideoTutorial';
import { TutorialName } from 'common/components/VideoPlayBtn/videoTutorial.config';

const ConditionsPageGuide: React.FC = () => {
    return (
        <Guide>
            <MainBlock>
                <PhysicalIcon
                    sx={{
                        fontSize: 72,
                        textAlign: 'center',
                        margin: 'auto',
                        marginBottom: 2,
                        fill: (theme: any) => theme.palette.primary.main,
                    }}
                />
            </MainBlock>
            <VideoTutorial sx={{ mb: 0.5 }} tutorialName={TutorialName.conditionsOverview} />
            <VideoTutorial sx={{ mb: 0.5 }} tutorialName={TutorialName.morningWellness} />
            <VideoTutorial sx={{ mb: 0.5 }} tutorialName={TutorialName.morningWellnessSetup} />
            <VideoTutorial sx={{ mb: 0.5 }} tutorialName={TutorialName.loadConditions} />
            <VideoTutorial sx={{ mb: 0.5 }} tutorialName={TutorialName.loadConditionsSetup} />
        </Guide>
    );
};

export default ConditionsPageGuide;
