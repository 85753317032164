import React from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { Box } from 'common/components';
import { styled, SxProps } from '@mui/material';
import TeamStats from 'entities/teams/TeamStats';
import { teamPlayersListSelector } from 'entities/players/players.selectors';
import TeamStatsInfo from './TeamStatsInfo';
import PlayersStatsTable from './players-list/PlayersStatsTable';

const Root = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

export type TeamStatsViewProps = {
    teamStats: TeamStats;
    teamId: string;
    organizationId: string;
    sx?: SxProps;
};

const TeamStatsView: React.FC<TeamStatsViewProps> = ({
    teamStats,
    teamId,
    organizationId,
    ...rest
}) => {
    const playersList = useSelector((state: RootState) =>
        teamPlayersListSelector(state, { teamId, organizationId }),
    );

    return (
        <Root {...rest}>
            <TeamStatsInfo teamStats={teamStats} sx={{ mb: 1 }} />
            <PlayersStatsTable playersList={playersList} playersStats={teamStats.playersStats} />
        </Root>
    );
};

export default TeamStatsView;
