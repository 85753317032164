import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toastr } from 'react-redux-toastr';
import SubmitButton from 'common/forms/SubmitButton';
import * as authGateway from 'gateways/authGateway';
import { Typography, Button, Input } from 'common/components';
import getErrorMessage from '../getErrorMessage';
import { useSuccessLogin } from '../auth.utils';
import ContinueWithGoogleBtn from './ContinueWithGoogleBtn';
// import ContinueWithAppleBtn from './ContinueWithAppleBtn';

const PREFIX = 'LoginPage';

const classes = {
    field: `${PREFIX}-field`,
    actionButton: `${PREFIX}-actionButton`,
};

const Root = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: 300,
    padding: theme.spacing(1),
    margin: 'auto',
    alignItems: 'center',

    [`& .${classes.field}`]: {
        marginTop: theme.spacing(1),
        width: '100%',
    },

    [`& .${classes.actionButton}`]: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
}));

type FormValues = { email: string; password: string };

const LoginPage: React.FC = () => {
    const { t } = useTranslation();
    const { onSuccess } = useSuccessLogin();
    const {
        register,
        handleSubmit,
        formState: { isSubmitting },
    } = useForm({
        defaultValues: { email: '', password: '' },
    });

    const onSubmit = useCallback(
        async ({ email, password }: FormValues) => {
            try {
                await authGateway.loginUser({ email, password });
                onSuccess();
            } catch (e: any) {
                toastr.error('', getErrorMessage({ code: e.code }, t));
            }
        },
        [onSuccess],
    );

    return (
        <Root onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h4" gutterBottom>
                {t('auth.login')}
            </Typography>
            <Input
                label="Email"
                type="email"
                placeholder={t('auth.forms.email.placeholder')}
                className={classes.field}
                {...register('email', {
                    required: true,
                })}
            />
            <Input
                label={t('auth.forms.password.label')}
                type="password"
                placeholder={t('auth.forms.password.placeholder')}
                className={classes.field}
                {...register('password', {
                    required: true,
                })}
            />
            <SubmitButton
                className={classes.actionButton}
                isLoading={isSubmitting}
                buttonText={t('auth.signIn') as string}
            />
            <Typography sx={{ mt: 2 }} align="center">
                {t('auth.common.or')}
            </Typography>
            <ContinueWithGoogleBtn sx={{ mt: 2 }} />
            <Button className={classes.actionButton} component={Link} to="/auth/signup">
                {t('auth.createAccount')}
            </Button>
            <Button className={classes.actionButton} component={Link} to="/auth/password-reset">
                {t('auth.forgotPassword')}
            </Button>
            {/* <ContinueWithAppleBtn /> */}
        </Root>
    );
};

export default LoginPage;
