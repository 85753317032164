import { createSelector } from 'reselect';
import { RootState } from '../../store';

export const evaluationsHashSelector = (state: RootState) =>
    state.entities.evaluations.evaluationsHash;

export const evaluationsListSelector = createSelector(evaluationsHashSelector, (evaluationsHash) =>
    Object.values(evaluationsHash),
);

export const evaluationsListByEventIdSelector = createSelector(
    evaluationsListSelector,
    (_: any, { eventId }: { eventId: string }) => eventId,
    (evaluationsList, eventId) => {
        return evaluationsList.filter((evaluation) => evaluation.eventId === eventId);
    },
);

export const playerEvaluationsForEventSelector = createSelector(
    evaluationsListSelector,
    (_: any, { eventId }: { eventId: string }) => eventId,
    (_: any, { playerId }: { playerId: string }) => playerId,
    (evaluationsList, eventId, playerId) => {
        return evaluationsList.filter(
            (evaluation) => evaluation.eventId === eventId && evaluation.playerId === playerId,
        );
    },
);
