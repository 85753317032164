import Notification, { NotificationData } from './Notification';
import actionTypes from './notifications.actionTypes';

export const notificationDataRequest = ({
    notificationId,
    userId,
}: {
    notificationId: string;
    userId: string;
}) => ({
    type: actionTypes.NOTIFICATION_DATA_REQUEST,
    notificationId,
    userId,
});

export const notificationsListRequest = ({ userId }: { userId: string }) => ({
    type: actionTypes.NOTIFICATIONS_LIST_REQUEST,
    userId,
});

export const notificationsListRecieved = ({
    notificationsList,
}: {
    notificationsList: Notification[];
}) => ({
    type: actionTypes.NOTIFICATIONS_LIST_RECIEVED,
    notificationsList,
});

export const notificationDataRecieved = ({ notification }: { notification: Notification }) => ({
    type: actionTypes.NOTIFICATION_DATA_RECIEVED,
    notification,
});

export const updateNotification = ({
    notificationData,
    userId,
    notificationId,
    onSuccess,
}: {
    notificationData: NotificationData;
    userId: string;
    notificationId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.UPDATE_NOTIFICATION,
    notificationData,
    userId,
    notificationId,
    onSuccess,
});
