import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from 'common/components';
import { InfoIcon } from 'common/icons';
import { useTranslation } from 'react-i18next';

const PREFIX = 'NotFoundPage';

const classes = {
    info: `${PREFIX}-info`,
    infoIcon: `${PREFIX}-infoIcon`,
    infoLink: `${PREFIX}-infoLink`,
};

const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '100%',

    [`& .${classes.info}`]: {
        display: 'flex',
        margin: 'auto',
        alignItems: 'center',
        backgroundColor: theme.custom.background.light,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1, 2),
        width: 336,
    },

    [`& .${classes.infoIcon}`]: {
        width: 32,
        height: 32,
        opacity: 0.4,
    },

    [`& .${classes.infoLink}`]: {
        marginLeft: theme.spacing(2),
    },
}));

const NotFoundPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <div className={classes.info}>
                <InfoIcon className={classes.infoIcon} />
                <Typography variant="caption" className={classes.infoLink}>
                    {t('notFoundPage.description')}
                </Typography>
            </div>
        </Root>
    );
};

export default NotFoundPage;
