import React, { useCallback } from 'react';
import {
    Select as MuiSelect,
    SelectProps as MuiSelectProps,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowDropDownIcon } from 'common/icons';
import Checkbox from './Checkbox';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    [`.MuiFormLabel-root`]: {
        transform: 'none',
        position: 'static',
        paddingLeft: theme.spacing(1),
        ...theme.typography.body2,
    },
    [`.MuiInputBase-root`]: {
        backgroundColor: 'rgba(9, 30, 66, 0.04)',
        border: '2px solid transparent',
        paddingRight: 0,
    },
    [`.MuiInputBase-root.Mui-focused`]: {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
    },
    [`.MuiInputBase-inputSizeSmall`]: {
        padding: theme.spacing(0.5, 1),
    },
    fieldset: {
        display: 'none',
    },
    [`& .MuiList-root`]: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.custom.boxShadow.main,
    },
    [`& .MuiSelect-icon`]: {
        right: 0,
    },
    [`& .MuiSelect-select`]: {
        paddingRight: '20px !important',
        paddingLeft: 4,
    },
}));

export type Option = {
    value: any;
    label: string;
};

export type SelectProps = MuiSelectProps & {
    placeholder?: string;
    options: Option[];
    sx?: any;
};

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
    ({ placeholder, label, options, sx, multiple, ...props }, ref) => {
        const renderValue = useCallback(
            (selected: any) => {
                if (!Array.isArray(selected)) {
                    const selectedOption = options.find((option) => option.value === selected);
                    return selectedOption ? selectedOption.label : '';
                }

                return selected
                    .map((value) => {
                        const selectedOption = options.find((option) => option.value === value);
                        return selectedOption ? selectedOption.label : '';
                    })
                    .join(', ');
            },
            [options],
        );

        return (
            <StyledFormControl sx={sx}>
                <InputLabel>{label}</InputLabel>
                <MuiSelect
                    ref={ref}
                    variant="outlined"
                    size="small"
                    multiple={multiple}
                    placeholder={placeholder}
                    IconComponent={ArrowDropDownIcon}
                    renderValue={renderValue}
                    MenuProps={{
                        MenuListProps: {
                            style: {
                                backgroundColor: '#fff',
                            },
                        },
                    }}
                    {...props}
                >
                    {placeholder && (
                        <MenuItem disabled value="">
                            {placeholder}
                        </MenuItem>
                    )}
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {multiple && (
                                <Checkbox
                                    sx={{ py: 0 }}
                                    // @ts-ignore
                                    checked={props.value.includes(option.value)}
                                />
                            )}
                            {option.label}
                        </MenuItem>
                    ))}
                </MuiSelect>
            </StyledFormControl>
        );
    },
);

export default Select;
