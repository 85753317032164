import { Author } from 'entities/users/User';
import MedicalTest, { MedicalTestData } from './MedicalTests';
import actionTypes from './medicalTests.actionTypes';
import { MedicalRecordType } from 'entities/medical-records/MedicalRecords';

export const createMedicalTest = ({
    teamId,
    organizationId,
    tests,
    title,
    onSuccess,
    onFail,
}: {
    teamId: string;
    organizationId: string;
    tests: MedicalRecordType[];
    title?: string;
    onSuccess: (medicalTestId: string) => void;
    onFail: VoidFunction;
}) => ({
    type: actionTypes.CREATE_MEDICAL_TEST,
    teamId,
    organizationId,
    tests,
    title,
    onSuccess,
    onFail,
});

export const updateMedicalTest = ({
    teamId,
    organizationId,
    medicalTestId,
    medicalTestData,
    onSuccess,
    onFail,
}: {
    teamId: string;
    organizationId: string;
    medicalTestId: string;
    medicalTestData: MedicalTestData;
    onSuccess?: () => void;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.UPDATE_MEDICAL_TEST,
    teamId,
    organizationId,
    medicalTestId,
    medicalTestData,
    onSuccess,
    onFail,
});

export const medicalTestRequest = ({
    teamId,
    organizationId,
    medicalTestId,
    onSuccess,
}: {
    medicalTestId: string;
    teamId: string;
    organizationId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.MEDICAL_TEST_REQUEST,
    medicalTestId,
    teamId,
    organizationId,
    onSuccess,
});

export const medicalTestDataRecieved = (medicalTest: MedicalTest) => ({
    type: actionTypes.MEDICAL_TEST_RECEIVED,
    medicalTest,
});

export const medicalTestsListRequest = ({
    teamId,
    organizationId,
    onSuccess,
}: {
    teamId: string;
    organizationId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.MEDICAL_TESTS_LIST_REQUEST,
    teamId,
    organizationId,
    onSuccess,
});

export const medicalTestsListRecieved = (medicalTestsList: MedicalTest[]) => ({
    type: actionTypes.MEDICAL_TESTS_LIST_RECEIVED,
    medicalTestsList,
});

export const medicalTestsAccessListRequest = ({
    organizationId,
    onSuccess,
}: {
    organizationId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.MEDICAL_TESTS_ACCESS_LIST_REQUEST,
    organizationId,
    onSuccess,
});

export const medicalTestsAccessListRecieved = (organizationId: string, usersInfo: Author[]) => ({
    type: actionTypes.MEDICAL_TESTS_ACCESS_LIST_RECEIVED,
    usersInfo,
    organizationId,
});
