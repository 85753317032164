import { takeLatest, all, call } from 'redux-saga/effects';
import * as emailsGateway from 'gateways/emailsGateway';
import { toastr } from 'react-redux-toastr';
import emailsActionTypes from './emails.actionTypes';
import * as emailsActions from './emails.actions';

export function* createEmailSaga({
    emailData,
    successMessage,
}: ReturnType<typeof emailsActions.createEmail>) {
    try {
        yield call([emailsGateway, emailsGateway.createEmail], { emailData });
        yield call(toastr.success, '', successMessage);
    } catch (e) {
        console.error(e);
    }
}

function* emailsSagas() {
    yield all([takeLatest(emailsActionTypes.CREATE_EMAIL, createEmailSaga)]);
}

export default emailsSagas;
