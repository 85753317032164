import React from 'react';
import { GameIcon } from 'common/icons';
import { Guide, MainBlock } from './CommonGuideComponents';
import { VideoTutorial } from 'common/components/VideoPlayBtn/VideoTutorial';
import { TutorialName } from 'common/components/VideoPlayBtn/videoTutorial.config';

const GamePageGuide: React.FC = () => {
    return (
        <Guide>
            <MainBlock>
                <GameIcon
                    sx={{
                        fontSize: 72,
                        textAlign: 'center',
                        margin: 'auto',
                        marginBottom: 2,
                        fill: (theme: any) => theme.palette.primary.main,
                    }}
                />
            </MainBlock>
            <VideoTutorial sx={{ mb: 0.5 }} tutorialName={TutorialName.createGame} />
            <VideoTutorial sx={{ mb: 0.5 }} tutorialName={TutorialName.evaluateGame} />
        </Guide>
    );
};

export default GamePageGuide;
