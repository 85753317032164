import MuiIconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const StyledIconButton = styled(MuiIconButton)({
    '&.MuiIconButton-root': {
        width: 32,
        height: 32,
        background: 'rgba(9, 30, 66, 0.04)',
        padding: 0,
        borderRadius: 4,

        '&:hover': {
            background: 'rgba(9, 30, 66, 0.1)',
        },
    },
    [`&.Mui-disabled`]: {
        backgroundColor: '#e3e8ef',
    },
    [`&.MuiIconButton-sizeSmall`]: {
        width: 24,
        height: 24,
    },
    [`&.MuiIconButton-colorInherit`]: {
        background: 'transparent',
    },
});

export default StyledIconButton;
