import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import Invitation from 'entities/invitations/Invitation';
import {
    playerToLinkSelector,
    userInvitationsListSelector,
} from 'entities/invitations/invitations.selectors';
import { fetchUserInvitations } from 'entities/invitations/invitations.actions';
import { Box, Button, Typography } from 'common/components';

const InvitationsList: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const playerToLink = useSelector(playerToLinkSelector);
    const invitationsList = useSelector(userInvitationsListSelector);

    useEffect(() => {
        dispatch(fetchUserInvitations());
    }, []);

    const handleLinkPlayer = useCallback((playerId: string) => {
        navigate(`/link-player/${playerId}`);
    }, []);

    const handleAcceptInvitation = useCallback((invitation: Invitation) => {
        const { target, accessType, targetName, id } = invitation;
        const params = { target, accessType, targetName };
        const query = qs.stringify(params);
        navigate(`/accept-invitation/${id}?${query}`);
    }, []);

    if (!playerToLink && invitationsList.length === 0) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                py: 2,
                mb: 2,
                maxWidth: 320,
            }}
        >
            <Typography variant="subtitle2" sx={{ p: 0.5 }}>
                {t('invitationsList.title')}
            </Typography>
            {playerToLink && (
                <Button
                    sx={{ mb: 1 }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleLinkPlayer(playerToLink)}
                >
                    {t('invitationsList.linkPlayerProfileBtn')}
                </Button>
            )}
            {invitationsList.map((invitation) => (
                <Button
                    key={invitation.id}
                    sx={{ mb: 1 }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleAcceptInvitation(invitation)}
                >
                    {t('invitationsList.joinResource', { targetName: invitation.targetName })}
                </Button>
            ))}
        </Box>
    );
};

export default InvitationsList;
