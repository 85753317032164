import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Typography } from 'common/components';
import { useTranslation } from 'react-i18next';
import Event, { EventTypes } from 'entities/events/Event';
import { format, isToday, isTomorrow, isYesterday, isSameDay, subDays } from 'date-fns';
import { TFunction } from 'i18next';

const PREFIX = 'EventsList';

const classes = {
    event: `${PREFIX}-event`,
    title: `${PREFIX}-title`,
};

const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [`& .${classes.event}`]: {
        display: 'flex',
        marginBottom: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(0.5, 1),
        backgroundColor: theme.custom.background.blueCard,
        width: '100%',
    },

    [`& .${classes.title}`]: {
        marginRight: 'auto',
        paddingRight: theme.spacing(1),
    },
}));

type EventsListProps = {
    eventsList: Event[];
    onSelect: (event: Event) => void;
};

const getDayString = (date: Date, t: TFunction) => {
    if (isYesterday(date)) {
        return t('common.days.yesterday');
    }

    if (isSameDay(date, subDays(new Date(), 2))) {
        return t('common.days.theDayBeforeYesterday');
    }

    if (isToday(date)) {
        return t('common.days.today');
    }

    if (isTomorrow(date)) {
        return t('common.days.tomorrow');
    }

    return t('common.days.theDayAfterTomorrow');
};

const getEventText = (event: Event, t: TFunction) => {
    const dayString = getDayString(event.start, t);
    const eventName =
        event.eventType === EventTypes.session
            ? t('assistant.eventsList.session')
            : t('assistant.eventsList.game');

    return t('assistant.eventsList.eventItem', {
        eventName,
        dayString,
        time: format(event.start, 'HH:mm'),
    });
};

const EventsList: React.FC<EventsListProps> = ({ eventsList, onSelect }) => {
    const { t } = useTranslation();

    return (
        <Root>
            {eventsList.map((event) => (
                <div key={event.id} className={classes.event}>
                    <Typography className={classes.title}>{getEventText(event, t)}</Typography>
                    <Button
                        onClick={() => onSelect(event)}
                        variant="contained"
                        color="primary"
                        size="small"
                    >
                        {t('assistant.eventsList.goToEventBtn')}
                    </Button>
                </div>
            ))}
        </Root>
    );
};

export default EventsList;
