import actionTypes from './evaluations.actionTypes';
import Evaluation from './Evaluation';

export const evaluationsListRecieved = (evaluationsList: Evaluation[]) => ({
    type: actionTypes.EVALUATIONS_LIST_RECIEVED,
    evaluationsList,
});

export const fetchEvaluationsForEvent = (eventId: string) => ({
    type: actionTypes.REQUEST_EVALUATIONS_FOR_EVENT,
    eventId,
});

export const fetchPlayerEvaluationsForEvent = ({
    eventId,
    playerId,
}: {
    eventId: string;
    playerId: string;
}) => ({
    type: actionTypes.REQUEST_PLAYER_EVALUATIONS_FOR_EVENT,
    eventId,
    playerId,
});

export const evaluationReceived = (evaluation: Evaluation) => ({
    type: actionTypes.EVALUATION_RECIEVED,
    evaluation,
});

export const fetchEvaluationsList = ({
    playerId,
    teamId,
    organizationId,
    from,
    to,
}: {
    organizationId?: string;
    teamId?: string;
    playerId?: string;
    from: Date;
    to: Date;
}) => ({
    type: actionTypes.REQUEST_EVALUATIONS_LIST,
    organizationId,
    teamId,
    playerId,
    from,
    to,
});
