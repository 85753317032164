import React from 'react';
import { Typography, Box } from 'common/components';

type AssistantBaseProps = {
    description: string;
    icon: string;
    content?: React.ReactElement;
    children?: React.ReactNode;
};

const AssistantBase: React.FC<AssistantBaseProps> = ({ description, icon, children, content }) => {
    return (
        <Box sx={{ display: 'flex', mb: 3 }}>
            <Box component="span" sx={{ mr: 2, fontSize: '32px' }}>
                {icon}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: '2px solid rgba(0, 0, 0, 0.04)',
                    borderRadius: 1,
                    p: 1,
                }}
            >
                <Typography sx={{ mb: 2 }}>{description}</Typography>
                {content}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    );
};

export default AssistantBase;
