import { TeamStatus } from 'entities/reports/Report';

export type EpisodesParams = {
    organizationId: string;
    teamId: string;
    eventId: string;
};

export enum EpisodesType {
    /* Tracking */
    passingLines = 'passingLines',
    runsToDepth = 'runsToDepth',
    progressiveRunOpportunitiesTaken = 'progressiveRunOpportunitiesTaken',
    progressiveRunOpportunitiesMissed = 'progressiveRunOpportunitiesMissed',
    progressiveRuns = 'progressiveRuns',
    possessions = 'possessions',
    spaces = 'spaces',
    offsides = 'offsides',
    wideDefenceBlockWidth = 'wideDefenceBlockWidth',
    normalDefenceBlockWidth = 'normalDefenceBlockWidth',
    expandedDefenceBlockHeight = 'expandedDefenceBlockHeight',
    normalDefenceBlockHeight = 'normalDefenceBlockHeight',
    flattenedDefenceBlockHeight = 'flattenedDefenceBlockHeight',
    goodDefencePlayersDistances = 'goodDefencePlayersDistances',
    corridorsBetweenDefencePlayers = 'corridorsBetweenDefencePlayers',
    narrowDefenceLine = 'narrowDefenceLine',
    lowPressing = 'lowPressing',
    middlePressing = 'middlePressing',
    pressingOvercome = 'pressingOvercome',
    ballLossBallArea = 'ballLossBallArea',
    ballLossFar = 'ballLossFar',
    ballGain = 'ballGain',
    narrowDefenceBlockWidth = 'narrowDefenceBlockWidth',
    /* Manual */
    depthAttack = 'depthAttack',
    finishingZonesCoverage = 'finishingZonesCoverage',
    finishingZonesAttack = 'finishingZonesAttack',
    noPressureAndHighDefLine = 'noPressureAndHighDefLine',
    optionsBetweenLines = 'optionsBetweenLines',
    secondBall = 'secondBall',
    attackReversal = 'attackReversal', // Attack: Разворот атаки(+ -)
    readyForLoss = 'readyForLoss', // Attack: Готовность к потере(защитниками)(+ -)
    halfFlangAttack = 'halfFlangAttack', // Attack: Атака полуфлангов(+ -)
    passBehindDeffence = 'passBehindDeffence', // + Attack: Передачи за спину защитников(+ -)
    deffenceBackOpenBall = 'deffenceBackOpenBall', // Deffence: Отскок линии обороны при открытом мяче(+ -)
    midfilderCoverDefenceGaps = 'midfilderCoverDefenceGaps', // Deffence: Опускание опорных в разрывы в линии защиты(+ -)
    loss = 'loss', // Просто событие отбора мяча
    tackle = 'tackle', // Просто событие возврата мяча
    pressingAfterLoss = 'pressingAfterLoss', // Прессинг после потери мяча
    structureCompressionQuality = 'structureCompressionQuality', // Прессинг после потери мяча
    individualPressing = 'individualPressing', // Индиыидуальные прессинг действия
    saveCompact = 'saveCompact',
    depthAttackAfterTackle = 'depthAttackAfterTackle',
    ballToFreeSpace = 'ballToFreeSpace',
    crossTackleLine = 'crossTackleLine',
    gameStop = 'gameStop',
    gameStart = 'gameStart',
    /* Both */
    highPressing = 'highPressing',
}

export type BallGainEpisodeDetails = {
    passTargets: string[];
    teamSpaceIncreased: boolean;
    runsToDepth: string[];
    freeSpacePlayerNumber: string | null;
    passToDepthOpportunities: string[];
};

export type BallLossDetails = {
    isActive: boolean;
    activePlayers: string[];
    passivePlayers: string[];
};

export type PassingLineDetails = {
    passTarget: string;
    playerNumber: string;
    x: number;
    y: number;
};

export type ProgressionDetails = {
    playerNumber: string;
    x: number;
    y: number;
    distance: number;
    finalX: number;
    finalY: number;
};

export type ProgressiveRunOpportunityDetails = {
    playerNumber: string;
    x: number;
    y: number;
    // 'G' - gate direction, 'F' - forward to the goal line, 'GF' - both direction
    directions: ('G' | 'F' | 'GF')[];
};

export type SpacesDetails = {
    playerNumber: string;
    x: number;
    y: number;
    opponents: string;
    isCenter: boolean;
};

export type RunToDepthDetails = {
    playerNumber: string;
    isLineCrossed: boolean;
};

export type DefenceDistancesDetails = {
    corridors?: string[];
};

export type OptionsBetweenLinesDetails = {
    area: 'buildup' | 'final';
};

export enum CheckResult {
    missed = 'missed',
    match = 'match',
    extra = 'extra',
}

export interface GenericEpisode<T> {
    id: string;
    startTime: number;
    tagTime?: number;
    endTime?: number;
    team: TeamStatus;
    isNegative?: boolean;
    isMiddle?: boolean;
    isPositive?: boolean;
    isManual?: boolean;
    isTracking?: boolean;
    checkResult?: CheckResult;
    details: T;
}

export type BaseEpisode = GenericEpisode<{}>;

/* Tracking episodes */
export type PassingLinesEpisode = GenericEpisode<PassingLineDetails>;
export type RunsToDepthEpisode = GenericEpisode<RunToDepthDetails>;
export type ProgressiveRunOpportunitiesEpisode = GenericEpisode<ProgressiveRunOpportunityDetails>;
export type ProgressiveRunEpisode = GenericEpisode<ProgressionDetails>;
// export type targetPassesEpisode = GenericEpisode<PassingLineDetails>
// export type missedPassesEpisode = GenericEpisode<PassingLineDetails>
export type SpaceEpisode = GenericEpisode<SpacesDetails>;
// export type offsidesEpisode = GenericEpisode<PassingLineDetails>
export type DefenceBlockEpisode = GenericEpisode<DefenceDistancesDetails>;
export type BallLossEpisode = GenericEpisode<BallLossDetails>;
export type BallGainEpisode = GenericEpisode<BallGainEpisodeDetails>;

/* Manual episodes */
export type OptionsBetweenLinesEpisode = GenericEpisode<OptionsBetweenLinesDetails>;

export type Episode =
    | BaseEpisode
    | PassingLinesEpisode
    | RunsToDepthEpisode
    | ProgressiveRunOpportunitiesEpisode
    | ProgressiveRunEpisode
    | SpaceEpisode
    | DefenceBlockEpisode
    | BallLossEpisode
    | BallGainEpisode
    | OptionsBetweenLinesEpisode;

export type EpisodesGroupData = {
    period: number;
    type: EpisodesType;
    episodes: Episode[];
};

export type EpisodesGroup = EpisodesGroupData & {
    id: string;
    eventId: string;
};

export default EpisodesGroup;
