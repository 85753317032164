import React, { useRef, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';

import classNames from 'classnames';
import pitchImg from 'img/pitch-vertical-cutted.svg';
import pitchImgBlack from 'img/pitch-vertical-cutted-black.svg';
import { PitchPosition } from 'common/components/pitch/PitchPosition';
import { getPitchSize } from './pitch.utils';

const PREFIX = 'Pitch';

const classes = {
    container: `${PREFIX}-container`,
    pitch: `${PREFIX}-pitch`,
    blackPitch: `${PREFIX}-blackPitch`,
};

const Root = styled('div')(() => ({
    width: '100%',
    display: 'flex',

    [`& .${classes.pitch}`]: {
        position: 'relative',
        marginRight: 'auto',
        marginLeft: 'auto',
        background: `url(${pitchImg}) center no-repeat`,
        backgroundSize: 'contain',
    },

    [`& .${classes.blackPitch}`]: {
        background: `url(${pitchImgBlack}) center no-repeat`,
        backgroundSize: 'contain',
    },
}));

type PitchProps = {
    positions?: PitchPosition[];
    className?: string;
    children?: Function;
    black?: boolean;
    fixedWidth?: number;
    sx?: any;
};

const Pitch: React.FC<PitchProps> = ({
    positions = [],
    black,
    className,
    children,
    fixedWidth,
    ...rest
}) => {
    const pitchRef = useRef(null);
    const [pitchSize, setPitchSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        setPitchSize(getPitchSize(pitchRef.current, fixedWidth));
    }, [pitchRef.current, fixedWidth]);

    const { width, height } = pitchSize;
    const circleSize = Math.min(32, width / 10);

    return (
        <Root ref={pitchRef} className={classNames(classes.container, className)} {...rest}>
            <div
                className={classNames(classes.pitch, black && classes.blackPitch)}
                style={{ width, height }}
            >
                {positions.map((position) =>
                    // @ts-ignore
                    children({ pitchPosition: position, circleSize, key: position.position }),
                )}
            </div>
        </Root>
    );
};

export default Pitch;
