import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Typography } from 'common/components';
import CreateOrgBtn from 'common/help/pages/CreateOrgBtn';
import { AccessTypes } from 'entities/access/Access';
import { userDataSelector } from 'entities/users/users.selectors';
import ChooseLanguage from 'features/profile/components/ChooseLanguage';
import { VideoTutorial } from 'common/components/VideoPlayBtn/VideoTutorial';
import { TutorialName } from 'common/components/VideoPlayBtn/videoTutorial.config';
import { userAccessRecordsSelector } from 'entities/access/access.selectors';

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: 'auto',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.custom.background.light,
    padding: theme.spacing(4),
    width: '100%',
    maxWidth: 500,
}));

const Optiondescription = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(0.5, 0, 2, 1),
    opacity: 0.7,
}));

const StyledCreateButton = styled(CreateOrgBtn)(({ theme }) => ({
    margin: theme.spacing(1, 'auto', 2),
}));

const LanguagePicker = styled(ChooseLanguage)(({ theme }) => ({
    margin: theme.spacing(3, 'auto', 0),

    [`& .MuiTypography-subtitle1`]: {
        ...theme.typography.body1,
    },
}));

const KickStart: React.FC = () => {
    const { t } = useTranslation();
    const user = useSelector(userDataSelector);
    const accessRecords = useSelector(userAccessRecordsSelector);

    const hasTeamAccess = accessRecords.some(({ accessType }) =>
        [AccessTypes.team, AccessTypes.organization, AccessTypes.player].includes(accessType),
    );

    if (!user || hasTeamAccess) {
        return null;
    }

    return (
        <Container>
            <Typography variant="h4" sx={{ opacity: 0.8, mb: 3, mx: 'auto' }}>
                {t('kickStart.welcome')}
            </Typography>
            <Typography sx={{ mb: 2 }}>{t('kickStart.optionsTitle')}</Typography>
            <Typography>{t('kickStart.options.one.title')}</Typography>
            <Optiondescription variant="body2">
                {t('kickStart.options.one.description', { email: user.email })}
            </Optiondescription>
            <Typography>{t('kickStart.options.two.title')}</Typography>
            <Optiondescription variant="body2">
                {t('kickStart.options.two.description')}
            </Optiondescription>
            <StyledCreateButton />
            <VideoTutorial sx={{ my: 0.5 }} tutorialName={TutorialName.createTeam} />
            <Typography>{t('kickStart.options.three.title')}</Typography>
            <Optiondescription variant="body2">
                {t('kickStart.options.three.description', { email: user.email })}
            </Optiondescription>
            <VideoTutorial sx={{ my: 0.5 }} tutorialName={TutorialName.linkPlayers} />
            <LanguagePicker />
        </Container>
    );
};

export default KickStart;
