export default {
    SCOUTING_LIST_RECEIVED: 'ENTITIES/LISTS/SCOUTING_LIST_RECEIVED',
    SCOUTING_LISTS_REQUEST: 'ENTITIES/LISTS/SCOUTING_LISTS_REQUEST',
    SCOUTING_LISTS_RECIEVED: 'ENTITIES/LISTS/SCOUTING_LISTS_RECIEVED',
    SCOUTING_LIST_REQUEST: 'ENTITIES/LISTS/SCOUTING_LIST_REQUEST',
    SCOUTING_LIST_CREATE: 'ENTITIES/LISTS/SCOUTING_LIST_CREATE',
    SCOUTING_LIST_UPDATE: 'ENTITIES/LISTS/SCOUTING_LIST_UPDATE',
    REMOVE_SCOUTING_LIST: 'ENTITIES/LISTS/REMOVE_SCOUTING_LIST',
    DELETE_SCOUTING_LIST: 'ENTITIES/LISTS/DELETE_SCOUTING_LIST',
    UPDATE_SCOUTING_LIST_LOGO: 'ENTITIES/LISTS/UPDATE_SCOUTING_LIST_LOGO',
    USER_LISTS_UPDATES_FOR_PERIOD_LIST_REQUEST: 'ENTITIES/LISTS/USER_LISTS_UPDATES_FOR_PERIOD_LIST_REQUEST',
    LIST_UPDATES_RECEIVED: 'ENTITIES/LISTS/LIST_UPDATES_RECEIVED',
    LIST_UPDATES_REQUEST: 'ENTITIES/LISTS/LIST_UPDATES_REQUEST',
    SCOUTING_LIST_FOLDERS_REQUEST: 'ENTITIES/LISTS/SCOUTING_LIST_FOLDERS_REQUEST',
    SCOUTING_LIST_FOLDERS_RECEIVED: 'ENTITIES/LISTS/SCOUTING_LIST_FOLDERS_RECEIVED',
    SCOUTING_LIST_FOLDERS_UPDATE: 'ENTITIES/LISTS/SCOUTING_LIST_FOLDERS_UPDATE',
};
