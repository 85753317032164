import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import actionTypes from './challenges.actionTypes';
import Challenge from './Challenge';

type ChallengeState = {
    challengesHash: {
        [key: string]: Challenge;
    };
};

const initialState: ChallengeState = {
    challengesHash: {},
};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.CHALLENGE_DATA_RECEIVED: {
            return {
                ...state,
                challengesHash: {
                    ...state.challengesHash,
                    [action.challengeData.id]: {
                        ..._get(state, `challengesHash.${action.challengeData.id}`, {}),
                        ...action.challengeData,
                    },
                },
            };
        }
        case actionTypes.CHALLENGES_LIST_RECEIVED: {
            return {
                ...state,
                challengesHash: {
                    ...state.challengesHash,
                    ..._keyBy(action.challengesList, 'id'),
                },
            };
        }
        case actionTypes.REMOVE_CHALLENGE_FROM_LIST: {
            return {
                ...state,
                challengesHash: { ..._omit(state.challengesHash, [action.challengeId]) },
            };
        }
        default:
            return state;
    }
};
