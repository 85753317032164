import { createSelector } from 'reselect';
import { isAfter, isBefore } from 'date-fns';
import { RootState } from 'store';
import Observation, { ObservationAccessType } from 'entities/observations/Observation';
import { isBetween } from 'common/utils/dateTimeHelpers';
import { auth } from 'common/firebase';

const compareObservations = (observation1: Observation, observation2: Observation) =>
    isAfter(observation2.createDate, observation1.createDate) ? 1 : -1;

const observationsHashSelector = (state: RootState) => state.entities.observations.observationsHash;

const observationsListSelector = createSelector(observationsHashSelector, (observationsHash) => {
    const currentUserId = auth.currentUser?.uid;
    return Object.values(observationsHash).filter(({ author, accessType }) => {
        const isHidden =
            accessType === ObservationAccessType.private && author.userId !== currentUserId;

        return !isHidden;
    });
});

export const playerObservationsListSelector = createSelector(
    observationsListSelector,
    (_: any, { playerId }: { playerId: string }) => playerId,
    (observationsList, playerId) => {
        const playerObservationsList = observationsList.filter(
            (observation: Observation) => observation.playerId === playerId,
        );
        playerObservationsList.sort(compareObservations);

        return playerObservationsList as Observation[];
    },
);

export const teamObservationsListSelector = createSelector(
    observationsListSelector,
    (_: any, { teamId }: { teamId: string }) => teamId,
    (observationsList, teamId) => {
        const teamObservationsList = observationsList.filter(
            (observation: Observation) => observation.teamId === teamId,
        );
        teamObservationsList.sort(compareObservations);

        return teamObservationsList;
    },
);

export const playerEventObservationsListSelector = createSelector(
    playerObservationsListSelector,
    (_: any, { eventId }: { eventId: string }) => eventId,
    (observationsList, eventId) => {
        const playerObservationsList = observationsList.filter(
            (observation: Observation) => observation.target?.eventId === eventId,
        );

        return playerObservationsList;
    },
);

export const playerGoalObservationsListSelector = createSelector(
    playerObservationsListSelector,
    (_: any, { goalId }: { goalId: string }) => goalId,
    (observationsList, goalId) => {
        const playerObservationsList = observationsList.filter(
            (observation: Observation) => observation.target?.goalId === goalId,
        );

        return playerObservationsList;
    },
);

export const playerInjuryObservationsListSelector = createSelector(
    playerObservationsListSelector,
    (_: any, { injuryId }: { injuryId: string }) => injuryId,
    (observationsList, injuryId) => {
        const playerObservationsList = observationsList.filter(
            (observation: Observation) => observation.target?.injuryId === injuryId,
        );

        return playerObservationsList;
    },
);

export const playerPeriodObservationsListSelector = createSelector(
    playerObservationsListSelector,
    (_: any, { from }: { from: Date }) => from,
    (_: any, { to }: { to: Date }) => to,
    (observationsList, from, to) => {
        const playerObservationsList = observationsList.filter(
            (observation: Observation) =>
                isAfter(observation.createDate, from) && isBefore(observation.createDate, to),
        );

        return playerObservationsList;
    },
);

export const teamPeriodObservationsListSelector = createSelector(
    teamObservationsListSelector,
    (_: any, { from }: { from: Date }) => from,
    (_: any, { to }: { to: Date }) => to,
    (observationsList, from, to) => {
        const teamObservationsList = observationsList.filter(
            (observation: Observation) =>
                isAfter(observation.createDate, from) && isBefore(observation.createDate, to),
        );

        return teamObservationsList;
    },
);

export const observationDataSelector = createSelector(
    observationsHashSelector,
    (_: any, { observationId }: { observationId: string }) => observationId,
    (observationsHash, observationId) => {
        return observationsHash[observationId];
    },
);

export const observationsByIdsSelector = createSelector(
    observationsListSelector,
    (_: any, { observationsIds }: { observationsIds: string[] }) => observationsIds,
    (observationsList, observationsIds) => {
        return observationsList.filter((observation) => observationsIds.includes(observation.id));
    },
);

export const observationsByPlayersIdsSelector = createSelector(
    observationsListSelector,
    (_: any, { playersIds }: { playersIds: string[] }) => playersIds,
    (observationsList, playersIds) => {
        return observationsList.filter((observation) => playersIds.includes(observation.playerId));
    },
);

export const userObservationsForPeriodSelector = createSelector(
    observationsListSelector,
    (_: any, { teamId }: { teamId: string }) => teamId,
    (_: any, { organizationId }: { organizationId: string }) => organizationId,
    (_: any, { userId }: { userId: string }) => userId,
    (_: any, { fromDate }: { fromDate: Date }) => fromDate,
    (_: any, { toDate }: { toDate: Date }) => toDate,
    (observationsList, teamId, organizationId, userId, fromDate, toDate) => {
        return observationsList.filter(
            ({ createDate, author, ...rest }) =>
                isBetween(createDate, fromDate, toDate) &&
                author.userId === userId &&
                rest.organizationId === organizationId &&
                rest.teamId === teamId,
        );
    },
);
