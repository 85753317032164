export default {
    CONFIRM_PASSWORD_RESET: 'CALENDAR/CONFIRM_PASSWORD_RESET',
    SIGN_UP: 'UI/AUTH/SIGN_UP',
    VERIFY_EMAIL_REQUEST: 'UI/AUTH/VERIFY_EMAIL_REQUEST',
    SET_EMAIL_VERIFICATION_ERROR: 'UI/AUTH/SET_EMAIL_VERIFICATION_ERROR',
    START_EMAIL_VERIFICATION: 'UI/AUTH/START_EMAIL_VERIFICATION',
    SET_EMAIL_VERIFIED: 'UI/AUTH/SET_EMAIL_VERIFIED',
    UPDATE_USER_PASSWORD: 'UI/AUTH/UPDATE_USER_PASSWORD',
    HANDLE_AUTH_STATE_CHANGE: 'UI/AUTH/HANDLE_AUTH_STATE_CHANGE',
    SEND_VERIFICATION_EMAIL: 'UI/AUTH/SEND_VERIFICATION_EMAIL',
    SET_AUTH_STATE: 'UI/AUTH/SET_AUTH_STATE',
    SIGN_OUT: 'UI/AUTH/SIGN_OUT',
    RESET_USER_PASSWORD_REQUEST: 'UI/AUTH/RESET_USER_PASSWORD_REQUEST',
};
