import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AccountCircleIcon, HelpIcon } from 'common/icons';
import ChooseLanguage from 'features/profile/components/ChooseLanguage';
import { Button, Typography } from 'common/components';
import { DocsLinks } from 'common/components/DocsLink';
import CreateOrgBtn from './CreateOrgBtn';
import {
    Guide,
    MainBlock,
    MainIcon,
    MainText,
    Step,
    StepActions,
    StepContent,
    StepDescription,
    StepDocsLink,
    StepEmojiIcon,
    StepsList,
} from './CommonGuideComponents';
import { VideoTutorial } from 'common/components/VideoPlayBtn/VideoTutorial';
import { TutorialName } from 'common/components/VideoPlayBtn/videoTutorial.config';

const PREFIX = 'WelcomeGuide';

const classes = {
    handIcon: `${PREFIX}-handIcon`,
    nextSteps: `${PREFIX}-nextSteps`,
    accountIcon: `${PREFIX}-accountIcon`,
    teamIcon: `${PREFIX}-teamIcon`,
    chooseLang: `${PREFIX}-chooseLang`,
    helpIcon: `${PREFIX}-helpIcon`,
};

const Root = styled(Guide)(({ theme }) => ({
    [`& .${classes.handIcon}`]: {
        fontSize: 32,
    },

    [`& .${classes.nextSteps}`]: {
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.accountIcon}`]: {
        opacity: 0.7,
        width: 32,
        height: 32,
        marginRight: theme.spacing(2),
    },

    [`& .${classes.teamIcon}`]: {
        fontSize: 32,
        width: 64,
        flexShrink: 0,
        marginLeft: -32,
        marginRight: theme.spacing(2),
    },

    [`& .${classes.chooseLang}`]: {
        maxWidth: '100%',
        margin: theme.spacing(0, 'auto', 1),
    },

    [`& .${classes.helpIcon}`]: {
        color: theme.palette.primary.main,
        width: 32,
        height: 32,
        marginRight: theme.spacing(2),
    },
}));

type WelcomeGuideProps = {};

const WelcomeGuide: React.FC<WelcomeGuideProps> = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleChallengeClick = useCallback(() => {
        navigate('/challenges');
    }, [navigate]);

    return (
        <Root>
            <VideoTutorial sx={{ my: 2 }} tutorialName={TutorialName.profileSetup} />
            <MainBlock>
                <MainIcon>
                    <span className={classes.handIcon}>👋</span>😃
                </MainIcon>
                <MainText>{t('help.wellcomeGuide.greeting')}</MainText>
            </MainBlock>
            <Typography className={classes.nextSteps}>
                {t('help.wellcomeGuide.nextSteps')}
            </Typography>
            <StepsList>
                <Step>
                    <HelpIcon className={classes.helpIcon} />
                    <StepContent>
                        <StepDescription>{t('help.wellcomeGuide.helpDescription')}</StepDescription>
                    </StepContent>
                </Step>
                <Step>
                    <AccountCircleIcon className={classes.accountIcon} />
                    <StepContent>
                        <StepDescription>{t('help.wellcomeGuide.accountText')}</StepDescription>
                        <ChooseLanguage className={classes.chooseLang} />
                    </StepContent>
                </Step>
                <Step>
                    <StepEmojiIcon className={classes.teamIcon}>🤜🥊</StepEmojiIcon>
                    <StepContent>
                        <StepDescription>{t('help.wellcomeGuide.teamDescription')}</StepDescription>
                    </StepContent>
                </Step>
                <Step>
                    <StepEmojiIcon>🎪</StepEmojiIcon>
                    <StepContent>
                        <StepDescription>
                            {t('help.wellcomeGuide.createOrganizationText')}
                        </StepDescription>
                        <StepActions>
                            <CreateOrgBtn />
                            <StepDocsLink
                                page={DocsLinks.howToCreateTeam}
                                title={t('help.wellcomeGuide.createOrganizationDocs')}
                            />
                        </StepActions>
                    </StepContent>
                </Step>
                <Step>
                    <StepEmojiIcon>🏆</StepEmojiIcon>
                    <StepContent>
                        <StepDescription>{t('help.wellcomeGuide.challengeText')}</StepDescription>
                        <StepActions>
                            <Button
                                onClick={handleChallengeClick}
                                variant="contained"
                                color="primary"
                                size="small"
                            >
                                {t('help.wellcomeGuide.challengeBtn')}
                            </Button>
                            <StepDocsLink
                                page={DocsLinks.challenge}
                                title={t('help.wellcomeGuide.challengeDocs')}
                            />
                        </StepActions>
                    </StepContent>
                </Step>
            </StepsList>
        </Root>
    );
};

export default WelcomeGuide;
