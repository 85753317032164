import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Tshirt = (props: any) => (
    <SvgIcon viewBox="0 0 250 221" {...props}>
        <g>
            <title>T-Shirt</title>
            <path d="M58.5 99V209C58.5 215.627 63.8726 221 70.5 221H178.5C185.127 221 190.5 215.627 190.5 209V99L198.168 105.949C202.855 110.197 210.033 110.077 214.576 105.676L245.678 75.5462C250.517 70.8588 250.55 63.1076 245.751 58.3794L190.004 3.4521C187.759 1.24 184.733 0 181.581 0H161.612C158.346 0 155.237 1.3644 152.876 3.62068C132.748 22.8555 116.196 23.5814 94.0796 3.39997C91.7505 1.27466 88.7324 0 85.5794 0H67.9706C64.788 0 61.7357 1.26429 59.4853 3.51473L4.48528 58.5147C-0.20101 63.201 -0.201014 70.799 4.48528 75.4853L34.4218 105.422C38.946 109.946 46.2242 110.125 50.9653 105.828L58.5 99Z" />
        </g>
    </SvgIcon>
);

export default Tshirt;
