import actionTypes from './leagues.actionTypes';
import League from './Leagues';
import _keyBy from 'lodash/keyBy';

type LeaguesState = {
    leaguesHash: {
        [key: string]: League;
    };
};
const initialState = {
    leaguesHash: {},
};

const leagues = (state: LeaguesState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.LEAGUE_DATA_RECEIVED:
            return {
                ...state,
                leaguesHash: {
                    ...state.leaguesHash,
                    [action.league.id]: {
                        ...action.league,
                    },
                },
            };
        case actionTypes.LEAGUES_LIST_RECEIVED:
            return {
                ...state,
                leaguesHash: {
                    ...state.leaguesHash,
                    ..._keyBy(action.leaguesList, 'id'),
                },
            };
        default:
            return state;
    }
};

export default leagues;
