import { db, functions } from 'common/firebase';
import { ReportData, ReportParams, ReportStatus } from 'entities/reports/Report';
import {
    QueryConstraint,
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { handleDocumentSnapshot, handleCollectionSnapshot } from './utils';

export const createReport = ({
    organizationId,
    teamId,
    reportData,
}: Pick<ReportParams, 'organizationId' | 'teamId'> & {
    reportData: ReportData;
}) => {
    const reportsRef = collection(db, `organizations/${organizationId}/teams/${teamId}/reports`);

    return addDoc(reportsRef, reportData).then((doc) => doc.id);
};

export const fetchReportById = ({ organizationId, teamId, eventId }: ReportParams) => {
    const reportRef = doc(db, `organizations/${organizationId}/teams/${teamId}/reports/${eventId}`);

    return getDoc(reportRef).then(handleDocumentSnapshot);
};

export const fetchReportsList = ({
    teamId,
    organizationId,
}: Pick<ReportParams, 'organizationId' | 'teamId'>) => {
    const reportsRef = collection(db, `organizations/${organizationId}/teams/${teamId}/reports`);
    const q = query(reportsRef, where('status', '!=', ReportStatus.removed));

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchReportsByAuthor = ({
    teamId,
    organizationId,
    userId,
    count,
    fromDate,
    toDate,
}: {
    organizationId: string;
    teamId: string;
    userId: string;
    fromDate?: Date;
    toDate?: Date;
    count?: number;
}) => {
    const reportsRef = collection(db, `organizations/${organizationId}/teams/${teamId}/reports`);
    const queryParams: QueryConstraint[] = [
        where('creator.userId', '==', userId),
        where('status', '==', ReportStatus.new),
    ];

    if (count) {
        queryParams.push(orderBy('dateCreated', 'desc'));
        queryParams.push(limit(count));
    }

    if (fromDate) {
        queryParams.push(where('dateCreated', '>=', fromDate));
    }

    if (toDate) {
        queryParams.push(where('dateCreated', '<=', toDate));
    }

    const q = query(reportsRef, ...queryParams);

    return getDocs(q).then(handleCollectionSnapshot);
};

export const removeReport = ({ organizationId, teamId, eventId }: ReportParams) => {
    const reportRef = doc(db, `organizations/${organizationId}/teams/${teamId}/reports/${eventId}`);

    return updateDoc(reportRef, {
        status: ReportStatus.removed,
    });
};

export const updateReportData = ({
    reportData,
    organizationId,
    teamId,
    eventId,
}: ReportParams & {
    reportData: ReportData;
}) => {
    const reportRef = doc(db, `organizations/${organizationId}/teams/${teamId}/reports/${eventId}`);

    return updateDoc(reportRef, reportData);
};

const manualTagsSummaryFunction = httpsCallable(functions, 'run_summary_for_manual_tags');
export const createManualTagsSummary = ({ organizationId, teamId, eventId }: ReportParams) => {
    return manualTagsSummaryFunction({
        organizationId,
        teamId,
        eventId,
    });
};

const createReportByExternalLinkFunction = httpsCallable(functions, 'createReportByExternalLink');
export const createReportByExternalLink = ({
    organizationId,
    pageUrl,
    creator,
}: {
    organizationId: string;
    pageUrl: string;
    creator: {
        userId: string;
        userName: string;
    };
}) => {
    return createReportByExternalLinkFunction({
        organizationId,
        pageUrl,
        creator,
    }).then((res: any) => res.data.reportId);
};
