import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import actionTypes from './solutions.actionTypes';
import Solution from './Solution';

type SolutionState = {
    solutionsHash: {
        [key: string]: Solution;
    };
};

const initialState: SolutionState = {
    solutionsHash: {},
};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SOLUTION_DATA_RECEIVED: {
            return {
                ...state,
                solutionsHash: {
                    ...state.solutionsHash,
                    [action.solutionData.id]: {
                        ..._get(state, `solutionsHash.${action.solutionData.id}`, {}),
                        ...action.solutionData,
                    },
                },
            };
        }
        case actionTypes.SOLUTIONS_LIST_RECEIVED: {
            return {
                ...state,
                solutionsHash: {
                    ...state.solutionsHash,
                    ..._keyBy(action.solutionsList, 'id'),
                },
            };
        }
        default:
            return state;
    }
};
