import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SessionIcon = (props: any) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <g>
            <title>Session</title>
            <path d="M8.53846 8.08254L10.4615 2.38448C10.5897 1.90964 11.0769 0.959961 12 0.959961C12.9231 0.959961 13.4103 1.90964 13.5385 2.38448L15.4615 8.08254H8.53846Z" />
            <path d="M8.49069 10.5754C8.05992 10.5754 7.67756 10.8513 7.54172 11.2601L6.23077 15.2051L17.7692 15.2051L16.4583 11.2601C16.3224 10.8513 15.9401 10.5754 15.5093 10.5754H8.49069Z" />
            <path d="M18.7765 18.0032C18.6337 17.6064 18.2573 17.3419 17.8356 17.3419H6.16437C5.74268 17.3419 5.36631 17.6064 5.22348 18.0032L4.4359 20.1909H2.76923C2 20.1909 2 20.4758 2 21.6154C2 22.7551 2 23.04 2.76923 23.04H21.2308C22 23.04 22 22.7551 22 21.6154C22 20.4758 22 20.1909 21.2308 20.1909H19.5641L18.7765 18.0032Z" />
        </g>
    </SvgIcon>
);

export default SessionIcon;
