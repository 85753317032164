import { EpisodesType, Episode } from 'entities/episodes/Episodes';
import { TFunction } from 'i18next';
import reportTaggingActionTypes from './reportTaggingPage.actionTypes';
import { ExtendedEpisode } from './reportTaggingPage.selectors';

export type EpisodeInfo = {
    episodeType: EpisodesType;
    episodeData: Episode;
    episodeName: string;
};

export const saveEpisode = ({
    organizationId,
    teamId,
    eventId,
    period,
    episodesList,
    t,
}: {
    organizationId: string;
    teamId: string;
    eventId: string;
    period: number;
    episodesList: EpisodeInfo[];
    t: TFunction;
}) => ({
    type: reportTaggingActionTypes.SAVE_EPISODE,
    organizationId,
    teamId,
    eventId,
    period,
    episodesList,
    t,
});

export const deleteEpisode = ({
    episodeData,
    eventId,
    organizationId,
    teamId,
    period,
}: {
    episodeData: ExtendedEpisode;
    eventId: string;
    organizationId: string;
    teamId: string;
    period: number;
}) => ({
    type: reportTaggingActionTypes.DELETE_EPISODE,
    episodeData,
    eventId,
    organizationId,
    teamId,
    period,
});

export const exportPeriodEpisodes = ({ eventId, period }: { eventId: string; period: number }) => ({
    type: reportTaggingActionTypes.EXPORT_PERIOD_EPISODES,
    eventId,
    period,
});

export const importPeriodEpisodes = ({
    eventId,
    period,
    organizationId,
    teamId,
    episodesFile,
}: {
    eventId: string;
    period: number;
    organizationId: string;
    teamId: string;
    episodesFile: File;
}) => ({
    type: reportTaggingActionTypes.IMPORT_PERIOD_EPISODES,
    eventId,
    period,
    organizationId,
    teamId,
    episodesFile,
});

export const setLastAddedEpisodes = ({ episodesIds }: { episodesIds: string[] }) => ({
    type: reportTaggingActionTypes.SET_LAST_ADDED_EPISODES,
    episodesIds,
});
