import { Typography, Avatar, Box } from 'common/components';
import { styled } from '@mui/material/styles';
import { PersonIcon } from 'common/icons';
import Player from 'entities/players/Player';
import React from 'react';
import { EventPlayerInfo } from 'entities/events/Event';
import { useSelector } from 'react-redux';
import { usePlayerName } from 'entities/users/users.utils';
import { isHiddenPlayersModeSelector } from 'common/config/config.selectors';

const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    maxWidth: 230,
    overflow: 'hidden',
    height: 40,
    padding: theme.spacing(0.25),
}));

const NumberChip = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 16,
    height: 16,
    left: -2,
    bottom: -2,
    zIndex: 1,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius / 2,
    color: theme.palette.common.white,
}));

const PlayerAvatar = styled(Avatar)(({ theme }) => ({
    marginRight: theme.spacing(0.5),
    width: 32,
    height: 32,
    borderRadius: 2,
}));

type PlayerDataProps = {
    playerData?: Pick<
        Player | EventPlayerInfo,
        'firstName' | 'lastName' | 'enFirstName' | 'enLastName' | 'filePath' | 'playerNumber'
    >;
    photoSize?: number;
    className?: string;
    hideNumber?: boolean;
    sx?: any;
};

const PlayerData: React.FC<PlayerDataProps> = ({
    playerData,
    photoSize = 64,
    hideNumber,
    ...rest
}) => {
    const isHiddenPlayersMode = useSelector(isHiddenPlayersModeSelector);
    const { fullName, firstName, lastName } = usePlayerName(playerData);

    if (!playerData) {
        return null;
    }

    const { filePath, playerNumber } = playerData;
    const imgUrl = filePath ? `/cdn/image/width=${photoSize}${filePath}` : '';

    return (
        <Root {...rest} style={{ filter: isHiddenPlayersMode ? 'blur(4px)' : 'none' }}>
            <Box sx={{ position: 'relative' }}>
                <PlayerAvatar alt={fullName} src={imgUrl}>
                    {!imgUrl && <PersonIcon sx={{ borderRadius: 0.5 }} />}
                </PlayerAvatar>
                {!hideNumber && playerNumber && (
                    <NumberChip noWrap variant="caption">
                        {playerNumber}
                    </NumberChip>
                )}
            </Box>
            <Box sx={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                <Typography align="left" noWrap variant="body2" sx={{ fontWeight: 600 }}>
                    {lastName}
                </Typography>
                <Typography
                    align="left"
                    noWrap
                    variant="body2"
                    sx={{
                        opacity: lastName ? 0.5 : 1,
                        fontSize: lastName ? 10 : 12,
                        fontWeight: !lastName ? 600 : 500,
                    }}
                >
                    {firstName}
                </Typography>
            </Box>
        </Root>
    );
};

export default PlayerData;
