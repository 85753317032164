import Agent, { AgentData } from './Agents';
import actionTypes from './agents.actionTypes';

export const agentDataRequest = ({
    agentId,
    onSuccess,
}: {
    agentId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.AGENT_DATA_REQUEST,
    agentId,
    onSuccess,
});

export const agentDataUpdate = ({
    agentId,
    agentData,
    onSuccess,
    onFail,
}: {
    agentId: string;
    agentData: AgentData;
    onSuccess?: (agentData: AgentData) => void;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.AGENT_DATA_UPDATE,
    agentId,
    agentData,
    onSuccess,
    onFail,
});

export const agentDataRecieved = (agent: Agent) => ({
    type: actionTypes.AGENT_DATA_RECEIVED,
    agent,
});

export const agentsListRequest = (organizationId: string) => ({
    type: actionTypes.AGENTS_LIST_REQUEST,
    organizationId,
});

export const agentsWithoutAgencyListRequest = (organizationId: string) => ({
    type: actionTypes.AGENTS_WITHOUT_AGENCY_LIST_REQUEST,
    organizationId,
});

export const agentsListRecieved = (agentsList: Agent[]) => ({
    type: actionTypes.AGENTS_LIST_RECEIVED,
    agentsList,
});

export const agencyAgentsListRequest = (agencyId: string) => ({
    type: actionTypes.AGENCY_AGENTS_LIST_REQUEST,
    agencyId,
});

export const agentCreate = ({
    agentData,
    onSuccess,
    onFail,
}: {
    agentData: AgentData;
    onSuccess?: (agentId: string) => void;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.AGENT_CREATE,
    agentData,
    onSuccess,
    onFail,
});
