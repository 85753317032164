import { createSelector } from 'reselect';
import _get from 'lodash/get';
import Drill from 'entities/drills/Drill';
import { RootState } from 'store';

export const drillsHashSelector = (state: RootState): { [key: string]: Drill } =>
    state.entities.drills.drillsHash;

export const drillDataSelector = createSelector(
    drillsHashSelector,
    (_: any, { drillId }: { drillId: string }) => drillId,
    (drillsHash, drillId) => _get(drillsHash, drillId),
);

export const drillsListByIdsSelector = createSelector(
    drillsHashSelector,
    (_: any, { drillsIds }: { drillsIds: string[] }) => drillsIds,
    (drillsHash, drillsIds) => {
        return drillsIds
            .map((drillId) => {
                return drillsHash[drillId];
            })
            .filter((drill) => drill) as Drill[];
    },
);

export const drillsListSelector = createSelector(
    drillsHashSelector,
    (_: any, props: { methodologyId: string }) => props.methodologyId,
    (drillsHash, methodologyId) =>
        Object.values(drillsHash).filter((drill) => drill.methodologyId === methodologyId),
);

export const drillsWithVariationsSelector = createSelector(
    drillsHashSelector,
    (_: any, props: { targetDrillId: string }) => props.targetDrillId,
    (drillsHash, targetDrillId) => {
        const targetDrill = drillsHash[targetDrillId];

        if (!targetDrill) {
            return {
                targetDrill: null,
                masterDrill: null,
                variations: [],
            };
        }
        const masterDrill = targetDrill.masterDrillId
            ? drillsHash[targetDrill.masterDrillId]
            : targetDrill;

        if (!masterDrill) {
            return {
                targetDrill,
                masterDrill: null,
                variations: [],
            };
        }

        const variations = (masterDrill.variations || [])
            .map((variationDrillId) => drillsHash[variationDrillId])
            .filter((drill) => drill);

        return {
            targetDrill,
            masterDrill,
            variations,
        };
    },
);
