import { PlayerTask, PlayerTaskData, TeamTask, TeamTaskData } from './Task';
import actionTypes from './tasks.actionTypes';

export const teamTasksListRequest = ({
    organizationId,
    teamId,
}: {
    organizationId: string;
    teamId: string;
}) => ({
    type: actionTypes.TEAM_TASKS_LIST_REQUEST,
    organizationId,
    teamId,
});

export const teamTaskRequest = ({
    organizationId,
    teamId,
    taskId,
}: {
    organizationId: string;
    teamId: string;
    taskId: string;
}) => ({
    type: actionTypes.TEAM_TASK_REQUEST,
    organizationId,
    teamId,
    taskId,
});

export const updatePlayerTask = ({
    playerTaskData,
    playerId,
    taskId,
}: {
    playerId: string;
    taskId: string;
    playerTaskData: PlayerTaskData;
}) => ({
    type: actionTypes.UPDATE_PLAYER_TASK,
    playerId,
    taskId,
    playerTaskData,
});

export const playerTaskRequest = ({ playerId, taskId }: { playerId: string; taskId: string }) => ({
    type: actionTypes.PLAYER_TASK_REQUEST,
    playerId,
    taskId,
});

export const teamTasksListReceived = ({ tasksList }: { tasksList: TeamTask[] }) => ({
    type: actionTypes.TEAM_TASKS_LIST_RECEIVED,
    tasksList,
});

export const playerTasksListRequest = ({ playerId }: { playerId: string }) => ({
    type: actionTypes.PLAYER_TASKS_LIST_REQUEST,
    playerId,
});

export const playerTasksListReceived = ({ tasksList }: { tasksList: PlayerTask[] }) => ({
    type: actionTypes.PLAYER_TASKS_LIST_RECEIVED,
    tasksList,
});

export const createTeamTask = ({
    taskData,
    teamId,
    organizationId,
}: {
    taskData: TeamTaskData;
    teamId: string;
    organizationId: string;
}) => ({
    type: actionTypes.CREATE_TEAM_TASK,
    taskData,
    teamId,
    organizationId,
});

export const deleteTeamTask = ({
    taskId,
    teamId,
    organizationId,
}: {
    taskId: string;
    teamId: string;
    organizationId: string;
}) => ({
    type: actionTypes.DELETE_TEAM_TASK,
    taskId,
    teamId,
    organizationId,
});

export const removeTeamTask = ({ taskId }: { taskId: string }) => ({
    type: actionTypes.REMOVE_TEAM_TASK,
    taskId,
});
