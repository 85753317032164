import React, { useState, useEffect } from 'react';
import { sliderColors5 } from 'features/evaluation/evaluationPage.constants';
import { Typography, Slider, SliderProps, Box } from 'common/components';

type SliderFieldProps = SliderProps & {
    label?: string;
    className?: string;
    disabled?: boolean;
    sx?: any;
    colorFunction?: (value: number | number[]) => string;
    onChange: (value: number | number[]) => void;
};

export const defaultColorFunction = (value: number | number[]) => {
    const currentValue = Array.isArray(value) ? value[0] : value;
    const sliderColor = sliderColors5[Math.ceil(currentValue)];

    return sliderColor || '#000';
};

const SliderField = React.forwardRef<HTMLSpanElement, SliderFieldProps>(
    (
        {
            value,
            className,
            label,
            colorFunction = defaultColorFunction,
            onChange,
            sx,
            ...restProps
        },
        ref,
    ) => {
        const [sliderValue, setSliderValue] = useState<number | number[]>(0);

        useEffect(() => {
            if (value) {
                setSliderValue(value);
            }
        }, [value]);

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    flexShrink: 0,
                    width: label ? '100%' : 'fit-content',
                    ...sx,
                }}
            >
                {label && (
                    <Typography
                        sx={{ width: '100%', opacity: sliderValue === 0 ? 0.54 : 1 }}
                        variant="body2"
                        noWrap
                    >
                        <Box
                            component={Typography}
                            variant="caption"
                            sx={{
                                px: 0.5,
                                mr: 1,
                                width: 32,
                                display: 'inline-flex',
                                justifyContent: 'center',
                                borderRadius: 1,
                                backgroundColor: colorFunction(sliderValue),
                                color: '#fff',
                            }}
                        >
                            {sliderValue}
                        </Box>
                        {label}
                    </Typography>
                )}
                <Slider
                    ref={ref}
                    className={className}
                    aria-labelledby="discrete-slider"
                    color="primary"
                    style={{ color: colorFunction(sliderValue) }}
                    onChangeCommitted={(_, currentValue) => onChange(currentValue)}
                    onChange={(_, val) => setSliderValue(val)}
                    value={sliderValue}
                    {...restProps}
                />
            </Box>
        );
    },
);

export default SliderField;
