import React, { useMemo } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { addDays, format, isBefore, parse } from 'date-fns';
import _groupBy from 'lodash/groupBy';
import { SxProps } from '@mui/material';
import Game from 'entities/events/Game';
import TeamStats from 'entities/teams/TeamStats';
import { getGamesStats } from 'entities/events/games.utils';
import { BallIcon, GameIcon, RedCardIcon, YellowCardIcon } from 'common/icons';
import StatsSection from './StatsSection';
import GameStatsTable from './GameStatsTable';

const getGamesStatsByMonth = (games: Game[]) => {
    const gamesByMonth = _groupBy(games, (a: Game) => format(a.start, 'yyyy-MMM'));
    const gamesStatsByMonth = Object.entries(gamesByMonth).map((entry) => {
        const [monthStr, monthGames] = entry;

        return {
            month: parse(monthStr, 'yyyy-MMM', new Date()),
            stats: getGamesStats(monthGames),
        };
    });

    gamesStatsByMonth.sort((a, b) => (isBefore(a.month, b.month) ? 1 : -1));

    const total = getGamesStats(games);

    return {
        gamesStatsByMonth,
        total,
    };
};

const getTableColumns = (t: TFunction) => [
    { dataKey: 'month' },
    {
        tooltip: t('teamPage.teamStats.gamesInfo.title'),
        titleComponent: <BallIcon />,
        dataKey: 'gamesCount',
    },
    {
        dataKey: 'winGamesCount',
        tooltip: t('teamPage.teamStats.gamesInfo.winGames'),
        titleText: '👍',
    },
    {
        dataKey: 'lossGamesCount',
        tooltip: t('teamPage.teamStats.gamesInfo.lossGames'),
        titleText: '👎',
    },
    {
        dataKey: 'drawGamesCount',
        tooltip: t('teamPage.teamStats.gamesInfo.drawGames'),
        titleText: '🤝',
    },
    {
        dataKey: 'homeGamesCount',
        tooltip: t('gameForm.setUp.homeAway.home'),
        titleText: '🏠',
    },
    {
        dataKey: 'awayGamesCount',
        tooltip: t('gameForm.setUp.homeAway.away'),
        titleText: '🚌',
    },
    {
        dataKey: 'scored',
        tooltip: t('teamPage.teamStats.gamesInfo.scored'),
        titleText: '🟢',
    },
    {
        dataKey: 'conceded',
        tooltip: t('teamPage.teamStats.gamesInfo.conceded'),
        titleText: '🔴',
    },
    {
        dataKey: 'yellowCards',
        tooltip: t('teamPage.teamStats.gamesInfo.yellowCards'),
        titleComponent: <YellowCardIcon />,
    },
    {
        dataKey: 'redCards',
        tooltip: t('teamPage.teamStats.gamesInfo.redCards'),
        titleComponent: <RedCardIcon />,
    },
];

export type GamesInfoProps = {
    teamStats: TeamStats;
    sx?: SxProps;
};

const GamesInfo: React.FC<GamesInfoProps> = ({ teamStats, ...rest }) => {
    const { t } = useTranslation();
    const { gamesList } = teamStats;

    const { gamesStatsByMonth, total } = getGamesStatsByMonth(
        gamesList.map((record) => record.game),
    );

    const columns = useMemo(() => getTableColumns(t), [t]);

    const dataRecords = gamesStatsByMonth
        .map(({ month, stats }) => ({ month: format(addDays(month, 2), 'MMM yy'), ...stats }))
        .concat({
            month: t('teamPage.teamStats.gamesInfo.total'),
            ...total,
        });

    return (
        <StatsSection
            IconComponent={GameIcon}
            title={t('teamPage.teamStats.gamesInfo.title')}
            {...rest}
        >
            <GameStatsTable
                columns={columns}
                dataRecords={dataRecords}
                dataId="month"
                shouldHightlightLasRow
            />
            {/* <GamesStatsList teamStats={teamStats} /> */}
        </StatsSection>
    );
};

export default GamesInfo;
