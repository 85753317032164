import { db, functions } from 'common/firebase';
import { AccessTarget, AccessTypes } from 'entities/access/Access';
import { InvitationData } from 'entities/invitations/Invitation';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { handleDocumentSnapshot, handleCollectionSnapshot } from './utils';

const pathBuilders = {
    [AccessTypes.methodology]: ({ methodologyId }: AccessTarget) =>
        `methodologies/${methodologyId}/invitations`,
    [AccessTypes.team]: ({ organizationId, teamId }: AccessTarget) =>
        `organizations/${organizationId}/teams/${teamId}/invitations`,
    [AccessTypes.organization]: ({ organizationId }: AccessTarget) =>
        `organizations/${organizationId}/invitations`,
    [AccessTypes.player]: ({ playerId }: AccessTarget) => `players/${playerId}/invitations`,
    [AccessTypes.longlist]: ({ listId, organizationId }: AccessTarget) =>
        `organizations/${organizationId}/lists/${listId}/invitations`,
    [AccessTypes.shadowTeam]: ({ listId, organizationId }: AccessTarget) =>
        `organizations/${organizationId}/lists/${listId}/invitations`,
};

const buildBasePath = ({ accessType, target }: { accessType: AccessTypes; target: AccessTarget }) =>
    pathBuilders[accessType]({ ...target });

export const fetchInvitationsList = ({
    accessType,
    target,
}: {
    accessType: AccessTypes;
    target: AccessTarget;
}) => {
    const accessesRef = collection(db, buildBasePath({ accessType, target }));

    return getDocs(accessesRef).then(handleCollectionSnapshot);
};

export const fetchInvitationData = ({
    invitationId,
    accessType,
    target,
}: {
    invitationId: string;
    accessType: AccessTypes;
    target: AccessTarget;
}) => {
    const accessRef = doc(db, `${buildBasePath({ accessType, target })}/${invitationId}`);

    return getDoc(accessRef).then(handleDocumentSnapshot);
};

export const createInvitation = (invitationData: InvitationData) => {
    const accessesRef = collection(db, buildBasePath(invitationData));

    return addDoc(accessesRef, invitationData).then((doc) => doc.id);
};

export const deleteInvitation = ({
    invitationId,
    accessType,
    target,
}: {
    invitationId: string;
    accessType: AccessTypes;
    target: AccessTarget;
}) => {
    const accessRef = doc(db, `${buildBasePath({ accessType, target })}/${invitationId}`);

    return deleteDoc(accessRef);
};

export const updateInvitation = ({
    invitationId,
    invitationData,
}: {
    invitationId: string;
    invitationData: InvitationData;
}) => {
    const accessRef = doc(db, `${buildBasePath(invitationData)}/${invitationId}`);

    return updateDoc(accessRef, invitationData);
};

export const acceptInvitation = ({
    target,
    invitationId,
    accessType,
    targetName,
}: {
    invitationId: string;
    accessType: AccessTypes;
    target: AccessTarget;
    targetName: string;
}) =>
    httpsCallable(
        functions,
        'acceptInvitation',
    )({
        target,
        accessType,
        invitationId,
        targetName,
    });

export const acceptUserToJoinPlayer = ({ playerId }: { playerId: string }) =>
    httpsCallable(
        functions,
        'acceptUserToJoinPlayer',
    )({
        playerId,
    });

export const askUserToJoinPlayer = ({ email, playerId }: { email: string; playerId: string }) =>
    httpsCallable(
        functions,
        'askUserToJoinPlayer',
    )({
        playerId,
        email,
    });

export const unlinkUserFromPlayer = httpsCallable(functions, 'unlinkUserFromPlayer');

export const getUserInvitations = httpsCallable(functions, 'getUserInvitations');
