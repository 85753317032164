import React from 'react';
import { Box } from 'common/components';
import TeamStats from 'entities/teams/TeamStats';
import { styled, SxProps } from '@mui/material/styles';
import StatsInfo from './StatsInfo';
import GoalsInfo from './GoalsInfo';
import GamesInfo from './GamesInfo';
import SessionsInfo from './SessionsInfo';
import InjuriesInfo from './InjuriesInfo';

const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1),

    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
}));

const Column = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    width: '100%',
    overflow: 'hidden',
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(0.5),
        width: 'fit-content',
    },
}));

export type TeamStatsInfoProps = {
    teamStats: TeamStats;
    sx?: SxProps;
};

const TeamStatsInfo: React.FC<TeamStatsInfoProps> = ({ teamStats, ...rest }) => {
    return (
        <Root {...rest}>
            <Column>
                <StatsInfo teamStats={teamStats} />
                <GoalsInfo teamStats={teamStats} />
                <InjuriesInfo teamStats={teamStats} />
            </Column>
            <Column sx={{ flexGrow: 1 }}>
                <SessionsInfo sessionsStats={teamStats.sessionsTime} sx={{ mb: 1 }} />
                <GamesInfo teamStats={teamStats} />
            </Column>
        </Root>
    );
};

export default TeamStatsInfo;
