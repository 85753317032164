import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const GoalIcon = (props: any) => (
    <SvgIcon viewBox="0 0 20 20" {...props}>
        <g>
            <title>Goal</title>
            <path d="M13.7833 9.16681L10.8333 6.21681L12.0083 5.04181L13.775 6.80848L17.3083 3.27515L18.4833 4.45015L13.7833 9.16681ZM9.16667 5.83348H1.66667V7.50015H9.16667V5.83348ZM17.5 11.1751L16.325 10.0001L14.1667 12.1585L12.0083 10.0001L10.8333 11.1751L12.9917 13.3335L10.8333 15.4918L12.0083 16.6668L14.1667 14.5085L16.325 16.6668L17.5 15.4918L15.3417 13.3335L17.5 11.1751ZM9.16667 12.5001H1.66667V14.1668H9.16667V12.5001Z" />
        </g>
    </SvgIcon>
);

export default GoalIcon;
