import React from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/material';
import { SessionIcon } from 'common/icons';
import { Box, Typography } from 'common/components';
import { TeamSessionsStats } from 'entities/events/sessions.utils';
import PeriodSessionsLoadRadar from 'features/player/statistic/PeriodSessionsLoadRadar';
import StatsSection from './StatsSection';

export type SessionsInfoProps = {
    sessionsStats: TeamSessionsStats;
    sx?: SxProps;
};

const SessionsInfo: React.FC<SessionsInfoProps> = ({ sessionsStats, ...rest }) => {
    const { t } = useTranslation();

    if (!sessionsStats) {
        return null;
    }

    const { totalSessionsTime, timePerDrillType, generalCount, individualCount } = sessionsStats;
    const contentText = [
        `${t('teamPage.teamStats.sessionsInfo.groupSessions')}: ${generalCount}`,
        `${t('teamPage.teamStats.sessionsInfo.individualSessions')}: ${individualCount}`,
        `${t('teamPage.teamStats.sessionsInfo.totalMinutes')}: ${totalSessionsTime}`,
    ].join(' | ');

    return (
        <StatsSection
            IconComponent={SessionIcon}
            title={t('teamPage.teamStats.sessionsInfo.title')}
            {...rest}
        >
            <Box sx={{ width: '100%', height: 400 }}>
                <PeriodSessionsLoadRadar timePerDrillType={timePerDrillType} />
            </Box>
            <Typography variant="body2" sx={{ mx: 'auto' }}>
                {contentText}
            </Typography>
        </StatsSection>
    );
};

export default SessionsInfo;
