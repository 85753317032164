import { fork, call, all, takeLatest, put } from 'redux-saga/effects';
import * as reportsSagas from 'entities/reports/reports.sagas';

import * as snackbarAcrions from 'common/snackbar/snackbar.actions';
import { uploadFile } from 'common/resourses/files';
import reportSetupPageActionTypes from './reportSetupPage.actionTypes';
import * as reportSetupPageActions from './reportSetupPage.actions';

function* uploadReportFilesSaga({
    files,
    eventId,
    organizationId,
    teamId,
}: ReturnType<typeof reportSetupPageActions.uploadReportFiles>) {
    try {
        for (let i = 0; i < files.length; i += 1) {
            yield fork(uploadFile, {
                file: files[i],
                filePath: `organizations/${organizationId}/teams/${teamId}/reports/${eventId}/${files[i].name}`,
            });
        }
    } catch (err) {
        console.error('match error:', err);
    }
}

function* createReportSaga({
    reportData,
    organizationId,
    teamId,
    successMessage,
    failMessage,
    onSuccess,
}: ReturnType<typeof reportSetupPageActions.createReport>) {
    try {
        const reportId: string = yield call(reportsSagas.createReportSaga, {
            organizationId,
            teamId,
            reportData,
        });
        yield call(onSuccess, { reportId });
        yield put(
            snackbarAcrions.showSuccess({
                message: successMessage,
            }),
        );
    } catch (err) {
        yield put(
            snackbarAcrions.showError({
                message: failMessage,
            }),
        );
        console.error('Report error:', err);
    }
}

function* removeReportSaga({
    navigate,
    organizationId,
    teamId,
    eventId,
}: ReturnType<typeof reportSetupPageActions.removeReport>) {
    try {
        yield call(reportsSagas.removeReportsSaga, {
            type: '',
            organizationId,
            teamId,
            eventId,
            onSuccess: undefined,
        });
        yield call(navigate, `/organizations/${organizationId}/teams/${teamId}/reports`);
        yield put(
            snackbarAcrions.showSuccess({
                message: `Report has been removed`,
            }),
        );
    } catch (err) {
        console.error('Report error:', err);
    }
}

function* reportSetupPageSagas() {
    yield all([
        takeLatest(reportSetupPageActionTypes.CREATE_REPORT, createReportSaga),
        takeLatest(reportSetupPageActionTypes.UPLOAD_REPORT_FILES, uploadReportFilesSaga),
        takeLatest(reportSetupPageActionTypes.REMOVE_REPORT, removeReportSaga),
    ]);
}

export default reportSetupPageSagas;
