import { db, functions } from 'common/firebase';
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { mapFitnessDataFromDatesStrings } from 'entities/fitness-data/fitnessData.utils';
import {
    FitnessDataMetadata,
    TeamEventFitnessRecord,
    TeamEventFitnessRecordData,
} from 'entities/fitness-data/FitnessData';
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';
import { handleCollectionSnapshot, handleDocumentSnapshot } from './utils';

const getOrgFitnessDataMetadataDocPath = (organizationId: string) => {
    return `organizations/${organizationId}/metadata/fitnessData`;
};

export const getOrgFitnessDataMetadataDocRef = (organizationId: string) => {
    return doc(db, getOrgFitnessDataMetadataDocPath(organizationId));
};

export const getFitnessDataCollectionPath = (organizationId: string) => {
    return `organizations/${organizationId}/fitnessData`;
};

export const getFitnessDataDocPath = ({
    organizationId,
    fitnessDataId,
}: {
    organizationId: string;
    fitnessDataId: string;
}) => {
    return `${getFitnessDataCollectionPath(organizationId)}/${fitnessDataId}`;
};

export const uploadFitnessData = (fitnessData: TeamEventFitnessRecordData) => {
    const fitnessDataRef = collection(db, getFitnessDataCollectionPath(fitnessData.organizationId));
    return addDoc(fitnessDataRef, fitnessData).then((doc) => doc.id);
};

export const fitnessDataPlayersIdsUpdate = httpsCallable(
    functions,
    'fitnessDataPlayersIdsUpdateV2',
);

export const fetchFitnessDataByEventId = ({
    organizationId,
    eventId,
}: {
    organizationId: string;
    eventId: string;
}) => {
    const fitnessDataRef = getFitnessDataCollectionPath(organizationId);
    const q = query(collection(db, fitnessDataRef), where('eventId', '==', eventId));
    return getDocs(q)
        .then(handleCollectionSnapshot)
        .then((data) => data[0]);
};

export const updateFitnessData = ({
    organizationId,
    fitnessData,
}: {
    organizationId: string;
    fitnessData: TeamEventFitnessRecord;
}) => {
    const fitnessDataRef = doc(
        db,
        getFitnessDataDocPath({ organizationId, fitnessDataId: fitnessData.id }),
    );

    return updateDoc(fitnessDataRef, fitnessData);
};

export const deleteFitnessData = ({
    organizationId,
    fitnessDataId,
}: {
    organizationId: string;
    fitnessDataId: string;
}) => {
    const fitnessDataRef = doc(db, getFitnessDataDocPath({ organizationId, fitnessDataId }));

    return deleteDoc(fitnessDataRef);
};

export const fetchOrganizationFitnessDataMetadata = (organizationId: string) => {
    const orgFitnessDataMetadataRef = doc(db, getOrgFitnessDataMetadataDocPath(organizationId));
    return getDoc(orgFitnessDataMetadataRef).then(handleDocumentSnapshot);
};

export const updateOrganizationFitnessDataMetadata = ({
    organizationId,
    orgFitnessDataMetadata,
}: {
    organizationId: string;
    orgFitnessDataMetadata: FitnessDataMetadata;
}) => {
    const orgFitnessDataMetadataRef = doc(db, getOrgFitnessDataMetadataDocPath(organizationId));
    return updateDoc(orgFitnessDataMetadataRef, orgFitnessDataMetadata);
};

const getPlayerFitnessRecordsFunction = httpsCallable(functions, 'getPlayerFitnessRecordsV2');
export const fetchPlayerFitnessRecordsList = async ({
    organizationId,
    teamId,
    playerId,
    startDate,
    endDate,
}: {
    organizationId: string;
    playerId: string;
    teamId: string;
    startDate: Date;
    endDate: Date;
}) => {
    const { data } = (await getPlayerFitnessRecordsFunction({
        organizationId,
        playerId,
        teamId,
        startDate,
        endDate,
    })) as HttpsCallableResult<{ recordsList: TeamEventFitnessRecord[] }>;

    const recordsList = data.recordsList.map(mapFitnessDataFromDatesStrings);

    return recordsList;
};

const getTeamFitnessRecordsFunction = httpsCallable(functions, 'getTeamFitnessRecordsV2');
export const fetchTeamFitnessRecordsList = async ({
    organizationId,
    teamId,
    startDate,
    endDate,
}: {
    organizationId: string;
    teamId: string;
    startDate: Date;
    endDate: Date;
}) => {
    const { data } = (await getTeamFitnessRecordsFunction({
        organizationId,
        teamId,
        startDate,
        endDate,
    })) as HttpsCallableResult<{ recordsList: TeamEventFitnessRecord[] }>;

    const recordsList = data.recordsList.map(mapFitnessDataFromDatesStrings);

    return recordsList;
};
