import React from 'react';
import { ImageIcon } from 'common/icons';
import AvatarCircle from 'common/components/AvatarCircle';
import { useOrganization } from 'entities/organizations/organizations.hooks';

type OrgLogoProps = {
    organizationId: string;
    size?: number;
    sx?: any;
};

const OrgLogo: React.FC<OrgLogoProps> = ({ organizationId, size = 48, ...rest }) => {
    const organization = useOrganization(organizationId);

    if (!organization?.logoPath) {
        return null;
    }

    return (
        <AvatarCircle
            size={size}
            DefaultIconComponent={ImageIcon}
            filePath={organization?.logoPath}
            alt={organization?.name || 'Organization logo'}
            {...rest}
        />
    );
};

export default OrgLogo;
