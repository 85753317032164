import { db, functions } from 'common/firebase';
import { ChallengeData, ChallengeParams, ChallengeStatus } from 'entities/challenges/Challenge';
import { addDoc, collection, doc, getDoc, getDocs, query, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { handleDocumentSnapshot, handleCollectionSnapshot } from './utils';

export const createChallenge = ({ challengeData }: { challengeData: ChallengeData }) => {
    const challendesRef = collection(db, `challenges`);

    return addDoc(challendesRef, challengeData).then((doc) => doc.id);
};

export const fetchChallengeById = ({ challengeId }: ChallengeParams) => {
    const challengeRef = doc(db, `challenges/${challengeId}`);

    return getDoc(challengeRef).then(handleDocumentSnapshot);
};

export const fetchChallengesList = () => {
    const challendesRef = collection(db, `challenges`);
    const q = query(
        challendesRef,
        // where('status', '!=', ChallengeStatus.removed)
    );

    return getDocs(q).then(handleCollectionSnapshot);
};

export const removeChallenge = ({ challengeId }: ChallengeParams) => {
    const challengeRef = doc(db, `challenges/${challengeId}`);

    return updateDoc(challengeRef, {
        status: ChallengeStatus.removed,
    });
};

export const updateChallengeData = ({
    challengeData,
    challengeId,
}: ChallengeParams & {
    challengeData: ChallengeData;
}) => {
    const challengeRef = doc(db, `challenges/${challengeId}`);

    return updateDoc(challengeRef, challengeData);
};

const saveLeaderBoardFunction = httpsCallable(functions, 'saveLeaderBoard');
export const saveLeaderBoard = ({ challengeId }: { challengeId: string }) => {
    return saveLeaderBoardFunction({ challengeId });
};
