import _keyBy from 'lodash/keyBy';
import actionTypes from './medicalRecords.actionTypes';
import MedicalRecord from './MedicalRecords';

type MedicalRecordsState = {
    medicalRecordsHash: Record<string, MedicalRecord>;
};
const initialState = {
    medicalRecordsHash: {},
};

const medicalRecords = (state: MedicalRecordsState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.MEDICAL_RECORD_RECEIVED:
            return {
                ...state,
                medicalRecordsHash: {
                    ...state.medicalRecordsHash,
                    [action.medicalRecord.id]: {
                        ...action.medicalRecord,
                    },
                },
            };
        case actionTypes.MEDICAL_RECORDS_LIST_RECEIVED:
            return {
                ...state,
                medicalRecordsHash: {
                    ...state.medicalRecordsHash,
                    ..._keyBy(action.medicalRecordsList, 'id'),
                },
            };
        default:
            return state;
    }
};

export default medicalRecords;
