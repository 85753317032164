import { AppConfig, LocalConfig, defaultLocalConfig } from './Config';
import configActionTypes from './config.actionTypes';

type State = {
    isOffline: boolean;
    isHiddenPlayersMode: boolean;
    appConfig: AppConfig | null;
    localConfig: LocalConfig;
};

const initialState = {
    isOffline: !navigator.onLine,
    isHiddenPlayersMode: false,
    appConfig: null,
    localConfig: defaultLocalConfig,
};

const config = (state: State = initialState, action: any) => {
    switch (action.type) {
        case configActionTypes.SET_OFFLINE:
            return {
                ...state,
                isOffline: true,
            };
        case configActionTypes.SET_ONLINE:
            return {
                ...state,
                isOffline: false,
            };
        case configActionTypes.TOGGLE_PLAYERS_VISIBILITY:
            return {
                ...state,
                isHiddenPlayersMode: action.isHidden,
            };
        case configActionTypes.APP_CONFIG_RECEIVED:
            return {
                ...state,
                appConfig: action.appConfig,
            };
        case configActionTypes.SET_LOCAL_CONFIG:
            const adjustedConfig = { ...defaultLocalConfig, ...action.localConfig };
            return {
                ...state,
                localConfig: adjustedConfig,
            };
        default:
            return state;
    }
};

export default config;
