import User from './User';
import usersActionTypes from './users.actionTypes';

export type UsersState = {
    userData: User | null;
};

const initialState: UsersState = {
    userData: null,
};

const users = (state: UsersState = initialState, action: any) => {
    switch (action.type) {
        case usersActionTypes.USER_DATA_RECEIVED:
            return {
                ...state,
                userData: action.userData,
            };
        case usersActionTypes.CLEAR_USER_DATA:
            return {
                ...state,
                userData: null,
            };
        default:
            return state;
    }
};

export default users;
