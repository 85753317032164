import { TFunction } from 'i18next';

const errorCodes = {
    'auth/argument-error': 'auth.errors.argumentError',
    'auth/wrong-password': 'auth.errors.wrongPassword',
    'auth/user-not-found': 'auth.errors.userNotFound',
    'auth/email-already-in-use': 'auth.errors.emailAlreadyInUse',
    'auth/too-many-requests': 'auth.errors.tooManyRequests',
    'auth/requires-recent-login': 'auth.errors.reloginRequired',
};

const errorCodesMap = new Map(Object.entries(errorCodes));

export default ({ code, message }: { code: string; message?: string }, t: TFunction): string => {
    const translationCode = errorCodesMap.get(code) as string | undefined;

    if (!translationCode) {
        return message || t('auth.errors.defaultError');
    }

    return t(translationCode);
};
