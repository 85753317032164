import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleButtons, Typography } from 'common/components';
import { styled } from '@mui/material/styles';
import { Language } from 'entities/users/User';
import { useUserLang } from './language.hooks';

const Root = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const StylesToggleButtons = styled(ToggleButtons)(() => ({
    [`.MuiToggleButton-root`]: {
        width: '64px !important',
        minWidth: '64px !important',
    },
}));

type ChooseLanguageProps = {
    className?: string;
    sx?: any;
};

const ChooseLanguage: React.FC<ChooseLanguageProps> = ({ className, ...rest }) => {
    const { t } = useTranslation();
    const { currentLang, onChangeLang } = useUserLang();

    const options = Object.values(Language).map((lang) => ({
        value: lang,
        label: t(`profile.languages.${lang}`),
    }));

    return (
        <Root className={className} {...rest}>
            <Typography sx={{ mb: 0.5 }} variant="subtitle1" align="center">
                {t('profile.chooseLang')}
            </Typography>
            <StylesToggleButtons onChange={onChangeLang} value={currentLang} options={options} />
        </Root>
    );
};

export default ChooseLanguage;
