import { EpisodesType } from 'entities/episodes/Episodes';
import { ReportParams } from 'entities/reports/Report';

export type ChallengeParams = {
    challengeId: string;
};

export enum ChallengeStatus {
    active = 'active',
    finished = 'finished',
    removed = 'removed',
}

export type SolutionRecord = {
    userName: string;
    userId: string;
    score: number;
};

export type ChallengeData = {
    name: string;
    creatorId: string;
    teams: {
        home: {
            name: string;
        };
        guest: {
            name: string;
        };
    };
    isPrivate: boolean;
    standardReportParams: ReportParams | null;
    matchDate: Date;
    startDate: Date;
    finishDate: Date;
    filesLink: string;
    status: ChallengeStatus;
    eligibleManualTags: EpisodesType[];
    showResultsFor: EpisodesType[];
    solutions: SolutionRecord[] | null;
};

type Challenge = ChallengeData & {
    id: string;
};

export default Challenge;
