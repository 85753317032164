export default {
    CREATE_EVENT_REQUEST: 'ENTITIES/EVENTS/CREATE_EVENT_REQUEST',
    UPDATE_EVENT_REQUEST: 'ENTITIES/EVENTS/UPDATE_EVENT_REQUEST',
    EVENTS_LIST_RECEIVED: 'ENTITIES/EVENTS/EVENTS_LIST_RECEIVED',
    EVENTS_LIST_REQUEST: 'ENTITIES/EVENTS/EVENTS_LIST_REQUEST',
    PLANIFICATION_EVENTS_LIST_REQUEST: 'ENTITIES/EVENTS/PLANIFICATION_EVENTS_LIST_REQUEST',
    EVENT_DATA_REQUEST: 'ENTITIES/EVENTS/EVENT_DATA_REQUEST',
    EVENT_DATA_RECEIVED: 'ENTITIES/EVENTS/EVENT_DATA_RECEIVED',
    REMOVE_EVENTS_FROM_LIST: 'ENTITIES/EVENTS/REMOVE_EVENTS_FROM_LIST',
    REMOVE_EVENTS_REQUEST: 'ENTITIES/EVENTS/REMOVE_EVENTS_REQUEST',
    EVENT_UPDATES_LIST_RECEIVED: 'ENTITIES/EVENTS/EVENT_UPDATES_LIST_RECEIVED',
    EVENT_UPDATES_LIST_REQUEST: 'ENTITIES/EVENTS/EVENT_UPDATES_LIST_REQUEST',
};
