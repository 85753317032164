import React from 'react';
import _groupBy from 'lodash/groupBy';
import { SxProps } from '@mui/material';
import { Table, TableBody, TableHead, TableRow, Tooltip } from 'common/components';
import {
    StyledTableCell,
    StyledTableContainer,
    StyledTableRow,
} from 'features/player/statistic/statsTableComponents';

export type TableColumn = {
    tooltip?: string;
    titleText?: string;
    titleComponent?: React.ReactElement;
    dataKey: string;
};

export type GameStatsTableProps = {
    columns: TableColumn[];
    dataRecords: Record<string, string | number>[];
    dataId: string;
    sx?: SxProps;
    shouldHightlightLasRow?: boolean;
};

const GameStatsTable: React.FC<GameStatsTableProps> = ({
    columns,
    dataRecords,
    dataId,
    shouldHightlightLasRow,
    ...rest
}) => {
    return (
        <StyledTableContainer {...rest}>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map(({ tooltip, titleText, titleComponent, dataKey }) => (
                            <Tooltip key={dataKey} title={tooltip}>
                                <StyledTableCell align="center" sx={{ fontSize: 20 }}>
                                    {titleText}
                                    {titleComponent}
                                </StyledTableCell>
                            </Tooltip>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[...dataRecords].map((record, index) => (
                        <StyledTableRow key={record[dataId]}>
                            {columns.map(({ dataKey }) => (
                                <StyledTableCell
                                    key={dataKey}
                                    align="center"
                                    sx={{
                                        fontWeight:
                                            shouldHightlightLasRow && index === dataRecords.length - 1
                                                ? 600
                                                : 400,
                                    }}
                                >
                                    {record[dataKey]}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

export default GameStatsTable;
