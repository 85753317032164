import { ReportParams } from 'entities/reports/Report';
import { MarkersDataTarget } from './Marker';
import actionTypes from './markers.actionTypes';

export const markersDataReceived = ({
    markersData,
    eventId,
    target,
    periodNumber,
}: {
    markersData: string;
    eventId: string;
    target: MarkersDataTarget;
    periodNumber: number;
}) => ({
    type: actionTypes.MARKERS_DATA_RECEIVED,
    markersData,
    eventId,
    periodNumber,
    target,
});

export const markersDataRequest = ({
    organizationId,
    teamId,
    eventId,
    periodNumber,
    target,
}: ReportParams & {
    eventId: string;
    periodNumber: number;
    target: MarkersDataTarget;
}) => ({
    type: actionTypes.MARKERS_DATA_REQUEST,
    organizationId,
    teamId,
    eventId,
    periodNumber,
    target,
});
