import { doc, getDoc, updateDoc } from 'firebase/firestore';
import User, { UserData } from 'entities/users/User';
import { db, functions } from 'common/firebase';
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { handleDocumentSnapshot } from './utils';

export const fetchUser = ({ userId }: { userId: string }) => {
    const userRef = doc(db, `users/${userId}`);

    return getDoc(userRef).then(handleDocumentSnapshot);
};

export const updateUser = ({ userId, userData }: { userId: string; userData: UserData }) => {
    const userRef = doc(db, `users/${userId}`);

    return updateDoc(userRef, userData);
};

const fetchOrgUsersFunction = httpsCallable(functions, 'fetchOrgUsers');
export const fetchOrgUsers = (organizationId: string) => {
    return fetchOrgUsersFunction({ organizationId }).then((res: HttpsCallableResult<any>) => {
        return res.data.usersList as Pick<User, 'id' | 'name'>[];
    });
};

export const deleteAccount = httpsCallable(functions, 'deleteAccount');
