import React from 'react';
import { Box, Typography } from 'common/components';

export type SectionTitleProps = {
    IconComponent: React.ReactElement;
    children: React.ReactElement | string;
};

const SectionTitle: React.FC<SectionTitleProps> = ({ IconComponent, children }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            {IconComponent}
            <Typography sx={{ ml: 1 }} variant="subtitle2">
                {children}
            </Typography>
        </Box>
    );
};

export default SectionTitle;
