import { call, put, takeLatest, all, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import drillsActionTypes from './drills.actionTypes';
import * as drillsActions from './drills.actions';
import Drill from './Drill';
import {
    createDrill,
    deleteDrill,
    fetchDrillById,
    fetchDrills,
    updateDrill,
} from 'gateways/drillsGateway';
import { createEmptyDrill } from './drills.utils';

export function* createDrillSaga({
    drillData,
    onSuccess,
    onFail,
}: ReturnType<typeof drillsActions.drillCreateRequest>) {
    try {
        const drillId: string = yield call(createDrill, {
            drillData,
        });
        onSuccess(drillId);
    } catch (e: any) {
        yield call(onFail);
        console.error('Failed to create drill', e);
    }
}

export function* fetchDrillSaga({
    drillId,
    methodologyId,
}: ReturnType<typeof drillsActions.requestDrillData>) {
    try {
        const drillData: Drill = yield call(fetchDrillById, {
            drillId,
            methodologyId,
        });
        yield put(drillsActions.drillDataReceived({ drillData }));
    } catch (e: any) {
        if (e.code === 'permission-denied') {
            yield call(toastr.error, 'No access', 'You have no access to this drill');
        }
    }
}

export function* fetchDrillWithVariationsSaga({
    drillId,
    methodologyId,
}: ReturnType<typeof drillsActions.requestDrillWithVariationsData>) {
    try {
        const targetDrill: Drill = yield call(fetchDrillById, {
            drillId,
            methodologyId,
        });
        yield put(drillsActions.drillDataReceived({ drillData: targetDrill }));

        let masterDrill: Drill;

        if (targetDrill.masterDrillId !== null) {
            masterDrill = yield call(fetchDrillById, {
                drillId: targetDrill.masterDrillId,
                methodologyId,
            });
            yield put(drillsActions.drillDataReceived({ drillData: masterDrill }));
        } else {
            masterDrill = targetDrill;
        }

        const { variations = [] } = masterDrill;
        const variationsRequests = variations.map((variationDrillId) =>
            put(drillsActions.requestDrillData({ drillId: variationDrillId, methodologyId })),
        );
        yield all(variationsRequests);
    } catch (e: any) {
        if (e.code === 'permission-denied') {
            yield call(toastr.error, 'No access', 'You have no access to this drill');
        }
    }
}

export function* fetchDrillsListSaga({
    methodologyId,
}: ReturnType<typeof drillsActions.drillsListRequest>) {
    try {
        const drillsList: Drill[] = yield call(fetchDrills, {
            methodologyId,
        });
        yield put(drillsActions.drillsListReceived({ drillsList }));
    } catch (e: any) {
        console.error(e);
        if (e.code === 'permission-denied') {
            yield call(
                toastr.error,
                'No access',
                'You have no access to the drill of this methodology',
            );
        }
    }
}

export function* updateDrillSaga({
    drillId,
    drillData,
    onFail,
}: ReturnType<typeof drillsActions.drillUpdateRequest>) {
    try {
        yield call(updateDrill, {
            drillId,
            drillData,
        });
        yield put(
            drillsActions.requestDrillData({ drillId, methodologyId: drillData.methodologyId }),
        );
    } catch (e) {
        console.error(`Failed to update drill ${drillId}`, e);
        yield call(onFail);
    }
}

export function* addDrillVariationSaga({
    masterDrill,
    methodologyId,
    onSuccess,
}: ReturnType<typeof drillsActions.addDrillVariation>) {
    try {
        const { id, variations, ...rest } = masterDrill;
        const newDrill = {
            ...createEmptyDrill(methodologyId),
            masterDrillId: id,
            name: `[var] ${masterDrill.name || '-'}`,
            drillType: masterDrill.drillType,
        };
        const newVariationId: string = yield call(createDrill, {
            drillData: newDrill,
        });
        const newVariations = masterDrill.variations.concat(newVariationId);
        const updatedMasterDrillData = {
            ...rest,
            variations: newVariations,
        };
        yield call(updateDrill, {
            drillId: id,
            drillData: updatedMasterDrillData,
        });
        yield put(drillsActions.requestDrillData({ drillId: id, methodologyId: methodologyId }));
        yield call(onSuccess, newVariationId);
    } catch (e: any) {
        if (e.code === 'permission-denied') {
            yield call(toastr.error, 'No access', 'You have no access to this drill');
        }
    }
}

export function* deleteDrillSaga({
    drillId,
    methodologyId,
    onSuccess,
    onFail,
}: ReturnType<typeof drillsActions.drillDeleteRequest>) {
    try {
        yield call(deleteDrill, { drillId, methodologyId });
        yield put(drillsActions.removeDrill({ drillId }));
        yield put(drillsActions.drillsListRequest({ methodologyId }));
        yield call(onSuccess);
    } catch (e) {
        yield call(onFail);
        console.error(`Failed to delete drill ${drillId}`, e);
    }
}

export default function* drillsSaga() {
    yield all([
        takeLatest(drillsActionTypes.DRILL_UPDATE_REQUEST, updateDrillSaga),
        takeLatest(drillsActionTypes.DRILL_DELETE_REQUEST, deleteDrillSaga),
        takeLatest(drillsActionTypes.DRILL_CREATE_REQUEST, createDrillSaga),
        takeEvery(drillsActionTypes.DRILL_DATA_REQUEST, fetchDrillSaga),
        takeLatest(drillsActionTypes.DRILL_WITH_VARIATIONS_REQUEST, fetchDrillWithVariationsSaga),
        takeLatest(drillsActionTypes.DRILLS_LIST_REQUEST, fetchDrillsListSaga),
        takeLatest(drillsActionTypes.ADD_DRILL_VARIATION, addDrillVariationSaga),
    ]);
}
