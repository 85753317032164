import { useCallback, useEffect } from 'react';
import { RootState } from 'store';
import _cloneDeep from 'lodash/cloneDeep';
import { useDispatch, useSelector } from 'react-redux';
import Team from './Team';
import { requestPlayersLabels, teamDataRequest, updatePlayersLabels } from './teams.actions';
import { playersLabelsSelector, teamDataSelector } from './teams.selectors';
import { PlayersLabelsMetadata } from './TeamPlayersLabels';
import { togglePlayerLabel } from './teams.utils';

export const useTeam = (organizationId: string, teamId: string) => {
    const dispatch = useDispatch();
    const team = useSelector((state: RootState) => teamDataSelector(state, { teamId })) as
        | Team
        | undefined;

    useEffect(() => {
        if (!team) {
            dispatch(teamDataRequest({ organizationId, teamId }));
        }
    }, [team]);

    return team;
};

const defaultPlayersLabelsMetadata: PlayersLabelsMetadata = {
    playersLabelsList: [],
    playersMapping: {},
};

export const usePlayersLabelsMetadata = (organizationId: string, teamId: string) => {
    const dispatch = useDispatch();
    const playersLabelsMetadata =
        useSelector((state: RootState) => playersLabelsSelector(state, { teamId })) ||
        (defaultPlayersLabelsMetadata as PlayersLabelsMetadata);

    useEffect(() => {
        dispatch(requestPlayersLabels({ teamId, organizationId }));
    }, [teamId, organizationId]);

    const handleToggleLabel = useCallback(
        (labelId: string, playerId: string) => {
            const updatedLabelsIds = togglePlayerLabel(playersLabelsMetadata, playerId, labelId);
            const updatedLabelsMetadata = _cloneDeep(playersLabelsMetadata);
            Object.assign(updatedLabelsMetadata.playersMapping, {
                [playerId]: { labels: updatedLabelsIds },
            });

            dispatch(
                updatePlayersLabels({
                    organizationId,
                    teamId,
                    playersLabelsMetadata: updatedLabelsMetadata,
                }),
            );
        },
        [playersLabelsMetadata, organizationId, teamId, dispatch],
    );

    return { playersLabelsMetadata, onTogglePlayerLabel: handleToggleLabel };
};

export const usePlayerLabels = ({
    organizationId,
    teamId,
    playerId,
}: {
    organizationId: string;
    teamId: string;
    playerId: string;
}) => {
    const { playersLabelsMetadata } = usePlayersLabelsMetadata(organizationId, teamId);
    const { playersLabelsList, playersMapping } = playersLabelsMetadata;
    const labelsMap = Object.fromEntries(playersLabelsList.map((label) => [label.id, label]));
    const playerLabels = (playersMapping[playerId]?.labels || [])
        .map((label) => labelsMap[label])
        .filter(Boolean);

    return playerLabels;
};
