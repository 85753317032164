import { mapEventFromDatesStrings } from 'entities/events/events.utils';
import Game from 'entities/events/Game';
// import { mapGoalFromDatesStrings } from 'entities/goals/goals.utils';
// import { mapObservationFromDatesStrings } from 'entities/observations/observations.utils';
// import { mapInjuryFromDatesString, PlayerStats } from 'entities/players/players.utils';
import Team from 'entities/teams/Team';
import TeamStats, {
    // GoalWithPlayer,
    // ObservationWithPlayer,
    GameInfo,
} from 'entities/teams/TeamStats';

// const mapGoalDates = (records: GoalWithPlayer[]) => {
//     return records.map(({ goal, player }) => {
//         return {
//             goal: mapGoalFromDatesStrings(goal),
//             player,
//         };
//     });
// };

// const mapObservations = (records: ObservationWithPlayer[]): ObservationWithPlayer[] => {
//     return records.map(({ player, observation }) => {
//         return {
//             player,
//             observation: mapObservationFromDatesStrings(observation),
//         };
//     });
// };

const mapGames = (records: GameInfo[]): GameInfo[] => {
    return records.map(({ game, evaluation }) => {
        return {
            evaluation,
            game: mapEventFromDatesStrings(game) as Game,
        };
    });
};

// const mapPlayersStats = (records: PlayerStats[]): PlayerStats[] => {
//     return records.map((record) => {
//         return {
//             ...record,
//             newGoals: record.newGoals.map(mapGoalFromDatesStrings),
//             activeGoals: record.activeGoals.map(mapGoalFromDatesStrings),
//             newInjuries: record.newInjuries.map(mapInjuryFromDatesString),
//             healedInjuries: record.healedInjuries.map(mapInjuryFromDatesString),
//             activeInjuries: record.activeInjuries.map(mapInjuryFromDatesString),
//         };
//     });
// };

export const parseTeamStatsResponse = (data: TeamStats) => {
    const result = { ...data };
    result.start = new Date(data.start);
    result.end = new Date(data.end);
    /* Goals */
    // result.goals.activeGoals = mapGoalDates(data.goals.activeGoals);
    // result.goals.expiredGoals = mapGoalDates(data.goals.expiredGoals);
    // result.goals.created = mapGoalDates(data.goals.created);
    // result.goals.finished = mapGoalDates(data.goals.finished);
    /* Observations */
    // result.observations = mapObservations(data.observations);
    /* Games */
    result.gamesList = mapGames(data.gamesList);
    /* Players stats */
    // result.playersStats = mapPlayersStats(data.playersStats);

    return result;
};

export const parseOgrTeamsListResponse = (data: Team[]) => {
    const teamsList = [...data];
    return teamsList.map(({ deletedDate, createDate, ...rest }) => ({
        ...rest,
        deletedDate: deletedDate ? new Date(deletedDate) : null,
        createDate: new Date(createDate),
    }));
};
