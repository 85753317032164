import { RootState } from 'store';
import Notification from 'entities/notifications/Notification';
import { createSelector } from 'reselect';
import { isAfter } from 'date-fns';
import { userDataSelector } from 'entities/users/users.selectors';

const compareNotifications = (notification1: Notification, notification2: Notification) =>
    isAfter(notification2.date, notification1.date) ? 1 : -1;

const notificationsHashSelector = (state: RootState) =>
    state.entities.notifications.notificationsHash;

const notificationsListSelector = createSelector(notificationsHashSelector, (notificationsHash) => {
    return Object.values(notificationsHash);
});

export const userNotificationsListSelector = createSelector(
    notificationsListSelector,
    userDataSelector,
    (notificationsList, user) => {
        if (!user) {
            return []
        }

        const userNotificationsList = notificationsList.filter(
            (notification: Notification) => notification.userId === user.id,
        );
        userNotificationsList.sort(compareNotifications);

        return userNotificationsList;
    },
);

export const notificationDataSelector = createSelector(
    notificationsHashSelector,
    (_: any, { notificationId }: { notificationId: string }) => notificationId,
    (notificationsHash, notificationId) => {
        return notificationsHash[notificationId];
    },
);
