export default {
    REPORTS_LIST_RECEIVED: 'ENTITIES/REPORTS/REPORTS_LIST_RECEIVED',
    REPORTS_LIST_REQUEST: 'ENTITIES/REPORTS/REPORTS_LIST_REQUEST',
    REPORTS_BY_AUTHOR_REQUEST: 'ENTITIES/REPORTS/REPORTS_BY_AUTHOR_REQUEST',
    REPORT_DATA_RECEIVED: 'ENTITIES/REPORTS/REPORT_DATA_RECEIVED',
    REPORT_DATA_REQUEST: 'ENTITIES/REPORTS/REPORT_DATA_REQUEST',
    REPORT_UPDATE_REQUEST: 'ENTITIES/REPORTS/REPORT_UPDATE_REQUEST',
    REMOVE_REPORT_FROM_LIST: 'ENTITIES/REPORTS/REMOVE_REPORT_FROM_LIST',
    REMOVE_REPORT: 'ENTITIES/REPORTS/REMOVE_REPORT',
};
