import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Event, { EventTypes } from 'entities/events/Event';
import Session from 'entities/events/Session';
import Game from 'entities/events/Game';
import Evaluation from 'entities/evaluations/Evaluation';
import EventsList from './EventsList';
import { MIN_EVALUATIONS_COUNT } from './assistant.constants';
import AssistantBase from './AssistantBase';

export const getNoEveluationsEventsState = (eventsList: Event[], evaluationsList: Evaluation[]) => {
    const gamesSessionsList = eventsList.filter(({ eventType }) =>
        [EventTypes.session, EventTypes.game].includes(eventType),
    ) as (Session | Game)[];

    if (gamesSessionsList.length === 0) {
        return { gamesSessionsList: [], noEvaluationsEvents: [], isVisible: false };
    }

    const noEvaluationsEvents = gamesSessionsList.filter((event) => {
        const eventEvaluations = evaluationsList.filter(
            (evaluation) => evaluation.eventId === event.id,
        );
        return eventEvaluations.length < MIN_EVALUATIONS_COUNT;
    });

    return { gamesSessionsList, noEvaluationsEvents, isVisible: noEvaluationsEvents.length !== 0 };
};

type NoEvaluationsProps = {
    teamId: string;
    organizationId: string;
    noEvaluationsEvents: Event[];
    isVisible: boolean;
    onClose: VoidFunction;
};

const NoEvaluations: React.FC<NoEvaluationsProps> = ({
    organizationId,
    teamId,
    isVisible,
    noEvaluationsEvents,
    onClose,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onSelectEvent = useCallback(
        (event: Event) => {
            onClose();
            const eventName = event.eventType === EventTypes.game ? 'games' : 'sessions';
            navigate(
                `/organizations/${organizationId}/teams/${teamId}/planning/${eventName}/${event.id}/evaluation`,
            );
        },
        [teamId, organizationId, navigate],
    );

    if (!isVisible) {
        return null;
    }

    return (
        <AssistantBase
            description={t('assistant.noEvaluations.description')}
            content={<EventsList onSelect={onSelectEvent} eventsList={noEvaluationsEvents} />}
            icon="⭐"
        >
            {/* <HelpLink href="https://undersports.club/ru/evaluation">
                {t('assistant.noEvaluations.helpBtn')}
            </HelpLink> */}
        </AssistantBase>
    );
};

export default NoEvaluations;
