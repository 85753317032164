import { auth, db, functions } from 'common/firebase';
import MedicalRecord, { MedicalRecordData } from 'entities/medical-records/MedicalRecords';
import { mapMedicalRecordFromDatesStrings } from 'entities/medical-records/medicalRecords.utils';
import {
    addDoc,
    collection,
    doc,
    getDoc,
    updateDoc,
} from 'firebase/firestore';
import { HttpsCallableResult, httpsCallable } from 'firebase/functions';
import { handleDocumentSnapshot } from 'gateways/utils';

export const getPlayerMedicalRecordsPath = (playerId: string) => {
    return `players/${playerId}/medicalRecords`;
};

export const getMedicalRecordPath = (playerId: string, medicalRecordId: string) => {
    return `${getPlayerMedicalRecordsPath(playerId)}/${medicalRecordId}`;
};

export const createMedicalRecord = ({
    medicalRecordData,
}: {
    medicalRecordData: MedicalRecordData;
}) => {
    const medicalRecordsRef = collection(
        db,
        getPlayerMedicalRecordsPath(medicalRecordData.playerId),
    );

    return addDoc(medicalRecordsRef, medicalRecordData).then((doc) => doc.id);
};

export const updateMedicalRecord = ({
    medicalRecordId,
    medicalRecordData,
}: {
    medicalRecordId: string;
    medicalRecordData: MedicalRecordData;
}) => {
    const medicalRecordRef = doc(
        db,
        getMedicalRecordPath(medicalRecordData.playerId, medicalRecordId),
    );
    const lastUpdateAuthorUid = auth.currentUser?.uid || null;
    Object.assign(medicalRecordData, { lastUpdateAuthorUid });

    return updateDoc(medicalRecordRef, medicalRecordData);
};

export const fetchMedicalRecordById = ({
    playerId,
    medicalRecordId,
}: {
    playerId: string;
    medicalRecordId: string;
}) => {
    const medicalRecordRef = doc(db, getMedicalRecordPath(playerId, medicalRecordId));

    return getDoc(medicalRecordRef).then(handleDocumentSnapshot);
};

const getPlayerMedicalRecordsFunction = httpsCallable(functions, 'getPlayerMedicalRecordsV2');
export const fetchPlayerMedicalRecordsList = async ({
    organizationId,
    playerId,
    testId,
    limit,
}: {
    organizationId: string;
    playerId: string;
    testId?: string;
    limit?: number;
}) => {
    const { data } = (await getPlayerMedicalRecordsFunction({
        organizationId,
        testId,
        limit,
        playerId,
    })) as HttpsCallableResult<{ recordsList: MedicalRecord[] }>;
    return data.recordsList.map(mapMedicalRecordFromDatesStrings);
};

const getMedicalRecordsByTestIdFunction = httpsCallable(functions, 'getMedicalRecordsByTestIdV2');
export const getMedicalRecordsByTestId = async ({
    organizationId,
    teamId,
    testId,
}: {
    organizationId: string;
    teamId: string;
    testId: string;
}) => {
    const { data } = (await getMedicalRecordsByTestIdFunction({
        organizationId,
        teamId,
        testId,
    })) as HttpsCallableResult<{ recordsList: MedicalRecord[] }>;
    return data.recordsList.map(mapMedicalRecordFromDatesStrings);
};
