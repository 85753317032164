import { PositionsGroup } from 'common/components/pitch/positionsCoordinates';
import { Language } from 'entities/users/User';

export type AppConfig = {
    maintenance: boolean;
    publicMethodology: string | null;
};
export type PlayersMapPositionsCount = Record<PositionsGroup, number>;

export type LocalConfig = {
    weekGoalsVisible: boolean;
    weekDrillsPreviewVisible: boolean;
    selectedScoutingGroup: string | null;
    scoutingGroupsVisible: boolean;
    favoriteLonglists: string[];
    playersMapPositionsCount: PlayersMapPositionsCount;
    lang: Language;
};

export const defaultLocalConfig: LocalConfig = {
    weekGoalsVisible: false,
    weekDrillsPreviewVisible: false,
    selectedScoutingGroup: null,
    scoutingGroupsVisible: false,
    favoriteLonglists: [],
    playersMapPositionsCount: {
        lw: 2,
        cf: 3,
        rw: 2,
        middle: 7,
        ll: 2,
        centerBack: 4,
        rl: 2,
        gk: 4,
        nope: 0,
    },
    lang: Language.en,
};
