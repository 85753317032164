import React from 'react';
import Slide from '@mui/material/Slide';
import { useMediaQuery, useTheme } from 'common/components';
import Dialog, { DialogProps } from './Dialog';

const Transition = React.forwardRef((props: any, ref) => {
    return <Slide direction="left" ref={ref} {...props} />;
});

const SideDialog: React.FC<DialogProps & { mobileFullScreen?: boolean }> = ({
    mobileFullScreen,
    ...rest
}) => {
    const theme = useTheme();
    const isFullScreen = mobileFullScreen && useMediaQuery(theme.breakpoints.only('xs'));

    return <Dialog TransitionComponent={Transition} fullScreen={isFullScreen} {...rest} />;
};

export default SideDialog;
