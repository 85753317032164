import { takeLatest, call, all, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import * as methodologiesGateway from 'gateways/methodologiesGateway';
import methodologiesActionTypes from './methodologies.actionTypes';
import * as methodologiesActions from './methodologies.actions';
import Methodology from './Methodology';

export function* updateMethodologySaga({
    methodologyId,
    methodologyData,
}: ReturnType<typeof methodologiesActions.updateMethodology>) {
    try {
        yield call(methodologiesGateway.updateMethodology, {
            methodologyId,
            methodologyData,
        });
        yield put(methodologiesActions.requestMethodologyData({ methodologyId }));
    } catch (e: any) {
        console.error('Update methodology error', e);
        throw new Error(e);
    }
}

export function* fetchMethodologyDataSaga({
    methodologyId,
}: ReturnType<typeof methodologiesActions.updateMethodology>) {
    try {
        const methodologyData: Methodology = yield call(methodologiesGateway.fetchMethodologyById, {
            methodologyId,
        });
        if (methodologyData) {
            yield put(methodologiesActions.methodologyDataReceived({ methodologyData }));
        }
    } catch (e: any) {
        if (e.code === 'permission-denied') {
            yield call(toastr.error, 'No access', 'You have no access to this methodology');
        }
    }
}

function* methodologiesSaga() {
    yield all([
        takeLatest(methodologiesActionTypes.METHODOLOGY_UPDATE_REQUEST, updateMethodologySaga),
        takeLatest(methodologiesActionTypes.METHODOLOGY_DATA_REQUEST, fetchMethodologyDataSaga),
    ]);
}

export default methodologiesSaga;
