import { functions, db, auth } from 'common/firebase';
import Event, { EventData, EventTypes } from 'entities/events/Event';
import { mapEventFromDatesStrings } from 'entities/events/events.utils';
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { handleDocumentSnapshot, handleCollectionSnapshot } from './utils';

export const getTeamEventsPath = (organizationId: string, teamId: string) => {
    return `organizations/${organizationId}/teams/${teamId}/events`;
};

export const getEventPath = (organizationId: string, teamId: string, eventId: string) => {
    return `${getTeamEventsPath(organizationId, teamId)}/${eventId}`;
};

export const getEventFilesPath = getEventPath;

export const getEventUpdatesPath = (organizationId: string, teamId: string, eventId: string) => {
    return `${getEventPath(organizationId, teamId, eventId)}/eventUpdates`;
};

export const fetchEventsList = ({
    teamId,
    organizationId,
    from,
    to,
}: {
    from: Date;
    to: Date;
    teamId: string;
    organizationId: string;
}) => {
    const eventsRef = collection(db, getTeamEventsPath(organizationId, teamId));
    const q = query(eventsRef, where('start', '>=', from), where('start', '<=', to));

    return getDocs(q)
        .then(handleCollectionSnapshot)
        .then((events) => events.map((event: Event) => ({ ...event, teamId, organizationId })));
};

export const fetchPlanificationEvents = ({
    teamId,
    organizationId,
    from,
    to,
}: {
    from: Date;
    to: Date;
    teamId: string;
    organizationId: string;
}) => {
    const eventsRef = collection(db, getTeamEventsPath(organizationId, teamId));
    const q = query(
        eventsRef,
        where('start', '>=', from),
        where('start', '<=', to),
        where('eventType', 'in', [EventTypes.game, EventTypes.session]),
    );

    return getDocs(q)
        .then(handleCollectionSnapshot)
        .then((events) => events.map((event: Event) => ({ ...event, teamId, organizationId })));
};

export const fetchEvent = ({
    eventId,
    teamId,
    organizationId,
}: {
    eventId: string;
    teamId: string;
    organizationId: string;
}) => {
    const eventRef = doc(db, getEventPath(organizationId, teamId, eventId));

    return getDoc(eventRef).then(handleDocumentSnapshot);
};

export const createEvent = ({
    eventData,
    teamId,
    organizationId,
}: {
    eventData: EventData;
    teamId: string;
    organizationId: string;
}) => {
    const eventsRef = collection(db, getTeamEventsPath(organizationId, teamId));
    const lastUpdateAuthorUid = auth.currentUser?.uid || null;

    return addDoc(eventsRef, { ...eventData, teamId, lastUpdateAuthorUid }).then((doc) => doc.id);
};

export const updateEvent = ({
    eventData,
    eventId,
    teamId,
    organizationId,
}: {
    eventData: EventData;
    eventId: string;
    teamId: string;
    organizationId: string;
}) => {
    const eventRef = doc(db, getEventPath(organizationId, teamId, eventId));
    const lastUpdateAuthorUid = auth.currentUser?.uid || null;
    Object.assign(eventData, { lastUpdateAuthorUid });

    return updateDoc(eventRef, eventData);
};

export const removeEvent = async ({
    eventId,
    teamId,
    organizationId,
}: {
    eventId: string;
    teamId: string;
    organizationId: string;
}) => {
    const eventRef = doc(db, getEventPath(organizationId, teamId, eventId));
    const lastUpdateAuthorUid = auth.currentUser?.uid || null;

    await updateDoc(eventRef, { lastUpdateAuthorUid });

    return deleteDoc(eventRef);
};

export const fetchPlayerEvents = ({
    teamId,
    organizationId,
    playerId,
    fromDate,
    toDate,
}: {
    teamId: string;
    organizationId: string;
    playerId: string;
    fromDate: string;
    toDate: string;
}) =>
    httpsCallable(
        functions,
        'fetchPlayerEvents',
    )({
        playerId,
        teamId,
        organizationId,
        fromDate,
        toDate,
    }).then((res: any) => {
        return res.data.map(mapEventFromDatesStrings);
    });

export const fetchEventUpdatesList = ({
    eventId,
    teamId,
    organizationId,
}: {
    eventId: string;
    teamId: string;
    organizationId: string;
}) => {
    const updatesRef = collection(db, getEventUpdatesPath(organizationId, teamId, eventId));

    const q = query(updatesRef, where('eventId', '==', eventId));

    return getDocs(q).then(handleCollectionSnapshot);
};
