export default {
    REQUEST_CONDITIONS_LIST: 'ENTITIES/CONDITION/REQUEST_CONDITIONS_LIST',
    REQUEST_CONDITIONS_FOR_EVENT: 'ENTITIES/CONDITION/REQUEST_CONDITIONS_FOR_EVENT',
    REQUEST_CONDITION_DATA: 'ENTITIES/CONDITION/REQUEST_CONDITION_DATA',
    REQUEST_PLAYER_EVENT_CONDITION: 'ENTITIES/CONDITION/REQUEST_PLAYER_EVENT_CONDITION',
    REQUEST_DAY_CONDITIONS_REPORT: 'ENTITIES/CONDITION/REQUEST_DAY_CONDITIONS_REPORT',
    CONDITIONS_LIST_RECEIVED: 'ENTITIES/CONDITION/CONDITIONS_LIST_RECEIVED',
    CONDITION_DATA_RECEIVED: 'ENTITIES/CONDITION/CONDITION_DATA_RECEIVED',
    DAY_CONDITIONS_REPORT_RECEIVED: 'ENTITIES/CONDITION/DAY_CONDITIONS_REPORT_RECEIVED',
    ADD_CONDITION_DATA: 'ENTITIES/CONDITION/ADD_CONDITION_DATA',
    EDIT_CONDITION_DATA: 'ENTITIES/CONDITION/EDIT_CONDITION_DATA',
    DELETE_CONDITION: 'ENTITIES/CONDITION/DELETE_CONDITION',
    REMOVE_CONDITION: 'ENTITIES/CONDITION/REMOVE_CONDITION',
};
