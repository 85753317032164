import { Permissions } from './Permissions';

export enum Roles {
    admin = 'admin',
    editor = 'editor',
    reader = 'reader',
    coach = 'coach',
    owner = 'owner',
    staff = 'staff',
    player = 'player',
    scout = 'scout',
    isolatedScout = 'isolatedScout',
    doctor = 'doctor',
}

export enum AccessTypes {
    methodology = 'methodology',
    organization = 'organization',
    team = 'team',
    player = 'player',
    longlist = 'longlist',
    shadowTeam = 'shadowTeam',
}

export type AccessTarget = {
    methodologyId?: string;
    organizationId?: string;
    teamId?: string;
    playerId?: string;
    listId?: string;
};

type AccessRecord = {
    gid?: string;
    accessType: AccessTypes;
    role: Roles;
    target: AccessTarget;
    targetName: string;
    userEmail: string;
    userId: string;
    userName: string;
    permissions: Permissions[];
};

export default AccessRecord;
