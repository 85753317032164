import { styled } from '@mui/material/styles';
import { SortIcon } from 'common/icons';

export const PlayersSortIcon = styled(SortIcon, {
    shouldForwardProp: (prop) => !['asc'].includes(prop as string),
})<{ asc?: boolean }>(({ theme, asc }) => ({
    marginRight: theme.spacing(0.5),
    width: 16,
    transform: `rotate(${asc ? 180 : 0}deg)`,
}));
