import React from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import OrgLogo from 'features/organization/components/OrgLogo';
import AccessRecord, { AccessTarget } from 'entities/access/Access';
import { Box, ButtonBase, IconButton, Tooltip, Typography } from 'common/components';
import { buildLink } from './sidebar.utils';

const ResourceLink = styled(NavLink)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.shape.borderRadius,
    lineHeight: '24px',
    width: 170,

    '&:hover': {
        color: 'rgba(0, 0, 0, 1)',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
}));

const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    // width: '100%',
    justifyContent: 'flex-start',
    padding: theme.spacing(0.5, 1),
    color: 'rgba(0, 0, 0, 0.54)',
}));

type AccessGroupProps = {
    title: string;
    IconComponent: React.FC;
    accessList: Array<AccessRecord>;
    shouldHideIfEmpty?: boolean;
    emptyPlaceholder?: string;
    SideComponent?: React.FC;
    checkMatch: (target: AccessTarget) => boolean;
    onSelect?: VoidFunction;
};

const AccessGroup: React.FC<AccessGroupProps> = ({
    accessList,
    title,
    IconComponent,
    shouldHideIfEmpty,
    emptyPlaceholder,
    SideComponent,
    checkMatch,
    onSelect,
}) => {
    if (accessList.length === 0 && shouldHideIfEmpty) {
        return null;
    }

    return (
        <Root>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title={title}>
                    <IconButton color="inherit">
                        <IconComponent />
                    </IconButton>
                </Tooltip>
                <ButtonBase component={Typography} variant="subtitle1" sx={{ ml: 3 }} noWrap>
                    {title}
                </ButtonBase>
                {SideComponent && <SideComponent />}
            </Box>
            <Box sx={{ mt: 1, ml: 3, borderLeft: '1px solid #ccc', pl: 1.5 }}>
                {accessList.length > 0 &&
                    accessList.map((accessRecord) => (
                        <ResourceLink
                            to={buildLink(accessRecord)}
                            key={accessRecord.gid}
                            onClick={onSelect}
                        >
                            {accessRecord.target.organizationId && (
                                <OrgLogo
                                    sx={{ m: 0, mr: 1 }}
                                    size={24}
                                    organizationId={accessRecord.target.organizationId}
                                />
                            )}
                            <Typography
                                sx={{
                                    color: (theme) =>
                                        checkMatch(accessRecord.target)
                                            ? theme.palette.primary.main
                                            : 'rgba(0, 0, 0, 0.54)',
                                }}
                                variant="body1"
                                noWrap
                            >
                                {accessRecord.targetName}
                            </Typography>
                        </ResourceLink>
                    ))}
                {accessList.length === 0 && (
                    <Typography sx={{ py: 1, opacity: 0.5 }} noWrap>
                        {emptyPlaceholder}
                    </Typography>
                )}
            </Box>
        </Root>
    );
};

export default AccessGroup;
