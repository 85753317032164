import { yellow, blue, deepOrange, orange, red, green } from '@mui/material/colors';
import { EventTypes } from './Event';
import Game, {
    ActivityType,
    GameActivity,
    GameData,
    GameStatus,
    Lineup,
    LineupPosition,
    PlayerStatus,
    Substitution,
} from './Game';

export const defaultGamesSettings = {
    showPlayerEvaluations: false,
    showGameStats: false,
    showLineup: 0,
    showGameLinks: false,
    showGameFiles: false,
};

export const createEmptyGameData = (startTime?: Date, endTime?: Date): GameData => {
    const newGame: GameData = {
        start: startTime || new Date(),
        end: endTime || new Date(),
        eventType: EventTypes.game,
        details: {
            gameTime: 90,
            lineup: {},
            formation: '4-4-2',
            substitutions: [],
            location: null,
            opponent: {
                name: '',
            },
            description: '',
            homeAway: GameStatus.home,
            gameScore: {
                team: null,
                opponent: null,
            },
            playerNotes: '',
            recordings: [],
            files: [],
        },
        createDate: new Date(),
        lastUpdateAuthorUid: null,
        settings: defaultGamesSettings,
    };

    return newGame;
};

export const createEmptyGame = (
    id: string,
    organizationId: string,
    teamId: string,
    startTime?: Date,
    endTime?: Date,
): Game => {
    return {
        ...createEmptyGameData(startTime, endTime),
        id,
        organizationId,
        teamId,
    };
};

export const GameAttendanceColors = {
    [PlayerStatus.lineup]: green[500],
    [PlayerStatus.bench]: green[500],
    [PlayerStatus.reserve]: green[500],
    [PlayerStatus.reason]: yellow[500],
    [PlayerStatus.ill]: deepOrange[500],
    [PlayerStatus.skip]: orange[500],
    [PlayerStatus.injury]: red[500],
    [PlayerStatus.promotion]: blue[500],
};

const checkSecondYellowCard = (activity: GameActivity, activities: GameActivity[]) => {
    const anotherYellowCard = activities.find(
        ({ minute, action }) => action === ActivityType.yellowCard && minute !== activity.minute,
    );

    if (!anotherYellowCard) {
        return false;
    }

    return activity.minute > anotherYellowCard.minute;
};

// converts second yellow card into yellowAndRedCard
export const getPlayerActivities = (activities: GameActivity[]) => {
    const result = activities.map((activity) => {
        if (activity.action !== ActivityType.yellowCard) {
            return activity;
        }
        const isSecondYellowCard = checkSecondYellowCard(activity, activities);

        if (isSecondYellowCard) {
            return {
                ...activity,
                action: ActivityType.yellowAndRedCard,
            };
        }

        return activity;
    });

    return result;
};

export const getPlayerGameTime = (
    substitutions: Substitution[],
    playerId: string,
    gameTime: number,
    isLineup: boolean,
    playerLineupPosition: LineupPosition,
): { playerGameTime: number; isCustom: boolean } => {
    const { activities, gameTime: playerGameTime } = playerLineupPosition;

    if (playerGameTime) {
        return { playerGameTime, isCustom: true };
    }

    if (!gameTime || gameTime === 0) {
        return { playerGameTime: 0, isCustom: false };
    }

    const redCard = getPlayerActivities(activities).find((activity) =>
        [ActivityType.redCard, ActivityType.yellowAndRedCard].includes(activity.action),
    );
    const finishTime = redCard ? redCard.minute : gameTime;

    const playerSubstitutions = substitutions
        .filter((substitution) => substitution.time)
        .filter((substitution) => {
            return substitution.in === playerId || substitution.out === playerId;
        });

    playerSubstitutions.sort((s1, s2) => (s1.time! > s2.time! ? -1 : 1));

    if (playerSubstitutions.length === 0) {
        return {
            playerGameTime: isLineup ? Number(finishTime) : 0,
            isCustom: false,
        };
    }

    if (isLineup) {
        const lineupPlayerGameTime = playerSubstitutions.reduce((acc, substitution) => {
            if (substitution.out === playerId) {
                return acc - (finishTime - substitution.time!);
            }

            return acc + (finishTime - substitution.time!);
        }, finishTime);

        return {
            playerGameTime: lineupPlayerGameTime,
            isCustom: false,
        };
    }

    const benchPlayerGameTime = playerSubstitutions.reduce((acc, substitution) => {
        if (substitution.in === playerId) {
            return acc + (finishTime - substitution.time!);
        }

        return acc - (finishTime - substitution.time!);
    }, 0);

    return {
        playerGameTime: benchPlayerGameTime,
        isCustom: false,
    };
};

type GamesStats = {
    scored: number;
    conceded: number;
    gamesCount: number;
    yellowCards: number;
    redCards: number;
    homeGamesCount: number;
    awayGamesCount: number;
    winGamesCount: number;
    lossGamesCount: number;
    drawGamesCount: number;
};

const emptyGamesStats: GamesStats = {
    scored: 0,
    conceded: 0,
    gamesCount: 0,
    yellowCards: 0,
    redCards: 0,
    homeGamesCount: 0,
    awayGamesCount: 0,
    winGamesCount: 0,
    lossGamesCount: 0,
    drawGamesCount: 0,
};

const getYellowCardsCount = (lineup: Lineup) => {
    return Object.values(lineup)
        .flatMap((lineupPosition) => lineupPosition.activities)
        .filter((gameActivity) => gameActivity.action === ActivityType.yellowCard).length;
};

const getRedCardsCount = (lineup: Lineup) => {
    return Object.values(lineup).reduce((acc, { activities }) => {
        const redCardsCount = activities.filter(
            (gameActivity) => gameActivity.action === ActivityType.redCard,
        );

        if (redCardsCount.length > 0) {
            return acc + 1;
        }
        const yellowCardsCount = activities.filter(
            (gameActivity) => gameActivity.action === ActivityType.yellowCard,
        );

        if (yellowCardsCount.length === 2) {
            return acc + 1;
        }

        return acc;
    }, 0);
};

export const getGamesStats = (games: Game[]) => {
    const winGamesCount = games.filter((game) => {
        const { opponent, team } = game.details.gameScore;
        if (opponent === null || team === null) {
            return false;
        }

        return team > opponent;
    }).length;

    const lossGamesCount = games.filter((game) => {
        const { opponent, team } = game.details.gameScore;
        if (opponent === null || team === null) {
            return false;
        }

        return opponent > team;
    }).length;

    const drawGamesCount = games.filter((game) => {
        const { opponent, team } = game.details.gameScore;
        if (opponent === null || team === null) {
            return false;
        }

        return opponent === team;
    }).length;

    return games.reduce(
        (acc, game) => {
            const {
                scored,
                conceded,
                gamesCount,
                yellowCards,
                redCards,
                homeGamesCount,
                awayGamesCount,
                ...rest
            } = acc;
            const { lineup, gameScore, homeAway } = game.details;
            const { opponent, team } = gameScore;
            const isHomeGame = homeAway === GameStatus.home;

            return {
                ...rest,
                scored: scored + (Number(team) || 0),
                conceded: conceded + (Number(opponent) || 0),
                gamesCount: gamesCount + 1,
                homeGamesCount: isHomeGame ? homeGamesCount + 1 : homeGamesCount,
                awayGamesCount: !isHomeGame ? awayGamesCount + 1 : awayGamesCount,
                yellowCards: yellowCards + getYellowCardsCount(lineup),
                redCards: redCards + getRedCardsCount(lineup),
            };
        },
        { ...emptyGamesStats, winGamesCount, lossGamesCount, drawGamesCount },
    );
};
