import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import actionTypes from './userTasks.actionTypes';

const initialState = {
    userTasksHash: {},
};

const userTasks = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.USER_TASKS_LIST_RECIEVED:
            return {
                ...state,
                userTasksHash: {
                    ...state.userTasksHash,
                    ..._keyBy(action.userTasksList, 'id'),
                },
            };
        case actionTypes.DELETE_USER_TASK_FROM_STATE:
            return {
                ...state,
                userTasksHash: {
                    ..._omit(state.userTasksHash, action.taskId),
                },
            };
        default:
            return state;
    }
};

export default userTasks;
