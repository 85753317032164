import { CycleData } from 'entities/cycles/Cycle';
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';
import { db } from 'common/firebase';
import { handleDocumentSnapshot, handleCollectionSnapshot } from './utils';

export const createCycleRequest = ({
    teamId,
    organizationId,
    cycleData,
}: {
    teamId: string;
    organizationId: string;
    cycleData: CycleData;
}) => {
    const cyclesCollectionRef = collection(
        db,
        `/organizations/${organizationId}/teams/${teamId}/cycles`,
    );

    return addDoc(cyclesCollectionRef, cycleData).then((doc) => doc.id);
};

export const updateCycleRequest = ({
    cycleId,
    teamId,
    organizationId,
    cycleData,
}: {
    cycleId: string;
    teamId: string;
    organizationId: string;
    cycleData: CycleData;
}) => {
    const cycleRef = doc(db, `/organizations/${organizationId}/teams/${teamId}/cycles/${cycleId}`);

    return updateDoc(cycleRef, cycleData);
};

export const fetchCyclesRequest = ({
    teamId,
    organizationId,
    fromDate,
    toDate,
}: {
    teamId: string;
    organizationId: string;
    fromDate: Date;
    toDate: Date;
}) => {
    const cyclesCollectionRef = collection(
        db,
        `/organizations/${organizationId}/teams/${teamId}/cycles`,
    );

    const q = query(
        cyclesCollectionRef,
        where('startDate', '<=', toDate),
        where('startDate', '>=', fromDate),
    );
    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchCycleRequest = ({
    cycleId,
    teamId,
    organizationId,
}: {
    teamId: string;
    organizationId: string;
    cycleId: string;
}) => {
    const cycleRef = doc(db, `/organizations/${organizationId}/teams/${teamId}/cycles/${cycleId}`);

    return getDoc(cycleRef).then(handleDocumentSnapshot);
};

export const deleteCycleRequest = ({ playerId, goalId }: { playerId: string; goalId: string }) => {
    const cycleRef = doc(db, `players/${playerId}/cycles/${goalId}`);

    return deleteDoc(cycleRef);
};
