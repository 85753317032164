import classNames from 'classnames';
import { styled } from '@mui/material/styles';
import {
    IconButton,
    Snackbar as MuiSnackbar,
    SnackbarContent,
    Typography,
} from 'common/components';
import { CheckCircleIcon, CloseIcon, ErrorIcon, InfoIcon, WarningIcon } from 'common/icons';
import _get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';
import * as snackbarActions from './snackbar.actions';
import * as snackbarSelectors from './snackbar.selectors';

const PREFIX = 'Snackbar';

const classes = {
    close: `${PREFIX}-close`,
    snackbar: `${PREFIX}-snackbar`,
    content: `${PREFIX}-content`,
    text: `${PREFIX}-text`,
    success: `${PREFIX}-success`,
    error: `${PREFIX}-error`,
    info: `${PREFIX}-info`,
    warning: `${PREFIX}-warning`,
    icon: `${PREFIX}-icon`,
    iconVariant: `${PREFIX}-iconVariant`,
    message: `${PREFIX}-message`,
};

const StyledMuiSnackbar = styled(MuiSnackbar)(({ theme }) => ({
    [`& .${classes.close}`]: {
        padding: theme.spacing(0.5),
    },

    [`& .${classes.snackbar}`]: {
        maxWidth: '80vw',
        flexWrap: 'nowrap',
    },

    [`& .${classes.content}`]: {
        display: 'flex',
        alignItems: 'center',
    },

    [`& .${classes.text}`]: {
        display: 'flex',
        flexDirection: 'column',
    },

    [`& .${classes.success}`]: {
        backgroundColor: theme.custom.colors.green.dark,
    },

    [`& .${classes.error}`]: {
        backgroundColor: theme.palette.error.dark,
    },

    [`& .${classes.info}`]: {
        backgroundColor: theme.palette.primary.dark,
    },

    [`& .${classes.warning}`]: {
        backgroundColor: theme.custom.colors.orange.dark,
    },

    [`& .${classes.icon}`]: {
        fontSize: 20,
    },

    [`& .${classes.iconVariant}`]: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },

    [`& .${classes.message}`]: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

type SnackbarProps = {
    activeSnackbar: ReturnType<typeof snackbarSelectors.activeSnackbarSelector>;
    closeSnackbar: typeof snackbarActions.closeSnackbar;
};

const Snackbar: React.FC<SnackbarProps> = ({ activeSnackbar, closeSnackbar }) => {
    const message = _get(activeSnackbar, 'message', '');
    const variant = _get(activeSnackbar, 'variant', '');
    const title = _get(activeSnackbar, 'title', '');
    // @ts-ignore
    const Icon = variantIcon[variant];
    const content = (
        <div className={classes.content}>
            {Icon && <Icon className={classNames(classes.icon, classes.iconVariant)} />}
            <div className={classes.text}>
                {title && <Typography variant="subtitle2">{title}</Typography>}
                <Typography id="client-snackbar" className={classes.message}>
                    {message}
                </Typography>
            </div>
        </div>
    );
    return (
        <StyledMuiSnackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={Boolean(activeSnackbar)}
            autoHideDuration={50000}
            onClose={closeSnackbar}
        >
            <SnackbarContent
                // @ts-ignore
                className={classNames(classes.snackbar, classes[variant])}
                aria-describedby="client-snackbar"
                message={content}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={closeSnackbar}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
            />
        </StyledMuiSnackbar>
    );
};

const mapState = (state: RootState) => ({
    activeSnackbar: snackbarSelectors.activeSnackbarSelector(state),
});

const mapDispatch = {
    closeSnackbar: snackbarActions.closeSnackbar,
};

export default connect(mapState, mapDispatch)(Snackbar);
