import actionTypes from './agents.actionTypes';
import Agent from './Agents';
import _keyBy from 'lodash/keyBy';

type AgentsState = {
    agentsHash: Record<string, Agent>;
};
const initialState = {
    agentsHash: {},
};

const agent = (state: AgentsState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.AGENT_DATA_RECEIVED:
            return {
                ...state,
                agentsHash: {
                    ...state.agentsHash,
                    [action.agent.id]: {
                        ...action.agent,
                    },
                },
            };
        case actionTypes.AGENTS_LIST_RECEIVED:
            return {
                ...state,
                agentsHash: {
                    ...state.agentsHash,
                    ..._keyBy(action.agentsList, 'id'),
                },
            };
        default:
            return state;
    }
};

export default agent;
