export const downloadFile = ({ content, fileName }: { content: string; fileName: string }) => {
    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`);
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

export const downloadURI = (uri: string, fileName: string) => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = fileName || 'file.png';
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const downloadVideoFile = ({
    videoUrl,
    fileName,
}: {
    videoUrl: string;
    fileName: string;
}) => {
    const element = document.createElement('a');
    element.setAttribute('href', videoUrl);
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

export const MB = 1024 ** 2;
export const GB = 1024 ** 3;

export const formatStorage = (bytes?: number) => {
    if (bytes === undefined || bytes === null) {
        return '-';
    }

    if (bytes >= GB) {
        const amount = Math.round((bytes / GB) * 100) / 100;
        return `${amount} Gb`;
    }

    const amount = Math.round((bytes / MB) * 100) / 100;
    return `${amount} Mb`;
};
