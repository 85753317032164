import { takeLatest, takeEvery, call, all, put } from 'redux-saga/effects';
import * as eventsGateway from 'gateways/eventsGateway';
import { actions as snackbarActions } from 'common/snackbar';
import Event from './Event';
import eventsActionTypes from './events.actionTypes';
import * as eventsActions from './events.actions';
import { GameUpdate } from './GameUpdates';

export function* deleteEventsSaga({
    eventsIds,
    teamId,
    organizationId,
}: ReturnType<typeof eventsActions.removeEventsRequest>) {
    try {
        yield all(
            eventsIds.map((eventId) =>
                call(eventsGateway.removeEvent, { eventId, teamId, organizationId }),
            ),
        );
        yield put(eventsActions.eventsRemoved({ eventsIds }));
    } catch (e: any) {
        yield call([console, console.error], e);
        if (e.code === 'permission-denied') {
            yield put(
                snackbarActions.showError({
                    title: 'No access',
                    message:
                        'You have no access to the events of this team. Please ask team admin for the access',
                }),
            );
        }
    }
}

export function* createEventSaga({
    eventData,
    teamId,
    organizationId,
}: ReturnType<typeof eventsActions.createEventRequest>) {
    try {
        const eventId: string = yield call(eventsGateway.createEvent, {
            eventData,
            teamId,
            organizationId,
        });
        yield put(eventsActions.eventDataRequest({ eventId, teamId, organizationId }));

        return eventId;
    } catch (e: any) {
        yield call(console.error, e, 'Failed to create event');
        if (e.code === 'permission-denied') {
            yield put(
                snackbarActions.showError({
                    title: 'No access',
                    message:
                        'You have no access to the events of this team. Please ask team admin for the access',
                }),
            );
        }
        throw new Error(e);
    }
}

export function* updateEventSaga({
    eventId,
    eventData,
    teamId,
    organizationId,
}: ReturnType<typeof eventsActions.updateEventRequest>) {
    try {
        yield call(eventsGateway.updateEvent, {
            eventId,
            eventData,
            teamId,
            organizationId,
        });
        yield put(eventsActions.eventDataRequest({ eventId, teamId, organizationId }));
    } catch (e: any) {
        yield call(console.error, e);
        if (e.code === 'permission-denied') {
            yield put(
                snackbarActions.showError({
                    title: 'No access',
                    message:
                        'You have no access to the events of this team. Please ask team admin for the access',
                }),
            );
        }
        throw new Error(e);
    }
}

export function* fetchEventsListSaga({
    teamId,
    organizationId,
    from,
    to,
}: ReturnType<typeof eventsActions.eventsListRequest>) {
    try {
        const eventsList: Event[] = yield call(eventsGateway.fetchEventsList, {
            teamId,
            organizationId,
            from,
            to,
        });
        if (eventsList) {
            yield put(eventsActions.eventsListReceived(eventsList));
        }
    } catch (e: any) {
        console.error(
            {
                _error: `Failed to fetch events for team(${teamId} for period from ${from.toLocaleDateString()} to ${to.toLocaleDateString()}`,
            },
            e,
        );
        if (e.code === 'permission-denied') {
            yield put(
                snackbarActions.showError({
                    title: 'No access',
                    message:
                        'You have no access to the events of this team. Please ask team admin for the access',
                }),
            );
        }
        throw new Error(e);
    }
}

export function* fetchPlanificationEventsSaga({
    teamId,
    organizationId,
    from,
    to,
}: ReturnType<typeof eventsActions.planificationEventsRequest>) {
    try {
        const eventsList: Event[] = yield call(eventsGateway.fetchPlanificationEvents, {
            teamId,
            organizationId,
            from,
            to,
        });
        if (eventsList) {
            yield put(eventsActions.eventsListReceived(eventsList));
        }
    } catch (e: any) {
        console.error(
            {
                _error: `Failed to fetch events for team(${teamId} for period from ${from.toLocaleDateString()} to ${to.toLocaleDateString()}`,
            },
            e,
        );
        if (e.code === 'permission-denied') {
            yield put(
                snackbarActions.showError({
                    title: 'No access',
                    message:
                        'You have no access to the events of this team. Please ask team admin for the access',
                }),
            );
        }
        throw new Error(e);
    }
}

export function* fetchEventSaga({
    eventId,
    teamId,
    organizationId,
}: ReturnType<typeof eventsActions.eventDataRequest>) {
    try {
        const eventData: Event = yield call(eventsGateway.fetchEvent, {
            eventId,
            teamId,
            organizationId,
        });
        if (eventData) {
            yield put(eventsActions.eventDataReceived(eventData));
        }
    } catch (e: any) {
        if (e.code === 'permission-denied') {
            yield put(
                snackbarActions.showError({
                    title: 'No access',
                    message:
                        'You have no access to the events of this team. Please ask team admin for the access',
                }),
            );
        }
        console.error({
            _error: `Failed to fetch event ${eventId}`,
        });
    }
}

export function* fetchEventUpdatesListSaga({
    organizationId,
    teamId,
    eventId,
}: ReturnType<typeof eventsActions.requestEventUpdatesList>) {
    try {
        const eventUpdatesList: GameUpdate[] = yield call(
            [eventsGateway, eventsGateway.fetchEventUpdatesList],
            { organizationId, teamId, eventId },
        );
        yield put(eventsActions.eventUpdatesListReceived({ eventUpdatesList }));
    } catch (e) {
        console.error(e);
    }
}

function* eventsSaga() {
    yield all([
        takeEvery(eventsActionTypes.CREATE_EVENT_REQUEST, createEventSaga),
        takeLatest(eventsActionTypes.UPDATE_EVENT_REQUEST, updateEventSaga),
        takeEvery(eventsActionTypes.EVENTS_LIST_REQUEST, fetchEventsListSaga),
        takeEvery(
            eventsActionTypes.PLANIFICATION_EVENTS_LIST_REQUEST,
            fetchPlanificationEventsSaga,
        ),
        takeEvery(eventsActionTypes.EVENT_DATA_REQUEST, fetchEventSaga),
        takeLatest(eventsActionTypes.REMOVE_EVENTS_REQUEST, deleteEventsSaga),
        takeLatest(eventsActionTypes.EVENT_UPDATES_LIST_REQUEST, fetchEventUpdatesListSaga),
    ]);
}

export default eventsSaga;
