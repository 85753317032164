import { takeLatest, all, put, call, takeEvery } from 'redux-saga/effects';
import * as userTasksGateway from 'gateways/userTasksGateway';
import actionTypes from './userTasks.actionTypes';
import * as userTasksActions from './userTasks.actions';
import UserTask from './UserTask';

export function* fetchUserTaskSaga({
    userId,
    taskId,
}: ReturnType<typeof userTasksActions.userTaskRequest>) {
    try {
        const userTask: UserTask = yield userTasksGateway.fetchUserTask({ userId, taskId });
        if (userTask) {
            yield put(userTasksActions.userTasksListRecieved([userTask]));
        }
    } catch (e) {
        console.error(
            {
                _error: `Failed to fetch userTask ${taskId} for ${userId}`,
            },
            e,
        );
    }
}

export function* fetchUserTasksSaga({
    userId,
}: ReturnType<typeof userTasksActions.userTaskRequest>) {
    try {
        const userTasksList: UserTask[] = yield userTasksGateway.fetchUserTasks({
            userId,
        });
        if (userTasksList) {
            yield put(userTasksActions.userTasksListRecieved(userTasksList));
        }
    } catch (e) {
        console.error(
            {
                _error: `Failed to fetch goals for Player: ${userId}`,
            },
            e,
        );
    }
}

export function* updateUserTaskSaga({
    userId,
    taskId,
    userTaskData,
}: ReturnType<typeof userTasksActions.updateUserTaskRequest>) {
    try {
        yield userTasksGateway.updateUserTask({ userId, taskId, userTaskData });
        yield put(userTasksActions.userTaskRequest({ userId, taskId }));
    } catch (e) {
        console.error(
            {
                _error: `Failed to fetch userTask: ${taskId}`,
            },
            e,
        );
    }
}

export function* createUserTaskSaga({
    userId,
    userTaskData,
    onSuccess,
}: ReturnType<typeof userTasksActions.addUserTaskRequest>) {
    try {
        const createdUserTaskId: string = yield userTasksGateway.createUserTask({
            userId,
            userTaskData,
        });

        yield put(userTasksActions.userTaskRequest({ userId, taskId: createdUserTaskId }));
        yield call(onSuccess);
    } catch (e) {
        console.error(
            {
                _error: `Failed to create userTask for player: ${userId}`,
            },
            e,
        );
    }
}

export function* deleteUserTaskSaga({
    userId,
    taskId,
}: ReturnType<typeof userTasksActions.deleteUserTaskRequest>) {
    try {
        yield call([userTasksGateway, userTasksGateway.deleteUserTask], { userId, taskId });
        yield put(userTasksActions.deleteUserTaskFromState({ taskId }));
    } catch (e) {
        console.error(
            {
                _error: `Failed to fetch userTask: ${taskId}`,
            },
            e,
        );
    }
}

function* userTasksSagas() {
    yield all([
        takeEvery(actionTypes.USER_TASK_REQUEST, fetchUserTaskSaga),
        takeEvery(actionTypes.USER_TASKS_LIST_REQUEST, fetchUserTasksSaga),
        takeLatest(actionTypes.ADD_USER_TASK_REQUEST, createUserTaskSaga),
        takeLatest(actionTypes.UPDATE_USER_TASK_REQUEST, updateUserTaskSaga),
        takeLatest(actionTypes.DELETE_USER_TASK_REQUEST, deleteUserTaskSaga),
    ]);
}

export default userTasksSagas;
