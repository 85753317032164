import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SubstitutionIcon = (props: any) => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g fill="none">
            <title>Substitution</title>
            <path
                d="M3.08203 8.28572C4.39091 4.54925 8.05245 1.85715 12.1464 1.85715C16.2403 1.85715 19.7152 4.54925 20.9489 8.28572"
                stroke="#70B873"
                strokeWidth="2"
            />
            <path
                d="M2.43224 10.1276L1.57754 5.7565L5.79035 7.20184L2.43224 10.1276Z"
                fill="#70B873"
            />
            <path
                d="M20.918 14.2857C19.6091 18.0222 15.9476 20.7143 11.8536 20.7143C7.75973 20.7143 4.28484 18.0222 3.05114 14.2857"
                stroke="#D82A2F"
                strokeWidth="2"
            />
            <path
                d="M21.5678 12.4439L22.4225 16.8149L18.2097 15.3696L21.5678 12.4439Z"
                fill="#D82A2F"
            />
        </g>
    </SvgIcon>
);

export default SubstitutionIcon;
