import { EmailData } from './Emails';
import actionTypes from './emails.actionTypes';

export const createEmail = ({
    emailData,
    successMessage,
}: {
    emailData: EmailData;
    successMessage: string;
}) => ({
    type: actionTypes.CREATE_EMAIL,
    emailData,
    successMessage,
});
