import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import actionTypes from './reports.actionTypes';
import Report, { ReportData } from './Report';

type ReportState = {
    reportsHash: {
        [key: string]: Report;
    };
};

const initialState: ReportState = {
    reportsHash: {},
};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.REPORT_DATA_RECEIVED: {
            return {
                ...state,
                reportsHash: {
                    ...state.reportsHash,
                    [action.reportData.id]: {
                        ..._get(state, `reportsHash.${action.reportData.id}`, {}),
                        ...action.reportData,
                        teamId: action.teamId,
                    },
                },
            };
        }
        case actionTypes.REPORTS_LIST_RECEIVED: {
            const reportsListWithTeamId = action.reportsList.map((reportData: ReportData) => ({
                ...reportData,
                teamId: action.teamId,
            }));
            return {
                ...state,
                reportsHash: {
                    ...state.reportsHash,
                    ..._keyBy(reportsListWithTeamId, 'id'),
                },
            };
        }
        case actionTypes.REMOVE_REPORT_FROM_LIST: {
            return {
                ...state,
                reportsHash: { ..._omit(state.reportsHash, action.eventId) },
            };
        }
        default:
            return state;
    }
};
