import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import translationEn from './locales/en.json';
import translationRu from './locales/ru.json';
import translationPl from './locales/pl.json';
import translationEs from './locales/es.json';
import translationUa from './locales/ua.json';

i18next.use(HttpApi).init({
    resources: {
        en: {
            translations: translationEn,
        },
        ua: {
            translations: translationUa,
        },
        pl: {
            translations: translationPl,
        },
        es: {
            translations: translationEs,
        },
        ru: {
            translations: translationRu,
        },
    },
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
        formatSeparator: ',',
    },
});

export default i18next;
