import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import actionTypes from './scoutingLists.actionTypes';
import ScoutingList, { ListUpdateRecord, ListsFolder } from './ScoutingList';

const initialState = {
    listsHash: {},
    listsUpdatesHash: {},
    listFolders: {},
};

type State = {
    listsHash: Record<string, ScoutingList>;
    listsUpdatesHash: Record<string, ListUpdateRecord>;
    listFolders: Record<string, ListsFolder[]>;
};

export default (state: State = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SCOUTING_LIST_RECEIVED:
            return {
                ...state,
                listsHash: {
                    ...state.listsHash,
                    [action.scoutingList.id]: action.scoutingList,
                },
            };
        case actionTypes.SCOUTING_LISTS_RECIEVED:
            return {
                ...state,
                listsHash: {
                    ...state.listsHash,
                    ..._keyBy(action.scoutingLists, 'id'),
                },
            };
        case actionTypes.LIST_UPDATES_RECEIVED:
            return {
                ...state,
                listsUpdatesHash: {
                    ...state.listsUpdatesHash,
                    ..._keyBy(action.listsUpdates, 'id'),
                },
            };
        case actionTypes.REMOVE_SCOUTING_LIST:
            return {
                ...state,
                listsHash: {
                    ..._omit(state.listsHash, action.listId),
                },
            };
        case actionTypes.SCOUTING_LIST_FOLDERS_RECEIVED:
            return {
                ...state,
                listFolders: {
                    ...state.listFolders,
                    [action.organizationId]: action.scoutingListFolders,
                },
            };
        default:
            return state;
    }
};
