import {
    green,
    deepPurple,
    cyan,
    amber,
    blue,
    deepOrange,
    indigo,
    teal,
    blueGrey,
    lightBlue,
    orange,
} from '@mui/material/colors';
import { DrillType } from './Drill';

export type ActivityConfig = {
    shortName: string;
    name: string;
    color: string;
};

export const gameConfig: ActivityConfig = {
    shortName: 'game',
    name: 'game',
    color: blue[300],
};

export const drillTypesConfig: { [key in DrillType]: ActivityConfig } = {
    [DrillType.APLI]: {
        shortName: 'AG',
        name: 'APLI',
        color: cyan[300],
    },
    [DrillType.SG]: {
        shortName: 'SG',
        name: 'SG',
        color: amber[300],
    },
    [DrillType.SP]: {
        shortName: 'SPC',
        name: 'SP',
        color: blue[300],
    },
    [DrillType.AR]: {
        shortName: 'FIN',
        name: 'AR',
        color: deepOrange[300],
    },
    [DrillType.POS]: {
        shortName: 'POS',
        name: 'POS',
        color: indigo[300],
    },
    [DrillType.POC]: {
        shortName: 'POC',
        name: 'POC',
        color: indigo[300],
    },
    [DrillType.CT]: {
        shortName: 'CT',
        name: 'CT',
        color: green[300],
    },
    [DrillType.IT]: {
        shortName: 'IT',
        name: 'IT',
        color: green[300],
    },
    [DrillType.WARMUP]: {
        shortName: 'WU',
        name: 'WARMUP',
        color: teal[300],
    },
    [DrillType.SETP]: {
        shortName: 'SET',
        name: 'SETP',
        color: blueGrey[300],
    },
    [DrillType.GPP]: {
        shortName: 'GPP',
        name: 'GPP',
        color: deepPurple[300],
    },
    [DrillType.SPP]: {
        shortName: 'SPP',
        name: 'SPP',
        color: lightBlue[300],
    },
    [DrillType.OTHER]: {
        shortName: 'OT',
        name: 'OTHER',
        color: orange[300],
    },
};

export const drillTypesColors = Array.from(
    new Set(Object.values(drillTypesConfig).map(({ color }) => color)),
);

export const WARMUPS = [
    { warmupType: 'Activation' },
    { warmupType: 'Core' },
    { warmupType: 'Speed' },
    { warmupType: 'Functional work' },
    { warmupType: 'Gym' },
    { warmupType: 'Specific' },
    { warmupType: 'Additional' },
];

/* Assigns 'duration: 0;' property to config */
export const getEventsDurationEmptyMap = (includeGames: boolean = false) => {
    const eventTypesMap = includeGames
        ? { ...drillTypesConfig, game: gameConfig }
        : drillTypesConfig;

    return Object.entries(eventTypesMap)
        .map(([loadType, config]: [string, any]) => [loadType, { ...config, duration: 0 }])
        .reduce((acc, [loadType, config]) => ({ ...acc, [loadType]: config }), {});
};
