import { nanoid } from 'nanoid';
import { toastr } from 'react-redux-toastr';
import { uploadFile } from 'common/resourses/files';
import * as organizationsGateways from 'gateways/organizationsGateway';
import { createStorageFile } from 'entities/storage-files/storageFiles.actions';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { StorageFileTargetType, StorageInfo } from 'entities/storage-files/StorageFile';
import Organization from './Organization';
import actionTypes from './organizations.actionTypes';
import * as organizationsActions from './organizations.actions';
import * as organizationsSelectors from './organizations.selectors';
import { organizationDataRecieved, organizationsListRecieved } from './organizations.actions';

export function* fetchOrganizationsListSaga({
    count,
}: ReturnType<typeof organizationsActions.organizationsListRequest>) {
    const organizationsList: Organization[] = yield call(
        organizationsGateways.fetchOrganizationsList,
        { count },
    );
    yield put(organizationsListRecieved({ organizationsList }));
}

export function* fetchOrganizationDataSaga({
    organizationId,
}: ReturnType<typeof organizationsActions.organizationDataRequest>) {
    try {
        const organizationData: Organization = yield call(
            organizationsGateways.fetchOrganizationById,
            {
                organizationId,
            },
        );
        yield put(organizationDataRecieved({ organizationData, organizationId }));
    } catch (e: any) {
        throw new Error(e);
    }
}

export function* fetchOrganizationStorageInfoSaga({
    organizationId,
}: ReturnType<typeof organizationsActions.organizationStorageInfoRequest>) {
    try {
        const orgStorageInfo: StorageInfo = yield call(
            organizationsGateways.fetchOrganizationsStorageInfo,
            {
                organizationId,
            },
        );

        if (orgStorageInfo) {
            yield put(
                organizationsActions.organizationStorageInfoRecieved({
                    orgStorageInfo,
                    organizationId,
                }),
            );
        }
    } catch (e: any) {
        call(toastr.error, 'Failed to fetch storage info', '');
    }
}

export function* updateOrganizationStorageInfoSaga({
    organizationId,
    orgStorageInfo,
}: ReturnType<typeof organizationsActions.organizationStorageInfoUpdate>) {
    try {
        yield call(organizationsGateways.updateOrganizationsStorageInfo, {
            orgStorageInfo,
            organizationId,
        });

        yield put(
            organizationsActions.organizationStorageInfoRequest({
                organizationId,
            }),
        );
    } catch (e: any) {
        call(toastr.error, 'Failed to update storage info', '');
    }
}

export function* fetchOrganizationPlayersInfoSaga({
    organizationId,
    onSuccess,
    onFail,
}: ReturnType<typeof organizationsActions.organizationPlayersInfoRequest>) {
    try {
        const organizationPlayersInfo: Record<string, string> = yield call(
            organizationsGateways.fetchOrganizationsPlayersInfo,
            { organizationId },
        );

        if (organizationPlayersInfo) {
            yield put(
                organizationsActions.organizationPlayersInfoRecieved({
                    organizationPlayersInfo,
                    organizationId,
                }),
            );
        }
        yield call(onSuccess);
    } catch (e: any) {
        yield call(onFail);
        call(toastr.error, 'Failed to fetch org players info', '');
    }
}

export function* fetchOrganizationClubsInfoSaga({
    organizationId,
    onSuccess,
    onFail,
}: ReturnType<typeof organizationsActions.organizationClubsInfoRequest>) {
    try {
        const organizationClubsInfo: Record<string, string> = yield call(
            organizationsGateways.fetchOrganizationsClubsInfo,
            { organizationId },
        );

        if (organizationClubsInfo) {
            yield put(
                organizationsActions.organizationClubsInfoRecieved({
                    organizationClubsInfo,
                    organizationId,
                }),
            );
        }
        yield call(onSuccess);
    } catch (e: any) {
        yield call(onFail);
        call(toastr.error, 'Failed to fetch org clubs info', '');
    }
}

export function* updateOrganizationDataSaga({
    organizationData,
    organizationId,
}: ReturnType<typeof organizationsActions.organizationDataUpdate>) {
    try {
        yield call(organizationsGateways.updateOrganizationData, {
            organizationData,
            organizationId,
        });
        yield call(fetchOrganizationDataSaga, {
            type: actionTypes.ORGANIZATION_DATA_REQUEST,
            organizationId,
        });
    } catch (e: any) {
        throw new Error(e);
    }
}

export function* addOrgLogoSaga({
    file,
    organizationId,
}: ReturnType<typeof organizationsActions.updateOrgLogo>) {
    try {
        const fileId = nanoid();
        const fileName = `logo-${fileId}.png`;
        const { filePath } = yield call(uploadFile, {
            file,
            filePath: `/organizations/${organizationId}/${fileName}`,
        });
        const { id, ...orgData } = yield select(organizationsSelectors.organizationSelector, {
            organizationId,
        });

        yield call(updateOrganizationDataSaga, {
            type: actionTypes.ORGANIZATION_DATA_UPDATE,
            organizationData: {
                ...orgData,
                logoPath: filePath,
            },
            organizationId: id,
        });

        yield put(
            createStorageFile({
                file,
                storageFileId: fileId,
                filePath,
                target: { organizationId },
                targetType: StorageFileTargetType.avatar,
            }),
        );
    } catch (e) {
        console.log(e);
        yield call(toastr.error, 'Oops', 'Failed to update logo');
    }
}

export default function* organizationsSagas() {
    yield all([
        takeEvery(actionTypes.ORGANIZATION_DATA_REQUEST, fetchOrganizationDataSaga),
        takeEvery(actionTypes.ORGANIZATION_STORAGE_INFO_REQUEST, fetchOrganizationStorageInfoSaga),
        takeEvery(actionTypes.ORGANIZATION_STORAGE_INFO_UPDATE, updateOrganizationStorageInfoSaga),
        takeEvery(actionTypes.ORGANIZATION_PLAYERS_INFO_REQUEST, fetchOrganizationPlayersInfoSaga),
        takeEvery(actionTypes.ORGANIZATION_CLUBS_INFO_REQUEST, fetchOrganizationClubsInfoSaga),
        takeLatest(actionTypes.ORGANIZATION_DATA_UPDATE, updateOrganizationDataSaga),
        takeLatest(actionTypes.ORGANIZATIONS_LIST_REQUEST, fetchOrganizationsListSaga),
        takeLatest(actionTypes.UPDATE_ORGANIZATION_LOGO, addOrgLogoSaga),
    ]);
}
