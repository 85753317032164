import { AccessTarget, AccessTypes } from 'entities/access/Access';
import actionTypes from './invitationPage.actionTypes';

export const submitInvitation = ({
    target,
    accessType,
    navigate,
    invitationId,
    targetName,
}: {
    target: AccessTarget;
    accessType: AccessTypes;
    invitationId: string;
    targetName: string;
    navigate: any;
}) => ({
    type: actionTypes.SUBMIT_INVITATION,
    target,
    accessType,
    navigate,
    invitationId,
    targetName,
});

export const startSubmit = () => ({
    type: actionTypes.START_SUBMIT,
});

export const stopSubmit = () => ({
    type: actionTypes.STOP_SUBMIT,
});
