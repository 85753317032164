import { Roles } from 'entities/access/Access';

export enum ObservationType {
    game = 'game',
    session = 'session',
    general = 'general',
    injury = 'injury',
    talk = 'talk',
    wellness = 'wellness',
    fullReport = 'fullReport',
    quickReport = 'quickReport',
    matchReport = 'matchReport',
    goal = 'goal',
    medicalNotes = 'medicalNotes',
}

export enum ObservationAccessType {
    private = 'private',
    team = 'team',
    all = 'all',
    scouts = 'scouts',
    client = 'client',
}

export type ObservationAuthor = {
    userId: string;
    name: string;
    filePath: string | null;
    role: Roles;
};

export type ObservationTarget = {
    organizationId?: string;
    teamId?: string;
    eventId?: string;
    goalId?: string;
    injuryId?: string;
};

export type ConclusionAccessRecord = {
    email: string;
};

export type FullReportConclusion = {
    content: string;
    availableFor: ConclusionAccessRecord[];
};

export type FullReport = {
    profile: string;
    physical: string;
    technical: string;
    tactical: string;
    conclusions: FullReportConclusion[];
};

export type QualitativeAspects = { [key: string]: { value: number } };

export type ObservationData = {
    editDate: Date;
    createDate: Date;
    accessType: ObservationAccessType;
    content: string;
    teamId: string;
    organizationId: string;
    playerId: string;
    type: ObservationType;
    author: ObservationAuthor;
    target: ObservationTarget | null;
    fullReport?: FullReport;
    details?: any;
    quantitiveAspects?: QualitativeAspects;
    attachments: string[];
};

type Observation = ObservationData & {
    id: string;
};

export default Observation;
