import { ButtonBase, Typography, Tooltip } from 'common/components';
import { styled } from '@mui/material/styles';
import { CheckListIcon } from 'common/icons';
import React, { useState } from 'react';
import AssistantContent from './AssistantContent';
import SlideDialogWithCloseBtn from 'common/components/SlideDialogWithCloseBtn';

export const StyledSidebarButton = styled(ButtonBase)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.shape.borderRadius,
    color: 'rgba(0, 0, 0, 0.54)',
    alignItems: 'center',

    '&:hover': {
        color: 'rgba(0, 0, 0, 1)',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },

    [theme.breakpoints.up('sm')]: {
        width: '100%',
    },
}));

type AssistantButtonProps = {
    title: string;
    className?: string;
    isExpanded: boolean;
    teamId: string;
    organizationId: string;
    hidden?: boolean;
};

const AssistantButton: React.FC<AssistantButtonProps> = ({
    title,
    className,
    isExpanded,
    teamId,
    organizationId,
    hidden,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    if (hidden) {
        return null;
    }

    return (
        <>
            <Tooltip title={title}>
                <StyledSidebarButton
                    className={className}
                    sx={{ width: isExpanded ? '100%' : 'fit-content' }}
                    onClick={() => setIsOpen(true)}
                >
                    <CheckListIcon style={{ color: 'orange' }} />
                    {isExpanded && (
                        <Typography variant="body1" sx={{ ml: 3 }} noWrap>
                            {title}
                        </Typography>
                    )}
                </StyledSidebarButton>
            </Tooltip>
            <SlideDialogWithCloseBtn isOpen={isOpen} onClose={() => setIsOpen(false)}>
                {isOpen && (
                    <AssistantContent
                        teamId={teamId}
                        organizationId={organizationId}
                        hidden={hidden}
                        onClose={() => setIsOpen(false)}
                    />
                )}
            </SlideDialogWithCloseBtn>
        </>
    );
};

export default AssistantButton;
