import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Button, Tooltip, Typography } from 'common/components';
import { AccountCircleIcon } from 'common/icons';
import { AuthState } from 'features/auth/Auth';
import { authStateSelector } from 'features/auth/auth.selectors';
import { requestSignOut } from 'features/auth/auth.actions';

const StyledAuthIcon = styled(Link)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.54)',

    '&:hover': {
        color: 'rgba(0, 0, 0, 1)',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
}));


type AuthIconProps = {
    isExpanded: boolean;
    setExpanded: Function;
};

const AuthIcon: React.FC<AuthIconProps> = ({ isExpanded, setExpanded }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authState = useSelector(authStateSelector);

    const handleSignOut = useCallback(() => {
        navigate('/');
        dispatch(requestSignOut());
    }, []);

    if ([AuthState.notAuthenticated, null].includes(authState)) {
        return (
            <Button
                variant="contained"
                sx={{
                    m: isExpanded ? 0 : -1,
                    mt: 1,
                    transform: isExpanded ? 'none' : 'scale(0.9)',
                }}
                color="primary"
                size="small"
                component={Link}
                to="/auth/login"
                onClick={() => setExpanded(false)}
            >
                {t('auth.signIn')}
            </Button>
        );
    }

    if (authState === AuthState.notVerified) {
        return (
            <Button
                variant="contained"
                sx={{
                    m: isExpanded ? 0 : -1,
                    mt: 1,
                    transform: isExpanded ? 'none' : 'scale(0.9)',
                }}
                color="primary"
                size="small"
                onClick={handleSignOut}
            >
                {t('auth.signOut')}
            </Button>
        );
    }

    return (
        <Tooltip title={String(t('sidebar.profile'))}>
            <StyledAuthIcon to="/account/profile" onClick={() => setExpanded(false)}>
                <AccountCircleIcon />
                {isExpanded && (
                    <Typography sx={{ ml: 3 }} noWrap>
                        {t('sidebar.profile')}
                    </Typography>
                )}
            </StyledAuthIcon>
        </Tooltip>
    );
};

export default AuthIcon;
