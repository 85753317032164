import React, { Component } from 'react';

const ballColor = {
    r: 150,
    g: 150,
    b: 150,
};
const R = 2;
const alphaF = 0.03;
const linkLineWidth = 0.8;
const disLimit = 260;

function randomArrayItem(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
}
function randomNumFrom(min, max) {
    return Math.random() * (max - min) + min;
}

function randomSidePos(length) {
    return Math.ceil(Math.random() * length);
}

const getDisOf = (b1, b2) => {
    const deltaX = Math.abs(b1.x - b2.x);
    const deltaY = Math.abs(b1.y - b2.y);

    return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
};

function getRandomSpeed(pos) {
    const min = -1;
    const max = 1;
    switch (pos) {
        case 'top':
            return [randomNumFrom(min, max), randomNumFrom(0.1, max)];
        case 'right':
            return [randomNumFrom(min, -0.1), randomNumFrom(min, max)];
        case 'bottom':
            return [randomNumFrom(min, max), randomNumFrom(min, -0.1)];
        case 'left':
            return [randomNumFrom(0.1, max), randomNumFrom(min, max)];
        default:
            return null;
    }
}

/* eslint-disable react/destructuring-assignment */
class AnimatedBackground extends Component {
    constructor(props) {
        super(props);

        this.ball = {
            x: 0,
            y: 0,
            vx: 0,
            vy: 0,
            r: 0,
            alpha: 1,
            phase: 0,
        };
        this.balls = [];
        this.mouseBall = {
            x: 0,
            y: 0,
            vx: 0,
            vy: 0,
            r: 0,
            type: 'mouse',
        };
        this.mouseIn = false;
    }

    componentDidMount() {
        this.canvasRef.addEventListener('mouseenter', this.onMouseEnter);
        this.canvasRef.addEventListener('mouseleave', this.onMouseLeave);
        this.canvasRef.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('resize', this.onWindowResize);
        this.goMovie();
    }

    componentWillUnmount() {
        this.canvasRef.removeEventListener('mouseenter', this.onMouseEnter);
        this.canvasRef.removeEventListener('mouseleave', this.onMouseLeave);
        this.canvasRef.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('resize', this.onWindowResize);
        cancelAnimationFrame(this.animationFrame);
    }

    onMouseLeave = () => {
        this.mouseIn = false;
        this.balls = this.balls.filter(({ type }) => !type);
    };

    onWindowResize = () => {
        this.initCanvas();
    };

    onMouseMove = (event) => {
        this.mouseBall.x = event.pageX;
        this.mouseBall.y = event.pageY;
    };

    onMouseEnter = () => {
        this.mouseIn = true;
        this.balls.push(this.mouseBall);
    };

    getRandomBall = () => {
        const pos = randomArrayItem(['top', 'right', 'bottom', 'left']);
        switch (pos) {
            case 'top':
                return {
                    x: randomSidePos(this.canvasWidth),
                    y: -R,
                    vx: getRandomSpeed('top')[0],
                    vy: getRandomSpeed('top')[1],
                    r: R,
                    alpha: 1,
                    phase: randomNumFrom(0, 10),
                };
            case 'right':
                return {
                    x: this.canvasWidth + R,
                    y: randomSidePos(this.canvasHeight),
                    vx: getRandomSpeed('right')[0],
                    vy: getRandomSpeed('right')[1],
                    r: R,
                    alpha: 1,
                    phase: randomNumFrom(0, 10),
                };
            case 'bottom':
                return {
                    x: randomSidePos(this.canvasWidth),
                    y: this.canvasHeight + R,
                    vx: getRandomSpeed('bottom')[0],
                    vy: getRandomSpeed('bottom')[1],
                    r: R,
                    alpha: 1,
                    phase: randomNumFrom(0, 10),
                };
            case 'left':
                return {
                    x: -R,
                    y: randomSidePos(this.canvasHeight),
                    vx: getRandomSpeed('left')[0],
                    vy: getRandomSpeed('left')[1],
                    r: R,
                    alpha: 1,
                    phase: randomNumFrom(0, 10),
                };
            default:
                return null;
        }
    };

    setCanvasRef = (node) => {
        if (node) {
            this.canvasRef = node;
            this.context = this.canvasRef.getContext('2d');
        }
    };

    updateBalls = () => {
        this.balls = this.balls
            .map(({ x, y, phase, vx, vy, ...ball }) => ({
                ...ball,
                vx,
                vy,
                x: x + vx,
                y: y + vy,
                phase: phase + alphaF,
                alpha: Math.abs(Math.cos(phase)),
            }))
            .filter(
                ({ x, y }) =>
                    x > -50 && x < this.canvasWidth + 50 && y > -50 && y < this.canvasHeight + 50,
            );
    };

    addBallIfy = () => {
        if (this.balls.length < this.pointsCount) {
            this.balls = this.balls.concat(this.getRandomBall());
        }
    };

    initBalls = (num) => {
        this.balls = Array(num)
            .fill()
            .map(() => ({
                x: randomSidePos(this.canvasWidth),
                y: randomSidePos(this.canvasHeight),
                vx: getRandomSpeed('top')[0],
                vy: getRandomSpeed('top')[1],
                r: R,
                alpha: 1,
                phase: randomNumFrom(0, 10),
            }));
    };

    goMovie = () => {
        this.initCanvas();
        this.initBalls(this.pointsCount);
        this.animationFrame = requestAnimationFrame(this.renderBackground);
    };

    initCanvas = () => {
        this.canvasRef.setAttribute('width', window.innerWidth);
        this.canvasRef.setAttribute('height', window.innerHeight);
        this.canvasWidth = parseInt(this.canvasRef.getAttribute('width'), 10);
        this.canvasHeight = parseInt(this.canvasRef.getAttribute('height'), 10);
        this.pointsCount = Math.ceil((this.canvasWidth * this.canvasHeight) / 15000);
    };

    renderBalls = () => {
        this.balls.forEach((ball) => {
            if (!ball.type) {
                const { r, g, b } = ballColor;
                this.context.fillStyle = `rgba(${r},${g}, ${b},${ball.alpha})`;
                this.context.beginPath();
                this.context.arc(ball.x, ball.y, R, 0, Math.PI * 2, true);
                this.context.closePath();
                this.context.fill();
            }
        });
    };

    renderLine = (ball, anotherBall, alpha) => {
        this.context.strokeStyle = `rgba(220,220,220,${alpha})`;
        this.context.lineWidth = linkLineWidth;
        this.context.beginPath();
        this.context.moveTo(ball.x, ball.y);
        this.context.lineTo(anotherBall.x, anotherBall.y);
        this.context.stroke();
        this.context.closePath();
    };

    renderLines = () => {
        const allBalls = this.mouseIn ? this.balls.concat(this.mouseBall) : this.balls;
        allBalls.forEach((ball, i, balls) => {
            const restBalls = balls.slice(i);
            restBalls.forEach((anotherBall) => {
                const fraction = getDisOf(ball, anotherBall) / disLimit;
                if (fraction < 1) {
                    this.renderLine(ball, anotherBall, 1 - fraction);
                }
            });
        });
    };

    renderBackground = () => {
        if (this.context.clearRect) {
            this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
            this.renderBalls();
            this.renderLines();
            this.updateBalls();
            this.addBallIfy();
        } else {
            this.context = this.canvasRef ? this.canvasRef.getContext('2d') : {};
        }
        this.animationFrame = requestAnimationFrame(this.renderBackground);
    };

    render() {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    overflowY: 'auto',
                }}
            >
                <canvas
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                    }}
                    ref={this.setCanvasRef}
                    width="800"
                    height="800"
                />
                {this.props.children}
            </div>
        );
    }
}

export default AnimatedBackground;
