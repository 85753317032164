import { FORMATIONS } from 'common/components/pitch';
import { PitchPosition } from 'common/components/pitch/PitchPosition';
import { Positions } from 'common/components/pitch/positionsCoordinates';
import { playerToEventInfo } from 'entities/events/events.utils';
import {
    ActivityType,
    GameActivity,
    Lineup,
    LineupPosition,
    PlayerStatus,
    Substitution,
} from 'entities/events/Game';
import Player, { PlayerPositions } from 'entities/players/Player';
import { positionSortFunc } from 'entities/players/players.utils';
import _get from 'lodash/get';
import { DEFAULT_BENCH_SIZE } from './constants';

export const getFormationPositions = (formationCode: string): string[] =>
    _get(FORMATIONS, `${formationCode}.positions`, []);

export const lineupToPosition = (lineupPosition: LineupPosition): PitchPosition => {
    const { player, gamePosition } = lineupPosition;
    const { id, lastName, positions, playerNumber, filePath } = player;
    return {
        // @ts-ignore
        position: gamePosition,
        // @ts-ignore
        priority: _get(positions, gamePosition, 4),
        player: {
            number: playerNumber || '',
            playerId: id,
            name: lastName,
            possiblePositions: positions,
            filePath,
        },
    };
};

export const mapPlayersToPriorPositions = (
    lineup: LineupPosition[],
    formationCode: string,
): Map<string, LineupPosition> => {
    const formationPositions = getFormationPositions(formationCode);
    const emptyPositions = new Set<string>(formationPositions);
    const lineupList = Object.values(lineup);
    const freePlayers = new Set<LineupPosition>(lineupList);
    const result = new Map();

    function addPlayer(player: LineupPosition, position: string) {
        result.set(position, player);
        emptyPositions.delete(position);
        freePlayers.delete(player);
    }

    emptyPositions.forEach((pos: string) => {
        const matchedPlayer = lineupList.find((player) => player.gamePosition === pos);

        if (matchedPlayer) {
            addPlayer(matchedPlayer, pos);
        }
    });

    function assignPositionsWithPriority(priority: number) {
        emptyPositions.forEach((pos) => {
            const matchedPlayer = Array.from(freePlayers).find(
                (player) => _get(player, `positions[${pos}]`) === priority,
            );
            if (matchedPlayer) {
                addPlayer(matchedPlayer, pos);
            }
        });
    }

    if (freePlayers.size > 0) {
        assignPositionsWithPriority(1);
    }

    if (freePlayers.size > 0) {
        assignPositionsWithPriority(2);
    }

    if (freePlayers.size > 0) {
        assignPositionsWithPriority(3);
    }

    if (freePlayers.size > 0) {
        freePlayers.forEach((player) => {
            if (emptyPositions.size > 0) {
                addPlayer(player, Array.from(emptyPositions)[0]);
            }
        });
    }

    return result;
};

const playerToLineupPosition = (player: Player): LineupPosition => {
    return {
        player: playerToEventInfo(player),
        status: PlayerStatus.bench,
        gamePosition: null,
        activities: [],
        // observations: [],
        time: 0,
        gameTime: 0,
    };
};

/*
 * Fills formation positions with available players
 * If players count is more than positions then assign player by position priority
 * Some of players will be missed in result formation map
 */
export const applyFormation = (
    lineupPlayers: LineupPosition[],
    formationCode: string,
): LineupPosition[] => {
    const positionsMap = mapPlayersToPriorPositions(lineupPlayers, formationCode);

    return Array.from(positionsMap).map((entry) => {
        const [gamePosition, lineupPos] = entry;

        return { ...lineupPos, gamePosition, status: PlayerStatus.lineup };
    });
};

export const getInitialLineup = (playersList: Player[], formationCode: string): Lineup => {
    const lineupPositions = playersList.map(playerToLineupPosition);
    const startingLineup = applyFormation(lineupPositions, formationCode);
    const startingLineupPlayersIds = startingLineup.map(({ player }) => player.id);
    const benchPlayers = lineupPositions.filter(
        ({ player }) => !startingLineupPlayersIds.includes(player.id),
    );
    const bench = benchPlayers.slice(0, DEFAULT_BENCH_SIZE);
    const reserve = benchPlayers.slice(DEFAULT_BENCH_SIZE);

    return startingLineup
        .concat(bench)
        .concat(reserve)
        .reduce(
            (acc, lineupPosition) => ({ ...acc, [lineupPosition.player.id]: lineupPosition }),
            {},
        );
};

export const getActivities = (activities: GameActivity[], activityName: string) =>
    activities.filter((activity) => activity.action === activityName);

export type PlayersPair = { lineup?: LineupPosition; bench?: LineupPosition };

export const getSortedLists = (lineupPositions: LineupPosition[]) => {
    const lineupPlayersList = lineupPositions.filter(
        (lineupPosition) => lineupPosition.status === PlayerStatus.lineup,
    );
    const benchPlayersList = lineupPositions.filter(
        (lineupPosition) => lineupPosition.status === PlayerStatus.bench,
    );

    lineupPlayersList.sort((a, b) => positionSortFunc(a.player, b.player));
    benchPlayersList.sort((a, b) => positionSortFunc(a.player, b.player));

    return { lineupPlayersList, benchPlayersList };
};

export const checkOutSubstitution = (substitutions: Substitution[], playerId: string) => {
    const outSubstitution = substitutions.find((substitution) => substitution.out === playerId);

    return Boolean(outSubstitution);
};

export const checkInSubstitution = (substitutions: Substitution[], playerId: string) => {
    const outSubstitution = substitutions.find((substitution) => substitution.in === playerId);

    return Boolean(outSubstitution);
};

export const isDisabledActivityItem = ({
    activities = [],
    activity,
    substitutions,
    playerId,
    isBenchPlayer = false,
    hasGameTime,
}: {
    activities?: GameActivity[];
    activity: string;
    playerId: string;
    isBenchPlayer?: boolean;
    substitutions: Substitution[];
    hasGameTime: boolean;
}): boolean => {
    const hasRedCard = Boolean(activities.find(({ action }) => action === ActivityType.redCard));
    const yellowCards = activities.filter(({ action }) => action === ActivityType.yellowCard);
    const hasInjuryCard = Boolean(activities.find(({ action }) => action === ActivityType.injury));
    const hasOutSubstitution = checkOutSubstitution(substitutions, playerId);

    if (activity === ActivityType.redCard) {
        return hasInjuryCard || hasRedCard || hasOutSubstitution || yellowCards.length === 2;
    }

    if (activity === ActivityType.substitution) {
        return (
            hasRedCard ||
            hasOutSubstitution ||
            (isBenchPlayer && hasOutSubstitution) ||
            (!isBenchPlayer && hasOutSubstitution) ||
            yellowCards.length === 2 ||
            !hasGameTime
        );
    }

    if (activity === ActivityType.yellowCard) {
        return (
            hasRedCard ||
            yellowCards.length === 2 ||
            (yellowCards.length === 1 && hasOutSubstitution && !isBenchPlayer)
        );
    }

    if (activity === ActivityType.injury) {
        return hasInjuryCard;
    }

    if (activity === ActivityType.ball) {
        return !hasGameTime;
    }

    if (activity === ActivityType.assist) {
        return !hasGameTime;
    }

    return false;
};

export const getPlayedBenchPlayers = (
    lineup: Lineup,
    substitutions: Substitution[],
): LineupPosition[] => {
    const bench = Object.values(lineup).filter((player) => player.status === PlayerStatus.bench);

    const activeBenchPlayers = substitutions.map((playersSet) =>
        bench.find(({ player }) => player.id === playersSet.in),
    );
    // TODO: remove filter | sometimes bench players is an array like [undefined]
    // @ts-ignore
    return activeBenchPlayers.filter((player) => Boolean(player));
};

export const checkIfAvailableForSubstitution = (
    lineup: Lineup,
    substitutions: Substitution[],
    playerId?: string,
) => {
    if (!playerId) {
        return false;
    }

    const player = Object.values(lineup).find(({ player }) => player.id === playerId);

    if (!player) {
        return false;
    }

    const isLineup = player.status === PlayerStatus.lineup;

    const hasOutSubstitution = checkOutSubstitution(substitutions, playerId);

    if (isLineup) {
        return hasOutSubstitution;
    }

    const hasInSubstitution = checkInSubstitution(substitutions, playerId);

    return !hasInSubstitution || hasOutSubstitution;
};

export const getPositionsByPriority = (positions: PlayerPositions, priority: number) => {
    const positionsByPriority = Object.entries(positions).reduce(
        (acc: Positions[], [pos, prior]) => {
            if (prior === priority) {
                return acc.concat(pos as Positions);
            }
            return acc;
        },
        [],
    );

    positionsByPriority.sort();

    return positionsByPriority;
};

export const getPlayerSubstitutions = (substitutions: Substitution[], playerId: string) => {
    const substitutionsList = substitutions
        .filter((substitution) => {
            return substitution.in === playerId || substitution.out === playerId;
        })
        .map((substitution) => {
            return {
                action:
                    substitution.in === playerId
                        ? ActivityType.inSubstitution
                        : ActivityType.outSubstitution,
                minute: substitution.time || 0,
                data: substitution,
            };
        });

    return substitutionsList;
};

export const isSameSubstitution = (s1: Substitution, s2: Substitution) => {
    if (s1.in !== s2.in) {
        return false;
    }

    if (s1.out !== s2.out) {
        return false;
    }

    return s1.time === s2.time;
};
