import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import actionTypes from './cycles.actionTypes';
import Cycyle from './Cycle';

type ChallengeState = {
    cyclesHash: {
        [key: string]: Cycyle;
    };
};

const initialState: ChallengeState = {
    cyclesHash: {},
};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.CYCLE_DATA_RECEIVED: {
            return {
                ...state,
                cyclesHash: {
                    ...state.cyclesHash,
                    [action.cycle.id]: action.cycle,
                },
            };
        }
        case actionTypes.CYCLES_LIST_RECEIVED: {
            return {
                ...state,
                cyclesHash: {
                    ...state.cyclesHash,
                    ..._keyBy(action.cycles, 'id'),
                },
            };
        }
        default:
            return state;
    }
};
