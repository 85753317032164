import * as medicalRecordsGateway from 'gateways/medicalRecordsGateway';
import { put, call, all, takeLatest, takeEvery } from 'redux-saga/effects';
import * as medicalRecordsAction from './medicalRecords.actions';
import MedicalRecord from './MedicalRecords';
import medicalRecordsActionTypes from './medicalRecords.actionTypes';

export function* createMedicalRecordSaga({
    medicalRecordData,
    onSuccess,
    onFail,
}: ReturnType<typeof medicalRecordsAction.createMedicalRecord>) {
    try {
        const medicalRecordId: string = yield call(medicalRecordsGateway.createMedicalRecord, {
            medicalRecordData,
        });

        yield put(
            medicalRecordsAction.medicalRecordRequest({
                medicalRecordId,
                playerId: medicalRecordData.playerId,
            }),
        );

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        if (onFail) {
            yield call(onFail);
        }
        console.error(e);
    }
}

export function* updateMedicalRecordSaga({
    medicalRecordData,
    medicalRecordId,
    onSuccess,
    onFail,
}: ReturnType<typeof medicalRecordsAction.updateMedicalRecord>) {
    try {
        yield call(medicalRecordsGateway.updateMedicalRecord, {
            medicalRecordData,
            medicalRecordId,
        });

        yield put(
            medicalRecordsAction.medicalRecordRequest({
                medicalRecordId,
                playerId: medicalRecordData.playerId,
            }),
        );

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        if (onFail) {
            yield call(onFail);
        }

        console.error(e);
    }
}

export function* fetchMedicalRecordDataSaga({
    playerId,
    medicalRecordId,
    onSuccess,
}: ReturnType<typeof medicalRecordsAction.medicalRecordRequest>) {
    try {
        const medicalRecord: MedicalRecord = yield call(
            medicalRecordsGateway.fetchMedicalRecordById,
            {
                medicalRecordId,
                playerId,
            },
        );
        if (medicalRecord) {
            yield put(medicalRecordsAction.medicalRecordDataRecieved(medicalRecord));
        }
        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

export function* fetchPlayerMedicalRecordsListsSaga({
    organizationId,
    playerId,
    testId,
    limit,
    onSuccess,
}: ReturnType<typeof medicalRecordsAction.playerMedicalRecordsRequest>) {
    try {
        const medicalRecordsList: MedicalRecord[] = yield call(
            [medicalRecordsGateway, medicalRecordsGateway.fetchPlayerMedicalRecordsList],
            {
                organizationId,
                playerId,
                testId,
                limit,
            },
        );
        if (medicalRecordsList) {
            yield put(medicalRecordsAction.medicalRecordsListRecieved(medicalRecordsList));
        }

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

export function* fetchMedicalRecordsByTestIdListsSaga({
    organizationId,
    teamId,
    medicalTestId,
    onSuccess,
}: ReturnType<typeof medicalRecordsAction.medicalRecordsByTestIdRequest>) {
    try {
        const medicalRecordsList: MedicalRecord[] = yield call(
            [medicalRecordsGateway, medicalRecordsGateway.getMedicalRecordsByTestId],
            {
                testId: medicalTestId,
                organizationId,
                teamId,
            },
        );
        if (medicalRecordsList) {
            yield put(medicalRecordsAction.medicalRecordsListRecieved(medicalRecordsList));
        }

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

function* medicalRecordsSagas() {
    yield all([
        takeLatest(medicalRecordsActionTypes.MEDICAL_RECORD_CREATE, createMedicalRecordSaga),
        takeLatest(medicalRecordsActionTypes.MEDICAL_RECORD_UPDATE, updateMedicalRecordSaga),
        takeLatest(medicalRecordsActionTypes.MEDICAL_RECORD_REQUEST, fetchMedicalRecordDataSaga),
        takeLatest(
            medicalRecordsActionTypes.MEDICAL_RECORDS_BY_TEST_ID_REQUEST,
            fetchMedicalRecordsByTestIdListsSaga,
        ),
        takeEvery(
            medicalRecordsActionTypes.PLAYER_MEDICAL_RECORDS_REQUEST,
            fetchPlayerMedicalRecordsListsSaga,
        ),
    ]);
}

export default medicalRecordsSagas;
