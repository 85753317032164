import {
    FitnessDataMetadata,
    TeamEventFitnessRecord,
    TeamEventFitnessRecordData,
} from '../fitness-data/FitnessData';
import actionTypes from './fitnessData.actionTypes';

export const eventFitnessRecordsCreate = ({
    fitnessData,
    onFail,
    onSuccess,
}: {
    fitnessData: TeamEventFitnessRecordData;
    onFail?: VoidFunction;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.ADD_EVENT_FITNESS_RECORDS,
    fitnessData,
    onFail,
    onSuccess,
});

export const eventFitnessRecordsDeleteRequest = ({
    organizationId,
    fitnessDataId,
    eventId,
    onFail,
    onSuccess,
}: {
    organizationId: string;
    fitnessDataId: string;
    eventId: string;
    onFail?: VoidFunction;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.EVENT_FITNESS_RECORDS_DELETE_REQUEST,
    eventId,
    organizationId,
    fitnessDataId,
    onFail,
    onSuccess,
});

export const removeEventFitnessRecords = ({ fitnessDataId }: { fitnessDataId: string }) => ({
    type: actionTypes.REMOVE_EVENT_FITNESS_RECORDS,
    fitnessDataId,
});

export const eventFitnessRecordsRequest = ({
    organizationId,
    eventId,
}: {
    organizationId: string;
    eventId: string;
}) => ({
    type: actionTypes.EVENT_FITNESS_RECORDS_REQUEST,
    organizationId,
    eventId,
});

export const eventFitnessRecordsRecieved = (fitnessData: TeamEventFitnessRecordData) => ({
    type: actionTypes.EVENT_FITNESS_RECORDS_RECEIVED,
    fitnessData,
});

export const eventFitnessRecordsUpdate = ({
    organizationId,
    fitnessData,
    onFail,
    onSuccess,
}: {
    organizationId: string;
    fitnessData: TeamEventFitnessRecord;
    onFail?: VoidFunction;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.EVENT_FITNESS_RECORDS_UPDATE,
    organizationId,
    fitnessData,
    onFail,
    onSuccess,
});

export const playerFitnessRecordsListRequest = ({
    playerId,
    organizationId,
    teamId,
    startDate,
    endDate,
}: {
    playerId: string;
    organizationId: string;
    teamId: string;
    startDate: Date;
    endDate: Date;
}) => ({
    type: actionTypes.PLAYER_FITNESS_RECORDS_LIST_REQUEST,
    playerId,
    organizationId,
    teamId,
    startDate,
    endDate,
});

export const teamFitnessRecordsListRequest = ({
    organizationId,
    teamId,
    startDate,
    endDate,
}: {
    organizationId: string;
    teamId: string;
    startDate: Date;
    endDate: Date;
}) => ({
    type: actionTypes.TEAM_FITNESS_RECORDS_LIST_REQUEST,
    organizationId,
    teamId,
    startDate,
    endDate,
});

export const fitnessRecordsListRecieved = (fitnessData: TeamEventFitnessRecordData[]) => ({
    type: actionTypes.FITNESS_RECORDS_LIST_RECEIVED,
    fitnessData,
});

export const fitnessDataMetadataRequest = (organizationId: string) => ({
    type: actionTypes.ORG_FITNESS_DATA_METADATA_REQUEST,
    organizationId,
});

export const fitnessDataMetadataRecieved = ({
    orgFitnessDataMetadata,
    organizationId,
}: {
    orgFitnessDataMetadata: FitnessDataMetadata;
    organizationId: string;
}) => ({
    type: actionTypes.ORG_FITNESS_DATA_METADATA_RECEIVED,
    orgFitnessDataMetadata,
    organizationId,
});

export const fitnessDataMetadataUpdate = ({
    orgFitnessDataMetadata,
    organizationId,
}: {
    orgFitnessDataMetadata: FitnessDataMetadata;
    organizationId: string;
}) => ({
    type: actionTypes.ORG_FITNESS_DATA_METADATA_UPDATE,
    orgFitnessDataMetadata,
    organizationId,
});
