import { useSelector } from 'react-redux';
import Player from 'entities/players/Player';
import User, { Author, Language } from './User';
import { userDataSelector } from './users.selectors';

export const getPlayerName = (
    player?: Pick<Player, 'firstName' | 'lastName' | 'enFirstName' | 'enLastName'>,
    user?: User,
) => {
    if (!player) {
        return { firstName: '', lastName: '', fullName: '' };
    }

    const { firstName, lastName, enFirstName, enLastName } = player;
    const isEn = user?.lang === Language.en;

    const fn = isEn ? enFirstName || firstName : firstName || enFirstName;
    const ln = isEn ? enLastName || lastName : lastName || enLastName;

    return { firstName: fn, lastName: ln, fullName: `${ln} ${fn}` };
};

export const usePlayerName = (
    player?: Pick<Player, 'firstName' | 'lastName' | 'enFirstName' | 'enLastName'>,
) => {
    const user = useSelector(userDataSelector);

    return getPlayerName(player, user);
};

export const getAuthor = (user: User | null): Author | null => {
    if (!user) {
        return null;
    }

    return {
        name: user.name || user.title || '',
        role: null,
        filePath: null,
        email: user.email,
        userId: user.id,
    };
};

export const convertUserToAuthorInfo = (user?: User | null): Author | null => {
    if (!user) {
        return null;
    }

    const authorInfo: Author = {
        name: user.name || user.title,
        email: user.email,
        filePath: null,
        userId: user.id,
        role: null,
    };

    return authorInfo;
};

export const useCurrentUser = () => {
    const user = useSelector(userDataSelector);

    return user;
};
