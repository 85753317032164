import { db, functions } from 'common/firebase';
import { DrillData } from 'entities/drills/Drill';
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { handleDocumentSnapshot, handleCollectionSnapshot } from './utils';

export const fetchDrillById = ({
    drillId,
    methodologyId,
}: {
    drillId: string;
    methodologyId: string;
}) => {
    const drillRef = doc(db, `methodologies/${methodologyId}/drills/${drillId}`);

    return getDoc(drillRef).then(handleDocumentSnapshot);
};

export const updateDrill = ({ drillId, drillData }: { drillId: string; drillData: DrillData }) => {
    const drillRef = doc(db, `methodologies/${drillData.methodologyId}/drills/${drillId}`);

    return updateDoc(drillRef, drillData);
};

export const createDrill = ({ drillData }: { drillData: DrillData }) => {
    const drillsRef = collection(db, `methodologies/${drillData.methodologyId}/drills`);

    return addDoc(drillsRef, drillData).then((doc) => doc.id);
};

export const fetchDrillsByType = ({
    drillType,
    methodologyId,
}: {
    drillType: string;
    methodologyId: string;
}) => {
    const q = query(
        collection(db, `methodologies/${methodologyId}/drills`),
        where('details.drillType', '==', drillType),
    );

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchDrills = ({ methodologyId }: { methodologyId: string }) => {
    const drillsRef = collection(db, `methodologies/${methodologyId}/drills`);

    return getDocs(drillsRef).then(handleCollectionSnapshot);
};

export const deleteDrill = httpsCallable(functions, 'deleteDrill');

export const copyDrill = httpsCallable(functions, 'copyDrill');
