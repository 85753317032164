import { db } from 'common/firebase';
import { AgentData } from 'entities/agents/Agents';
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';
import { handleCollectionSnapshot, handleDocumentSnapshot } from 'gateways/utils';

export const fetchAgentById = ({ agentId }: { agentId: string }) => {
    const agentRef = doc(db, `agents/${agentId}`);

    return getDoc(agentRef).then(handleDocumentSnapshot);
};

export const createAgent = ({ agentData }: { agentData: AgentData }) => {
    const agentRef = collection(db, 'agents');

    return addDoc(agentRef, agentData).then((doc) => doc.id);
};

export const updateAgent = ({ agentId, agentData }: { agentId: string; agentData: AgentData }) => {
    const agentRef = doc(db, `agents/${agentId}`);

    return updateDoc(agentRef, agentData);
};

export const fetchAgentsList = ({ organizationId }: { organizationId: string }) => {
    const agentsRef = collection(db, 'agents');

    const q = query(agentsRef, where('organizationId', '==', organizationId));

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchAgentsWithoutAgencyList = ({ organizationId }: { organizationId: string }) => {
    const agentsRef = collection(db, 'agents');

    const q = query(
        agentsRef,
        where('organizationId', '==', organizationId),
        where('agency', '==', null),
    );

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchAgencyAgentsList = ({ agencyId }: { agencyId: string }) => {
    const agentsRef = collection(db, 'agents');

    const q = query(agentsRef, where('agency.id', '==', agencyId));
    return getDocs(q).then(handleCollectionSnapshot);
};
