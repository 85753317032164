import { takeLatest, all, call } from 'redux-saga/effects';

import { createEvaluationSaga, editEvaluationSaga } from 'entities/evaluations/evaluations.sagas';
import * as evaluationPageActions from './evaluationPage.actions';
import actionTypes from './evaluationPage.actionTypes';

export function* setEvaluationSaga({
    evaluationData,
    evaluationId,
}: ReturnType<typeof evaluationPageActions.setEvaluation>) {
    try {
        if (!evaluationId) {
            yield call(createEvaluationSaga, { evaluationData });
        } else {
            yield call(editEvaluationSaga, { evaluationData, evaluationId });
        }
    } catch (e) {
        console.error(e);
    }
}

function* evaluationPageSagas() {
    yield all([takeLatest(actionTypes.SET_EVALUATION, setEvaluationSaga)]);
}

export default evaluationPageSagas;
