import { RootState } from 'store';
import { createSelector } from 'reselect';
import { isBefore } from 'date-fns';
import ScoutingList, { ListStatus } from './ScoutingList';
import { isBetween } from 'common/utils/dateTimeHelpers';
import { organizationPlayersInfoSelector } from 'entities/organizations/organizations.selectors';

export const scoutingListsHashSelector = (state: RootState) =>
    state.entities.scoutingLists.listsHash;

export const scoutingListFoldersHashSelector = (state: RootState) =>
    state.entities.scoutingLists.listFolders;

export const scoutingListFoldersSelector = createSelector(
    scoutingListFoldersHashSelector,
    (_: any, { organizationId }: { organizationId: string }) => organizationId,
    (scoutingListFoldersHash, organizationId) => {
        return scoutingListFoldersHash[organizationId];
    },
);

export const listsUpdatesHashSelector = (state: RootState) =>
    state.entities.scoutingLists.listsUpdatesHash;

export const listsUpdatesSelector = createSelector(listsUpdatesHashSelector, (listsUpdatesHash) => {
    return Object.values(listsUpdatesHash);
});

export const scoutingListsSelector = createSelector(
    scoutingListsHashSelector,
    (_: any, { organizationId }: { organizationId: string }) => organizationId,
    (scoutingListsHash, organizationId) => {
        const scoutingListsList: ScoutingList[] = Object.values(scoutingListsHash);
        return scoutingListsList.filter(
            (scoutingList: ScoutingList) =>
                scoutingList.organizationId === organizationId &&
                scoutingList.status === ListStatus.active,
        );
    },
);

export const longlistsSelector = createSelector(
    scoutingListsSelector,
    (_: any, { listType }: { listType: string }) => listType,
    (scoutingLists, listType) => {
        return scoutingLists.filter((scoutingList: ScoutingList) => scoutingList.type === listType);
    },
);

export const scoutingListSelector = createSelector(
    scoutingListsHashSelector,
    (_: any, { listId }: { listId: string }) => listId,
    (scoutingListsHash, listId) => scoutingListsHash[listId],
);

export const shadowTeamsSelector = createSelector(
    scoutingListsSelector,
    (_: any, { parentListId }: { parentListId: string }) => parentListId,
    (scoutingLists, parentListId) => {
        return scoutingLists.filter((list) => list.parentListId === parentListId);
    },
);

export const userListUpdatesForPeriodSelector = createSelector(
    listsUpdatesSelector,
    (_: any, { organizationId }: { organizationId: string }) => organizationId,
    (_: any, { userId }: { userId: string }) => userId,
    (_: any, { fromDate }: { fromDate: Date }) => fromDate,
    (_: any, { toDate }: { toDate: Date }) => toDate,
    (listsUpdates, organizationId, userId, fromDate, toDate) => {
        return listsUpdates.filter(
            (listUpdateRecord) =>
                isBetween(listUpdateRecord.date, fromDate, toDate) &&
                listUpdateRecord.userId === userId &&
                listUpdateRecord.organizationId === organizationId,
        );
    },
);

export const listUpdatesSelector = createSelector(
    listsUpdatesSelector,
    organizationPlayersInfoSelector,
    (_: any, { listId }: { listId: string }) => listId,
    (listsUpdates, playersInfoMap, listId) => {
        return listsUpdates
            .filter((listUpdateRecord) => listUpdateRecord.listId === listId)
            .map((record) => ({
                playerInfoHash: playersInfoMap[record.playerId] || '',
                record,
            }))
            .sort((a, b) => (isBefore(a.record.date, b.record.date) ? 1 : -1));
    },
);
