import _omit from 'lodash/omit';
import _keyBy from 'lodash/keyBy';
import actionTypes from './fitnessData.actionTypes';
import { FitnessDataMetadata, TeamEventFitnessRecord } from './FitnessData';

type FitnessDataState = {
    fitnessDataHash: Record<string, TeamEventFitnessRecord>;
    fitnessDataMetadataHash: Record<string, FitnessDataMetadata>;
};
const initialState = {
    fitnessDataHash: {},
    fitnessDataMetadataHash: {},
};

const fitnessData = (state: FitnessDataState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.EVENT_FITNESS_RECORDS_RECEIVED:
            return {
                ...state,
                fitnessDataHash: {
                    ...state.fitnessDataHash,
                    [action.fitnessData.id]: {
                        ...action.fitnessData,
                    },
                },
            };
        case actionTypes.FITNESS_RECORDS_LIST_RECEIVED:
            return {
                ...state,
                fitnessDataHash: {
                    ...state.fitnessDataHash,
                    ..._keyBy(action.fitnessData, 'id'),
                },
            };
        case actionTypes.REMOVE_EVENT_FITNESS_RECORDS:
            return {
                ...state,
                fitnessDataHash: { ..._omit(state.fitnessDataHash, action.fitnessDataId) },
            };
        case actionTypes.ORG_FITNESS_DATA_METADATA_RECEIVED:
            return {
                ...state,
                fitnessDataMetadataHash: {
                    ...state.fitnessDataMetadataHash,
                    [action.organizationId]: action.orgFitnessDataMetadata,
                },
            };
        default:
            return state;
    }
};

export default fitnessData;
