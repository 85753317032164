import { getDownloadURL, ref } from 'firebase/storage';
import { takeEvery, all, call, put } from 'redux-saga/effects';
import { storage } from 'common/firebase';
import markersActionTypes from './markers.actionTypes';
import { markersDataReceived, markersDataRequest } from './markers.actions';

export function* fetchMarkersDataSaga({
    organizationId,
    teamId,
    eventId,
    periodNumber,
    target,
}: ReturnType<typeof markersDataRequest>) {
    try {
        const dataRef = ref(
            storage,
            `organizations/${organizationId}/teams/${teamId}/reports/${eventId}/generated_period_${periodNumber}_${target}_markers.csv`,
        );
        const fileUrl: string = yield getDownloadURL(dataRef);
        const response: Response = yield fetch(fileUrl);
        if (!response.ok) {
            throw new Error('Failed to load markers csv');
        }
        const markersData: string = yield response.text();
        yield put(markersDataReceived({ eventId, periodNumber, target, markersData }));
    } catch (e) {
        yield call(console.error, e);
    }
}

function* markersSaga() {
    yield all([takeEvery(markersActionTypes.MARKERS_DATA_REQUEST, fetchMarkersDataSaga)]);
}

export default markersSaga;
