import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CheckboxRoundedEmpty = ({ stroke = 'black', ...restProps }: any) => (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" {...restProps}>
        <g>
            <title>Checkbox Empty</title>
            <circle cx="9" cy="9" r="8.5" stroke={stroke} strokeOpacity="0.54" fill="none" />
        </g>
    </SvgIcon>
);

export default CheckboxRoundedEmpty;
