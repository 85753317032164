import { AuthState } from 'features/auth/Auth';
import { authStateSelector } from 'features/auth/auth.selectors';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

export const useCustomAuthToken = () => {
    const [isTryingToAuth, setIsTryingToAuth] = useState<boolean>(false);
    const { search, pathname } = useLocation();
    const navigate = useNavigate();
    const authState = useSelector(authStateSelector);
    const searchParams = new URLSearchParams(search);
    const authToken = searchParams.get('authToken');

    useEffect(() => {
        const auth = getAuth();

        if (authToken && authState !== AuthState.verified) {
            setIsTryingToAuth(true);
            signInWithCustomToken(auth, authToken)
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log('===> Error: ', errorCode, errorMessage);
                })
                .finally(() => {
                    searchParams.delete('authToken');
                    navigate(`${pathname}?${searchParams.toString()}`);
                    setIsTryingToAuth(false);
                });
        }
    }, [authToken, authState]);

    return isTryingToAuth;
};
