import React, { useMemo } from 'react';
import {
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    ResponsiveContainer,
} from 'recharts';
import { styled } from '@mui/material/styles';
import { Typography } from 'common/components';
import { TimePerDrillType } from 'entities/drills/drills.utils';
import { DrillType } from 'entities/drills/Drill';
import { drillTypesConfig } from 'entities/drills/drills.constants';

type ChartRecord = {
    drillType: DrillType;
    value: number;
};

const getChartData = (timePerDrillType: TimePerDrillType): ChartRecord[] => {
    const typesList = Object.entries(timePerDrillType)
        .map(([drillType, duration]) => ({
            drillType,
            value: duration,
        }))
        .filter((record) => record.value > 0);

    typesList.sort((a, b) => (a.value > b.value ? -1 : 1));

    // @ts-ignore
    return typesList;
};

export const Label = styled(Typography)(({ theme }) => ({
    ...theme.typography.caption,
}));

const CustomXTick = ({ x, y, payload, timePerDrillType }: any) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <rect
                x="-32"
                y="-11"
                width={64}
                height={24}
                rx={4}
                fill={drillTypesConfig[payload.value as DrillType]?.color}
            />
            <Typography
                component="text"
                variant="caption"
                fill="#fff"
                x="0"
                y="0"
                textAnchor="middle"
            >
                <tspan x="0">{payload.value}</tspan>
                <tspan x="0" dy="1em">
                    {timePerDrillType[payload.value]}
                </tspan>
            </Typography>
        </g>
    );
};

export type PeriodSessionsLoadRadarProps = {
    timePerDrillType: TimePerDrillType;
};

const PeriodSessionsLoadRadar: React.FC<PeriodSessionsLoadRadarProps> = ({ timePerDrillType }) => {
    const chartData = useMemo(() => getChartData(timePerDrillType), [timePerDrillType]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <RadarChart cx="50%" cy="50%" outerRadius="70%" data={chartData}>
                <PolarGrid gridType="circle" />
                <PolarAngleAxis
                    dataKey="drillType"
                    tick={<CustomXTick timePerDrillType={timePerDrillType} />}
                    // tick={{
                    //     fontSize: 12,
                    //     fontWeight: 600,
                    //     fill: '#999999',
                    //     style: {
                    //         transform: 'translateX(5px)',
                    //     },
                    // }}
                />
                <PolarRadiusAxis
                    axisLine={false}
                    // tick={<CustomRadiusTick />}
                    tick={{
                        fontSize: 12,
                        fontWeight: 600,
                        fill: '#999999',
                        style: {
                            transform: 'translateX(5px)',
                        },
                    }}
                    tickFormatter={(v: number) => (v === 0 ? '' : `${v}'`)}
                />
                <Radar
                    name="drills"
                    dataKey="value"
                    stroke="#1B82F3"
                    fill="#1B82F3"
                    fillOpacity={0.6}
                />
            </RadarChart>
        </ResponsiveContainer>
    );
};

export default PeriodSessionsLoadRadar;
