import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const YellowAndRedCardIcon = (props: any) => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g>
            <title>YellowAndRedCard</title>
            <rect
                x="0.25"
                y="3.25"
                width="13.5"
                height="20.5"
                rx="3.75"
                fill="#FFE600"
                stroke="#E4B12C"
                strokeWidth="0.5"
            />
            <rect
                x="6.25"
                y="0.25"
                width="13.5"
                height="20.5"
                rx="3.75"
                fill="#D82A2F"
                stroke="#930000"
                strokeWidth="0.5"
            />
        </g>
    </SvgIcon>
);

export default YellowAndRedCardIcon;
