import { db } from 'common/firebase';
import { AgencyData } from 'entities/agencies/Agencies';
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';
import { handleCollectionSnapshot, handleDocumentSnapshot } from 'gateways/utils';

export const createAgency = ({ agencyData }: { agencyData: AgencyData }) => {
    const agencyRef = collection(db, 'agencies');

    return addDoc(agencyRef, agencyData).then((doc) => doc.id);
};

export const updateAgency = ({
    agencyId,
    agencyData,
}: {
    agencyId: string;
    agencyData: AgencyData;
}) => {
    const agencyRef = doc(db, `agencies/${agencyId}`);

    return updateDoc(agencyRef, agencyData);
};

export const fetchAgencyById = ({ agencyId }: { agencyId: string }) => {
    const agencyRef = doc(db, `agencies/${agencyId}`);

    return getDoc(agencyRef).then(handleDocumentSnapshot);
};

export const fetchAgenciesList = ({ organizationId }: { organizationId: string }) => {
    const agenciesRef = collection(db, 'agencies');

    const q = query(agenciesRef, where(`organizationId`, '==', organizationId));

    return getDocs(q).then(handleCollectionSnapshot);
};
