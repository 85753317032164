import UserTask, { UserTaskData } from './UserTask';
import actionTypes from './userTasks.actionTypes';

export const userTaskRequest = ({ taskId, userId }: { taskId: string; userId: string }) => ({
    type: actionTypes.USER_TASK_REQUEST,
    userId,
    taskId,
});

export const userTasksRequest = ({ userId }: { userId: string }) => ({
    type: actionTypes.USER_TASKS_LIST_REQUEST,
    userId,
});

export const userTasksListRecieved = (userTasksList: UserTask[]) => ({
    type: actionTypes.USER_TASKS_LIST_RECIEVED,
    userTasksList,
});

export const addUserTaskRequest = ({
    userId,
    userTaskData,
    onSuccess,
}: {
    userId: string;
    userTaskData: UserTaskData;
    onSuccess: VoidFunction;
}) => ({
    type: actionTypes.ADD_USER_TASK_REQUEST,
    userId,
    userTaskData,
    onSuccess,
});

export const updateUserTaskRequest = ({
    userId,
    taskId,
    userTaskData,
}: {
    userId: string;
    taskId: string;
    userTaskData: UserTaskData;
}) => ({
    type: actionTypes.UPDATE_USER_TASK_REQUEST,
    userId,
    taskId,
    userTaskData,
});

export const deleteUserTaskRequest = ({ userId, taskId }: { userId: string; taskId: string }) => ({
    type: actionTypes.DELETE_USER_TASK_REQUEST,
    userId,
    taskId,
});

export const deleteUserTaskFromState = ({ taskId }: { taskId: string }) => ({
    type: actionTypes.DELETE_USER_TASK_FROM_STATE,
    taskId,
});
