import { red, orange, yellow, lightGreen, green, grey } from '@mui/material/colors';

interface SliderColors {
    [key: number]: string;
}

export const sliderColors5: SliderColors = {
    0: grey[300],
    1: red[500],
    2: orange[500],
    3: yellow[700],
    4: lightGreen[500],
    5: green[500],
};

export const sliderColors10: SliderColors = {
    0: grey[300],
    1: red[700],
    2: red[700],
    3: red[500],
    4: red[500],
    5: orange[500],
    6: orange[500],
    7: orange[500],
    8: lightGreen[500],
    9: lightGreen[500],
    10: green[500],
};

export const colorFunction10 = (value: number | number[]) => {
    const currentValue = Array.isArray(value) ? value[0] : value;
    const color = sliderColors10[currentValue];

    return color || '#000';
};
