export default {
    DELETE_CALENDAR_EVENT: 'UI/CALENDAR/DELETE_CALENDAR_EVENT',
    CREATE_GAME_REQUEST: 'UI/CALENDAR/CREATE_GAME_REQUEST',
    EDIT_GAME_REQUEST: 'UI/CALENDAR/EDIT_GAME_REQUEST',
    GO_TODAY: 'UI/CALENDAR/GO_TODAY',
    GO_NEXT_DAY: 'UI/GO_NEXT_DAY',
    GO_PREVIOUS_DAY: 'UI/CALENDAR/GO_PREVIOUS_DAY',
    GO_NEXT_MONTH: 'UI/CALENDAR/GO_NEXT_MONTH',
    GO_PREVIOUS_MONTH: 'UI/CALENDAR/GO_PREVIOUS_MONTH',
    GO_NEXT_WEEK: 'UI/CALENDAR/GO_NEXT_WEEK',
    GO_PREVIOUS_WEEK: 'UI/CALENDAR/GO_PREVIOUS_WEEK',
    SET_DATE: 'UI/CALENDAR/SET_DATE',
    CREATE_EVENTS_REQUEST: 'UI/CALENDAR/CREATE_EVENTS_REQUEST',
    SET_SEARCH_DRILL_CONFIG: 'UI/CALENDAR/SET_SEARCH_DRILL_CONFIG',
    COPY_EVENTS: 'UI/CALENDAR/COPY_EVENTS',
    SET_LAST_CALENDAR_VIEW_PARAMS: 'UI/CALENDAR/SET_LAST_CALENDAR_VIEW_PARAMS',
};
