import { db, functions } from 'common/firebase';
import Solution, { SolutionParams } from 'entities/solutions/Solution';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { handleDocumentSnapshot, handleCollectionSnapshot } from './utils';

export const fetchSolutionById = ({ challengeId, solutionId }: SolutionParams) => {
    const solutionRef = doc(db, `challenges/${challengeId}/solutions/${solutionId}`);

    return (
        getDoc(solutionRef)
            .then(handleDocumentSnapshot)
            // @ts-ignore
            .then((solution: Solution | null) => {
                if (solution === null) {
                    return null;
                }

                return {
                    ...solution,
                    /*
                     * User id is an id for solution in the collection.
                     * So we can get multiple solutions with same id
                     */
                    id: `${challengeId}-${solution.id}`,
                };
            })
    );
};

export const fetchSolutionsList = ({ challengeId }: { challengeId: string }) => {
    const solutionsRef = collection(db, `challenges/${challengeId}/solutions`);

    return getDocs(solutionsRef)
        .then(handleCollectionSnapshot)
        .then((list) =>
            list.map((solution: Solution) => ({
                ...solution,
                /*
                 * User id is an id for solution in the collection.
                 * So we can get multiple solutions with same id
                 */
                id: `${challengeId}-${solution.id}`,
            })),
        );
};

const submitSolutiontFunction = httpsCallable(functions, 'submitSolution');
export const submitSolution = ({ challengeId }: { challengeId: string }) => {
    return submitSolutiontFunction({ challengeId });
};
