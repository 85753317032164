import * as clubsGateway from 'gateways/clubsGateway';
import { put, call, all, takeEvery } from 'redux-saga/effects';
import * as clubsActions from './clubs.actions';
import Club from './Clubs';
import clubsActionTypes from './clubs.actionTypes';

export function* fetchClubDataSaga({
    clubId,
    onSuccess,
}: ReturnType<typeof clubsActions.clubDataRequest>) {
    try {
        const club: Club = yield call(clubsGateway.fetchClubById, {
            clubId,
        });
        if (club) {
            yield put(clubsActions.clubDataRecieved(club));
        }
        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

export function* fetchClubsListsSaga({
    organizationId,
}: ReturnType<typeof clubsActions.clubsListRequest>) {
    try {
        const clubsList: Club[] = yield call([clubsGateway, clubsGateway.fetchClubsList], {
            organizationId,
        });

        if (clubsList) {
            yield put(clubsActions.clubsListRecieved(clubsList));
        }
    } catch (e) {
        console.error(e);
    }
}

export function* fetchLeagueClubsListsSaga({
    organizationId,
    leagueId,
}: ReturnType<typeof clubsActions.leagueClubsListRequest>) {
    try {
        const clubsList: Club[] = yield call([clubsGateway, clubsGateway.fetchLeagueClubsList], {
            organizationId,
            leagueId,
        });

        if (clubsList) {
            yield put(clubsActions.clubsListRecieved(clubsList));
        }
    } catch (e) {
        console.error(e);
    }
}

function* clubsSagas() {
    yield all([
        takeEvery(clubsActionTypes.CLUB_DATA_REQUEST, fetchClubDataSaga),
        takeEvery(clubsActionTypes.CLUBS_LIST_REQUEST, fetchClubsListsSaga),
        takeEvery(clubsActionTypes.LEAGUE_CLUBS_LIST_REQUEST, fetchLeagueClubsListsSaga),
    ]);
}

export default clubsSagas;
