export default {
    AGENT_CREATE: 'ENTITIES/AGENTS/AGENT_CREATE',
    AGENT_DATA_REQUEST: 'ENTITIES/AGENTS/AGENT_DATA_REQUEST',
    AGENT_DATA_RECEIVED: 'ENTITIES/AGENTS/AGENT_DATA_RECEIVED',
    AGENTS_LIST_REQUEST: 'ENTITIES/AGENTS/AGENTS_LIST_REQUEST',
    AGENTS_LIST_RECEIVED: 'ENTITIES/AGENTS/AGENTS_LIST_RECEIVED',
    AGENCY_AGENTS_LIST_REQUEST: 'ENTITIES/AGENTS/AGENCY_AGENTS_LIST_REQUEST',
    AGENTS_WITHOUT_AGENCY_LIST_REQUEST: 'ENTITIES/AGENTS/AGENTS_WITHOUT_AGENCY_LIST_REQUEST',
    AGENT_DATA_UPDATE: 'ENTITIES/AGENTS/AGENT_DATA_UPDATE',
};
