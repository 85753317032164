import actionTypes from './clubs.actionTypes';
import Club from './Clubs';
import _keyBy from 'lodash/keyBy';

type ClubsState = {
    clubsHash: {
        [key: string]: Club;
    };
};
const initialState = {
    clubsHash: {},
};

const clubs = (state: ClubsState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.CLUB_DATA_RECEIVED:
            return {
                ...state,
                clubsHash: {
                    ...state.clubsHash,
                    [action.club.id]: {
                        ...action.club,
                    },
                },
            };
        case actionTypes.CLUBS_LIST_RECEIVED:
            return {
                ...state,
                clubsHash: {
                    ...state.clubsHash,
                    ..._keyBy(action.clubsList, 'id'),
                },
            };
        default:
            return state;
    }
};

export default clubs;
