import { formatMatchTime } from 'common/utils/dateTimeFormatter';
import { Episode, EpisodesType } from 'entities/episodes/Episodes';
import { TeamStatus } from 'entities/reports/Report';
import { TFunction } from 'i18next';
import { v4 as uuid4 } from 'uuid';
import { EpisodeInfo } from './reportTaggingPage.actions';

const SECONDS_IN_PERIOD = 45 * 60;

export const getMatchTime = (periodTime: number, period: number) => {
    const periodStartTime = (period - 1) * SECONDS_IN_PERIOD;
    return periodStartTime + periodTime;
};

export const getFormattedMatchTime = (periodTime: number, period: number) => {
    const matchTime = getMatchTime(periodTime, period);
    return formatMatchTime(matchTime);
};

export const getOpponentEpisode = (
    episodeType: EpisodesType,
    episodeData: Episode,
    t: TFunction,
): EpisodeInfo | null => {
    if (episodeType === EpisodesType.tackle) {
        return {
            episodeType: EpisodesType.loss,
            episodeName: t(`reports.tagging.${EpisodesType.loss}.short`),
            episodeData: {
                ...episodeData,
                id: uuid4(),
                team: episodeData.team === TeamStatus.guest ? TeamStatus.home : TeamStatus.guest,
            },
        };
    }

    if (episodeType === EpisodesType.loss) {
        return {
            episodeType: EpisodesType.tackle,
            episodeName: t(`reports.tagging.${EpisodesType.tackle}.short`),
            episodeData: {
                ...episodeData,
                id: uuid4(),
                team: episodeData.team === TeamStatus.guest ? TeamStatus.home : TeamStatus.guest,
            },
        };
    }

    if (episodeType === EpisodesType.highPressing) {
        const { isPositive, isNegative, ...rest } = episodeData;
        const newEpisodeData = {
            ...rest,
            id: uuid4(),
            team: rest.team === TeamStatus.guest ? TeamStatus.home : TeamStatus.guest,
        };

        if (isNegative) {
            Object.assign(newEpisodeData, { isPositive: true });
        }

        if (isPositive) {
            Object.assign(newEpisodeData, { isNegative: true });
        }

        return {
            episodeType: EpisodesType.pressingOvercome,
            episodeName: t(`reports.tagging.${EpisodesType.pressingOvercome}.short`),
            episodeData: newEpisodeData,
        };
    }

    if (episodeType === EpisodesType.secondBall) {
        // @ts-ignore
        if (episodeData.details.area === 'midfield') {
            return null;
        }

        const { isPositive, isNegative, ...rest } = episodeData;
        const newEpisodeData = {
            ...rest,
            id: uuid4(),
            team: rest.team === TeamStatus.guest ? TeamStatus.home : TeamStatus.guest,
            details: {
                // @ts-ignore
                area: rest.details.area === 'fz' ? 'defenceFz' : 'fz',
            },
        };

        if (isNegative) {
            Object.assign(newEpisodeData, { isPositive: true });
        }

        if (isPositive) {
            Object.assign(newEpisodeData, { isNegative: true });
        }

        return {
            episodeType: EpisodesType.secondBall,
            episodeName: t(`reports.tagging.${EpisodesType.secondBall}.short`),
            episodeData: newEpisodeData,
        };
    }

    return null;
};
