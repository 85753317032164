import Solution, { SolutionParams } from './Solution';
import actionTypes from './solutions.actionTypes';

export const solutionDataReceived = ({ solutionData }: { solutionData: Solution }) => ({
    type: actionTypes.SOLUTION_DATA_RECEIVED,
    solutionData,
});

export const solutionsListRequest = ({ challengeId }: { challengeId: string }) => ({
    type: actionTypes.SOLUTIONS_LIST_REQUEST,
    challengeId,
});

export const solutionsListReceived = ({ solutionsList }: { solutionsList: Solution[] }) => ({
    type: actionTypes.SOLUTIONS_LIST_RECEIVED,
    solutionsList,
});

export const solutionDataRequest = ({
    solutionId,
    challengeId,
    failMessage,
    onFinish,
}: SolutionParams & { failMessage?: string; onFinish?: VoidFunction }) => ({
    type: actionTypes.SOLUTION_DATA_REQUEST,
    challengeId,
    solutionId,
    failMessage,
    onFinish,
});
