import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { playerDataSelector } from 'entities/players/players.selectors';
import Player from 'entities/players/Player';
import { getInitialPositions } from './scouting.utils';
import scoutingActionTypes from './scouting.actionTypes';
import { refreshPlayerLonglists, toggleListPlayer } from './scouting.actions';
import { getPlayerLonglistsIds, togglePlayerLonglist } from 'gateways/scoutingListsGateway';
import { longlistsSelector } from 'entities/scouting-lists/scoutingLists.selectors';
import ScoutingList, { ListType } from 'entities/scouting-lists/ScoutingList';
import { scoutingListRequest } from 'entities/scouting-lists/scoutingLists.actions';

/* For the case when browser is open for a long time and longlist was updated by different client */
export function* refreshPlayerLonglistsSaga({
    playerId,
    organizationId,
    onSuccess,
    onFail,
}: ReturnType<typeof refreshPlayerLonglists>) {
    const longlists: ScoutingList[] = yield select(longlistsSelector, {
        organizationId,
        listType: ListType.longlist,
    });

    try {
        const { data: remotePlayerLonglistsIds }: { data: string[] } = yield call(
            getPlayerLonglistsIds,
            {
                playerId,
                organizationId,
            },
        );
        const localPlayerLonglistsIds = longlists
            .filter((list) => list.players[playerId])
            .map((list) => list.id);
        const diffListIds = remotePlayerLonglistsIds
            .filter((longlistId) => !localPlayerLonglistsIds.includes(longlistId))
            .concat(
                localPlayerLonglistsIds.filter(
                    (longlistId) => !remotePlayerLonglistsIds.includes(longlistId),
                ),
            );
        const getUpToDateLonglists = diffListIds.map((listId) =>
            put(scoutingListRequest({ listId, organizationId })),
        );

        yield all(getUpToDateLonglists);

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        if (onFail) {
            yield call(onFail);
        }
        console.error(e);
    }
}

export function* toggleListPlayerSaga({
    playerId,
    listId,
    organizationId,
    shouldInclude,
    onSuccess,
    onFail,
}: ReturnType<typeof toggleListPlayer>) {
    const player: Player = yield select(playerDataSelector, { playerId });

    try {
        yield call(togglePlayerLonglist, {
            playerId,
            organizationId,
            listId,
            shouldInclude,
            listPositions: getInitialPositions(player.positions),
        });

        if (shouldInclude) {
            toastr.success('Player added to the longlist', '');
        } else {
            toastr.success('Player removed from the longlist', '');
        }

        yield call(refreshPlayerLonglistsSaga, {
            type: scoutingActionTypes.REFRESH_PLAYER_LONGLISTS,
            playerId,
            organizationId,
            onSuccess: undefined,
            onFail: undefined,
        });

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        if (onFail) {
            yield call(onFail);
        }
        console.error(e);
    }
}

function* scoutingSagas() {
    yield all([
        takeEvery(scoutingActionTypes.TOGGLE_LIST_PLAYER, toggleListPlayerSaga),
        takeEvery(scoutingActionTypes.REFRESH_PLAYER_LONGLISTS, refreshPlayerLonglistsSaga),
    ]);
}

export default scoutingSagas;
