import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import Report from 'entities/reports/Report';
import * as reportsSelectors from 'entities/reports/reports.selectors';
import * as reportsActions from 'entities/reports/reports.actions';

import _omit from 'lodash/omit';
import * as snackbarAcrions from 'common/snackbar/snackbar.actions';
import reportPageActionTypes from './reportPage.actionTypes';
import * as reportPageActions from './reportPage.actions';

function* toggleFavouriteEpisode({
    episode,
    reportId,
    teamId,
    organizationId,
    failMessage,
}: ReturnType<typeof reportPageActions.toggleFavouriteEpisode>) {
    try {
        const { favouriteEpisodes, ...rest }: Report = yield select(
            reportsSelectors.reportDataSelector,
            {
                eventId: reportId,
            },
        );
        const hasEpisode = Boolean(
            favouriteEpisodes.find((favEpisode) => episode.id === favEpisode.episodeData.id),
        );
        const { period, type, ...restEpisode } = episode;
        const episodeData = _omit(restEpisode, ['player']);
        const newFavEpisode = {
            notes: '',
            episodeData,
            period,
            type,
        };
        const newFavouriteEpisodes = hasEpisode
            ? favouriteEpisodes.filter((favEpisode) => episode.id !== favEpisode.episodeData.id)
            : favouriteEpisodes.concat(newFavEpisode);
        yield put(
            reportsActions.reportDataUpdate({
                eventId: reportId,
                teamId,
                organizationId,
                reportData: {
                    ..._omit(rest, ['id']),
                    favouriteEpisodes: newFavouriteEpisodes,
                },
            }),
        );
    } catch (err) {
        yield put(
            snackbarAcrions.showError({
                message: failMessage,
            }),
        );
    }
}

function* clearFavouritesSaga({
    eventId,
    teamId,
    organizationId,
    failMessage,
}: ReturnType<typeof reportPageActions.clearFavouriteEpisodes>) {
    try {
        const report: Report = yield select(reportsSelectors.reportDataSelector, {
            eventId,
        });
        yield put(
            reportsActions.reportDataUpdate({
                eventId,
                teamId,
                organizationId,
                reportData: {
                    ..._omit(report, ['id']),
                    favouriteEpisodes: [],
                },
            }),
        );
    } catch (err) {
        yield put(
            snackbarAcrions.showError({
                message: failMessage,
            }),
        );
    }
}

function* updateFavouriteEpisodeSaga({
    eventId,
    teamId,
    organizationId,
    episode,
    failMessage,
    onSuccess,
}: ReturnType<typeof reportPageActions.updateFavouriteEpisode>) {
    try {
        const report: Report = yield select(reportsSelectors.reportDataSelector, {
            eventId,
        });
        const updatedEpisodesList = report.favouriteEpisodes.map((favEpisode) => {
            if (favEpisode.episodeData.id === episode.id) {
                return {
                    ...favEpisode,
                    episodeData: episode,
                };
            }

            return favEpisode;
        });
        yield put(
            reportsActions.reportDataUpdate({
                eventId,
                teamId,
                organizationId,
                reportData: {
                    ..._omit(report, ['id']),
                    favouriteEpisodes: updatedEpisodesList,
                },
            }),
        );
        // @ts-ignore
        yield call(onSuccess);
    } catch (err) {
        yield put(
            snackbarAcrions.showError({
                message: failMessage,
            }),
        );
    }
}

function* reportPageSagas() {
    yield all([
        takeLatest(reportPageActionTypes.TOGGLE_FAVOURITE, toggleFavouriteEpisode),
        takeLatest(reportPageActionTypes.CLEAR_FAVOURITES, clearFavouritesSaga),
        takeLatest(reportPageActionTypes.UPDATE_FAVOURITE_EPISODE, updateFavouriteEpisodeSaga),
    ]);
}

export default reportPageSagas;
