import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from 'common/components';

const StyledPlaceholderBox = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.custom.background.light,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
}));

type PlaceholderBoxProps = {
    sx?: any;
    children: React.ReactElement | string | any[] | null;
    align?: any;
};

const PlaceholderBox: React.FC<PlaceholderBoxProps> = ({ children, ...rest }) => {
    return (
        <StyledPlaceholderBox align="center" variant="body2" {...rest}>
            {children}
        </StyledPlaceholderBox>
    );
};

export default PlaceholderBox;
