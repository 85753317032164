import React from 'react';
import TextField from '@mui/material/TextField';
import { InputAdornment } from 'common/components';
import { styled } from '@mui/material/styles';

export { type TextFieldProps as InputProps } from '@mui/material/TextField';

const StyledInput = styled(TextField)(({ theme }) => ({
    [`.MuiInputBase-input`]: {
        fontSize: 16,

        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    [`.MuiInputBase-root`]: {
        backgroundColor: 'rgba(9, 30, 66, 0.04)',
        border: '2px solid transparent',
        paddingRight: 0,
    },
    [`.MuiInputLabel-root`]: {
        position: 'static',
        transform: 'none',
        paddingLeft: theme.spacing(1),
        ...theme.typography.body2,
    },
    [`.MuiInputBase-root.Mui-focused`]: {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
    },
    [`.MuiInputBase-inputSizeSmall`]: {
        padding: theme.spacing(0.5),
    },
    [`.MuiInputBase-multiline`]: {
        padding: 0,
    },
    fieldset: {
        display: 'none',
    },
}));

const Input = React.forwardRef(({ inputClassName, adornment = '', ...props }: any, ref) => {
    return (
        <StyledInput
            ref={ref}
            InputLabelProps={{ disableAnimation: true, shrink: true }}
            InputProps={{
                className: inputClassName,
                endAdornment: adornment && (
                    <InputAdornment position="end">{adornment}</InputAdornment>
                ),
            }}
            variant="outlined"
            size="small"
            {...props}
        />
    );
});

export default Input;
