import React, { useCallback, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from 'common/components';
import { setOffline, setOnline } from 'common/config/config.actions';
import { isOfflineSelector } from 'common/config/config.selectors';

const Root = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(0, 0.25),
    borderRadius: theme.shape.borderRadius,
    opacity: 0.3,
    border: '1px solid',
    margin: theme.spacing(1, 'auto', 0),
    width: 'fit-content',
}));

type OfflineStatusProps = {};

const OfflineStatus: React.FC<OfflineStatusProps> = () => {
    const isOffline = useSelector(isOfflineSelector);
    const dispatch = useDispatch();

    const handleOffline = useCallback(() => {
        dispatch(setOffline());
    }, []);

    const handleOnline = useCallback(() => {
        dispatch(setOnline());
    }, []);

    useEffect(() => {
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, [handleOnline, handleOffline]);

    if (!isOffline) {
        return null;
    }

    return <Root variant="caption">Offline</Root>;
};

export default OfflineStatus;
