import Agency from './Agencies';
import actionTypes from './agencies.actionTypes';
import _keyBy from 'lodash/keyBy';

type AgenciesState = {
    agenciesHash: Record<string, Agency>;
};
const initialState = {
    agenciesHash: {},
};

const agency = (state: AgenciesState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.AGENCY_DATA_RECEIVED:
            return {
                ...state,
                agenciesHash: {
                    ...state.agenciesHash,
                    [action.agency.id]: {
                        ...action.agency,
                    },
                },
            };
        case actionTypes.AGENCIES_LIST_RECEIVED:
            return {
                ...state,
                agenciesHash: {
                    ...state.agenciesHash,
                    ..._keyBy(action.agenciesList, 'id'),
                },
            };
        default:
            return state;
    }
};

export default agency;
