import snackbarActionTypes from './snackbar.actionTypes';
import { SnackbarVariants } from './index';

export const showSnackbar = ({
    variant,
    message,
    title,
}: {
    variant: SnackbarVariants;
    message: string;
    title?: string;
}) => ({
    type: snackbarActionTypes.SHOW_SNACKBAR,
    variant,
    message,
    title,
});

export const showWarning = ({ message, title }: { message: string; title?: string }) =>
    showSnackbar({ message, variant: SnackbarVariants.warning, title });

export const showError = ({ message, title }: { message: string; title?: string }) =>
    showSnackbar({ message, variant: SnackbarVariants.error, title });

export const showSuccess = ({ message, title }: { message: string; title?: string }) =>
    showSnackbar({ message, variant: SnackbarVariants.success, title });

export const closeSnackbar = () => ({
    type: snackbarActionTypes.CLOSE_SNACKBAR,
});
