import { db, functions } from 'common/firebase';
import Club from 'entities/clubs/Clubs';
import League from 'entities/leagues/Leagues';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { handleCollectionSnapshot, handleDocumentSnapshot } from 'gateways/utils';

export const fetchLeagueById = ({ leagueId }: { leagueId: string }) => {
    const leagueRef = doc(db, `leagues/${leagueId}`);

    return getDoc(leagueRef).then(handleDocumentSnapshot);
};

export const fetchLeaguesList = ({ organizationId }: { organizationId: string }) => {
    const leaguesRef = collection(db, 'leagues');

    const q = query(leaguesRef, where(`organizationId`, '==', organizationId));

    return getDocs(q).then(handleCollectionSnapshot);
};

type GetClubsByLeagueLinkResult =
    | {
          status: 'success';
          leagueData: League;
          leagueId: string;
          clubs: Club;
      }
    | {
          status: 'error';
      };

const getClubsByLeagueLinkFunction = httpsCallable(functions, 'getClubsByLeagueLink');
export const getClubsByLeagueLink = ({
    pageUrl,
    organizationId,
}: {
    pageUrl: string;
    organizationId: string;
}) => {
    return getClubsByLeagueLinkFunction({ pageUrl, organizationId }).then((res) => {
        const result = res.data as GetClubsByLeagueLinkResult;

        if (result.status === 'error') {
            throw new Error();
        }

        return result;
    });
};
