export const DRAG_TYPES = {
    PITCH_PLAYER: 'PITCH_PLAYER',
    LIST_PLAYER: 'LIST_PLAYER',
};

export const FORMATIONS = {
    '4-2-2-2': {
        name: '4-2-2-2',
        positions: [`gk`, `lfb`, `lcb`, `rcb`, `rfb`, `lcm`, `rcm`, `lw`, `rw`, `lst`, `rst`],
    },
    '4-4-1-1': {
        name: '4-4-1-1',
        positions: [`gk`, `lfb`, `lcb`, `rcb`, `rfb`, `lm`, `lcm`, `rcm`, `rm`, `cam`, `st`],
    },
    '4-2-3-1': {
        name: '4-2-3-1',
        positions: [`gk`, `lfb`, `lcb`, `rcb`, `rfb`, `lcm`, `rcm`, `cam`, `lw`, `rw`, `st`],
    },
    '4-3-3': {
        name: '4-3-3',
        positions: [`gk`, `lfb`, `lcb`, `rcb`, `rfb`, `lcm`, `cdm`, `rcm`, `lw`, `st`, `rw`],
    },
    '4-1-4-1': {
        name: '4-1-4-1',
        positions: [`gk`, `lfb`, `lcb`, `rcb`, `rfb`, `cdm`, `lm`, `lcm`, `rcm`, `rm`, `sst`],
    },
    '4-1-2-1-2-N': {
        name: '4-1-2-1-2-N',
        positions: [`gk`, `lfb`, `lcb`, `rcb`, `rfb`, `cdm`, `lcm`, `rcm`, `cam`, `lst`, `rst`],
    },
    '4-1-2-1-2-W': {
        name: '4-1-2-1-2-W',
        positions: [`gk`, `lfb`, `lcb`, `rcb`, `rfb`, `cdm`, `lm`, `rm`, `cam`, `lst`, `rst`],
    },
    '4-4-2': {
        name: '4-4-2',
        positions: [`gk`, `lfb`, `lcb`, `rcb`, `rfb`, `lm`, `lcm`, `rcm`, `rm`, `lst`, `rst`],
    },
    '3-4-1-2': {
        name: '3-4-1-2',
        positions: [`gk`, `lcb`, `cb`, `rcb`, `lm`, `lcm`, `rcm`, `rm`, `cam`, `lst`, `rst`],
    },
    '3-5-2': {
        name: '3-5-2',
        positions: [`gk`, `lcb`, `cb`, `rcb`, `cdm`, `lm`, `lcm`, `rcm`, `rm`, `lst`, `rst`],
    },
    '3-4-3': {
        name: '3-4-3',
        positions: [`gk`, `lfb`, `cb`, `rfb`, `lm`, `lcm`, `rcm`, `rm`, `lam`, `ram`, `st`],
    },
    '5-4-1': {
        name: '5-4-1',
        positions: [`gk`, `lwb`, `lcb`, `cb`, `rcb`, `rwb`, `lm`, `lcm`, `rcm`, `rm`, `sst`],
    },
    '5-3-2': {
        name: '5-3-2',
        positions: [`gk`, `lwb`, `lcb`, `cb`, `rcb`, `rwb`, `lcm`, `cm`, `rcm`, `lst`, `rst`],
    },
    '5-2-3': {
        name: '5-2-3',
        positions: [`gk`, `lwb`, `lcb`, `cb`, `rcb`, `rwb`, `lcm`, `rcm`, `lw`, `rw`, `st`],
    },
};
