export default {
    CREATE_MEDICAL_TEST: 'ENTITIES/MEDICAL_TESTS/CREATE_MEDICAL_TEST',
    UPDATE_MEDICAL_TEST: 'ENTITIES/MEDICAL_TESTS/UPDATE_MEDICAL_TEST',
    MEDICAL_TEST_REQUEST: 'ENTITIES/MEDICAL_TESTS/MEDICAL_TEST_REQUEST',
    MEDICAL_TEST_CREATE: 'ENTITIES/MEDICAL_TESTS/MEDICAL_TEST_CREATE',
    MEDICAL_TEST_RECEIVED: 'ENTITIES/MEDICAL_TESTS/MEDICAL_TEST_RECEIVED',
    MEDICAL_TESTS_LIST_REQUEST: 'ENTITIES/MEDICAL_TESTS/MEDICAL_TESTS_LIST_REQUEST',
    MEDICAL_TESTS_LIST_RECEIVED: 'ENTITIES/MEDICAL_TESTS/MEDICAL_TESTS_LIST_RECEIVED',
    MEDICAL_TESTS_ACCESS_LIST_REQUEST: 'ENTITIES/MEDICAL_TESTS/MEDICAL_TESTS_ACCESS_LIST_REQUEST',
    MEDICAL_TESTS_ACCESS_LIST_RECEIVED: 'ENTITIES/MEDICAL_TESTS/MEDICAL_TESTS_ACCESS_LIST_RECEIVED',
};
