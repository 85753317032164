export const checkIfEmpty = (htmlString: string | null = '') => {
    if (!htmlString) {
        return true;
    }

    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    const content = doc.body.textContent;

    return !/\S+/.test(content || '');
};
