const pitchRatio = 0.7;

export const getPitchSize = (
    el: HTMLDivElement | null,
    ratio: number = pitchRatio,
    fixedWidth?: number,
) => {
    if (!el) {
        return { width: 0, height: 0 };
    }

    const { clientHeight, clientWidth } = el;
    const tooHighContainer = clientWidth / clientHeight < ratio;
    const isZeroHeight = clientWidth !== 0 && clientHeight === 0;

    if (fixedWidth) {
        return { width: fixedWidth, height: fixedWidth / ratio };
    }

    if (isZeroHeight || tooHighContainer) {
        return { width: clientWidth, height: clientWidth / ratio };
    }
    return { width: clientHeight * ratio, height: clientHeight };
};
