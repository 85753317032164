import { TFunction } from 'i18next';
import { AccessTarget, AccessTypes } from 'entities/access/Access';
import actionTypes from './invitations.actionTypes';
import Invitation, { InvitationData } from './Invitation';

export const createInvitation = ({
    invitationData,
    successMessage,
    t,
}: {
    invitationData: InvitationData;
    successMessage: string;
    t: TFunction;
}) => ({
    type: actionTypes.CREATE_INVITATION,
    invitationData,
    successMessage,
    t,
});

export const updateInvitation = ({
    invitationId,
    invitationData,
}: {
    invitationId: string;
    invitationData: InvitationData;
}) => ({
    type: actionTypes.UPDATE_INVITATION,
    invitationId,
    invitationData,
});

export const deleteInvitation = ({
    invitationId,
    accessType,
    target,
    onSuccess,
    onFail,
}: {
    invitationId: string;
    accessType: AccessTypes;
    target: AccessTarget;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.DELETE_INVITATION,
    invitationId,
    accessType,
    target,
    onSuccess,
    onFail,
});

export const removeInvitationFromListAction = ({ invitationId }: { invitationId: string }) => ({
    type: actionTypes.REMOVE_INVITATION_FROM_LIST,
    invitationId,
});

export const requestInvitationsList = ({
    target,
    accessType,
}: {
    target: AccessTarget;
    accessType: AccessTypes;
}) => ({
    type: actionTypes.REQUEST_INVITATIONS_LIST,
    target,
    accessType,
});

export const invitationsListReceived = ({ invitationsList }: { invitationsList: Invitation[] }) => {
    return <const>{
        type: actionTypes.INVITATIONS_LIST_RECEIVED,
        invitationsList,
    };
};

export const requestInvitationData = ({
    invitationId,
    accessType,
    target,
}: {
    target: AccessTarget;
    accessType: AccessTypes;
    invitationId: string;
}) => ({
    type: actionTypes.REQUEST_INVITATION_DATA,
    invitationId,
    accessType,
    target,
});

export const invitationDataReceived = ({ invitationData }: { invitationData: Invitation }) => ({
    type: actionTypes.INVITATION_DATA_RECEIVED,
    invitationData,
});

export const setPlayerToLink = (playerId: string | null) => ({
    type: actionTypes.SET_PLAYER_TO_LINK,
    playerId,
});

export const fetchUserInvitations = () => ({
    type: actionTypes.FETCH_USER_INVITATIONS,
});
