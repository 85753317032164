import Agency, { AgencyData } from './Agencies';
import agenciesTypes from './agencies.actionTypes';

export const agencyCreate = ({
    agencyData,
    onSuccess,
    onFail,
}: {
    agencyData: AgencyData;
    onSuccess?: (agencyId: string) => void;
    onFail?: VoidFunction;
}) => ({
    type: agenciesTypes.AGENCY_CREATE,
    agencyData,
    onSuccess,
    onFail,
});

export const agencyDataRequest = ({
    agencyId,
    onSuccess,
}: {
    agencyId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: agenciesTypes.AGENCY_DATA_REQUEST,
    agencyId,
    onSuccess,
});

export const agencyDataUpdate = ({
    agencyId,
    agencyData,
    onSuccess,
    onFail,
}: {
    agencyId: string;
    agencyData: AgencyData;
    onSuccess?: (agencyName: string) => void;
    onFail?: VoidFunction;
}) => ({
    type: agenciesTypes.AGENCY_DATA_UPDATE,
    agencyId,
    agencyData,
    onSuccess,
    onFail,
});

export const agencyDataRecieved = (agency: Agency) => ({
    type: agenciesTypes.AGENCY_DATA_RECEIVED,
    agency,
});

export const agenciesListRequest = (organizationId: string) => ({
    type: agenciesTypes.AGENCIES_LIST_REQUEST,
    organizationId,
});

export const agenciesListRecieved = (agenciesList: Agency[]) => ({
    type: agenciesTypes.AGENCIES_LIST_RECEIVED,
    agenciesList,
});
