import React, { useCallback, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress } from 'common/components';
import { appConfigSelector } from 'common/config/config.selectors';
import { grantMethodologyAccess } from 'gateways/methodologiesGateway';
import { requestUserAccessRecords } from 'entities/access/access.actions';

type GetMethodologyBtnProps = {
    sx?: any;
    onSuccess?: (methodologyId: string) => void;
};

const GetMethodologyBtn: React.FC<GetMethodologyBtnProps> = ({ onSuccess, ...rest }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const appConfig = useSelector(appConfigSelector);

    const handleGetMethodologyAccess = useCallback(async () => {
        try {
            setIsLoading(true);
            await grantMethodologyAccess();
            dispatch(requestUserAccessRecords());
            if (onSuccess) {
                onSuccess(appConfig?.publicMethodology);
            }
        } catch {
            toastr.error(
                'Failed to get access',
                'Please refresh the page and try again. Or contact the support',
            );
        }

        setIsLoading(false);
    }, [appConfig]);

    if (!appConfig?.publicMethodology) {
        return null;
    }

    return (
        <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={handleGetMethodologyAccess}
            {...rest}
        >
            {isLoading ? (
                <CircularProgress size={16} />
            ) : (
                t('session.drills.noMethodology.getMethodologyBtn')
            )}
        </Button>
    );
};

export default GetMethodologyBtn;
