import { createSelector } from 'reselect';
import { RootState } from 'store';
import MedicalTest from './MedicalTests';
import { isAfter } from 'date-fns';

export const medicalTestsHashSelector = (state: RootState) => {
    return state.entities.medicalTests.medicalTestsHash;
};

export const medicalTestsAccessHashSelector = (state: RootState) => {
    return state.entities.medicalTests.medicalTestsAccessHash;
};

export const isLoadingTestsAccessSelector = (state: RootState) => {
    return state.entities.medicalTests.isLoadingTestsAccess;
};

export const medicalTestSelector = createSelector(
    medicalTestsHashSelector,
    (_: RootState, { medicalTestId }: { medicalTestId: string }) => medicalTestId,
    (medicalTestsHash, medicalTestId) => medicalTestsHash[medicalTestId],
);

export const medicalTestsListSelector = createSelector(
    medicalTestsHashSelector,
    (medicalTestsHash) => Object.values<MedicalTest>(medicalTestsHash),
);

export const teamMedicalTestsListSelector = createSelector(
    medicalTestsListSelector,
    (_: RootState, { teamId }: { teamId: string }) => teamId,
    (medicalTestsList, teamId) => {
        return medicalTestsList
            .filter((record) => record.teamId === teamId)
            .sort((a, b) => (isAfter(a.date, b.date) ? -1 : 1));
    },
);

export const medicalTestsAccessListSelector = createSelector(
    medicalTestsAccessHashSelector,
    (_: RootState, { organizationId }: { organizationId: string }) => organizationId,
    (medicalTestsAccessHash, organizationId) => {
        return medicalTestsAccessHash[organizationId] || [];
    },
);
