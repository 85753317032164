import { auth } from 'common/firebase';
import ScoutingList, {
    ListStatus,
    ListType,
    ListUpdateRecord,
    ScoutingListData,
} from './ScoutingList';

export const createEmptyScoutingList = (
    name: string = '',
    listType: ListType,
): ScoutingListData => {
    return {
        name,
        type: listType,
        createDate: new Date(),
        editDate: new Date(),
        creatorId: auth.currentUser?.uid || '',
        deletedDate: null,
        status: ListStatus.active,
        logoPath: null,
        players: {},
        regions: [],
        frozenPlayersIds: [],
        groups: [],
        leagues: [],
        parentListId: null,
        withShadowTeams: false,
        showOnlyPrimaryPosition: false,
    };
};

export const longlistsSortFunction = (a: ScoutingList, b: ScoutingList) => {
    if (Object.keys(a.players).length === 0) {
        return 1;
    }

    if (Object.keys(b.players).length === 0) {
        return -1;
    }

    return a.name.localeCompare(b.name);
};

export const mapListsUpdatesFromDatesStrings = (listUpdate: ListUpdateRecord): ListUpdateRecord => {
    const date = new Date(listUpdate.date);
    const mappedListUpdate = {
        ...listUpdate,
        date,
    };

    return mappedListUpdate;
};
