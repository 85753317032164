import { db } from 'common/firebase';
import EpisodesGroup, { EpisodesGroupData, EpisodesParams } from 'entities/episodes/Episodes';
import {
    addDoc,
    collection,
    doc,
    getDocs,
    updateDoc,
    onSnapshot,
    query,
    where,
    QuerySnapshot,
    DocumentData,
} from 'firebase/firestore';
import { handleCollectionSnapshot, handleDocumentSnapshot } from './utils';

export const fetchEpisodesGroupsList = ({ eventId, teamId, organizationId }: EpisodesParams) => {
    const episodesRef = collection(
        db,
        `organizations/${organizationId}/teams/${teamId}/reports/${eventId}/episodes`,
    );

    return getDocs(episodesRef).then(handleCollectionSnapshot);
};

export const updateEpisodesGroup = ({
    eventId,
    teamId,
    organizationId,
    episodesGroupId,
    episodesGroupData,
}: EpisodesParams & { episodesGroupId: string; episodesGroupData: EpisodesGroupData }) => {
    const episodeGroupRef = doc(
        db,
        `organizations/${organizationId}/teams/${teamId}/reports/${eventId}/episodes/${episodesGroupId}`,
    );

    return updateDoc(episodeGroupRef, episodesGroupData);
};

export const createEpisodesGroup = ({
    eventId,
    teamId,
    organizationId,
    episodesGroupData,
}: EpisodesParams & { episodesGroupData: EpisodesGroupData }) => {
    const episodesGroupRef = collection(
        db,
        `organizations/${organizationId}/teams/${teamId}/reports/${eventId}/episodes`,
    );

    return addDoc(episodesGroupRef, episodesGroupData);
};

export const subscribeForCollectionUpdates = ({
    onDocumentUpdate,
    organizationId,
    teamId,
    eventId,
    period,
}: {
    onDocumentUpdate: (episodesGroup: EpisodesGroup) => void;
    organizationId: string;
    teamId: string;
    eventId: string;
    period: number;
}) => {
    const episodesGroupsRef = collection(
        db,
        `organizations/${organizationId}/teams/${teamId}/reports/${eventId}/episodes`,
    );
    const q = query(episodesGroupsRef, where('period', '==', period));
    const cb = (querySnapshot: QuerySnapshot<DocumentData>) => {
        querySnapshot.docChanges().forEach((change) => {
            if (['added', 'modified'].includes(change.type)) {
                // @ts-ignore
                const episodesGroup: EpisodesGroup | null = handleDocumentSnapshot(change.doc);
                // @ts-ignore
                onDocumentUpdate({ ...episodesGroup, eventId });
            }
        });
    };
    return onSnapshot(q, cb);
};
