export enum AuthState {
    notAuthenticated = 'notAuthenticated',
    notVerified = 'notVerified',
    verified = 'verified',
}

export type RedirectConfig = {
    targetPage: 'linkPlayer';
    playerId: string;
};
