import actionTypes from './evaluationPage.actionTypes';

export type FilterOptions = {
    drillType: string;
    drillId: string;
};
const initialState = {
    filterOptions: {
        drillType: 'all',
        drillId: 'all',
    },
};

const evaluationPage = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_EVALUATION_FILTER_DRILL_TYPE_OPTION:
            return {
                filterOptions: {
                    drillType: action.drillType,
                    drillId: 'all',
                },
            };
        case actionTypes.SET_EVALUATION_FILTER_DRILL_OPTION:
            return {
                filterOptions: {
                    ...state.filterOptions,
                    drillId: action.drillId,
                },
            };
        default:
            return state;
    }
};

export default evaluationPage;
