import actionTypes from './episodes.actionTypes';
import EpisodesGroup, { EpisodesParams } from './Episodes';

export const episodesGroupsListReceived = ({
    episodesGroupsList,
    eventId,
}: {
    episodesGroupsList: EpisodesGroup[];
    eventId: string;
}) => ({
    type: actionTypes.EPISODES_GROUPS_LIST_RECEIVED,
    episodesGroupsList,
    eventId,
});

export const episodesGroupReceived = ({
    episodesGroup,
    eventId,
}: {
    episodesGroup: EpisodesGroup;
    eventId: string;
}) => ({
    type: actionTypes.EPISODES_GROUP_RECEIVED,
    episodesGroup,
    eventId,
});

export const episodesGroupsListRequest = ({ organizationId, teamId, eventId }: EpisodesParams) => ({
    type: actionTypes.EPISODES_GROUPS_LIST_REQUEST,
    organizationId,
    teamId,
    eventId,
});
