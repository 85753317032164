import { toastr } from 'react-redux-toastr';
import { endOfDay, startOfDay } from 'date-fns';
import * as conditionGateway from 'gateways/conditionsGateway';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import actionTypes from './conditions.actionTypes';
import * as conditionActions from './conditions.actions';
import { Condition, ConditionData, DayConditionsReport } from './Condition';

export function* fetchConditionsListSaga({
    organizationId,
    teamId,
    fromDate,
    toDate,
}: ReturnType<typeof conditionActions.fetchConditionsList>) {
    try {
        const conditionsList: Condition[] = yield call(
            [conditionGateway, conditionGateway.fetchConditionListByRange],
            {
                organizationId,
                teamId,
                fromDate: startOfDay(fromDate),
                toDate: endOfDay(toDate),
            },
        );

        yield put(conditionActions.conditionsListReceived({ conditionsList }));
    } catch (e) {
        console.error(e);
    }
}

export function* fetchConditionsForEventSaga({
    organizationId,
    teamId,
    eventId,
}: ReturnType<typeof conditionActions.fetchConditionsForEvent>) {
    try {
        const conditionsList: Condition[] = yield call(
            [conditionGateway, conditionGateway.fetchConditionsForEvent],
            {
                organizationId,
                teamId,
                eventId,
            },
        );

        yield put(conditionActions.conditionsListReceived({ conditionsList }));
    } catch (e) {
        console.error(e);
    }
}

export function* fetchConditionDataSaga({
    organizationId,
    teamId,
    conditionId,
}: ReturnType<typeof conditionActions.requestConditionData>) {
    try {
        const condition: Condition = yield call(
            [conditionGateway, conditionGateway.fetchConditionData],
            {
                organizationId,
                teamId,
                conditionId,
            },
        );

        yield put(conditionActions.conditionDataReceived({ condition }));
    } catch (e) {
        console.error(e);
    }
}

export function* fetchPlayerEventConditionSaga({
    organizationId,
    teamId,
    playerId,
    eventId,
}: ReturnType<typeof conditionActions.requestPlayerEventCondition>) {
    try {
        const condition: Condition = yield call(conditionGateway.fetchPlayerEventCondition, {
            organizationId,
            teamId,
            playerId,
            eventId,
        });

        if (condition) {
            yield put(conditionActions.conditionDataReceived({ condition }));
        }
    } catch (e) {
        console.error(e);
    }
}

const getDayConditions = (dayConditionsReport?: DayConditionsReport) => {
    if (!dayConditionsReport) {
        return [];
    }

    const dayConditions = Object.values(dayConditionsReport.players).reduce(
        (acc: Condition[], playerConditions) => {
            const playerDayConditions = Object.entries(playerConditions).map(
                ([id, conditionData]) => ({ id, ...(conditionData as ConditionData) }),
            );
            return acc.concat(playerDayConditions);
        },
        [],
    );

    return dayConditions;
};

export function* fetchDayConditionsReportSaga({
    organizationId,
    teamId,
    dayConditionsReportId,
}: ReturnType<typeof conditionActions.requestDayConditionsReport>) {
    try {
        const dayConditionsReport: DayConditionsReport = yield call(
            [conditionGateway, conditionGateway.fetchDayConditionsReport],
            {
                organizationId,
                teamId,
                dayConditionsReportId,
            },
        );

        if (dayConditionsReport) {
            const conditionsList = getDayConditions(dayConditionsReport);
            yield put(conditionActions.conditionsListReceived({ conditionsList }));
            yield put(conditionActions.dayConditionsReportReceived({ dayConditionsReport }));
        }
    } catch (e) {
        console.error(e);
    }
}

export function* addConditionDataSaga({
    conditionData,
    teamId,
    organizationId,
    onSuccess,
}: ReturnType<typeof conditionActions.addConditionData>) {
    try {
        const condition: Condition = yield call(
            [conditionGateway, conditionGateway.addConditionData],
            {
                organizationId,
                teamId,
                conditionData,
            },
        );

        yield put(
            conditionActions.conditionDataReceived({
                condition,
            }),
        );

        if (onSuccess) {
            yield call(onSuccess, condition.id);
        }
    } catch (e) {
        console.error(e);
        yield call(toastr.error, 'Condition not saved', 'Reload page and try again');
    }
}

export function* editConditionDataSaga({
    organizationId,
    teamId,
    conditionId,
    conditionData,
}: ReturnType<typeof conditionActions.editConditionData>) {
    try {
        yield call([conditionGateway, conditionGateway.editConditionData], {
            organizationId,
            teamId,
            conditionId,
            conditionData,
        });

        yield put(
            conditionActions.requestConditionData({
                teamId,
                organizationId,
                conditionId,
            }),
        );
    } catch (e) {
        console.error(e);
    }
}

export function* deleteConditionSaga({
    organizationId,
    teamId,
    conditionId,
}: ReturnType<typeof conditionActions.deleteCondition>) {
    try {
        yield call([conditionGateway, conditionGateway.deleteCondition], {
            organizationId,
            teamId,
            conditionId,
        });
        yield put(
            conditionActions.removeCondition({
                teamId,
                organizationId,
                conditionId,
            }),
        );
    } catch (e) {
        console.error(e);
    }
}

function* conditionSagas() {
    yield all([
        takeLatest(actionTypes.REQUEST_CONDITION_DATA, fetchConditionDataSaga),
        takeLatest(actionTypes.REQUEST_PLAYER_EVENT_CONDITION, fetchPlayerEventConditionSaga),
        takeEvery(actionTypes.REQUEST_DAY_CONDITIONS_REPORT, fetchDayConditionsReportSaga),
        takeLatest(actionTypes.REQUEST_CONDITIONS_LIST, fetchConditionsListSaga),
        takeLatest(actionTypes.REQUEST_CONDITIONS_FOR_EVENT, fetchConditionsForEventSaga),
        takeLatest(actionTypes.ADD_CONDITION_DATA, addConditionDataSaga),
        takeLatest(actionTypes.EDIT_CONDITION_DATA, editConditionDataSaga),
        takeLatest(actionTypes.DELETE_CONDITION, deleteConditionSaga),
    ]);
}

export default conditionSagas;
