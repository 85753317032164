import * as playersGateway from 'gateways/playersGateway';
import { all, call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import Player, { PlayerData, PlayerSkills, PlayerStatus } from './Player';
import * as playersActions from './players.actions';
import * as playersSelectors from './players.selectors';
import actionTypes from './players.actionTypes';
import { PlayerUpdate } from './PlayerUpdates';

export function* fetchScoutingPlayersListSaga({
    organizationId,
    country,
}: ReturnType<typeof playersActions.requestScoutingPlayersList>) {
    const requestedCountries: string[] = yield select(playersSelectors.requestedCountriesSelector);

    try {
        if (!requestedCountries.includes(country)) {
            const playersList: Player[] = yield call(playersGateway.fetchScoutingPlayersList, {
                organizationId,
                country,
            });

            yield put(playersActions.playersListReceived(playersList));
            yield put(playersActions.countryPlayersRequested(country));
        }
    } catch (e) {
        console.error(e);
        yield call(toastr.error, 'Something went wrong', 'Not able to load players');
    }
}

export function* scoutingNoCountryPlayersRequestSaga({
    organizationId,
}: ReturnType<typeof playersActions.scoutingNoCountryPlayersRequest>) {
    try {
        const playersList: Player[] = yield call(playersGateway.fetchScoutingNoCountryPlayersList, {
            organizationId,
        });

        yield put(playersActions.playersListReceived(playersList));
    } catch (e) {
        console.error(e);
        yield call(toastr.error, 'Something went wrong', 'Not able to load players');
    }
}

export function* scoutingRecentPlayersRequestSaga({
    organizationId,
}: ReturnType<typeof playersActions.scoutingRecentPlayersRequest>) {
    try {
        const playersList: Player[] = yield call(playersGateway.fetchScoutingRecentPlayersList, {
            organizationId,
        });

        yield put(playersActions.playersListReceived(playersList));
    } catch (e) {
        console.error(e);
        yield call(toastr.error, 'Something went wrong', 'Not able to load players');
    }
}

export function* scoutingNoRefPlayersRequestSaga({
    organizationId,
}: ReturnType<typeof playersActions.scoutingNoRefPlayersRequest>) {
    try {
        const playersList: Player[] = yield call(playersGateway.fetchScoutingNoRefPlayersList, {
            organizationId,
        });

        yield put(playersActions.playersListReceived(playersList));
    } catch (e) {
        console.error(e);
        yield call(toastr.error, 'Something went wrong', 'Not able to load players');
    }
}

export function* scoutingClubPlayersRequestSaga({
    clubId,
}: ReturnType<typeof playersActions.scoutingClubPlayersRequest>) {
    try {
        const playersList: Player[] = yield call(playersGateway.fetchScoutingClubPlayersList, {
            clubId,
        });

        yield put(playersActions.playersListReceived(playersList));
    } catch (e) {
        console.error(e);
        yield call(toastr.error, 'Something went wrong', 'Not able to load players');
    }
}

export function* fetchTeamPlayersListSaga({
    teamId,
}: ReturnType<typeof playersActions.requestTeamPlayersList>) {
    const requestedTeams: string[] = yield select(playersSelectors.requestedTeamsSelector);

    try {
        if (!requestedTeams.includes(teamId)) {
            const playersList: Player[] = yield call(
                [playersGateway, playersGateway.fetchTeamPlayersList],
                {
                    teamId,
                },
            );

            yield put(playersActions.playersListReceived(playersList));
            yield put(playersActions.teamPlayersRequested(teamId));
        }
    } catch (e) {
        yield put(playersActions.playersListLoadError());
    }
}

export function* fetchAgentPlayersListSaga({
    agentId,
}: ReturnType<typeof playersActions.requestAgentPlayersList>) {
    try {
        const playersList: Player[] = yield call(
            [playersGateway, playersGateway.fetchAgentPlayersList],
            { agentId },
        );
        yield put(playersActions.playersListReceived(playersList));
    } catch (e) {
        console.error(e);
    }
}

export function* fetchPlayerUpdatesListSaga({
    playerId,
    organizationId,
    updatesType,
}: ReturnType<typeof playersActions.requestPlayerUpdatesList>) {
    try {
        const playerUpdatesList: PlayerUpdate[] = yield call(
            [playersGateway, playersGateway.fetchPlayerUpdatesList],
            { playerId, organizationId, updatesType },
        );
        yield put(playersActions.playerUpdatesListReceived({ playerUpdatesList }));
    } catch (e) {
        console.error(e);
    }
}

export function* fetchPlayerDataSaga({
    playerId,
}: ReturnType<typeof playersActions.playerDataRequest>) {
    try {
        const playerData: Player = yield call([playersGateway, playersGateway.fetchPlayerById], {
            playerId,
        });
        if (playerData?.status === PlayerStatus.active) {
            yield put(playersActions.playerDataReceived(playerData));
        }

        if (playerData?.status === PlayerStatus.deleted) {
            yield put(playersActions.setRemovedPlayer({ playerId }));
        }
    } catch (e) {
        console.error(e);
    }
}

export function* fetchPlayerSkillsSaga({
    playerId,
}: ReturnType<typeof playersActions.playerSkillsRequest>) {
    try {
        const skills: PlayerSkills = yield call(playersGateway.fetchPlayerSkills, playerId);

        if (skills) {
            yield put(playersActions.playerSkillsReceived({ playerId, skills }));
        }
    } catch (e) {
        console.error(e);
    }
}

export function* updatePlayerSkillsSaga({
    playerId,
    skills,
}: ReturnType<typeof playersActions.updatePlayerSkills>) {
    try {
        yield call(playersGateway.updatePlayerSkills, {
            playerId,
            skills,
        });

        yield put(playersActions.playerSkillsRequest(playerId));
    } catch (e) {
        console.error(e);
    }
}

export function* createPlayerSaga({ playerData }: { playerData: PlayerData }) {
    const playerId: string = yield call([playersGateway, playersGateway.createPlayer], {
        playerData,
    });
    yield put(playersActions.playerDataRequest({ playerId }));
    return playerId;
}

export function* editPlayerInfoSaga({
    playerId,
    playerData,
    onSuccess,
}: ReturnType<typeof playersActions.updatePlayer>) {
    yield call([playersGateway, playersGateway.editPlayer], {
        playerId,
        playerData,
    });
    yield put(playersActions.playerDataRequest({ playerId }));

    if (onSuccess) {
        yield call(onSuccess);
    }
}

export function* removePlayerSaga({
    playerId,
    onSuccess,
}: ReturnType<typeof playersActions.removePlayer>) {
    try {
        yield call([playersGateway, playersGateway.removePlayer], {
            playerId,
        });
        yield put(playersActions.removePlayerFromLocalState({ playerId }));
        yield put(playersActions.setRemovedPlayer({ playerId }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

function* playersSagas() {
    yield all([
        takeLatest(
            actionTypes.ORGANIZATION_SCOUTING_PLAYERS_LIST_REQUEST,
            fetchScoutingPlayersListSaga,
        ),
        takeLatest(
            actionTypes.SCOUTING_NO_COUNTRY_PLAYERS_REQUEST,
            scoutingNoCountryPlayersRequestSaga,
        ),
        takeLatest(actionTypes.SCOUTING_RECENT_PLAYERS_REQUEST, scoutingRecentPlayersRequestSaga),
        takeLatest(actionTypes.SCOUTING_NO_REF_PLAYERS_REQUEST, scoutingNoRefPlayersRequestSaga),
        takeLatest(actionTypes.SCOUTING_CLUB_PLAYERS_REQUESTED, scoutingClubPlayersRequestSaga),
        takeLatest(actionTypes.TEAM_PLAYERS_LIST_REQUEST, fetchTeamPlayersListSaga),
        takeLatest(actionTypes.AGENT_PLAYERS_LIST_REQUEST, fetchAgentPlayersListSaga),
        takeLatest(actionTypes.PLAYERS_UPDATES_LIST_REQUEST, fetchPlayerUpdatesListSaga),
        takeEvery(actionTypes.PLAYER_DATA_REQUEST, fetchPlayerDataSaga),
        takeLatest(actionTypes.UPDATE_PLAYER_DATA, editPlayerInfoSaga),
        takeLatest(actionTypes.REMOVE_PLAYER, removePlayerSaga),
        takeLatest(actionTypes.PLAYERS_SKILLS_REQUEST, fetchPlayerSkillsSaga),
        takeLatest(actionTypes.UPDATE_PLAYERS_SKILLS, updatePlayerSkillsSaga),
    ]);
}

export default playersSagas;
