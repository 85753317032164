import { User } from 'firebase/auth';
import { TFunction } from 'i18next';
import actionTypes from './auth.actionTypes';
import { AuthState } from './Auth';

export const confirmNewPassword = ({
    newPassword,
    location,
    onFail,
    onSuccess,
}: {
    newPassword: string;
    location: any;
    onFail: (error: any) => void;
    onSuccess: VoidFunction;
}) => ({
    type: actionTypes.CONFIRM_PASSWORD_RESET,
    location,
    newPassword,
    onFail,
    onSuccess,
});

export const signUp = ({
    email,
    password,
    navigate,
    t,
}: {
    navigate: any;
    email: string;
    password: string;
    t: TFunction;
}) => ({
    type: actionTypes.SIGN_UP,
    email,
    password,
    navigate,
    t,
});

export const verifyEmailRequest = ({
    verificationCode,
    t,
}: {
    verificationCode: string | null;
    t: TFunction;
}) => ({
    type: actionTypes.VERIFY_EMAIL_REQUEST,
    verificationCode,
    t,
});

export const setEmailVerificationError = ({ errorMessage }: { errorMessage: string }) => ({
    type: actionTypes.SET_EMAIL_VERIFICATION_ERROR,
    errorMessage,
});

export const startEmailVerification = () => ({
    type: actionTypes.START_EMAIL_VERIFICATION,
});

export const updateUserPassword = ({
    newPassword,
    oldPassword,
    t,
}: {
    newPassword: string;
    oldPassword: string;
    t: TFunction;
}) => ({
    type: actionTypes.UPDATE_USER_PASSWORD,
    newPassword,
    oldPassword,
    t,
});

export const setEmailVerified = () => ({
    type: actionTypes.SET_EMAIL_VERIFIED,
});

export const handleAuthStateChanged = ({ user, t }: { user: User | null; t: TFunction }) => ({
    type: actionTypes.HANDLE_AUTH_STATE_CHANGE,
    user,
    t,
});

export const sendVerifictionEmail = ({
    t,
    onSuccess,
    onFail,
}: {
    t: TFunction;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.SEND_VERIFICATION_EMAIL,
    t,
    onSuccess,
    onFail,
});

export const setAuthState = (authState: AuthState) => ({
    type: actionTypes.SET_AUTH_STATE,
    authState,
});

export const requestSignOut = () => ({
    type: actionTypes.SIGN_OUT,
});

export const resetUserPasswordRequest = ({
    email,
    successMessage,
    failMessage,
}: {
    email: string;
    successMessage: string;
    failMessage: string;
}) => ({
    type: actionTypes.RESET_USER_PASSWORD_REQUEST,
    email,
    successMessage,
    failMessage,
});
