import React from 'react';
import { styled } from '@mui/material/styles';
import { Avatar } from 'common/components';

const Root = styled('div')(() => ({
    display: 'flex',
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    margin: 'auto',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    border: '2px solid transparent',
}));

type AvatarCircleProps = {
    alt: string;
    filePath?: string | null;
    size?: number;
    DefaultIconComponent?: React.FC<{ className?: string; sx?: any }>;
    sx?: any;
};

const AvatarCircle: React.FC<AvatarCircleProps> = ({
    alt,
    filePath,
    size = 64,
    DefaultIconComponent,
    sx,
    ...rest
}) => {
    const innerSize = size - 4; // 2 * borderWidth

    return (
        <Root
            sx={{
                width: size,
                height: size,
                // borderColor: filePath ? '#2196F3' : 'transparent',
                ...sx,
            }}
            {...rest}
        >
            {filePath && (
                <Avatar
                    sx={{ m: 'auto', width: innerSize, height: innerSize }}
                    alt={alt}
                    src={`/cdn/image/width=${size * 4}${filePath}`}
                />
            )}
            {!filePath && DefaultIconComponent && (
                <DefaultIconComponent
                    sx={{
                        color: (theme: any) => theme.palette.text.primary,
                        opacity: 0.5,
                        m: 'auto',
                    }}
                />
            )}
        </Root>
    );
};

export default AvatarCircle;
