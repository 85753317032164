import actionTypes from './reportsProgress.actionTypes';
import ReportProgress from './ReportProgress';

type ReportState = {
    reportsProgressHash: {
        [key: string]: ReportProgress;
    };
};

const initialState: ReportState = {
    reportsProgressHash: {},
};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.REPORT_PROGRESS_DATA_RECEIVED: {
            return {
                ...state,
                reportsProgressHash: {
                    ...state.reportsProgressHash,
                    [action.reportProgressData.id]: action.reportProgressData,
                },
            };
        }
        default:
            return state;
    }
};
