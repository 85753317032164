import { styled } from '@mui/material/styles';
import { ButtonBase } from 'common/components';

export const ColumnTitle = styled(ButtonBase, {
    shouldForwardProp: (prop) => !['isActive'].includes(prop as string),
})<{ isActive?: boolean }>(({ theme, isActive }) => ({
    width: 80,
    ...theme.typography.caption,
    opacity: isActive ? 1 : 0.5,
    justifyContent: 'flex-start',
    flexShrink: 0,
    overflow: 'visible',
}));
