import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'common/components';
import TeamStats from 'entities/teams/TeamStats';
import { StarIcon, TrendingUpIcon } from 'common/icons';
import StatsSection from './StatsSection';

export type StatsInfoProps = {
    teamStats: TeamStats;
};

const StatsInfo: React.FC<StatsInfoProps> = ({ teamStats }) => {
    const { t } = useTranslation();
    const { attendance, evaluation, observations } = teamStats;
    const { teamAverage, playersAverage } = evaluation;
    const attendanceText = attendance ? `${Math.floor(Number(attendance) * 100)}%` : '-';

    return (
        <StatsSection
            IconComponent={TrendingUpIcon}
            sx={{ mb: 1 }}
            title={t('teamPage.teamStats.statsInfo.title')}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                    {`${t('teamPage.teamStats.statsInfo.teamAvgEvaluation')}: ${
                        teamAverage || '-'
                    }`}
                    <StarIcon sx={{ color: '#FFD224', width: 16, height: 16 }} />
                </Typography>
                <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                    {`${t('teamPage.teamStats.statsInfo.playersAvgEvaluation')}: ${
                        playersAverage || '-'
                    }`}
                    <StarIcon sx={{ color: '#FFD224', width: 16, height: 16 }} />
                </Typography>
                <Typography variant="body2">
                    {`${t('teamPage.teamStats.statsInfo.avgAttendance')}: ${attendanceText}`}
                </Typography>
                <Typography variant="body2">
                    {`${t('teamPage.teamStats.statsInfo.newNotes')}: ${observations}`}
                </Typography>
            </Box>
        </StatsSection>
    );
};

export default StatsInfo;
