import actionTypes from './drills.actionTypes';
import Drill, { DrillData } from './Drill';

export const drillCreateRequest = ({
    drillData,
    onSuccess,
    onFail,
}: {
    drillData: DrillData;
    onSuccess: (createdDrillId: string) => void;
    onFail: VoidFunction;
}) => ({
    type: actionTypes.DRILL_CREATE_REQUEST,
    drillData,
    onSuccess,
    onFail,
});

export const drillUpdateRequest = ({
    drillData,
    drillId,
    onFail,
}: {
    drillData: DrillData;
    drillId: string;
    onFail: VoidFunction;
}) => ({
    type: actionTypes.DRILL_UPDATE_REQUEST,
    drillData,
    drillId,
    onFail,
});

export const requestDrillData = ({
    drillId,
    methodologyId,
}: {
    drillId: string;
    methodologyId: string;
}) => ({
    type: actionTypes.DRILL_DATA_REQUEST,
    drillId,
    methodologyId,
});

export const addDrillVariation = ({
    masterDrill,
    methodologyId,
    onSuccess,
}: {
    masterDrill: Drill;
    methodologyId: string;
    onSuccess: (newVariationId: string) => void;
}) => ({
    type: actionTypes.ADD_DRILL_VARIATION,
    masterDrill,
    methodologyId,
    onSuccess,
});

export const requestDrillWithVariationsData = ({
    drillId,
    methodologyId,
}: {
    drillId: string;
    methodologyId: string;
}) => ({
    type: actionTypes.DRILL_WITH_VARIATIONS_REQUEST,
    drillId,
    methodologyId,
});

export const drillDataReceived = ({ drillData }: { drillData: Drill }) => ({
    type: actionTypes.DRILL_DATA_RECEIVED,
    drillData,
});

export const removeDrill = ({ drillId }: { drillId: string }) => ({
    type: actionTypes.REMOVE_DRILL,
    drillId,
});

export const drillDeleteRequest = ({
    drillId,
    methodologyId,
    onSuccess,
    onFail,
}: {
    drillId: string;
    methodologyId: string;
    onSuccess: VoidFunction;
    onFail: VoidFunction;
}) => ({
    type: actionTypes.DRILL_DELETE_REQUEST,
    drillId,
    methodologyId,
    onSuccess,
    onFail,
});

export const drillsListRequest = ({ methodologyId }: { methodologyId: string }) => {
    return {
        type: actionTypes.DRILLS_LIST_REQUEST,
        methodologyId,
    };
};

export const drillsListReceived = ({ drillsList }: { drillsList: Drill[] }) => {
    return {
        type: actionTypes.DRILLS_LIST_RECEIVED,
        drillsList,
    };
};
