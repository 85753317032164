import actionTypes from './markers.actionTypes';
import Marker from './Marker';

export type MarkersState = {
    markersHash: {
        [key: string]: Marker[];
    };
};

const initialState = {
    markersHash: {},
};

const formatMarkersData = (markersString: string) => {
    const rows = markersString.split('\n');
    const columns = rows[0].split(',').slice(1);
    const frameMarkers = rows.slice(1).map((frameRow) => {
        const markers = frameRow
            .split(',')
            .slice(1)
            .reduce((acc, marker, index) => {
                return {
                    ...acc,
                    [columns[index]]: marker,
                };
            }, {});

        return markers;
    });

    return frameMarkers;
};

export default (state: MarkersState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.MARKERS_DATA_RECEIVED: {
            const { eventId, periodNumber, markersData, target } = action;
            const recordId = `${eventId}_${periodNumber}_${target}`;
            return {
                ...state,
                markersHash: {
                    ...state.markersHash,
                    [recordId]: formatMarkersData(markersData),
                },
            };
        }
        default:
            return state;
    }
};
