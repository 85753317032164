import { takeLatest, all, call, put } from 'redux-saga/effects';
import { sendEmailVerification } from 'firebase/auth';
import { toastr } from 'react-redux-toastr';
import _get from 'lodash/get';
import qs from 'qs';
import * as usersActions from 'entities/users/users.actions';
import * as authGateway from 'gateways/authGateway';
import { auth } from 'common/firebase';
import * as analytics from 'common/analytics';
import { AuthState } from './Auth';
import authActionTypes from './auth.actionTypes';
import * as authActions from './auth.actions';
import getErrorMessage from './getErrorMessage';

export function* signOutSaga() {
    try {
        yield call([authGateway, authGateway.signOut]);
        yield put(usersActions.setUserData(null));
    } catch (e: any) {
        console.error(e);
    }
}

export function* resetUserPasswordSaga({
    email,
    successMessage,
    failMessage,
}: ReturnType<typeof authActions.resetUserPasswordRequest>) {
    try {
        yield call([authGateway, authGateway.resetUserPassword], { email });
        yield call(toastr.success, '', successMessage);
    } catch (e: any) {
        yield call(toastr.error, '', failMessage, { timeOut: 0 });
        console.error(e);
    }
}

export function* confirmPasswordResetSaga({
    location,
    newPassword,
    onFail,
    onSuccess,
}: ReturnType<typeof authActions.confirmNewPassword>) {
    const confirmationCode = _get(qs.parse(location.search), 'oobCode') as string;
    try {
        if (!confirmationCode) {
            throw new Error('Wrong confirmation code');
        }
        yield call([authGateway, authGateway.setNewPassword], {
            confirmationCode,
            newPassword,
        });
        yield call(onSuccess);
    } catch (e: any) {
        yield call(onFail, e);
    }
}

export function* signUpSaga({
    email,
    password,
    navigate,
    t,
}: ReturnType<typeof authActions.signUp>) {
    try {
        const { user } = yield call([authGateway, authGateway.createUserWithEmailAndPassword], {
            email: email.toLowerCase(),
            password,
        });
        yield call(sendEmailVerification, user);
        yield call(navigate, '/auth/not-verified-email');
        yield call(toastr.success, '', t('auth.emailVerification.emailSent', { email }));
    } catch (e: any) {
        yield call(toastr.error, '', getErrorMessage(e, t), { timeOut: 0 });
    }
}

export function* verifyEmailSaga({
    verificationCode,
    t,
}: ReturnType<typeof authActions.verifyEmailRequest>) {
    try {
        yield put(authActions.startEmailVerification());
        yield call([authGateway, authGateway.applyActionCode], {
            code: verificationCode || '',
        });
        yield put(authActions.setEmailVerified());
        yield call([authGateway, authGateway.signOut]);
        yield call(toastr.success, '', 'Your email verified! Please log in');
        yield call([analytics, analytics.logEvent], {
            event: analytics.GtmCustomEvents.createAccount,
        });
    } catch (e: any) {
        yield put(
            authActions.setEmailVerificationError({
                errorMessage: getErrorMessage(e, t),
            }),
        );
    }
}

export function* updatePasswordSaga({
    newPassword,
    oldPassword,
    t,
}: ReturnType<typeof authActions.updateUserPassword>) {
    try {
        yield call([authGateway, authGateway.reAuthenticateUser], oldPassword);
        yield call([authGateway, authGateway.updatePassword], { newPassword });
        yield call(toastr.success, '', t('auth.changePassword.success'));
    } catch (e: any) {
        yield call(toastr.error, '', getErrorMessage(e, t), { timeOut: 0 });
    }
}

export function* authStateChangedSaga({
    user,
    t,
}: ReturnType<typeof authActions.handleAuthStateChanged>) {
    try {
        if (!user) {
            yield put(usersActions.clearUserData());
            yield put(authActions.setAuthState(AuthState.notAuthenticated));
        } else {
            const authState = user.emailVerified ? AuthState.verified : AuthState.notVerified;
            yield put(authActions.setAuthState(authState));
        }
    } catch (e: any) {
        yield call(toastr.error, '', getErrorMessage(e, t), { timeOut: 0 });
    }
}

export function* sendVerificationEmailSaga({
    t,
    onSuccess,
    onFail,
}: ReturnType<typeof authActions.sendVerifictionEmail>) {
    try {
        yield call(sendEmailVerification, auth.currentUser!);
        yield call(
            toastr.success,
            '',
            t('auth.emailVerification.emailSent', { email: auth.currentUser?.email }),
        );

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e: any) {
        yield call(toastr.error, '', getErrorMessage(e, t), { timeOut: 0 });

        if (onFail) {
            yield call(onFail);
        }
    }
}

function* authSagas() {
    yield all([
        takeLatest(authActionTypes.SEND_VERIFICATION_EMAIL, sendVerificationEmailSaga),
        takeLatest(authActionTypes.SIGN_UP, signUpSaga),
        takeLatest(authActionTypes.CONFIRM_PASSWORD_RESET, confirmPasswordResetSaga),
        takeLatest(authActionTypes.VERIFY_EMAIL_REQUEST, verifyEmailSaga),
        takeLatest(authActionTypes.UPDATE_USER_PASSWORD, updatePasswordSaga),
        takeLatest(authActionTypes.HANDLE_AUTH_STATE_CHANGE, authStateChangedSaga),
        takeLatest(authActionTypes.SIGN_OUT, signOutSaga),
        takeLatest(authActionTypes.RESET_USER_PASSWORD_REQUEST, resetUserPasswordSaga),
    ]);
}

export default authSagas;
