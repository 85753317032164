import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ClockIcon = (props: any) => (
    <SvgIcon viewBox="0 0 10 10" {...props}>
        <g>
            <title>Clock</title>
            <path
                d="M4.996 0.833252C2.696 0.833252 0.833496 2.69992 0.833496 4.99992C0.833496 7.29992 2.696 9.16659 4.996 9.16659C7.30016 9.16659 9.16683 7.29992 9.16683 4.99992C9.16683 2.69992 7.30016 0.833252 4.996 0.833252ZM5.00016 8.33325C3.1585 8.33325 1.66683 6.84159 1.66683 4.99992C1.66683 3.15825 3.1585 1.66659 5.00016 1.66659C6.84183 1.66659 8.3335 3.15825 8.3335 4.99992C8.3335 6.84159 6.84183 8.33325 5.00016 8.33325ZM4.9085 2.91659H4.8835C4.71683 2.91659 4.5835 3.04992 4.5835 3.21659V5.18325C4.5835 5.32909 4.6585 5.46659 4.78766 5.54159L6.51683 6.57909C6.6585 6.66242 6.84183 6.62075 6.92516 6.47909C7.01266 6.33742 6.96683 6.14992 6.821 6.06659L5.2085 5.10825V3.21659C5.2085 3.04992 5.07516 2.91659 4.9085 2.91659Z"
                fill="black"
            />
        </g>
    </SvgIcon>
);

export default ClockIcon;
