import { DrillData, DrillType, drillTypesList } from './Drill';
import { drillTypesConfig } from './drills.constants';

export type TimePerDrillType = {
    [key in DrillType]: number;
};

export const getEmptyDrillsTimeByTypeMap = () => {
    const initialData: TimePerDrillType = {
        [DrillType.APLI]: 0,
        [DrillType.AR]: 0,
        [DrillType.CT]: 0,
        [DrillType.GPP]: 0,
        [DrillType.OTHER]: 0,
        [DrillType.POS]: 0,
        [DrillType.POC]: 0,
        [DrillType.IT]: 0,
        [DrillType.SETP]: 0,
        [DrillType.SG]: 0,
        [DrillType.SP]: 0,
        [DrillType.SPP]: 0,
        [DrillType.WARMUP]: 0,
    };

    return initialData;
};

export const addTimePerdrillType = (a: TimePerDrillType, b: TimePerDrillType) => {
    const result = { ...a };
    drillTypesList.forEach((drillType) => {
        result[drillType] += b[drillType];
    });

    return result;
};

export const getDrillColorByDrillType = (drillType: DrillType) => {
    return drillTypesConfig[drillType]?.color || 'rgba(0, 0, 0, 0.04)';
};

export const createEmptyDrill = (methodologyId: string, name?: string) => {
    const drillData: DrillData = {
        drillType: DrillType.OTHER,
        description: '',
        objectives: '',
        equipment: '',
        illustrations: [],
        descriptionFilePath: null,
        instructionsFilePath: null,
        name: name || '',
        methodologyId,
        duration: 10,
        sets: 1,
        predecessors: [],
        evolutions: [],
        links: [],
        recordings: [],
        masterDrillId: null,
        variations: [],
        previewImg: null,
        tacticalSpecialization: null,
        ageGroups: [],
        focus: null,
        spaceSize: [],
        spaceZone: [],
        load: [],
        volume: [],
        complexity: null,
        tacticBoardScheme: null,
        prototypeDrill: null,
    };

    return drillData;
};
