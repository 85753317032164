import * as agentsGateway from 'gateways/agentsGateway';
import { put, call, all, takeLatest, takeEvery } from 'redux-saga/effects';
import * as agentsActions from './agents.actions';
import Agent from './Agents';
import agentsActionTypes from './agents.actionTypes';

export function* fetchAgentDataSaga({
    agentId,
    onSuccess,
}: ReturnType<typeof agentsActions.agentDataRequest>) {
    try {
        const agent: Agent = yield call(agentsGateway.fetchAgentById, {
            agentId,
        });
        if (agent) {
            yield put(agentsActions.agentDataRecieved(agent));
        }
        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

export function* fetchAgentsListsSaga({
    organizationId,
}: ReturnType<typeof agentsActions.agentsListRequest>) {
    try {
        const agentsList: Agent[] = yield call([agentsGateway, agentsGateway.fetchAgentsList], {
            organizationId,
        });
        if (agentsList) {
            yield put(agentsActions.agentsListRecieved(agentsList));
        }
    } catch (e) {
        console.error(e);
    }
}

export function* fetchAgentsWithoutAgencyListsSaga({
    organizationId,
}: ReturnType<typeof agentsActions.agentsWithoutAgencyListRequest>) {
    try {
        const agentsList: Agent[] = yield call([agentsGateway, agentsGateway.fetchAgentsWithoutAgencyList], {
            organizationId,
        });
        if (agentsList) {
            yield put(agentsActions.agentsListRecieved(agentsList));
        }
    } catch (e) {
        console.error(e);
    }
}

export function* fetchAgencyAgentsListsSaga({
    agencyId,
}: ReturnType<typeof agentsActions.agencyAgentsListRequest>) {
    try {
        const agentsList: Agent[] = yield call([agentsGateway, agentsGateway.fetchAgencyAgentsList], {
            agencyId,
        });
        if (agentsList) {
            yield put(agentsActions.agentsListRecieved(agentsList));
        }
    } catch (e) {
        console.error(e);
    }
}

export function* updateAgentSaga({
    agentId,
    agentData,
    onSuccess,
}: ReturnType<typeof agentsActions.agentDataUpdate>) {
    try {
        yield call([agentsGateway, agentsGateway.updateAgent], {
            agentId,
            agentData,
        });
        yield put(agentsActions.agentDataRequest({ agentId }));

        if (onSuccess) {
            onSuccess(agentData);
        }
    } catch (e) {
        console.error(e);
    }
}

export function* createAgentSaga({
    agentData,
    onSuccess,
}: ReturnType<typeof agentsActions.agentCreate>) {
    try {
        const agentId: string = yield call([agentsGateway, agentsGateway.createAgent], {
            agentData,
        });
        yield put(agentsActions.agentDataRequest({ agentId }));
        if (onSuccess) {
            onSuccess(agentId);
        }
    } catch (e) {
        console.error(e);
    }
}

function* agentsSagas() {
    yield all([
        takeLatest(agentsActionTypes.AGENT_DATA_REQUEST, fetchAgentDataSaga),
        takeLatest(agentsActionTypes.AGENT_DATA_UPDATE, updateAgentSaga),
        takeLatest(agentsActionTypes.AGENT_CREATE, createAgentSaga),
        takeEvery(agentsActionTypes.AGENTS_LIST_REQUEST, fetchAgentsListsSaga),
        takeLatest(agentsActionTypes.AGENCY_AGENTS_LIST_REQUEST, fetchAgencyAgentsListsSaga),
        takeLatest(agentsActionTypes.AGENTS_WITHOUT_AGENCY_LIST_REQUEST, fetchAgentsWithoutAgencyListsSaga),
    ]);
}

export default agentsSagas;
