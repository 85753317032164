import React from 'react';
import { styled } from '@mui/material/styles';
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import { CheckBoxEmptyIcon, CheckBoxFilledIcon } from 'common/icons';

const PREFIX = 'Checkbox';

const classes = {
    root: `${PREFIX}-root`,
    checkbox: `${PREFIX}-checkbox`,
};

const StyledCheckBoxFilledIcon = styled(CheckBoxFilledIcon)(({ theme }) => ({
    [`& .${classes.root}`]: {
        marginLeft: 0,
    },

    [`& .${classes.checkbox}`]: {
        padding: theme.spacing(0, 1, 0, 0),
    },
}));

type CheckboxProps = MuiCheckboxProps & {
    stroke?: string;
    fill?: string;
};

const Checkbox: React.FC<CheckboxProps> = ({ stroke, fill, ...props }) => {
    return (
        <MuiCheckbox
            classes={{
                root: classes.checkbox,
            }}
            {...props}
            icon={<CheckBoxEmptyIcon style={{ stroke, fill: stroke }} />}
            checkedIcon={<StyledCheckBoxFilledIcon style={{ stroke, fill }} />}
        />
    );
};

export default Checkbox;
