import { takeLatest, all, put, call } from 'redux-saga/effects';
import * as configGateway from 'gateways/configsGateway';
import configActionTypes from './config.actionTypes';
import * as configActions from './config.actions';
import { AppConfig, defaultLocalConfig } from './Config';

export function* fetchAppConfigSaga() {
    try {
        const appConfig: AppConfig = yield call([configGateway, configGateway.fetchAppConfig]);
        yield put(configActions.appConfigReceived({ appConfig }));
    } catch (e) {
        console.error(e);
    }
}

export function* saveLocalConfigSaga({
    localConfig,
}: ReturnType<typeof configActions.saveLocalConfig>) {
    try {
        localStorage.setItem('localConfig', JSON.stringify(localConfig));
        yield put(configActions.setLocalConfig({ localConfig }));
    } catch (e) {
        console.error('Failed to update local config', e);
    }
}

export function* applyLocalConfigSaga() {
    try {
        const localConfig = JSON.parse(localStorage.getItem('localConfig') || '{}');

        yield put(
            configActions.setLocalConfig({
                localConfig: {
                    ...defaultLocalConfig,
                    ...localConfig,
                },
            }),
        );
    } catch (e) {
        console.error('Failed to apply local config', e);
    }
}

function* appConfigSagas() {
    yield all([
        takeLatest(configActionTypes.APP_CONFIG_REQUEST, fetchAppConfigSaga),
        takeLatest(configActionTypes.SAVE_LOCAL_CONFIG, saveLocalConfigSaga),
        takeLatest(configActionTypes.APPLY_LOCAL_CONFIG, applyLocalConfigSaga),
    ]);
}

export default appConfigSagas;
