import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'common/components';
import { WhatsAppIcon } from 'common/icons';
import { styled } from '@mui/material/styles';

const StylesWhatsAppBtn = styled(Button)({
    backgroundColor: '#128c7e',

    '&:hover': {
        backgroundColor: '#075e54',
    },
});

type WhatsAppBtnProps = {
    size?: 'small';
    className?: string;
    sx?: any;
};

const WhatsAppBtn: React.FC<WhatsAppBtnProps> = ({ size, ...rest }) => {
    const { t } = useTranslation();

    return (
        <StylesWhatsAppBtn
            component="a"
            target="_blank"
            variant="contained"
            color="primary"
            size={size}
            startIcon={<WhatsAppIcon />}
            href="https://wa.me/message/S5D5TM5HMDILM1"
            {...rest}
        >
            {t('support.openChat.whatsApp')}
        </StylesWhatsAppBtn>
    );
};

export default WhatsAppBtn;
