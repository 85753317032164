import { db } from 'common/firebase';
import { EvaluationData } from 'entities/evaluations/Evaluation';
import {
    addDoc,
    collection,
    collectionGroup,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    where,
} from 'firebase/firestore';
import { handleCollectionSnapshot, handleDocumentSnapshot } from './utils';

const getEvaluationsCollectionPath = ({
    organizationId,
    teamId,
    playerId,
}: {
    organizationId?: string;
    teamId?: string;
    playerId?: string;
}) => {
    const collectionPath = playerId
        ? `players/${playerId}/evaluations`
        : `organizations/${organizationId}/teams/${teamId}/evaluations`;
    return collectionPath;
};

export const editEvaluation = ({
    evaluationData,
    evaluationId,
}: {
    evaluationData: EvaluationData;
    evaluationId: string;
}) => {
    const { organizationId, teamId, playerId } = evaluationData;
    const collectionPath = getEvaluationsCollectionPath({ organizationId, teamId, playerId });
    const evaluationRef = doc(db, `${collectionPath}/${evaluationId}`);

    return setDoc(evaluationRef, evaluationData);
};

export const createEvaluation = (evaluationData: EvaluationData) => {
    const { organizationId, teamId, playerId } = evaluationData;
    const collectionPath = getEvaluationsCollectionPath({ organizationId, teamId, playerId });
    const evaluationsRef = collection(db, collectionPath);

    return addDoc(evaluationsRef, evaluationData).then((doc) => doc.id);
};

export const fetchEvaluationsForEvent = (eventId: string) => {
    const evaluationsRef = collectionGroup(db, 'evaluations');
    const q = query(evaluationsRef, where('eventId', '==', eventId));

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchPlayerEvaluationsForEvent = ({
    eventId,
    playerId,
}: {
    eventId: string;
    playerId: string;
}) => {
    const evaluationsRef = collection(db, `players/${playerId}/evaluations`);
    const q = query(evaluationsRef, where('eventId', '==', eventId));

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchEvaluationData = ({
    organizationId,
    teamId,
    playerId,
    evaluationId,
}: {
    organizationId?: string;
    teamId?: string;
    playerId?: string;
    evaluationId: string;
}) => {
    const collectionPath = getEvaluationsCollectionPath({ organizationId, teamId, playerId });
    const evaluationRef = doc(db, `${collectionPath}/${evaluationId}`);

    return getDoc(evaluationRef).then(handleDocumentSnapshot);
};

export const fetchEvaluationsListByRange = ({
    organizationId,
    teamId,
    playerId,
    from,
    to,
}: {
    organizationId?: string;
    teamId?: string;
    playerId?: string;
    from: Date;
    to: Date;
}) => {
    const collectionPath = getEvaluationsCollectionPath({ organizationId, teamId, playerId });
    const evaluationsRef = collection(db, collectionPath);
    const q = query(evaluationsRef, where('dateTime', '>=', from), where('dateTime', '<=', to));

    return getDocs(q).then(handleCollectionSnapshot);
};
