import { all, select, call, takeEvery, put } from 'redux-saga/effects';

import _groupBy from 'lodash/groupBy';
import * as episodesSelectors from 'entities/episodes/episodes.selectors';
import { isOfflineSelector } from 'common/config/config.selectors';
import * as episodesGateway from 'gateways/episodesGateway';
import { formatMatchTime } from 'common/utils/dateTimeFormatter';
import { Periods } from 'features/report/ReportPageTypes';
import { toastr } from 'react-redux-toastr';
import { downloadFile } from 'common/utils/filesUtils';
import EpisodesGroup, {
    Episode,
    EpisodesGroupData,
    EpisodesType,
} from 'entities/episodes/Episodes';
import reportTaggingPageActionTypes from './reportTaggingPage.actionTypes';
import * as reportTaggingPageActions from './reportTaggingPage.actions';
import { getFormattedMatchTime, getOpponentEpisode } from './reportTaggingPage.utils';
import { EpisodeInfo } from './reportTaggingPage.actions';

type EpisodeToSave = {
    episodeData: Episode;
    episodeName: string;
};

function* saveEpisodesGroup({
    episodeType,
    period,
    eventId,
    teamId,
    organizationId,
    episodesList,
}: {
    episodeType: EpisodesType;
    period: Periods;
    eventId: string;
    teamId: string;
    organizationId: string;
    episodesList: EpisodeToSave[];
}) {
    try {
        const episodesGroupsList: EpisodesGroup[] = yield select(
            episodesSelectors.episodesGroupSelector,
            {
                period,
                eventId,
                episodesType: episodeType,
            },
        );

        const isOffline: boolean = yield select(isOfflineSelector);

        if (isOffline) {
            throw new Error('isOffline');
        }

        const episodesDataList = episodesList.map((episode) => episode.episodeData);

        if (episodesGroupsList.length > 0) {
            const { id, episodes } = episodesGroupsList[0];
            const newEpisodesList = episodes.concat(episodesDataList);
            yield call([episodesGateway, episodesGateway.updateEpisodesGroup], {
                organizationId,
                teamId,
                eventId,
                episodesGroupId: id,
                episodesGroupData: {
                    type: episodeType,
                    episodes: newEpisodesList,
                    period,
                },
            });
        } else {
            const episodesGroupData: EpisodesGroupData = {
                type: episodeType,
                period,
                episodes: episodesDataList,
            };
            yield call([episodesGateway, episodesGateway.createEpisodesGroup], {
                organizationId,
                teamId,
                eventId,
                episodesGroupData,
            });
        }

        yield all(
            episodesList.map((savedEpisode) => {
                const time = `${getFormattedMatchTime(
                    savedEpisode.episodeData.startTime || 0,
                    period,
                )} - ${getFormattedMatchTime(savedEpisode.episodeData.endTime || 0, period)}`;
                return call(
                    toastr.success,
                    'Episode saved',
                    `[p. ${period}][ ${time} ] ${savedEpisode.episodeName}`,
                );
            }),
        );
    } catch (err) {
        yield all(
            episodesList.map((savedEpisode) => {
                const time = `${getFormattedMatchTime(
                    savedEpisode.episodeData.startTime || 0,
                    period,
                )} - ${getFormattedMatchTime(savedEpisode.episodeData.endTime || 0, period)}`;
                return call(
                    toastr.error,
                    'Failed to save episode. Check your internet connection and reload the page',
                    `[ p. ${period} ][ ${time} ] ${savedEpisode.episodeName}`,
                );
            }),
        );
    }
}

/*
 * The reason of complicated logic here is the way we store episodes within episodesGroup
 * All episodes of the same type should be stored at once
 * While we create oposite episodes for opponent team there could be episodes of same or a different type
 * by a single click on tag pannel
 */
function* saveEpisodeSaga({
    organizationId,
    teamId,
    eventId,
    period,
    episodesList,
    t,
}: ReturnType<typeof reportTaggingPageActions.saveEpisode>) {
    try {
        const opponentEpisodes = episodesList
            .map((episodeInfo) =>
                getOpponentEpisode(episodeInfo.episodeType, episodeInfo.episodeData, t),
            )
            .filter((episodeInfo) => episodeInfo);

        const fullEpisodesList = [...episodesList, ...opponentEpisodes];
        const groupedEpisodesMap = _groupBy(
            fullEpisodesList,
            (episodeInfo) => episodeInfo?.episodeType,
        );
        const entries = Object.entries(groupedEpisodesMap) as Array<[EpisodesType, EpisodeInfo[]]>;

        yield all(
            entries.map(([episodeType, episodesGroupList]) =>
                call(saveEpisodesGroup, {
                    episodeType,
                    period,
                    eventId,
                    teamId,
                    organizationId,
                    episodesList: episodesGroupList.map(({ episodeName, episodeData }) => ({
                        episodeName,
                        episodeData,
                    })),
                }),
            ),
        );

        const addedEpisodesIds = fullEpisodesList.map((episode) => episode!.episodeData.id);
        yield put(reportTaggingPageActions.setLastAddedEpisodes({ episodesIds: addedEpisodesIds }));
    } catch (err) {
        yield call(toastr.error, 'Failed to save episodes.', `Something wrong :(`);
    }
}

function* deleteEpisodeSaga({
    episodeData,
    period,
    eventId,
    teamId,
    organizationId,
}: ReturnType<typeof reportTaggingPageActions.deleteEpisode>) {
    try {
        const episodesGroupsList: EpisodesGroup[] = yield select(
            episodesSelectors.episodesGroupSelector,
            {
                period,
                eventId,
                episodesType: episodeData.type,
            },
        );

        const isOffline: boolean = yield select(isOfflineSelector);

        if (isOffline || episodesGroupsList.length === 0) {
            throw new Error('isOffline');
        }

        const { id, episodes } = episodesGroupsList[0];
        const newEpisodesList = episodes.filter((episode) => !(episode.id === episodeData.id));
        yield call([episodesGateway, episodesGateway.updateEpisodesGroup], {
            organizationId,
            teamId,
            eventId,
            episodesGroupId: id,
            episodesGroupData: {
                type: episodeData.type,
                episodes: newEpisodesList,
                period,
            },
        });
        const time = `${formatMatchTime(episodeData.startTime)} - ${formatMatchTime(
            episodeData.endTime,
        )}`;
        yield call(toastr.success, 'Episode deleted', `[p. ${period}][ ${time} ]`);
    } catch (err) {
        const time = `${formatMatchTime(episodeData.startTime)} - ${formatMatchTime(
            episodeData.endTime,
        )}`;
        yield call(
            toastr.error,
            'Failed to delete episode. Check your internet connection and reload the page',
            `[ p. ${period} ][ ${time} ] ${episodeData.type}`,
        );
    }
}

function* exportPeriodEpisodesSaga({
    period,
    eventId,
}: ReturnType<typeof reportTaggingPageActions.exportPeriodEpisodes>) {
    const episodesGroupsList: EpisodesGroup[] = yield select(
        episodesSelectors.episodesGroupsListSelector,
        {
            eventId,
        },
    );
    const periodEpisodesGroupsList = episodesGroupsList.filter(
        (episodesGroup) => episodesGroup.period === period,
    );
    const fileName = `${eventId}_${period}_episodes-groups-list.json`;
    yield call(downloadFile, {
        content: JSON.stringify(periodEpisodesGroupsList),
        fileName,
    });
}

const readFile = (file: File) => {
    const fileReadPromise = new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
            const fileContent = reader.result as string;
            resolve(JSON.parse(fileContent));
        };
    });

    return fileReadPromise as Promise<EpisodesGroup[]>;
};

function* importPeriodEpisodesSaga({
    organizationId,
    teamId,
    eventId,
    episodesFile,
}: ReturnType<typeof reportTaggingPageActions.importPeriodEpisodes>) {
    const periodEpisodesGroupsList = (yield readFile(episodesFile)) as EpisodesGroup[];
    yield all(
        periodEpisodesGroupsList.map(({ episodes, period, type }) =>
            call([episodesGateway, episodesGateway.createEpisodesGroup], {
                organizationId,
                teamId,
                eventId,
                episodesGroupData: {
                    episodes,
                    period,
                    type,
                },
            }),
        ),
    );
}

function* reportTaggingPageSaga() {
    yield all([
        takeEvery(reportTaggingPageActionTypes.SAVE_EPISODE, saveEpisodeSaga),
        takeEvery(reportTaggingPageActionTypes.DELETE_EPISODE, deleteEpisodeSaga),
        takeEvery(reportTaggingPageActionTypes.EXPORT_PERIOD_EPISODES, exportPeriodEpisodesSaga),
        takeEvery(reportTaggingPageActionTypes.IMPORT_PERIOD_EPISODES, importPeriodEpisodesSaga),
    ]);
}

export default reportTaggingPageSaga;
