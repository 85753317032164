import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SubstitutionOutIcon = (props: any) => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g>
            <title>SubstitutionOut</title>
            <path
                fill="#cf3917"
                d="M14.79 13H13V4c0-.55-.45-1-1-1s-1 .45-1 1v9H9.21c-.45 0-.67.54-.35.85l2.79 2.79c.2.2.51.2.71 0l2.79-2.79c.31-.31.09-.85-.36-.85zM4 20c0 .55.45 1 1 1z"
            />
        </g>
    </SvgIcon>
);

export default SubstitutionOutIcon;
