import { useSelector } from 'react-redux';
import { defaultPermissionsSelector, userAccessRecordsSelector } from './access.selectors';
import AccessRecord, { AccessTarget, AccessTypes, Roles } from './Access';
import { Permissions } from './Permissions';
import { usePlayer } from 'entities/players/players.hooks';

export const useDefaultPermissionsForRole = (accessRecord?: AccessRecord) => {
    const defaultPermissions = useSelector(defaultPermissionsSelector);

    if (!accessRecord) {
        return [];
    }

    const { accessType, role } = accessRecord;

    return (defaultPermissions?.[accessType]?.[role] || []) as Permissions[];
};

const mergePermissions = (a: Permissions[], b: Permissions[]) => {
    return Array.from(new Set(a.concat(b)));
};

const makePermissionsFunc = (permissions: Permissions[]) => {
    return (p: Permissions) => permissions.includes(p);
};

const useOrgPermissionsList = (accessRecords: AccessRecord[], organizationId: string) => {
    const orgAccessRecord = accessRecords.find(
        (accessRecord) =>
            accessRecord.target.organizationId === organizationId &&
            accessRecord.accessType === AccessTypes.organization,
    );

    const defaultOrgPermissions = useDefaultPermissionsForRole(orgAccessRecord);

    return orgAccessRecord?.permissions || defaultOrgPermissions;
};

export const useOrgPermissions = ({ organizationId }: { organizationId: string }) => {
    const accessRecords = useSelector(userAccessRecordsSelector);
    const orgPermissions = useOrgPermissionsList(accessRecords, organizationId);

    return makePermissionsFunc(orgPermissions);
};

export const useTeamPermissionsList = (accessRecords: AccessRecord[], teamId: string) => {
    const teamAccessRecord = accessRecords.find(
        (accessRecord) =>
            accessRecord.target.teamId === teamId && accessRecord.accessType === AccessTypes.team,
    );

    const defaultTeamPermissions = useDefaultPermissionsForRole(teamAccessRecord);

    return teamAccessRecord?.permissions || defaultTeamPermissions;
};

export const useTeamPermissions = ({
    organizationId,
    teamId,
}: {
    organizationId: string;
    teamId: string;
}) => {
    const accessRecords = useSelector(userAccessRecordsSelector);
    const orgPermissions = useOrgPermissionsList(accessRecords, organizationId);
    const teamPermissions = useTeamPermissionsList(accessRecords, teamId);

    return makePermissionsFunc(mergePermissions(orgPermissions, teamPermissions));
};

/* Check user permissions for each active player team */
const getPlayerTeamsOrgsPermissions = (accessRecords: AccessRecord[], playerId: string) => {
    const player = usePlayer(playerId);

    if (!player) {
        return [];
    }

    const activePlayerTeamsIds = Object.entries(player.teams)
        .filter((entry) => entry[1].active)
        .map((entry) => entry[0]);
    const activePlayerOrganizationsIds = Object.entries(player.organizations)
        .filter((entry) => entry[1].active)
        .map((entry) => entry[0]);

    const organizationsPermissions = accessRecords
        .filter(
            ({ accessType, target }) =>
                accessType === AccessTypes.organization &&
                activePlayerOrganizationsIds.includes(target.organizationId!),
        )
        .reduce((acc: Permissions[], { permissions }) => acc.concat(permissions), []);

    const teamsPermissions = accessRecords
        .filter(
            ({ accessType, target }) =>
                accessType === AccessTypes.team && activePlayerTeamsIds.includes(target.teamId!),
        )
        .reduce((acc: Permissions[], { permissions }) => acc.concat(permissions), []);

    return Array.from(new Set(organizationsPermissions.concat(teamsPermissions)));
};

const usePlayerPermissionsList = (accessRecords: AccessRecord[], playerId: string) => {
    const playerAccessRecord = accessRecords.find(
        (accessRecord) =>
            accessRecord.target.playerId === playerId &&
            accessRecord.accessType === AccessTypes.player,
    );

    const defaultPlayerPermissions = useDefaultPermissionsForRole(playerAccessRecord);
    const playerTeamsPermissions = getPlayerTeamsOrgsPermissions(accessRecords, playerId);

    return (playerAccessRecord?.permissions || defaultPlayerPermissions).concat(
        playerTeamsPermissions,
    );
};

export const usePlayerPermissions = (playerId: string) => {
    const accessRecords = useSelector(userAccessRecordsSelector);
    const playerPermissions = usePlayerPermissionsList(accessRecords, playerId);

    return makePermissionsFunc(playerPermissions);
};

const useMethodologyPermissionsList = (accessRecords: AccessRecord[], methodologyId: string) => {
    const methodologyAccessRecord = accessRecords.find(
        (accessRecord) =>
            accessRecord.target.methodologyId === methodologyId &&
            accessRecord.accessType === AccessTypes.methodology,
    );

    const defaultMethodologyPermissions = useDefaultPermissionsForRole(methodologyAccessRecord);

    return methodologyAccessRecord?.permissions || defaultMethodologyPermissions;
};

export const useMethodologyPermissions = ({ methodologyId }: { methodologyId: string }) => {
    const accessRecords = useSelector(userAccessRecordsSelector);
    const methodologyPermissions = useMethodologyPermissionsList(accessRecords, methodologyId);

    return makePermissionsFunc(methodologyPermissions);
};

/* TODO: removed after permissions released */
export const isSameTarget = (t1: AccessTarget, t2: AccessTarget, accessType: AccessTypes) => {
    if (accessType === AccessTypes.longlist) {
        return t1.listId === t2.listId;
    }

    if (accessType === AccessTypes.organization) {
        return t1.organizationId === t2.organizationId;
    }

    if (accessType === AccessTypes.team) {
        return t1.teamId === t2.teamId;
    }

    if (accessType === AccessTypes.methodology) {
        return t1.methodologyId === t2.methodologyId;
    }

    if (accessType === AccessTypes.player) {
        return t1.playerId === t2.playerId;
    }

    return false;
};

export const hasAccess = (
    accessRecords: AccessRecord[],
    target: AccessTarget,
    accessType: AccessTypes,
    roles: Roles[],
) => {
    const accessRecord = accessRecords.find(
        (record) =>
            record.accessType === accessType && isSameTarget(target, record.target, accessType),
    );

    return accessRecord ? roles.includes(accessRecord.role) : false;
};

/* TODO: removed after permissions released

    const useAccess = ({ organizationId, teamId }: { organizationId: string; teamId?: string }) => {
        const accessRecords = useSelector(userAccessRecordsSelector);

        return useMemo(
            () => getFeaturesAccessMap(accessRecords, organizationId, teamId),
            [accessRecords, organizationId, teamId],
        );
    };

    export const isSameTarget = (t1: AccessTarget, t2: AccessTarget, accessType: AccessTypes) => {
        if (accessType === AccessTypes.longlist) {
            return t1.listId === t2.listId;
        }

        if (accessType === AccessTypes.organization) {
            return t1.organizationId === t2.organizationId;
        }

        if (accessType === AccessTypes.team) {
            return t1.teamId === t2.teamId;
        }

        if (accessType === AccessTypes.methodology) {
            return t1.methodologyId === t2.methodologyId;
        }

        if (accessType === AccessTypes.player) {
            return t1.playerId === t2.playerId;
        }

        return false;
    };

    export const getFeaturesAccessMap = (
        accessRecords: AccessRecord[],
        organizationId: string,
        teamId?: string,
    ) => {
        // Roles
        const isOrgAdmin = hasAccess(accessRecords, { organizationId }, AccessTypes.organization, [
            Roles.admin,
        ]);
        const isOrgChiefScout = hasAccess(accessRecords, { organizationId }, AccessTypes.organization, [
            Roles.scout,
        ]);
        const isTeamAdmin = hasAccess(accessRecords, { organizationId, teamId }, AccessTypes.team, [
            Roles.admin,
        ]);
        const isTeamCoach = hasAccess(accessRecords, { organizationId, teamId }, AccessTypes.team, [
            Roles.coach,
        ]);
        const isTeamDoctor = hasAccess(accessRecords, { organizationId, teamId }, AccessTypes.team, [
            Roles.doctor,
        ]);
        const isTeamStaff = hasAccess(accessRecords, { organizationId, teamId }, AccessTypes.team, [
            Roles.staff,
        ]);

        // Player
        const hasPlayerMedicalPageAccess = isOrgAdmin || isTeamAdmin || isTeamCoach || isTeamDoctor;
        const canSeePlayerProfile = isOrgAdmin || isTeamAdmin || isTeamCoach;
        const canEditPlayer = isOrgAdmin || isTeamAdmin || isTeamCoach;
        const canManagePlayerAccess = isOrgAdmin || isTeamAdmin;

        // Team
        const canSeeTeamDashboard = isOrgAdmin || isTeamAdmin || isTeamCoach;
        const canSeeTeamPlayersPage = isOrgAdmin || isTeamAdmin || isTeamCoach;
        const canSeeTeamEvaluationsPage = isOrgAdmin || isTeamAdmin || isTeamCoach;
        const canSeeTeamAttendancePage = isOrgAdmin || isTeamAdmin || isTeamCoach;
        const canSeeTeamConditionsPage = isOrgAdmin || isTeamAdmin || isTeamCoach;
        const canSeeTeamAssistant = isOrgAdmin || isTeamAdmin || isTeamCoach;
        const canSeeTeamStats = isOrgAdmin || isTeamAdmin || isTeamCoach;
        const canSeeTeamCalendar = isOrgAdmin || isTeamAdmin || isTeamCoach || isTeamStaff;
        const canSeeTeamAIReports = isOrgAdmin || isTeamAdmin || isTeamCoach;
        const canSeeTeamDoctorPage = isTeamDoctor;
        const canCopyEvents = isOrgAdmin || isTeamAdmin || isTeamCoach;
        const canDeleteTeam = isOrgAdmin;
        const canArchiveTeam = isOrgAdmin;
        const canUnarchiveTeam = isOrgAdmin;
        const canManageTeamAccess = isOrgAdmin;
        const canManageTeamFiles = isOrgAdmin || isTeamAdmin;
        const canSeeGamesSessions = isOrgAdmin || isTeamAdmin || isTeamCoach;
        const isTeamMember = isOrgAdmin || isTeamAdmin || isTeamCoach || isTeamDoctor || isTeamStaff;
        const canCreateEvents = isOrgAdmin || isTeamAdmin || isTeamCoach;
        const canReadPlayerUpdatesHistory = isOrgAdmin;
        const canReadRegularEventsLinks = isOrgAdmin || isTeamAdmin || isTeamCoach;
        const canSeeTeamUpdatesHistory = isOrgAdmin || isTeamAdmin;
        const canReadTeamLinks =
            isOrgAdmin || isTeamAdmin || isTeamCoach || isTeamDoctor || isTeamStaff;
        const canWriteTeamLinks = isOrgAdmin || isTeamAdmin;

        // Org
        const canMovePlayersBetweenTeams = isOrgAdmin;
        const canChangeOrgSettings = isOrgAdmin;
        const canManageOrgAccess = isOrgAdmin;
        const canSeeOrgPage = isOrgAdmin || isOrgChiefScout;
        const canWriteOrgLinks = isOrgAdmin;
        const canReadOrgLinks = isOrgAdmin || isOrgChiefScout;

        return {
            // Player
            canSeePlayerProfile,
            canEditPlayer,
            hasPlayerMedicalPageAccess,
            canManagePlayerAccess,

            // Team
            canSeeTeamDashboard,
            canSeeTeamPlayersPage,
            canSeeTeamEvaluationsPage,
            canSeeTeamAttendancePage,
            canSeeTeamConditionsPage,
            canSeeTeamAssistant,
            canSeeTeamStats,
            canSeeTeamCalendar,
            canSeeTeamAIReports,
            canSeeTeamDoctorPage,
            canCopyEvents,
            canDeleteTeam,
            canArchiveTeam,
            canUnarchiveTeam,
            canManageTeamAccess,
            canManageTeamFiles,
            isTeamMember,
            canSeeGamesSessions,
            canCreateEvents,
            canReadPlayerUpdatesHistory,
            canReadRegularEventsLinks,
            canSeeTeamUpdatesHistory,
            canReadTeamLinks,
            canWriteTeamLinks,

            // Org
            canMovePlayersBetweenTeams,
            canChangeOrgSettings,
            canManageOrgAccess,
            canSeeOrgPage,
            canWriteOrgLinks,
            canReadOrgLinks,
        };
    };
    export const usePlayerAccess = ({ playerId }: { playerId: string }) => {
        const accessRecords = useSelector(userAccessRecordsSelector);

        // Roles
        const isPlayerViever = hasAccess(accessRecords, { playerId }, AccessTypes.player, [
            Roles.reader,
        ]);

        // Features
        const canViewPlayerProfile = isPlayerViever;

        return {
            canViewPlayerProfile,
        };
    };

    export const useMethodologyAccess = ({ methodologyId }: { methodologyId: string }) => {
        const accessRecords = useSelector(userAccessRecordsSelector);
        const methodology = useMethodology(methodologyId);

        // Roles
        const isMethodologyOwner = hasAccess(
            accessRecords,
            { methodologyId },
            AccessTypes.methodology,
            [Roles.owner],
        );
        const isMethodologyAdmin = hasAccess(
            accessRecords,
            { methodologyId },
            AccessTypes.methodology,
            [Roles.admin],
        );
        const isMethodologyEditor = hasAccess(
            accessRecords,
            { methodologyId },
            AccessTypes.methodology,
            [Roles.editor],
        );
        const isMethodologyReader = hasAccess(
            accessRecords,
            { methodologyId },
            AccessTypes.methodology,
            [Roles.reader],
        );

        // Features
        const canDeleteMethodology = methodology && auth.currentUser?.uid === methodology?.creatorId;
        const hasMethodologySettingsAccess = isMethodologyOwner || isMethodologyAdmin;
        const canEditDrills = isMethodologyOwner || isMethodologyAdmin || isMethodologyEditor;
        const canSeeDrills =
            isMethodologyOwner || isMethodologyAdmin || isMethodologyEditor || isMethodologyReader;

        return {
            // Methodology
            canDeleteMethodology,
            hasMethodologySettingsAccess,

            // Drills
            canEditDrills,
            canSeeDrills,
        };
    };
 */
