import React from 'react';
import { SxProps } from '@mui/material';
import MedicalRecord, { MedicalRecordData, MedicalRecordType } from './MedicalRecords';
import {
    HeightIcon,
    WeightIcon,
    BloodIcon,
    PercentIcon,
    RulerIcon,
    MedicationLiquidIcon,
    MonitorHeartIcon,
    InventoryIcon,
    WarningIcon,
    FastfoodIcon,
    AccessibilityIcon,
    JointsIcon,
} from 'common/icons';
import User from 'entities/users/User';

const medicalRecordsIconsMap: Record<MedicalRecordType, React.FC<{ sx?: SxProps<any> }>> = {
    [MedicalRecordType.height]: HeightIcon,
    [MedicalRecordType.weight]: WeightIcon,
    [MedicalRecordType.bloodPressure]: BloodIcon,
    [MedicalRecordType.bodyFatPercentage]: PercentIcon,
    [MedicalRecordType.bodyMassIndex]: WeightIcon,
    [MedicalRecordType.chestGirth]: RulerIcon,
    [MedicalRecordType.neckGirth]: RulerIcon,
    [MedicalRecordType.hipGirth]: RulerIcon,
    [MedicalRecordType.shoulderGirth]: RulerIcon,
    [MedicalRecordType.shinGirth]: RulerIcon,
    [MedicalRecordType.abdomenGirth]: RulerIcon,
    [MedicalRecordType.basicMetabolism]: MedicationLiquidIcon,
    [MedicalRecordType.heartRate]: MonitorHeartIcon,
    [MedicalRecordType.footAxis]: InventoryIcon,
    [MedicalRecordType.trainingRestrictions]: WarningIcon,
    [MedicalRecordType.maxDayCalories]: FastfoodIcon,
    [MedicalRecordType.spinalAxis]: AccessibilityIcon,
    [MedicalRecordType.jointsCheck]: JointsIcon,
    [MedicalRecordType.jumpTest]: InventoryIcon,
    [MedicalRecordType.plxp9OWiSBZRNrGZrmtxy]: InventoryIcon,
};

export const getMedicalRecordIcon = (medicalTecordType: MedicalRecordType) => {
    return medicalRecordsIconsMap[medicalTecordType];
};

export const createmptyMedicalRecord = ({
    organizationId,
    teamId,
    user,
    playerId,
    testId,
    medicalRecordType,
}: {
    organizationId: string;
    teamId: string;
    user: User;
    playerId: string;
    testId: string;
    medicalRecordType: MedicalRecordType;
}) => {
    const author = {
        userId: user.id,
        name: user.name || '',
        email: user.email,
        filePath: null,
        role: null,
    };
    const newTest: MedicalRecordData = {
        organizationId,
        teamId,
        playerId,
        testId,
        date: new Date(),
        author: author,
        files: [],
        links: [],
        type: medicalRecordType,
        result: null,
        updates: [],
        comment: '',
        isCustom: false,
        category: null,
    };

    return newTest;
};

export const mapMedicalRecordFromDatesStrings = (
    medicalRecord: MedicalRecord | MedicalRecordData,
) => {
    const { date, updates, ...rest } = medicalRecord;
    const mappedUpdates = updates.map((update) => ({
        ...update,
        date: new Date(update.date),
    }));

    return {
        ...rest,
        date: new Date(date),
        updates: mappedUpdates,
    };
};
