import React from 'react';
import { parse, format } from 'date-fns';
import Input from 'common/components/Input';
import { styled } from '@mui/material/styles';
import { TextFieldProps } from '@mui/material';

type TimePickerProps = TextFieldProps & {
    value: Date;
    className?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, date: Date) => void;
};

const StyledInput = styled(Input)({
    [`& .MuiInputBase-input`]: {
        paddingRight: 0,
    },
    [`& .MuiInputBase-root`]: {
        paddingRight: 0,
    },
});

const TimePicker = React.forwardRef(({ value, onChange, ...props }: TimePickerProps, ref) => {
    return (
        <StyledInput
            type="time"
            ref={ref}
            // onBlur={() => onChange()}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newTimeString = event.target.value;

                if (newTimeString) {
                    onChange(event, parse(event.target.value, 'HH:mm', value));
                }
            }}
            value={format(value, 'HH:mm')}
            {...props}
        />
    );
});

export default TimePicker;
