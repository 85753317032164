import React from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { TelegramIcon } from 'common/icons';
import { styled } from '@mui/material/styles';
import { AppConfig } from 'common/config/Config';
import { Button, Typography } from 'common/components';
import Content from './Content';
import Sidebar from './Sidebar';

const ErrorPageBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    background: theme.custom.background.gradient,
    padding: theme.spacing(2),
}));

const LayoutPage = styled('div')(({ theme }) => ({
    flexGrow: 1,
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    height: '100%',
    overflow: 'hidden',
    background: theme.custom.background.gradient,
}));

const ErrorPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <ErrorPageBox>
            {/* eslint-disable-next-line */}
            <Typography sx={{ fontSize: 32 }} component="span" role="img">
                🔄
            </Typography>
            <Typography sx={{ m: 2, maxWidth: 400 }}>
                {String(t('errorPage.newUpdates'))}
            </Typography>
            <Button
                color="primary"
                variant="contained"
                fullWidth
                sx={{ maxWidth: 400, marginBottom: 2 }}
                onClick={() => {
                    window.location.reload();
                }}
            >
                {t('errorPage.reloadBtn')}
            </Button>
            <Button
                color="primary"
                variant="contained"
                sx={{ maxWidth: 400, marginBottom: 2 }}
                fullWidth
                onClick={() => {
                    window.location.href = window.location.origin;
                }}
            >
                {t('errorPage.goToHomePage')}
            </Button>
            <Typography
                sx={{
                    opacity: 0.5,
                    margin: 2,
                    maxWidth: 400,
                }}
            >
                {String(t('errorPage.contactUs'))}
            </Typography>
        </ErrorPageBox>
    );
};

type LayoutProps = {
    t: TFunction;
    appConfig?: AppConfig;
};

const Layout: React.FC<LayoutProps> = ({ appConfig }) => {
    if (appConfig?.maintenance) {
        return (
            <ErrorPageBox>
                <Typography sx={{ fontSize: 32 }} component="span" role="img">
                    🔧
                </Typography>
                <Typography sx={{ m: 2, maxWidth: 400 }}>
                    We are working on scheduled maintenance. Follow the updates in our Telegram
                    channel
                </Typography>
                <Button
                    component="a"
                    target="_blank"
                    variant="contained"
                    color="primary"
                    startIcon={<TelegramIcon />}
                    href="https://t.me/undersports_updates"
                >
                    Go to Telegram
                </Button>
            </ErrorPageBox>
        );
    }

    return (
        <Sentry.ErrorBoundary fallback={<ErrorPage />}>
            <LayoutPage>
                <Sidebar />
                <Content />
            </LayoutPage>
        </Sentry.ErrorBoundary>
    );
};

export default Layout;
