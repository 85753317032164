import React from 'react';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledMuiButton = styled(MuiButton)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 500,
    flexShrink: 0,

    [`&.MuiButton-sizeSmall`]: {
        height: 24,
        padding: theme.spacing(0, 1),
    },
    [`&.MuiButton-text`]: {
        height: 24,
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        color: theme.palette.common.black,

        '&:hover': {
            background: 'rgba(9, 30, 66, 0.1)',
        },
    },
    [`&.MuiButton-outlined`]: {
        backgroundColor: 'transparent',
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,

        [`&:active`]: {
            backgroundColor: theme.palette.grey[100],
        },
    },
    '&:disabled': {
        background: 'transparent !important',
        border: '1px solid rgba(0, 0, 0, 0.26)',
        color: 'rgba(0, 0, 0, 0.26)',
    },
}));

const Button: React.FC<any> = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ ...restProps }, ref) => {
        return <StyledMuiButton ref={ref} disableElevation disableRipple {...restProps} />;
    },
);

export default Button;
