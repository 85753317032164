import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CheckboxRoundedFilled = ({ stroke = '#2196F3', fill = '#2196F3', ...restProps }: any) => (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" {...restProps}>
        <g>
            <title>Checkbox Empty</title>
            <circle cx="9" cy="9" r="8.5" fill={fill} stroke={stroke} />
            <path d="M4 8.2L8 12L14 6" stroke="white" fill="none" />
        </g>
    </SvgIcon>
);

export default CheckboxRoundedFilled;
