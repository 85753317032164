import React, { useCallback } from 'react';
import { Button } from 'common/components';
import { useNavigate } from 'react-router-dom';
import { isBefore } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Goal from 'entities/goals/Goal';
import Player from 'entities/players/Player';
import { GOALS_MIN_FRACTION } from './assistant.constants';
import AssistantBase from './AssistantBase';
// import HelpLink from './HelpLink';

type NoGoalsProps = {
    teamId: string;
    organizationId: string;
    isVisible: boolean;
    expiredGoalsCount: number;
    playersCount: number;
    playersWithGoalsCount: number;
    playersWithGoalsFraction: number;
    onClose: VoidFunction;
};

export const getPlayersGoalsState = (goalsList: Goal[], playersList: Player[]) => {
    const playersWithGoals = playersList.filter((player) => {
        const hasGoals = goalsList.some((goal) => goal.playerId === player.id && !goal.done);
        return hasGoals;
    });

    if (playersList.length === 0) {
        return {
            isVisible: false,
            playersWithGoalsFraction: 0,
            expiredGoalsCount: 0,
            playersWithGoalsCount: 0,
            playersCount: 0,
        };
    }

    const expiredGoalsList = goalsList.filter((goal) => {
        return !goal.done && isBefore(goal.deadline, new Date());
    });
    const playersWithGoalsFraction = (playersWithGoals.length / playersList.length) * 100;

    if (playersWithGoalsFraction >= GOALS_MIN_FRACTION && expiredGoalsList.length === 0) {
        return {
            isVisible: false,
            playersWithGoalsFraction: 0,
            expiredGoalsCount: 0,
            playersWithGoalsCount: 0,
            playersCount: 0,
        };
    }

    return {
        isVisible: true,
        playersWithGoalsFraction,
        expiredGoalsCount: expiredGoalsList.length,
        playersWithGoalsCount: playersWithGoals.length,
        playersCount: playersList.length,
    };
};

const NoGoals: React.FC<NoGoalsProps> = ({
    organizationId,
    teamId,
    isVisible,
    expiredGoalsCount,
    playersCount,
    playersWithGoalsCount,
    playersWithGoalsFraction,
    onClose,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        onClose();
        navigate(`/organizations/${organizationId}/teams/${teamId}/goals`);
    }, [teamId, organizationId, navigate]);

    if (!isVisible) {
        return null;
    }

    const notEnoughPlayersText = t('assistant.noGoals.description', {
        playersWithGoalsCount,
        playersCount,
        expiredGoalsCount,
    });
    const noGoalsText = t('assistant.noGoals.zeroGoals');
    const descriptionText = playersWithGoalsFraction === 0 ? noGoalsText : notEnoughPlayersText;

    return (
        <AssistantBase description={descriptionText} icon="🎯">
            <Button onClick={handleClose} variant="contained" color="primary" size="small">
                {t('assistant.noGoals.addGoalsBtn')}
            </Button>
            {/* <HelpLink href="https://undersports.club/ru/player-goals">
                {t('assistant.noGoals.helpBtn')}
            </HelpLink> */}
        </AssistantBase>
    );
};

export default NoGoals;
