import { takeEvery, all, call, put } from 'redux-saga/effects';
import { storage } from 'common/firebase';
import { getDownloadURL, ref } from 'firebase/storage';
import trackingActionTypes from './tracking.actionTypes';
import { trackingDataReceived, trackingDataRequest } from './tracking.actions';

export function* downloadTrackingData({
    eventId,
    periodNumber,
    target,
    organizationId,
    teamId,
}: ReturnType<typeof trackingDataRequest>) {
    try {
        const dataRef = ref(
            storage,
            `organizations/${organizationId}/teams/${teamId}/reports/${eventId}/generated_period_${periodNumber}_${target}.csv`,
        );
        const fileUrl: string = yield getDownloadURL(dataRef);

        const response: Response = yield fetch(fileUrl);
        if (!response.ok) {
            throw new Error('Failed to load csv');
        }
        const trackingData: string = yield response.text();
        yield put(trackingDataReceived({ eventId, periodNumber, target, trackingData }));
    } catch (e) {
        yield call(console.error, e);
    }
}

function* trackingSaga() {
    yield all([takeEvery(trackingActionTypes.DOWNLOAD_TRACKING_DATA, downloadTrackingData)]);
}

export default trackingSaga;
