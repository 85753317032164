import React from 'react';
import { styled } from '@mui/material';
import {
    MobileDatePicker as MuiDatePicker,
    MobileDatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers/MobileDatePicker';
import Input from 'common/components/Input';

const StyledDatePicker = styled(MuiDatePicker)(({ theme }) => ({
    [`& .MuiInputAdornment-root`]: {
        marginLeft: 0,
    },
    [`& .MuiIconButton-root`]: {
        padding: 0,
        paddingRight: theme.spacing(0.5),
        marginRight: 0,
        opacity: 0.5,

        '&:hover': {
            opacity: 1,
        },
    },
    [`& .MuiInputBase-input`]: {
        padding: theme.spacing(0.5),
    },
    [`& .MuiSvgIcon-root`]: {
        width: 20,
        height: 20,
    },
}));

export type DatePickerProps = Omit<MuiDatePickerProps<any, any>, 'renderInput'> & {
    errorText?: string;
    sx?: any;
};

const DatePicker: React.FC<DatePickerProps> = React.forwardRef(
    ({ errorText, ...restProps }, ref?: React.Ref<HTMLInputElement>) => {
        return (
            <StyledDatePicker
                {...restProps}
                inputRef={ref}
                slots={{
                    textField: (params) => (
                        <Input {...params} helperText={errorText} error={Boolean(errorText)} />
                    ),
                }}
                // renderInput={(params) => (
                //     <Input {...params} helperText={errorText} error={Boolean(errorText)} />
                // )}
            />
        );
    },
);

export default DatePicker;
