import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import actionTypes from './players.actionTypes';
import Player, { PlayerSkills } from './Player';
import { PlayerUpdate } from './PlayerUpdates';

type PlayersState = {
    requestedTeams: string[];
    requestedCountries: string[];
    playersHash: Record<string, Player>;
    playersSkillsHash: Record<string, PlayerSkills>;
    removedPlayers: string[];
    playersUpdatesHash: Record<string, PlayerUpdate>;
};
const initialState = {
    playersHash: {},
    playersSkillsHash: {},
    playersUpdatesHash: {},
    requestedTeams: [],
    requestedCountries: [],
    removedPlayers: [],
};

const player = (state: PlayersState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.PLAYERS_LIST_RECEIVED:
            return {
                ...state,
                playersHash: {
                    ...state.playersHash,
                    ..._keyBy(action.playersList, 'id'),
                },
            };
        case actionTypes.PLAYERS_UPDATES_LIST_RECEIVED:
            return {
                ...state,
                playersUpdatesHash: {
                    ...state.playersUpdatesHash,
                    ..._keyBy(action.playerUpdatesList, 'id'),
                },
            };
        case actionTypes.PLAYER_DATA_RECEIVED:
            return {
                ...state,
                playersHash: {
                    ...state.playersHash,
                    [action.playerData.id]: {
                        ...action.playerData,
                    },
                },
            };
        case actionTypes.REMOVE_PLAYER_DATA:
            return {
                ...state,
                playersHash: { ..._omit(state.playersHash, action.playerId) },
            };
        case actionTypes.TEAM_PLAYERS_REQUESTED:
            return {
                ...state,
                requestedTeams: state.requestedTeams.concat(action.teamId),
            };
        case actionTypes.COUNTRY_PLAYERS_REQUESTED:
            return {
                ...state,
                requestedCountries: state.requestedCountries.concat(action.country),
            };
        case actionTypes.SET_REMOVED_PLAYER:
            return {
                ...state,
                removedPlayers: [...state.removedPlayers, action.playerId],
            };
        case actionTypes.PLAYERS_SKILLS_RECEIVED:
            return {
                ...state,
                playersSkillsHash: {
                    ...state.playersSkillsHash,
                    [action.playerId]: action.skills,
                },
            };
        default:
            return state;
    }
};

export default player;
