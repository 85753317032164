import Goal, { GoalData } from 'entities/goals/Goal';
import actionTypes from './goals.actionTypes';

export const goalDataRequest = ({ goalId, playerId }: { goalId: string; playerId: string }) => ({
    type: actionTypes.GOAL_DATA_REQUEST,
    goalId,
    playerId,
});

export const playerGoalsRequest = ({ playerId }: { playerId: string }) => ({
    type: actionTypes.PLAYER_GOALS_LIST_REQUEST,
    playerId,
});

export const goalsListRecieved = (goalsList: Goal[]) => ({
    type: actionTypes.GOALS_LIST_RECIEVED,
    goalsList,
});

export const addGoalRequest = ({
    playerId,
    goalData,
    onSuccess,
    onFail,
}: {
    playerId: string;
    goalData: GoalData;
    onSuccess: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.ADD_GOAL_REQUEST,
    playerId,
    goalData,
    onSuccess,
    onFail,
});

export const updateGoalRequest = ({
    playerId,
    goalId,
    goalData,
    onSuccess,
    onFail,
}: {
    playerId: string;
    goalId: string;
    goalData: GoalData;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.UPDATE_GOAL_REQUEST,
    playerId,
    goalId,
    goalData,
    onSuccess,
    onFail,
});

export const deleteGoalRequest = ({ playerId, goalId }: { playerId: string; goalId: string }) => ({
    type: actionTypes.DELETE_GOAL_REQUEST,
    playerId,
    goalId,
});

export const deleteGoalFromState = ({ goalId }: { goalId: string }) => ({
    type: actionTypes.DELETE_GOAL_FROM_STATE,
    goalId,
});
