import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { db } from 'common/firebase';
import { getDays } from 'common/utils/dateTimeHelpers';
import {
    addDays,
    addMinutes,
    differenceInDays,
    endOfDay,
    format,
    isBefore,
    isSunday,
    startOfDay,
    subDays,
} from 'date-fns';
import Cycle from 'entities/cycles/Cycle';
import Event, { EventTypes } from 'entities/events/Event';
import { eventDataReceived, eventDataRequest } from 'entities/events/events.actions';
import Game, { GameData } from 'entities/events/Game';
import { createEmptyGameData } from 'entities/events/games.utils';
import Session, { SessionData } from 'entities/events/Session';
import { createSessionAttendance } from 'entities/events/sessions.utils';
import Player from 'entities/players/Player';
import { getInitialLineup } from 'features/game/utils';
import { doc, onSnapshot } from 'firebase/firestore';
import { handleDocumentSnapshot } from 'gateways/utils';
import { SearchDrillConfig } from './calendar.reducer';

export const checkForDrillSearchMatch = (
    session: Session,
    searchDrillConfig: SearchDrillConfig,
) => {
    const { text, withRecordings } = searchDrillConfig;

    if (withRecordings && text.length === 0) {
        return session.details.drills.some(({ recordings }) => {
            const hasRecording =
                recordings && recordings.length > 0 && recordings.some(({ href }) => href);

            return hasRecording;
        });
    }

    if (text.length <= 1) {
        return false;
    }

    return session.details.drills.some(({ name, recordings }) => {
        const matchName = name.toLowerCase().includes(text);
        const hasRecording =
            recordings && recordings.length > 0 && recordings.some(({ href }) => href);

        if (withRecordings) {
            return matchName && hasRecording;
        }

        return matchName;
    });
};

export const copyGameData = (game: Game, playersList: Player[], newStartDate: Date) => {
    const {
        details: { formation },
    } = game as Game;
    const newGameData = createEmptyGameData(newStartDate, addMinutes(newStartDate, 90));
    const lineup = getInitialLineup(playersList, formation);
    const newGame: GameData = {
        ...newGameData,
        details: {
            ...newGameData.details,
            files: [],
            substitutions: [],
            gameScore: {
                team: null,
                opponent: null,
            },
            recordings: [],
            formation,
            lineup,
            opponent: {
                name: '-',
            },
        },
    };

    return newGame;
};

export const copySessionData = (session: Session, playersList: Player[], newStartDate: Date) => {
    const {
        details: { drills, ...sessionDetails },
    } = session as Session;
    const newDrills = drills.map((drill) => ({ ...drill, groups: [], recordings: [] }));
    const attendance = createSessionAttendance(playersList, newStartDate);
    const newSession: SessionData = {
        start: newStartDate,
        end: addMinutes(newStartDate, 90),
        eventType: EventTypes.session,
        attendance,
        lastUpdateAuthorUid: null,
        createDate: new Date(),
        details: {
            ...sessionDetails,
            files: [],
            links: [],
            drills: newDrills,
        },
    };

    return newSession;
};

export type Period = {
    periodStart: Date;
    periodEnd: Date;
    duration: number;
    cycle: Cycle | null;
    possibleStart: Date;
    possibleEnd: Date;
};

const assignPosibleDays = (
    periodsList: Period[],
    daysMap: Map<string, string | null>,
): Period[] => {
    return periodsList.map((period) => {
        const { periodStart, periodEnd } = period;

        /* Check day by day in the past to find possible period start. But not more than 2 weeks before period start */
        let pastCount = 1;
        let possibleStart = periodStart;

        do {
            const potentialPastDay = subDays(periodStart, pastCount);
            const isAvailablePastDay =
                daysMap.get(format(potentialPastDay, 'dd.MMM.yyyy')) === null;

            if (isAvailablePastDay) {
                possibleStart = potentialPastDay;
                pastCount += 1;
            } else {
                pastCount = 7;
            }
        } while (pastCount < 7); /* 1 week max */

        /* Check day by day in the future to find possible period end. But not more than 2 weeks after period end */
        let futureCount = 1;
        let possibleEnd = periodEnd;

        do {
            const potentialFutureDay = addDays(periodEnd, futureCount);
            const isAvailableFutureDay =
                daysMap.get(format(potentialFutureDay, 'dd.MMM.yyyy')) === null;

            if (isAvailableFutureDay) {
                possibleEnd = potentialFutureDay;
                futureCount += 1;
            } else {
                futureCount = 7;
            }
        } while (futureCount < 7); /* 1 week max */

        return { ...period, possibleEnd, possibleStart };
    });
};

export const generatePeriodsList = (
    cyclesList: Cycle[],
    selectedDate: Date,
    periodLenght: number,
): Period[] => {
    const periodStartDay = subDays(selectedDate, Math.ceil(periodLenght / 2));

    const daysList = Array(periodLenght)
        .fill(periodStartDay)
        .map((value, index) => addDays(value, index));
    const daysMap = new Map<string, string | null>(
        daysList.map((day) => [format(day, 'dd.MMM.yyyy'), null]),
    );
    cyclesList.forEach(({ id, startDate, endDate }) => {
        const cycleDays = getDays(startDate, endDate);

        cycleDays.forEach((cycleDay) => {
            daysMap.set(format(cycleDay, 'dd.MMM.yyyy'), id);
        });
    });

    let periodsList: Period[] = [];
    let periodDays: Date[] = [];

    function pushNewPeriod() {
        const periodStart = startOfDay(periodDays[0]);
        const periodEnd = endOfDay(periodDays[periodDays.length - 1]);

        const newPeriod = {
            periodStart,
            periodEnd,
            possibleStart: periodStart,
            possibleEnd: periodEnd,
            duration: periodDays.length,
            cycle: null,
        };

        periodsList.push(newPeriod);
        periodDays = [];
    }

    daysList.forEach((day) => {
        const isInCycle = daysMap.get(format(day, 'dd.MMM.yyyy')) !== null;

        // console.log(`>>> ${format(day, 'EEEEE, dd MMM')} (in cycle: ${isInCycle}) <<<`);

        if (isInCycle && periodDays.length > 0) {
            // console.log('Create a period before cycle');
            pushNewPeriod();

            return;
        }

        if (isInCycle && periodDays.length === 0) {
            // console.log('Skip sequent cycle day');
            return;
        }

        periodDays.push(day);

        if (periodDays.length === 7 || isSunday(day)) {
            // console.log('Finishing the cycle and starting new');
            pushNewPeriod();

            return;
        }
    });

    if (periodDays.length > 0) {
        pushNewPeriod();
    }

    const storedPeriods = cyclesList.map((cycle) => {
        const { startDate, endDate } = cycle;

        return {
            periodStart: startDate,
            periodEnd: endDate,
            cycle,
            duration: differenceInDays(endDate, startDate) + 1,
            possibleStart: startDate,
            possibleEnd: endDate,
        };
    });

    periodsList = periodsList.concat(storedPeriods);

    periodsList.sort((a, b) => (isBefore(a.periodStart, b.periodStart) ? -1 : 1));

    return assignPosibleDays(periodsList, daysMap);
};

/* Subscribe to get updates if someone edit event in parallel */
export const useEventUpdates = (organizationId: string, teamId: string, eventId: string) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            eventDataRequest({
                teamId,
                organizationId,
                eventId,
            }),
        );
    }, []);

    useEffect(() => {
        let isInitialLoad = true;

        const eventRef = doc(
            db,
            `organizations/${organizationId}/teams/${teamId}/events/${eventId}`,
        );

        const unsubscribeSessionUpdates = onSnapshot(eventRef, (doc) => {
            const eventSnapData = handleDocumentSnapshot(doc) as Event;

            if (eventSnapData) {
                dispatch(eventDataReceived(eventSnapData));
            }

            if (isInitialLoad) {
                isInitialLoad = false;
            } else if (eventSnapData) {
                toastr.info('Event updated', '');
            } else {
                toastr.info('Event deleted', '');
            }
        });

        return unsubscribeSessionUpdates;
    }, [organizationId, teamId, eventId]);
};
