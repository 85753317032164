import Cycle, { CycleData } from './Cycle';
import actionTypes from './cycles.actionTypes';

export const cycleDataReceived = ({ cycle }: { cycle: Cycle }) => ({
    type: actionTypes.CYCLE_DATA_RECEIVED,
    cycle,
});

export const cyclesListReceived = ({ cycles }: { cycles: Cycle[] }) => ({
    type: actionTypes.CYCLES_LIST_RECEIVED,
    cycles,
});

export const updateCycle = ({
    cycleId,
    teamId,
    organizationId,
    cycleData,
    onSuccess,
    onFail,
}: {
    cycleId: string;
    teamId: string;
    organizationId: string;
    cycleData: CycleData;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.UPDATE_CYCLE,
    cycleId,
    cycleData,
    teamId,
    organizationId,
    onSuccess,
    onFail,
});

export const createCycle = ({
    cycleData,
    teamId,
    organizationId,
    onSuccess,
    onFail,
}: {
    cycleData: CycleData;
    teamId: string;
    organizationId: string;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.CREATE_CYCLE,
    cycleData,
    teamId,
    organizationId,
    onSuccess,
    onFail,
});

export const fetchCyclesByDateRange = ({
    fromDate,
    toDate,
    teamId,
    organizationId,
    onSuccess,
    onFail,
}: {
    fromDate: Date;
    toDate: Date;
    teamId: string;
    organizationId: string;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.FETCH_CYCLES_BY_DATE_RANGE,
    fromDate,
    toDate,
    teamId,
    organizationId,
    onSuccess,
    onFail,
});

export const fetchCycleById = ({
    cycleId,
    teamId,
    organizationId,
    onSuccess,
    onFail,
}: {
    cycleId: string;
    teamId: string;
    organizationId: string;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.FETCH_CYCLE_BY_ID,
    cycleId,
    teamId,
    organizationId,
    onSuccess,
    onFail,
});
