export default {
    ORGANIZATION_DATA_REQUEST: 'ORGANIZATIONS/ENTITY/ORGANIZATION_DATA_REQUEST',
    ORGANIZATIONS_LIST_REQUEST: 'ORGANIZATIONS/ENTITY/ORGANIZATIONS_LIST_REQUEST',
    ORGANIZATIONS_LIST_RECIEVED: 'ORGANIZATIONS/ENTITY/ORGANIZATIONS_LIST_RECIEVED',
    ORGANIZATION_DATA_RECIEVED: 'ORGANIZATIONS/ENTITY/ORGANIZATION_DATA_RECIEVED',
    ORGANIZATION_DATA_UPDATE: 'ORGANIZATIONS/ENTITY/ORGANIZATION_DATA_UPDATE',
    UPDATE_ORGANIZATION_LOGO: 'ORGANIZATIONS/ENTITY/UPDATE_ORGANIZATION_LOGO',
    ORGANIZATION_STORAGE_INFO_RECIEVED: 'ORGANIZATIONS/ENTITY/ORGANIZATION_STORAGE_INFO_RECIEVED',
    ORGANIZATION_STORAGE_INFO_REQUEST: 'ORGANIZATIONS/ENTITY/ORGANIZATION_STORAGE_INFO_REQUEST',
    ORGANIZATION_STORAGE_INFO_UPDATE: 'ORGANIZATIONS/ENTITY/ORGANIZATION_STORAGE_INFO_UPDATE',
    ORGANIZATION_PLAYERS_INFO_REQUEST: 'ORGANIZATIONS/ENTITY/ORGANIZATION_PLAYERS_INFO_REQUEST',
    ORGANIZATION_PLAYERS_INFO_RECEIVED: 'ORGANIZATIONS/ENTITY/ORGANIZATION_PLAYERS_INFO_RECEIVED',
    ORGANIZATION_CLUBS_INFO_REQUEST: 'ORGANIZATIONS/ENTITY/ORGANIZATION_CLUBS_INFO_REQUEST',
    ORGANIZATION_CLUBS_INFO_RECEIVED: 'ORGANIZATIONS/ENTITY/ORGANIZATION_CLUBS_INFO_RECEIVED',
};
