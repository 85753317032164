import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { Button } from 'common/components';
import { GoogleIcon } from 'common/icons';
import { auth, gogleAuthProvider } from 'common/firebase';
import { useSuccessLogin } from '../auth.utils';

type ContinueWithGoogleBtnProps = {
    sx?: any;
};

const ContinueWithGoogleBtn: React.FC<ContinueWithGoogleBtnProps> = ({ ...rest }) => {
    const { t } = useTranslation();
    const { onSuccess } = useSuccessLogin();

    const handleGoogleSignIn = useCallback(async () => {
        signInWithPopup(auth, gogleAuthProvider)
            .then(onSuccess)
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);

                console.log({ errorCode, errorMessage, email, credential });
            });
    }, [onSuccess]);

    return (
        <Button
            startIcon={<GoogleIcon />}
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleGoogleSignIn}
            {...rest}
        >
            {t('auth.createAccount.continueWithGoogleBtn')}
        </Button>
    );
};

export default ContinueWithGoogleBtn;
