import { styled } from '@mui/material/styles';
import { TableContainer, TableRow, TableCell } from 'common/components';

export const StatsRootContainer = styled('section')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    boxShadow: theme.custom.boxShadow.main,
    backgroundColor: theme.palette.common.white,
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.custom.background.light,
    },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(1),
}));
