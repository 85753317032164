import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import actionTypes from './goals.actionTypes';

const initialState = {
    goalsHash: {},
};

const goals = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.GOALS_LIST_RECIEVED:
            return {
                ...state,
                goalsHash: {
                    ...state.goalsHash,
                    ..._keyBy(action.goalsList, 'id'),
                },
            };
        case actionTypes.DELETE_GOAL_FROM_STATE:
            return {
                ...state,
                goalsHash: {
                    ..._omit(state.goalsHash, action.goalId),
                },
            };
        default:
            return state;
    }
};

export default goals;
