export default {
    DRILL_DATA_REQUEST: 'ENTITIES/DRILLS/DRILL_DATA_REQUEST',
    DRILL_WITH_VARIATIONS_REQUEST: 'ENTITIES/DRILLS/DRILL_WITH_VARIATIONS_REQUEST',
    DRILL_DATA_RECEIVED: 'ENTITIES/DRILLS/DRILL_DATA_RECEIVED',
    DRILLS_LIST_RECEIVED: 'ENTITIES/DRILLS/DRILLS_LIST_RECEIVED',
    DRILLS_LIST_REQUEST: 'ENTITIES/DRILLS/DRILLS_LIST_REQUEST',
    DRILL_UPDATE_REQUEST: 'ENTITIES/DRILLS/DRILL_UPDATE_REQUEST',
    DRILL_DELETE_REQUEST: 'ENTITIES/DRILLS/DRILL_DELETE_REQUEST',
    DRILL_CREATE_REQUEST: 'ENTITIES/DRILLS/DRILL_CREATE_REQUEST',
    REMOVE_DRILL: 'ENTITIES/DRILLS/REMOVE_DRILL',
    ADD_DRILL_VARIATION: 'ENTITIES/DRILLS/ADD_DRILL_VARIATION',
};
