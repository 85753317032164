import { EpisodesType } from 'entities/episodes/Episodes';
import { TeamStatus } from 'entities/teams/Team';
import actionTypes from './reportPage.actionTypes';
import { EpisodesFiltersName } from './episodes-list/episodesFilters';
import { Periods } from './ReportPageTypes';

export type ReportPageState = {
    selectedEpisodesType: EpisodesType | null;
    selectedEpisodesFilterName: EpisodesFiltersName | null;
    selectedTeam: TeamStatus | null;
    selectedPlayer: string;
    selectedPitchArea: string;
    selectedPeriod: Periods;
    selectedTime: number[];
};

const initialState = {
    selectedEpisodesType: null,
    selectedEpisodesFilterName: null,
    selectedTeam: null,
    selectedPlayer: '',
    selectedPitchArea: '',
    selectedPeriod: Periods.one,
    selectedTime: [0, 45 * 60],
};

export default (state: ReportPageState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_EPISODES_TYPE:
            return {
                ...state,
                selectedEpisodesType: action.episodesType,
                selectedEpisodesFilterName: null,
            };
        case actionTypes.SET_EPISODES_FILTER_NAME:
            return {
                ...state,
                selectedEpisodesFilterName: action.filterName,
            };
        case actionTypes.SET_PLAYER:
            return {
                ...state,
                selectedPlayer: action.playerNumber,
            };
        case actionTypes.SET_TIME:
            return {
                ...state,
                selectedTime: action.time,
            };
        case actionTypes.SET_TEAM:
            return {
                ...state,
                selectedTeam: action.teamStatus,
                selectedPlayer: '',
                selectedPitchArea: action.teamStatus === '' ? '' : state.selectedPitchArea,
            };
        case actionTypes.SET_PITCH_AREA:
            return {
                ...state,
                selectedPitchArea:
                    action.pitchArea === state.selectedPitchArea ? null : action.pitchArea,
            };
        case actionTypes.SET_PERIOD:
            return {
                ...state,
                selectedPeriod: action.periodNumber,
            };
        default:
            return state;
    }
};
