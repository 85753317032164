import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import classNames from 'classnames';

const PREFIX = 'Dialog';

const classes = {
    fullHeight: `${PREFIX}-fullHeight`,
};

const StyledMuiDialog = styled(MuiDialog)(({ theme }) => ({
    [`& .MuiDialog-paper`]: {
        background: theme.custom.background.gradient,
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
    [`& .MuiBackdrop-root`]: {
        backgroundColor: 'rgba(32, 38, 45, 0.2)',
        backdropFilter: 'blur(2px)',
    },

    [`& .${classes.fullHeight}`]: {
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            margin: 0,
            height: '100vh',
            minHeight: '100vh',
            maxHeight: '100vh',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
}));

export type DialogProps = MuiDialogProps & {
    className?: string;
    dialogClassName?: string;
    fullHeight?: boolean;
};

const Dialog: React.FC<DialogProps> = ({
    className,
    dialogClassName,
    fullHeight,
    ...restProps
}) => {
    return (
        <StyledMuiDialog
            classes={{
                root: dialogClassName,
                paper: classNames(className, { [classes.fullHeight]: fullHeight }),
            }}
            {...restProps}
        />
    );
};

export default Dialog;
