import MedicalTest, { MedicalRecordData } from './MedicalRecords';
import actionTypes from './medicalRecords.actionTypes';

export const createMedicalRecord = ({
    medicalRecordData,
    onSuccess,
    onFail,
}: {
    medicalRecordData: MedicalRecordData;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.MEDICAL_RECORD_CREATE,
    medicalRecordData,
    onSuccess,
    onFail,
});

export const updateMedicalRecord = ({
    medicalRecordId,
    medicalRecordData,
    onSuccess,
    onFail,
}: {
    medicalRecordId: string;
    medicalRecordData: MedicalRecordData;
    onSuccess?: () => void;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.MEDICAL_RECORD_UPDATE,
    medicalRecordId,
    medicalRecordData,
    onSuccess,
    onFail,
});

export const medicalRecordRequest = ({
    medicalRecordId,
    playerId,
    onSuccess,
}: {
    playerId: string;
    medicalRecordId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.MEDICAL_RECORD_REQUEST,
    playerId,
    medicalRecordId,
    onSuccess,
});

export const medicalRecordsByTestIdRequest = ({
    medicalTestId,
    organizationId,
    teamId,
    onSuccess,
}: {
    medicalTestId: string;
    organizationId: string;
    teamId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.MEDICAL_RECORDS_BY_TEST_ID_REQUEST,
    medicalTestId,
    organizationId,
    teamId,
    onSuccess,
});

export const playerMedicalRecordsRequest = ({
    organizationId,
    playerId,
    testId,
    limit,
    onSuccess,
}: {
    playerId: string;
    organizationId: string;
    testId?: string;
    limit?: number;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.PLAYER_MEDICAL_RECORDS_REQUEST,
    organizationId,
    playerId,
    testId,
    limit,
    onSuccess,
});

export const medicalRecordDataRecieved = (medicalRecord: MedicalTest) => ({
    type: actionTypes.MEDICAL_RECORD_RECEIVED,
    medicalRecord,
});

export const medicalRecordsListRecieved = (medicalRecordsList: MedicalTest[]) => ({
    type: actionTypes.MEDICAL_RECORDS_LIST_RECEIVED,
    medicalRecordsList,
});
