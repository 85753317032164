import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const InjuryIcon = (props: any) => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g>
            <title>Injury</title>
            <circle cx="12" cy="12" r="11.5" stroke="black" fill="#fff" strokeOpacity="0.38" />
            <rect x="4" y="10" width="16" height="4" rx="1" fill="#D82A2F" />
            <rect
                x="14"
                y="4"
                width="16"
                height="4"
                rx="1"
                transform="rotate(90 14 4)"
                fill="#D82A2F"
            />
        </g>
    </SvgIcon>
);

export default InjuryIcon;
