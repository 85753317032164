import React, { useState } from 'react';
import { ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PlayIcon } from 'common/icons';
import VideoDialog from './VideoDialog';
import PlaceholderBox from '../PlaceholderBox';
import { useTranslation } from 'react-i18next';

const baseSize = 84;

const StyledIframe = styled('iframe')(() => ({
    border: 'none',
    width: '100%',
    aspectRatio: '16 / 9',
    margin: 'auto 0',
}));

const StyledPlayIcon = styled(PlayIcon)(() => ({
    width: 32,
    height: 32,
}));

const StyledVideoPlayBtn = styled(ButtonBase)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 84,
    height: 84,
    borderRadius: '50%',
    backgroundColor: '#5a99d4',
    cursor: 'pointer',
    boxShadow: '0 0 0 0 rgba(90, 153, 212, 0.5)',
    animation: 'pulse 1.5s infinite',
    opacity: 0.6,
    flexShrink: 0,

    '@keyframes pulse': {
        '0%': {
            transform: 'scale(0.9)',
        },
        '70%': {
            transform: 'scale(1)',
            boxShadow: '0 0 0 50px rgba(90, 153, 212, 0)',
        },
        '100%': {
            transform: 'scale(0.9)',
            boxShadow: '0 0 0 0 rgba(90, 153, 212, 0)',
        },
    },
}));

type VideoPlayBtnProps = {
    videoUrl?: string;
    className?: string;
    size?: number;
    sx?: any;
    mobileFullScreen?: boolean;
};

export const VideoPlayBtn: React.FC<VideoPlayBtnProps> = ({
    className,
    videoUrl,
    size = baseSize,
    sx,
    mobileFullScreen,
    ...rest
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { t } = useTranslation();

    return (
        <>
            <StyledVideoPlayBtn
                sx={{
                    width: size,
                    height: size,
                    ...sx,
                }}
                {...rest}
                onClick={() => setIsOpen(true)}
            >
                <StyledPlayIcon
                    sx={{
                        width: size / 2,
                        height: size / 2,
                    }}
                />
            </StyledVideoPlayBtn>
            <VideoDialog
                mobileFullScreen
                open={isOpen}
                onClose={() => setIsOpen(false)}
                fullWidth
                maxWidth="lg"
                showCloseBtn
            >
                {!videoUrl && (
                    <PlaceholderBox sx={{ color: 'white' }}>
                        {t('videoPlayBtn.placeholderText') as string}
                    </PlaceholderBox>
                )}
                <StyledIframe
                    src={videoUrl}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </VideoDialog>
        </>
    );
};
