import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const BallIcon = (props: any) => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g>
            <title>Ball</title>
            <path
                d="M12 2C6.47721 2 2 6.47721 2 12C2 17.5228 6.47721 22 12 22C17.5228 22 22 17.5228 22 12C21.9938 6.47982 17.5202 2.00618 12 2ZM19.7443 6.79671L19.0513 9.74268L15.4001 10.9596L12.3333 8.50667V4.89225L15.8333 3.49235C17.418 4.21029 18.7718 5.35417 19.7443 6.79671ZM19.8 17.1151L16.4943 17.637L14.6943 15.2593L15.6113 11.5926L19.222 10.3893L21.2904 12.8713C21.1494 14.3867 20.6375 15.8442 19.8 17.1151ZM7.52035 17.6393L4.20003 17.1151C3.36247 15.8439 2.85075 14.3861 2.70996 12.8703L4.77669 10.3883L8.38737 11.5916L9.30436 15.2603L7.52035 17.6393ZM2.6696 11.8757C2.68734 10.3887 3.0625 8.92757 3.76335 7.61605L4.30404 9.91602L2.6696 11.8757ZM9.92692 15L9.04264 11.4593L12 9.09359L14.958 11.4603L14.0731 15H9.92692ZM19.696 9.91471L20.2367 7.61475C20.9375 8.92627 21.3127 10.3872 21.3304 11.8743L19.696 9.91471ZM14.9243 3.13835L12 4.30794L9.06006 3.14372C10.9631 2.50944 13.0202 2.50765 14.9243 3.13835ZM8.15072 3.5L11.6667 4.89339V8.50667L8.59994 10.9596L4.94873 9.74268L4.2557 6.79671C5.22494 5.35938 6.57292 4.21842 8.15072 3.5ZM4.757 17.8779L7.46663 18.306L9.10531 20.8727C7.3986 20.3122 5.88916 19.2726 4.757 17.8779ZM10.061 21.1294L8.07194 18.0153L9.83333 15.6667H14.1667L15.9533 18.0267L14.2201 21.0643C12.8566 21.4001 11.4349 21.4229 10.0614 21.131L10.061 21.1294ZM15.146 20.7863L16.5667 18.3L19.2443 17.8776C18.1686 19.2023 16.7515 20.208 15.146 20.7863Z"
                fill="black"
                fillOpacity="0.54"
            />
        </g>
    </SvgIcon>
);

export default BallIcon;
