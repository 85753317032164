import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const PsychologyIcon = (props: any) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <g>
            <title>Psychology</title>
            <path d="M15.8196 7.22L14.8196 7.62C14.6096 7.46 14.3896 7.33 14.1496 7.23L13.9996 6.17C13.9796 6.07 13.8996 6 13.7996 6H12.1996C12.0996 6 12.0196 6.07 12.0096 6.17L11.8596 7.23C11.6196 7.33 11.3896 7.46 11.1896 7.62L10.1896 7.22C10.0996 7.19 9.98956 7.22 9.94956 7.31L9.14956 8.69C9.09956 8.78 9.11956 8.89 9.19956 8.95L10.0496 9.61C10.0196 9.73 9.99956 9.87 9.99956 10C9.99956 10.13 10.0096 10.26 10.0296 10.39L9.18956 11.05C9.10956 11.11 9.08956 11.22 9.13956 11.3L9.93956 12.69C9.98956 12.78 10.0896 12.81 10.1896 12.78L11.1796 12.38C11.3896 12.54 11.6096 12.67 11.8596 12.77L11.9996 13.83C12.0196 13.93 12.0996 14 12.1996 14H13.7996C13.8996 14 13.9796 13.93 13.9996 13.83L14.1496 12.77C14.3896 12.67 14.6196 12.54 14.8196 12.38L15.8096 12.78C15.8996 12.82 16.0096 12.78 16.0496 12.69L16.8496 11.3C16.8996 11.21 16.8796 11.11 16.7996 11.05L15.9696 10.39C15.9896 10.26 15.9996 10.13 15.9996 10C15.9996 9.86 15.9896 9.73 15.9696 9.61L16.8196 8.95C16.8996 8.89 16.9196 8.78 16.8696 8.69L16.0696 7.31C16.0196 7.22 15.9096 7.19 15.8196 7.22ZM12.9996 11.43C12.2096 11.43 11.5696 10.79 11.5696 10C11.5696 9.21 12.2096 8.57 12.9996 8.57C13.7896 8.57 14.4296 9.21 14.4296 10C14.4296 10.79 13.7896 11.43 12.9996 11.43Z" />
            <path d="M19.9398 9.06C19.5098 5.79 16.7098 3.2 13.4098 3.01C13.2698 3 13.1398 3 12.9998 3C9.46985 3 6.56985 5.61 6.07985 9L4.14985 12.48C3.73985 13.14 4.21985 14 4.99985 14H5.99985V16C5.99985 17.1 6.89985 18 7.99985 18H8.99985V21H15.9998V16.32C18.6198 15.07 20.3498 12.24 19.9398 9.06ZM14.8898 14.63L13.9998 15.05V19H10.9998V16H7.99985V12H6.69985L8.02985 9.67C8.20985 7.06 10.3498 5 12.9998 5C15.7598 5 17.9998 7.24 17.9998 10C17.9998 12.09 16.7098 13.88 14.8898 14.63Z" />
        </g>
    </SvgIcon>
);

export default PsychologyIcon;
