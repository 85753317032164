import { RootState } from 'store';
import { getMode } from 'common/utils/urlUtils';

export const isVerifyingSelector = (state: RootState) => state.ui.auth.isVerifyingEmail;

export const isVerificationModeSelector = (_: any, { location }: { location: any }) =>
    getMode({ location });

export const verificationErrorSelector = (state: RootState) => state.ui.auth.emailVerificationError;

export const authStateSelector = (state: RootState) => state.ui.auth.authState;
