import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { SchoolIcon, ArrowDropDownIcon } from 'common/icons';
import { Box, Button } from 'common/components';
import SlideDialogWithCloseBtn from 'common/components/SlideDialogWithCloseBtn';
import OrgLogo from 'features/organization/components/OrgLogo';
import UserLinks from './UserLinks';
import { PageParams, useUserLinks } from './userLinks.utils';
import { useSelector } from 'react-redux';
import { userDataSelector } from 'entities/users/users.selectors';

type UserLinksBtnProps = {
    sx?: any;
};

const UserLinksBtn: React.FC<UserLinksBtnProps> = ({ sx }) => {
    const { organizationId, methodologyId } = useParams<PageParams>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { currentResourseName } = useUserLinks();
    const user = useSelector(userDataSelector);

    if (!user) return null;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden auto',
                flexShrink: 0,
                ...sx,
            }}
        >
            {organizationId && (
                <Link to={`/organizations/${organizationId}`}>
                    <OrgLogo size={32} sx={{ mr: 1 }} organizationId={organizationId} />
                </Link>
            )}
            {methodologyId && <SchoolIcon sx={{ mr: 1, opacity: 0.7 }} />}
            <Button
                sx={{
                    border: '2px solid',
                    borderColor: isOpen ? '#2196f3' : 'transparent',
                    height: '32px !important',
                }}
                onClick={() => setIsOpen(true)}
                startIcon={<ArrowDropDownIcon />}
            >
                {currentResourseName}
            </Button>
            <SlideDialogWithCloseBtn isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <UserLinks sx={{ width: '100%' }} onSelect={() => setIsOpen(false)} />
            </SlideDialogWithCloseBtn>
        </Box>
    );
};

export default UserLinksBtn;
