import { takeLatest, all, call, put } from 'redux-saga/effects';
import * as reportsGateway from 'gateways/episodesGateway';
import episodesActionTypes from './episodes.actionTypes';
import { episodesGroupsListReceived, episodesGroupsListRequest } from './episodes.actions';
import { EpisodesGroup } from './Episodes';

export function* fetchEpisodesGroupsListSaga({
    organizationId,
    teamId,
    eventId,
}: ReturnType<typeof episodesGroupsListRequest>) {
    try {
        const episodesGroupsList: EpisodesGroup[] = yield call(
            reportsGateway.fetchEpisodesGroupsList,
            {
                eventId,
                organizationId,
                teamId,
            },
        );
        if (episodesGroupsList) {
            yield put(episodesGroupsListReceived({ episodesGroupsList, eventId }));
        }
    } catch (e) {
        console.error({ _error: `Failed to fetch episodes for event ${eventId}` }, e);
    }
}

function* episodesSaga() {
    yield all([
        takeLatest(episodesActionTypes.EPISODES_GROUPS_LIST_REQUEST, fetchEpisodesGroupsListSaga),
    ]);
}

export default episodesSaga;
