import * as actions from './snackbar.actions';
import * as selectors from './snackbar.selectors';
import reducer from './snackbar.reducer';

enum SnackbarVariants {
    success = 'success',
    error = 'error',
    warning = 'warning',
}

export { reducer, selectors, actions, SnackbarVariants };
