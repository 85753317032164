import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import Invitation from './Invitation';
import actionTypes from './invitations.actionTypes';

type InvitationsState = {
    invitationsHash: {
        [key: string]: Invitation;
    };
    playerToLink: string | null;
};

const initialState = {
    invitationsHash: {},
    playerToLink: null,
};

export default (state: InvitationsState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.INVITATIONS_LIST_RECEIVED: {
            return {
                ...state,
                invitationsHash: {
                    ...state.invitationsHash,
                    ..._keyBy(action.invitationsList, 'id'),
                },
            };
        }
        case actionTypes.INVITATION_DATA_RECEIVED:
            return {
                ...state,
                invitationsHash: {
                    ...state.invitationsHash,
                    [action.invitationData.id]: action.invitationData,
                },
            };
        case actionTypes.REMOVE_INVITATION_FROM_LIST:
            return {
                ...state,
                invitationsHash: {
                    ..._omit(state.invitationsHash, action.invitationId),
                },
            };
        case actionTypes.SET_PLAYER_TO_LINK: {
            return {
                ...state,
                playerToLink: action.playerId,
            };
        }
        default:
            return state;
    }
};
