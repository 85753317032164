export default {
    SET_OFFLINE: 'CONFIG/SET_OFFLINE',
    SET_ONLINE: 'CONFIG/SET_ONLINE',
    APP_CONFIG_REQUEST: 'CONFIG/APP_CONFIG_REQUEST',
    APP_CONFIG_RECEIVED: 'CONFIG/APP_CONFIG_RECEIVED',
    TOGGLE_PLAYERS_VISIBILITY: 'CONFIG/TOGGLE_PLAYERS_VISIBILITY',
    SET_LOCAL_CONFIG: 'CONFIG/SET_LOCAL_CONFIG',
    SAVE_LOCAL_CONFIG: 'CONFIG/SAVE_LOCAL_CONFIG',
    APPLY_LOCAL_CONFIG: 'CONFIG/APPLY_LOCAL_CONFIG',
};
