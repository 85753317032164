import React from 'react';
import { styled } from '@mui/material/styles';
import MuiPopover, { PopoverProps } from '@mui/material/Popover';

const StyledMuiPopover = styled(MuiPopover)(({ theme }) => ({
    [`& .MuiPopover-root`]: {
        textTransform: 'none',
        fontWeight: 700,
        borderRadius: theme.shape.borderRadius,
    },

    [`& .MuiPopover-paper`]: {
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
        background: theme.custom.background.gradient,
    },
}));

const Popover = React.forwardRef<HTMLDivElement, PopoverProps>(({ ...restProps }, ref) => {
    return <StyledMuiPopover ref={ref} elevation={0} {...restProps} />;
});

export default Popover;
