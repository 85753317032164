import React from 'react';
// import { useDispatch } from 'react-redux';
import { Box, Typography, ButtonBase } from 'common/components';
import Notification from 'entities/notifications/Notification';
import { format } from 'date-fns';

export type NotificationsListProps = {
    notificationsList: Notification[];
    onSelect: (notification: Notification) => void;
};

const NotificationsList: React.FC<NotificationsListProps> = ({ notificationsList, onSelect }) => {
    // const dispatch = useDispatch();

    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto', p: 1 }}
        >
            {notificationsList.map((notification) => (
                <ButtonBase
                    key={notification.id}
                    onClick={() => onSelect(notification)}
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        mb: 1,
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        maxWidth: { sm: 300 },
                        backgroundColor: (theme) => theme.custom.background.light,
                    }}
                >
                    <Typography align="left">
                        {!notification.seen && (
                            <Box
                                component="span"
                                sx={{
                                    display: 'inline-flex',
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    backgroundColor: 'orange',
                                    mr: 1,
                                }}
                            />
                        )}
                        {notification.subject}
                    </Typography>
                    <Typography variant="body2" align="left">
                        {notification.notificationBody}
                    </Typography>
                    <Typography variant="caption" sx={{ ml: 'auto', opacity: 0.5 }}>
                        {format(notification.date, 'dd MMM HH:mm')}
                    </Typography>
                </ButtonBase>
            ))}
        </Box>
    );
};

export default NotificationsList;
