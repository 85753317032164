import actionTypes from './scoutingLists.actionTypes';
import ScoutingList, { ListUpdateRecord, ListsFolder, ScoutingListData } from './ScoutingList';

export const scoutingListRequest = ({
    listId,
    organizationId,
}: {
    listId: string;
    organizationId: string;
}) => ({
    type: actionTypes.SCOUTING_LIST_REQUEST,
    listId,
    organizationId,
});

export const scoutingListRecieved = ({ scoutingList }: { scoutingList: ScoutingList }) => ({
    type: actionTypes.SCOUTING_LIST_RECEIVED,
    scoutingList,
});

export const scoutingListsRequest = ({
    organizationId,
    parentListId,
}: {
    organizationId: string;
    parentListId?: string;
}) => ({
    type: actionTypes.SCOUTING_LISTS_REQUEST,
    organizationId,
    parentListId,
});

export const scoutingListsRecieved = ({ scoutingLists }: { scoutingLists: ScoutingList[] }) => ({
    type: actionTypes.SCOUTING_LISTS_RECIEVED,
    scoutingLists,
});

export const deleteScoutingListRequest = ({
    listId,
    organizationId,
    onSuccess,
}: {
    listId: string;
    organizationId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.DELETE_SCOUTING_LIST,
    listId,
    organizationId,
    onSuccess,
});

export const scoutingListCreate = ({
    organizationId,
    scoutingListData,
    onSuccess,
}: {
    organizationId: string;
    scoutingListData: ScoutingListData;
    onSuccess: (listId: string) => void;
}) => ({
    type: actionTypes.SCOUTING_LIST_CREATE,
    scoutingListData,
    organizationId,
    onSuccess,
});

export const scoutingListUpdate = ({
    listId,
    organizationId,
    scoutingListData,
    onSuccess,
}: {
    listId: string;
    organizationId: string;
    scoutingListData: ScoutingListData;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.SCOUTING_LIST_UPDATE,
    scoutingListData,
    listId,
    organizationId,
    onSuccess,
});

export const removeScoutingList = ({ listId }: { listId: string }) => ({
    type: actionTypes.REMOVE_SCOUTING_LIST,
    listId,
});

export const updateScoutingListLogo = ({
    file,
    organizationId,
    listId,
}: {
    file: File;
    organizationId: string;
    listId: string;
}) => ({
    type: actionTypes.UPDATE_SCOUTING_LIST_LOGO,
    file,
    organizationId,
    listId,
});

export const userListsUpdatesForPeriodListRequest = ({
    userId,
    organizationId,
    fromDate,
    toDate,
    onSuccess,
    onFail,
}: {
    userId: string;
    organizationId: string;
    fromDate: Date;
    toDate: Date;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.USER_LISTS_UPDATES_FOR_PERIOD_LIST_REQUEST,
    userId,
    organizationId,
    fromDate,
    toDate,
    onSuccess,
    onFail,
});

export const listUpdatesRequest = ({
    listId,
    organizationId,
    limit,
    offset,
    onSuccess,
    onFail,
}: {
    listId: string;
    organizationId: string;
    limit: number,
    offset: number,
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.LIST_UPDATES_REQUEST,
    listId,
    organizationId,
    limit,
    offset,
    onSuccess,
    onFail,
});

export const listUpdatesRecieved = ({ listsUpdates }: { listsUpdates: ListUpdateRecord[] }) => ({
    type: actionTypes.LIST_UPDATES_RECEIVED,
    listsUpdates,
});

export const scoutingListFoldersRequest = ({
    organizationId,
    onFail,
}: {
    organizationId: string; 
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.SCOUTING_LIST_FOLDERS_REQUEST,
    organizationId,
    onFail,
});

export const scoutingListFoldersReceived = ({
    organizationId,
    scoutingListFolders,
}: {
    organizationId: string;
    scoutingListFolders: ListsFolder[];
}) => ({
    type: actionTypes.SCOUTING_LIST_FOLDERS_RECEIVED,
    organizationId,
    scoutingListFolders,
});

export const scoutingListFoldersUpdate = ({
    scoutingListFolders,
    organizationId,
    onSuccess,
}: {
    scoutingListFolders: ListsFolder[];
    organizationId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.SCOUTING_LIST_FOLDERS_UPDATE,
    scoutingListFolders,
    organizationId,
    onSuccess,
});
