import { Box } from 'common/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { addDays, endOfDay, isAfter, isBefore, startOfDay, subDays } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { requestTeamPlayersList } from 'entities/players/players.actions';
import { eventsListRequest } from 'entities/events/events.actions';
import { playerGoalsRequest } from 'entities/goals/goals.actions';
import { fetchEvaluationsForEvent } from 'entities/evaluations/evaluations.actions';
import { teamPlayersListSelector } from 'entities/players/players.selectors';
import { teamEventsListSelector } from 'entities/events/events.selectors';
import { evaluationsListSelector } from 'entities/evaluations/evaluations.selectors';
import { goalsListSelector } from 'entities/goals/goals.selectors';
import NoPlayers from './NoPlayers';
import NoGoals, { getPlayersGoalsState } from './NoGoals';
import NoEvents, { getNoEventsDaysString } from './NoEvents';
import NoDrills, { getNoDrillsSessions } from './NoDrills';
import NoEvaluations, { getNoEveluationsEventsState } from './NoEvaluations';
import { MIN_PLAYERS_COUNT } from './assistant.constants';
import AssistantPlaceholder from './AssistantPlaceholder';

type AssistantContentProps = {
    teamId: string;
    organizationId: string;
    hidden?: boolean;
    onClose: VoidFunction;
};

const AssistantContent: React.FC<AssistantContentProps> = ({
    teamId,
    organizationId,
    hidden,
    onClose,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const playersList = useSelector((state: RootState) =>
        teamPlayersListSelector(state, { teamId, organizationId }),
    );
    const eventsList = useSelector((state: RootState) => teamEventsListSelector(state, { teamId }));
    const goalsList = useSelector((state: RootState) => goalsListSelector(state));
    const evaluationsList = useSelector((state: RootState) => evaluationsListSelector(state));

    // 2 days and 2 days after today
    const startOfEventsPeriod = subDays(startOfDay(new Date()), 2);
    const endOfEventsPeriod = addDays(endOfDay(new Date()), 2);

    const futureEvents = eventsList.filter(
        ({ start }) => isAfter(start, startOfDay(new Date())) && isBefore(start, endOfEventsPeriod),
    );

    const pastEvents = eventsList.filter(
        ({ start }) => isAfter(start, startOfEventsPeriod) && isBefore(start, new Date()),
    );

    const noDrillsSessions = getNoDrillsSessions(futureEvents);
    const playersGoalsState = getPlayersGoalsState(goalsList, playersList);
    const noEventsDayString = getNoEventsDaysString(futureEvents, t);

    useEffect(() => {
        dispatch(requestTeamPlayersList({ teamId }));
        dispatch(
            eventsListRequest({
                teamId,
                organizationId,
                from: startOfEventsPeriod,
                to: endOfEventsPeriod,
            }),
        );
    }, []);

    useEffect(() => {
        if (playersList.length > 0) {
            playersList.forEach((player) => {
                dispatch(playerGoalsRequest({ playerId: player.id }));
            });
        }
    }, [teamId, playersList.length]);

    const noEveluationsEventsState = getNoEveluationsEventsState(pastEvents, evaluationsList);

    useEffect(() => {
        noEveluationsEventsState.gamesSessionsList.forEach((event) => {
            dispatch(fetchEvaluationsForEvent(event.id));
        });
    }, [noEveluationsEventsState.gamesSessionsList.length]);

    if (hidden) {
        return null;
    }

    const hasSuggestions =
        playersList.length < MIN_PLAYERS_COUNT ||
        playersGoalsState.isVisible ||
        noEventsDayString !== '' ||
        noDrillsSessions.length > 0 ||
        noEveluationsEventsState.isVisible;

    return (
        <Box sx={{ m: 0.5, maxWidth: 400 }}>
            <NoPlayers
                teamId={teamId}
                organizationId={organizationId}
                playersCount={playersList.length}
                onClose={onClose}
            />
            <NoGoals
                teamId={teamId}
                organizationId={organizationId}
                isVisible={playersGoalsState.isVisible}
                playersCount={playersGoalsState.playersCount}
                expiredGoalsCount={playersGoalsState.expiredGoalsCount}
                playersWithGoalsCount={playersGoalsState.playersWithGoalsCount}
                playersWithGoalsFraction={playersGoalsState.playersWithGoalsFraction}
                onClose={onClose}
            />
            <NoEvents
                teamId={teamId}
                organizationId={organizationId}
                noEventsDayString={noEventsDayString}
                onClose={onClose}
            />
            <NoDrills
                teamId={teamId}
                organizationId={organizationId}
                eventsList={noDrillsSessions}
                onClose={onClose}
            />
            <NoEvaluations
                teamId={teamId}
                organizationId={organizationId}
                isVisible={noEveluationsEventsState.isVisible}
                noEvaluationsEvents={noEveluationsEventsState.noEvaluationsEvents}
                onClose={onClose}
            />
            <AssistantPlaceholder isVisible={!hasSuggestions} />
        </Box>
    );
};

export default AssistantContent;
