import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { DocsLinks } from 'common/components/DocsLink';
import { SaveIcon, SessionIcon } from 'common/icons';
import {
    Guide,
    MainBlock,
    MainText,
    Step,
    StepContent,
    StepDescription,
    StepDocsLink,
    StepsList,
} from './CommonGuideComponents';
import { VideoTutorial } from 'common/components/VideoPlayBtn/VideoTutorial';
import { TutorialName } from 'common/components/VideoPlayBtn/videoTutorial.config';

const PREFIX = 'SessionPageGuide';

const classes = {
    sessionIcon: `${PREFIX}-sessionIcon`,
    saveIcon: `${PREFIX}-saveIcon`,
};

const Root = styled(Guide)(({ theme }) => ({
    [`& .${classes.sessionIcon}`]: {
        fill: theme.palette.primary.main,
        fontSize: '72px',
        textAlign: 'center',
        margin: 'auto',
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.saveIcon}`]: {
        fill: '#e1ad69',
        width: 32,
        height: 32,
        marginRight: theme.spacing(2),
    },
}));

const SessionPageGuide: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <VideoTutorial sx={{ mb: 0.5 }} tutorialName={TutorialName.createSession} />
            <VideoTutorial sx={{ mb: 2 }} tutorialName={TutorialName.evaluateSession} />
            <MainBlock>
                <SessionIcon className={classes.sessionIcon} />
                <MainText>{t('help.sessionPageGuide.description')}</MainText>
                <StepDocsLink
                    page={DocsLinks.howToCreateSession}
                    title={t('help.sessionPageGuide.sessionDocs')}
                />
            </MainBlock>
            <StepsList>
                <Step>
                    <SaveIcon className={classes.saveIcon} />
                    <StepContent>
                        <StepDescription>{t('help.sessionPageGuide.saveHotkeys')}</StepDescription>
                    </StepContent>
                </Step>
            </StepsList>
        </Root>
    );
};

export default SessionPageGuide;
