import React, { useCallback } from 'react';
import { Button } from 'common/components';
import { TFunction } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Event from 'entities/events/Event';
import { addDays, isSameDay, isToday, isTomorrow } from 'date-fns';
import AssistantBase from './AssistantBase';

export const getNoEventsDaysString = (eventsList: Event[], t: TFunction) => {
    const hasEventsToday = eventsList.filter((event) => isToday(event.start)).length > 0;
    const hasEventsTomorrow = eventsList.filter((event) => isTomorrow(event.start)).length > 0;
    const hasEventsTheDayAftertomorrow =
        eventsList.filter((event) => isSameDay(addDays(new Date(), 2), event.start)).length > 0;

    const noEventsDays = [];

    if (!hasEventsToday) {
        noEventsDays.push(t('common.days.today'));
    }

    if (!hasEventsTomorrow) {
        noEventsDays.push(t('common.days.tomorrow'));
    }

    if (!hasEventsTheDayAftertomorrow) {
        noEventsDays.push(t('common.days.theDayAfterTomorrow'));
    }

    if (noEventsDays.length === 0) {
        return '';
    }

    if (noEventsDays.length === 1) {
        return noEventsDays[0];
    }

    const andWord = t('common.words.and');

    if (noEventsDays.length === 2) {
        return noEventsDays.join(` ${andWord} `);
    }

    return `${noEventsDays[0]}, ${noEventsDays[1]} ${andWord} ${noEventsDays[2]}`;
};

type NoEventsProps = {
    teamId: string;
    organizationId: string;
    noEventsDayString: string;
    onClose: VoidFunction;
};

const NoEvents: React.FC<NoEventsProps> = ({
    organizationId,
    teamId,
    noEventsDayString,
    onClose,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        onClose();
        navigate(`/organizations/${organizationId}/teams/${teamId}/planning/month`);
    }, [teamId, organizationId, navigate]);

    if (noEventsDayString === '') {
        return null;
    }

    const notEnoughPlayersText = t('assistant.noEvents.description', {
        daysStr: noEventsDayString,
    });

    return (
        <AssistantBase description={notEnoughPlayersText} icon="🗓">
            <Button onClick={handleClose} variant="contained" color="primary" size="small">
                {t('assistant.noEvents.addEventsBtn')}
            </Button>
            {/* <HelpLink href="https://undersports.club/ru/team-calendar">
                    {t('assistant.noEvents.helpBtn')}
                </HelpLink> */}
        </AssistantBase>
    );
};

export default NoEvents;
