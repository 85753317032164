import Challenge, { ChallengeData, ChallengeParams } from './Challenge';
import actionTypes from './challenges.actionTypes';

export const challengeDataReceived = ({ challengeData }: { challengeData: Challenge }) => ({
    type: actionTypes.CHALLENGE_DATA_RECEIVED,
    challengeData,
});

export const challengesListRequest = () => ({
    type: actionTypes.CHALLENGES_LIST_REQUEST,
});

export const challengesListReceived = ({ challengesList }: { challengesList: Challenge[] }) => ({
    type: actionTypes.CHALLENGES_LIST_RECEIVED,
    challengesList,
});

export const challengeDataUpdate = ({
    challengeId,
    challengeData,
    successMessage,
    failMessage,
}: ChallengeParams & {
    challengeData: ChallengeData;
    successMessage: string;
    failMessage: string;
}) => ({
    type: actionTypes.CHALLENGE_UPDATE_REQUEST,
    challengeId,
    challengeData,
    successMessage,
    failMessage,
});

export const challengeCreateRequest = ({
    challengeData,
    successMessage,
}: {
    challengeData: ChallengeData;
    successMessage: string;
}) => ({
    type: actionTypes.CHALLENGE_CREATE_REQUEST,
    challengeData,
    successMessage,
});

export const challengeDataRequest = ({ challengeId }: ChallengeParams) => ({
    type: actionTypes.CHALLENGE_DATA_REQUEST,
    challengeId,
});

export const removeChallengeFromList = ({ challengeId }: ChallengeParams) => ({
    type: actionTypes.REMOVE_CHALLENGE_FROM_LIST,
    challengeId,
});
