import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { DocsLinks } from 'common/components/DocsLink';
import { PersonAddIcon } from 'common/icons';
import {
    Guide,
    MainBlock,
    MainIcon,
    MainText,
    Step,
    StepContent,
    StepDescription,
    StepDocsLink,
    StepEmojiIcon,
    StepsList,
} from './CommonGuideComponents';
import { VideoTutorial } from 'common/components/VideoPlayBtn/VideoTutorial';
import { TutorialName } from 'common/components/VideoPlayBtn/videoTutorial.config';

const PREFIX = 'OrgPageGuide';

const classes = {
    accessIcon: `${PREFIX}-accessIcon`,
    teamIcon: `${PREFIX}-teamIcon`,
};

const Root = styled(Guide)(({ theme }) => ({
    [`& .${classes.accessIcon}`]: {
        opacity: 0.7,
        width: 32,
        height: 32,
        marginRight: theme.spacing(2),
    },

    [`& .${classes.teamIcon}`]: {
        width: 64,
        flexShrink: 0,
        marginLeft: -32,
    },
}));

type OrgPageGuideProps = {};

const OrgPageGuide: React.FC<OrgPageGuideProps> = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <VideoTutorial sx={{ my: 0.5 }} tutorialName={TutorialName.appNavigation} />
            <VideoTutorial sx={{ mb: 2 }} tutorialName={TutorialName.profileSetup} />
            <MainBlock>
                <MainIcon>🎪</MainIcon>
                <MainText>{t('help.orgPageGuide.description')}</MainText>
            </MainBlock>
            <StepsList>
                <Step>
                    <PersonAddIcon className={classes.accessIcon} />
                    <StepContent>
                        <StepDescription>{t('help.orgPageGuide.accessText')}</StepDescription>
                        <StepDocsLink
                            page={DocsLinks.accessManagement}
                            title={t('help.orgPageGuide.accessDocs')}
                        />
                    </StepContent>
                </Step>
                <Step>
                    <StepEmojiIcon className={classes.teamIcon}>🤜🥊</StepEmojiIcon>
                    <StepContent>
                        <StepDescription>{t('help.orgPageGuide.teamDescription')}</StepDescription>
                        <StepDocsLink
                            page={DocsLinks.howToCreateTeam}
                            title={t('help.orgPageGuide.createTeamDocs')}
                        />
                    </StepContent>
                </Step>
            </StepsList>
        </Root>
    );
};

export default OrgPageGuide;
