import League from './Leagues';
import actionTypes from './leagues.actionTypes';

export const leagueDataRequest = ({
    leagueId,
    onSuccess,
}: {
    leagueId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.LEAGUE_DATA_REQUEST,
    leagueId,
    onSuccess,
});

export const leagueDataRecieved = (league: League) => ({
    type: actionTypes.LEAGUE_DATA_RECEIVED,
    league,
});

export const leaguesListRequest = ({
    organizationId,
    onSuccess,
}: {
    organizationId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.LEAGUES_LIST_REQUEST,
    organizationId,
    onSuccess,
});

export const leaguesListRecieved = (leaguesList: League[]) => ({
    type: actionTypes.LEAGUES_LIST_RECEIVED,
    leaguesList,
});
