export default {
    TEAM_TASK_REQUEST: 'ENTITIES/TASKS/TEAM_TASK_REQUEST',
    TEAM_TASK_RECEIVED: 'ENTITIES/TASKS/TEAM_TASK_RECEIVED',
    TEAM_TASKS_LIST_RECEIVED: 'ENTITIES/TASKS/TEAM_TASKS_LIST_RECEIVED',
    PLAYER_TASKS_LIST_RECEIVED: 'ENTITIES/TASKS/PLAYER_TASKS_LIST_RECEIVED',
    TEAM_TASKS_LIST_REQUEST: 'ENTITIES/TASKS/TEAM_TASKS_LIST_REQUEST',
    PLAYER_TASKS_LIST_REQUEST: 'ENTITIES/TASKS/PLAYER_TASKS_LIST_REQUEST',
    CREATE_TEAM_TASK: 'ENTITIES/TASKS/CREATE_TEAM_TASK',
    PLAYER_TASK_REQUEST: 'ENTITIES/TASKS/PLAYER_TASK_REQUEST',
    UPDATE_PLAYER_TASK: 'ENTITIES/TASKS/UPDATE_PLAYER_TASK',
    DELETE_TEAM_TASK: 'ENTITIES/TASKS/DELETE_TEAM_TASK',
    REMOVE_TEAM_TASK: 'ENTITIES/TASKS/REMOVE_TEAM_TASK',
};
