import UserTask, { UserTaskData } from 'entities/user-tasks/UserTask';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { db } from 'common/firebase';
import { handleDocumentSnapshot, handleCollectionSnapshot } from './utils';

export const createUserTask = ({
    userId,
    userTaskData,
}: {
    userId: string;
    userTaskData: UserTaskData;
}) => {
    const userTasksRef = collection(db, `users/${userId}/user_tasks`);

    return addDoc(userTasksRef, userTaskData).then((doc) => doc.id);
};

export const updateUserTask = ({
    userId,
    taskId,
    userTaskData,
}: {
    userId: string;
    taskId: string;
    userTaskData: UserTaskData;
}) => {
    const userTaskRef = doc(db, `users/${userId}/user_tasks/${taskId}`);

    return updateDoc(userTaskRef, userTaskData);
};

export const fetchUserTasks = ({ userId }: { userId: string }) => {
    const userTasksRef = collection(db, `users/${userId}/user_tasks`);

    return getDocs(userTasksRef)
        .then(handleCollectionSnapshot)
        .then((userTasksList) =>
            userTasksList.map((userTaskData: UserTask) => ({ ...userTaskData, userId })),
        );
};

export const fetchUserTask = ({ userId, taskId }: { userId: string; taskId: string }) => {
    const userTaskRef = doc(db, `users/${userId}/user_tasks/${taskId}`);

    return getDoc(userTaskRef)
        .then(handleDocumentSnapshot)
        .then((userTaskData) => ({ ...userTaskData, userId }));
};

export const deleteUserTask = ({ userId, taskId }: { userId: string; taskId: string }) => {
    const userTaskRef = doc(db, `users/${userId}/user_tasks/${taskId}`);

    return deleteDoc(userTaskRef);
};
