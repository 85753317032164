import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import User, { HelpProps } from 'entities/users/User';
import {
    isOrgPage,
    isPlayersListPage,
    isSessionPage,
    isGamePage,
    isSkillsPage,
    isPlanningPage,
    isConditionsPage,
    isMethodologyPage,
} from 'common/utils/pageHelpers';
import WelcomeGuide from './pages/WelcomeGuide';
import OrgPageGuide from './pages/OrgPageGuide';
import PlayersPageGuide from './pages/PlayersPageGuide';
import SessionPageGuide from './pages/SessionPageGuide';
import SkillsPageGuide from './pages/SkillsPageGuide';
import CalendarPageGuide from './pages/PlanningPageGuide';
import GamePageGuide from './pages/GamePageGuide';
import ConditionsPageGuide from './pages/ConditionsPageGuide';
import MethodologyPageGuide from './pages/MethodologyPageGuide';

type HelpState = {
    isOpen: boolean;
    ContentComponent: React.FunctionComponent<any> | null;
    prop: HelpProps | null;
    setIsOpen: (value: boolean) => void;
};

const getHelpConfig = (path: string) => {
    if (path === '/') {
        return { ContentComponent: WelcomeGuide, prop: HelpProps.sawWelcomeGuide };
    }

    if (isPlayersListPage(path)) {
        return { ContentComponent: PlayersPageGuide, prop: HelpProps.sawPlayersPageGuide };
    }

    if (isOrgPage(path)) {
        return { ContentComponent: OrgPageGuide, prop: HelpProps.sawOrgPageGuide };
    }

    if (isSessionPage(path)) {
        return { ContentComponent: SessionPageGuide, prop: HelpProps.sawSessionPageGuide };
    }

    if (isGamePage(path)) {
        return { ContentComponent: GamePageGuide, prop: HelpProps.sawGamePageGuide };
    }

    if (isSkillsPage(path)) {
        return { ContentComponent: SkillsPageGuide, prop: HelpProps.sawSkillsPageGuide };
    }

    if (isConditionsPage(path)) {
        return { ContentComponent: ConditionsPageGuide, prop: HelpProps.sawConditionsPageGuide };
    }

    if (isMethodologyPage(path)) {
        return { ContentComponent: MethodologyPageGuide, prop: HelpProps.sawMethodologyPageGuide };
    }

    if (isPlanningPage(path)) {
        return { ContentComponent: CalendarPageGuide, prop: HelpProps.sawPlanningPageGuide };
    }

    return null;
};

export const useHelpState = (user?: User): HelpState => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const helpConfig = getHelpConfig(location.pathname);

    useEffect(() => {
        if (user && helpConfig && !user.config?.[helpConfig.prop]) {
            setIsOpen(true);
        }
    }, [user, helpConfig?.prop]);

    if (!helpConfig || !user) {
        return { isOpen, setIsOpen, ContentComponent: null, prop: null };
    }

    return {
        isOpen,
        setIsOpen,
        ContentComponent: helpConfig.ContentComponent,
        prop: helpConfig.prop,
    };
};
