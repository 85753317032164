import { styled } from '@mui/material/styles';
import { Typography } from 'common/components';
import DocsLink from 'common/components/DocsLink';

export const Guide = styled('div')(({ theme }) => ({
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const MainIcon = styled('span')(({ theme }) => ({
    fontSize: '72px',
    textAlign: 'center',
    margin: 'auto',
    marginBottom: theme.spacing(2),
}));

export const MainBlock = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
}));

export const MainText = styled(Typography)(({ theme }) => ({
    opacity: 0.7,
    marginLeft: theme.spacing(6),
}));

export const StepsList = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

export const Step = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(1),
}));

export const StepContent = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid',
    borderColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    flexGrow: 1,
}));

export const StepEmojiIcon = styled('div')(({ theme }) => ({
    marginRight: theme.spacing(2),
    fontSize: '32px',
}));

export const StepDescription = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

export const StepActions = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
    },
}));

export const StepDocsLink = styled(DocsLink)(({ theme }) => ({
    marginTop: theme.spacing(1),

    [theme.breakpoints.up('sm')]: {
        marginLeft: 'auto',
        marginTop: 0,
    },
}));
