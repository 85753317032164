import { db, functions } from 'common/firebase';
import { ClubData } from 'entities/clubs/Clubs';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { handleCollectionSnapshot, handleDocumentSnapshot } from 'gateways/utils';

export const fetchClubById = ({ clubId }: { clubId: string }) => {
    const clubRef = doc(db, `clubs/${clubId}`);

    return getDoc(clubRef).then(handleDocumentSnapshot);
};

export const fetchClubsList = ({ organizationId }: { organizationId: string }) => {
    const clubsRef = collection(db, 'clubs');

    const q = query(clubsRef, where('organizationId', '==', organizationId));

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchLeagueClubsList = ({
    organizationId,
    leagueId,
}: {
    organizationId: string;
    leagueId: string;
}) => {
    const clubsRef = collection(db, 'clubs');

    const q = query(
        clubsRef,
        where('organizationId', '==', organizationId),
        where('leagueId', '==', leagueId),
    );

    return getDocs(q).then(handleCollectionSnapshot);
};

export type ClubDataRequestResult = {
    status: 'success';
    clubId: string;
    clubData: ClubData;
    avatarStr: string;
    clubPlayersRefIds: string[];
};

const getClubDataByProviderLinkFunction = httpsCallable(functions, 'getClubDataByProviderLink');
export const getClubDataByProviderLink = ({
    pageUrl,
    organizationId,
    skipSearch,
}: {
    pageUrl: string;
    organizationId: string;
    skipSearch: boolean;
}) => {
    return getClubDataByProviderLinkFunction({ pageUrl, organizationId, skipSearch }).then(
        (res) => {
            const result = res.data as ClubDataRequestResult;

            return {
                clubId: result.clubId,
                clubPlayersRefIds: result.clubPlayersRefIds,
            };
        },
    );
};
