import userActionTypes from './users.actionTypes';
import User, { UserData } from './User';

export const requestUserData = ({ userId }: { userId: string }) => ({
    type: userActionTypes.REQUEST_USER_DATA,
    userId,
});

export const setUserData = (userData: User | null) => ({
    type: userActionTypes.USER_DATA_RECEIVED,
    userData,
});

export const clearUserData = () => ({
    type: userActionTypes.CLEAR_USER_DATA,
});

export const updateUserData = ({ userId, userData }: { userId: string; userData: UserData }) => ({
    type: userActionTypes.UPDATE_USER_DATA,
    userId,
    userData,
});
