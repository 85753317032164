import { takeLatest, all, put, call, select, takeEvery } from 'redux-saga/effects';
import * as scoutingListsGateway from 'gateways/scoutingListsGateway';
import listsActionTypes from './scoutingLists.actionTypes';
import {
    scoutingListRecieved,
    scoutingListsRequest,
    scoutingListRequest,
    scoutingListsRecieved,
    removeScoutingList,
    scoutingListUpdate,
    deleteScoutingListRequest,
    scoutingListCreate,
    updateScoutingListLogo,
    userListsUpdatesForPeriodListRequest,
    listUpdatesRecieved,
    scoutingListFoldersRequest,
    scoutingListFoldersReceived,
    scoutingListFoldersUpdate,
    listUpdatesRequest,
} from './scoutingLists.actions';
import ScoutingList, { ListUpdateRecord, ListsFolder } from './ScoutingList';
import { toastr } from 'react-redux-toastr';
import { uploadFile } from 'common/resourses/files';
import { scoutingListSelector } from './scoutingLists.selectors';
import { format } from 'date-fns';
import { fetchScoutingListFolders, updateScoutingListFolders } from 'gateways/organizationsGateway';

export function* fetchScoutingListSaga({
    listId,
    organizationId,
}: ReturnType<typeof scoutingListRequest>) {
    try {
        const scoutingList: ScoutingList = yield call(scoutingListsGateway.fetchScoutingList, {
            listId,
            organizationId,
        });
        if (scoutingList) {
            yield put(scoutingListRecieved({ scoutingList }));
        }
    } catch (e) {
        console.error(e);
    }
}

export function* fetchScoutingListsSaga({
    organizationId,
    parentListId,
}: ReturnType<typeof scoutingListsRequest>) {
    try {
        const scoutingLists: ScoutingList[] = yield call(
            [scoutingListsGateway, scoutingListsGateway.fetchScoutingLists],
            { organizationId, parentListId },
        );
        yield put(scoutingListsRecieved({ scoutingLists }));
    } catch (e) {
        console.error(e);
    }
}

export function* createScoutingListSaga({
    scoutingListData,
    organizationId,
    onSuccess,
}: ReturnType<typeof scoutingListCreate>) {
    const listId: string = yield call(scoutingListsGateway.createScoutingList, {
        scoutingListData,
        organizationId,
    });
    yield put(scoutingListRequest({ listId, organizationId }));
    yield call(onSuccess, listId);
}

export function* deleteScoutingListSaga({
    listId,
    organizationId,
    onSuccess,
}: ReturnType<typeof deleteScoutingListRequest>) {
    try {
        yield call([scoutingListsGateway, scoutingListsGateway.deleteScoutingList], {
            listId,
            organizationId,
        });
        yield put(removeScoutingList({ listId }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

export function* updateScoutingListSaga({
    listId,
    organizationId,
    scoutingListData,
    onSuccess,
}: ReturnType<typeof scoutingListUpdate>) {
    try {
        yield call(scoutingListsGateway.updateScoutingList, {
            scoutingListData,
            listId,
            organizationId,
        });
        yield call(fetchScoutingListSaga, { type: '', listId, organizationId });

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

export function* addScoutingListLogoSaga({
    file,
    organizationId,
    listId,
}: ReturnType<typeof updateScoutingListLogo>) {
    try {
        const fileName = `logo-${new Date().getTime()}.png`;
        const { filePath } = yield call(uploadFile, {
            file,
            filePath: `/organizations/${organizationId}/lists/${listId}/${fileName}`,
        });
        const { id, ...listData }: ScoutingList = yield select(scoutingListSelector, {
            listId,
        });

        yield call(updateScoutingListSaga, {
            type: listsActionTypes.SCOUTING_LIST_UPDATE,
            scoutingListData: {
                ...listData,
                logoPath: filePath,
            },
            listId: id,
            organizationId,
            onSuccess: () => {},
        });
    } catch (e) {
        yield call(toastr.error, 'Oops', 'Failed to update logo');
    }
}

export function* fetchUserObservationsByPeriodSaga({
    organizationId,
    userId,
    toDate,
    fromDate,
    onSuccess,
    onFail,
}: ReturnType<typeof userListsUpdatesForPeriodListRequest>) {
    try {
        const listsUpdates: ListUpdateRecord[] = yield call(
            scoutingListsGateway.fetchUserListsUpdatesByPeriod,
            { fromDate, toDate, organizationId, userId },
        );

        if (listsUpdates) {
            yield put(listUpdatesRecieved({ listsUpdates }));
        }

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        const formattedFromDate = format(fromDate, 'dd MMM');
        const formattedtoDate = format(toDate, 'dd MMM');
        const errorMessage = `Failed to fetch lists updates for: ${formattedFromDate} - ${formattedtoDate}`;
        console.error(errorMessage, e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* fetchListUdatesSaga({
    organizationId,
    listId,
    limit,
    offset,
    onSuccess,
    onFail,
}: ReturnType<typeof listUpdatesRequest>) {
    try {
        const { data: listsUpdates }: { data: ListUpdateRecord[] } = yield call(
            scoutingListsGateway.fetchListUpdates,
            {
                organizationId,
                listId,
                limit,
                offset,
            },
        );

        if (listsUpdates) {
            yield put(listUpdatesRecieved({ listsUpdates }));
        }

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        const errorMessage = `Failed to fetch lists updates for list: ${listId} (${limit} starting at ${offset})`;
        console.error(errorMessage, e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* fetchScoutingListFoldersSaga({
    organizationId,
    onFail,
}: ReturnType<typeof scoutingListFoldersRequest>) {
    try {
        const scoutingListFolders: { structure: ListsFolder[] } = yield call(
            fetchScoutingListFolders,
            { organizationId },
        );
        if (scoutingListFolders) {
            yield put(
                scoutingListFoldersReceived({
                    organizationId,
                    scoutingListFolders: scoutingListFolders.structure,
                }),
            );
        }
    } catch (e) {
        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* scoutingListFoldersUpdateSaga({
    scoutingListFolders,
    organizationId,
    onSuccess,
}: ReturnType<typeof scoutingListFoldersUpdate>) {
    try {
        yield call(updateScoutingListFolders, {
            scoutingListFolders,
            organizationId,
        });
        yield put(scoutingListFoldersReceived({ scoutingListFolders, organizationId }));
        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

function* listsSagas() {
    yield all([
        takeEvery(listsActionTypes.SCOUTING_LIST_REQUEST, fetchScoutingListSaga),
        takeLatest(listsActionTypes.SCOUTING_LISTS_REQUEST, fetchScoutingListsSaga),
        takeLatest(listsActionTypes.SCOUTING_LIST_CREATE, createScoutingListSaga),
        takeLatest(listsActionTypes.SCOUTING_LIST_UPDATE, updateScoutingListSaga),
        takeLatest(listsActionTypes.DELETE_SCOUTING_LIST, deleteScoutingListSaga),
        takeLatest(listsActionTypes.UPDATE_SCOUTING_LIST_LOGO, addScoutingListLogoSaga),
        takeLatest(listsActionTypes.SCOUTING_LIST_FOLDERS_REQUEST, fetchScoutingListFoldersSaga),
        takeLatest(listsActionTypes.SCOUTING_LIST_FOLDERS_UPDATE, scoutingListFoldersUpdateSaga),
        takeEvery(
            listsActionTypes.USER_LISTS_UPDATES_FOR_PERIOD_LIST_REQUEST,
            fetchUserObservationsByPeriodSaga,
        ),
        takeEvery(listsActionTypes.LIST_UPDATES_REQUEST, fetchListUdatesSaga),
    ]);
}

export default listsSagas;
