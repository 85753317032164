import { EpisodesType } from 'entities/episodes/Episodes';
import { TeamStatus } from 'entities/reports/Report';
import { EpisodesFiltersName } from './episodes-list/episodesFilters';

export enum Periods {
    'one' = 1,
    'two' = 2,
}

export type TeamsStatus = {
    [TeamStatus.guest]: string;
    [TeamStatus.home]: string;
};

export type TeamGoalkeepers = {
    [key: string]: string[];
};

export type TeamSelectFunctionOptions = {
    team: TeamStatus;
    episodeType: EpisodesType;
    filterName?: EpisodesFiltersName;
};
export type TeamSelectFunction = (options: TeamSelectFunctionOptions) => void;

export type PlayerSelectFunctionOptions = {
    team: TeamStatus;
    playerId: string;
    episodeType: EpisodesType;
};
export type PlayerSelectFunction = (options: PlayerSelectFunctionOptions) => void;
