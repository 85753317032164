import challengesPageActionTypes from './challengesPage.actionTypes';

const initialState = {
    isSubmitting: false,
    submitError: null,
};

type InitialStateType = {
    isSubmitting: boolean;
    submitError: null | 'general';
};

const challengesPage = (state: InitialStateType = initialState, action: any) => {
    switch (action.type) {
        case challengesPageActionTypes.SUBMIT_SOLUTION:
            return {
                ...state,
                isSubmitting: true,
                submitError: null,
            };
        case challengesPageActionTypes.SUBMIT_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
            };
        case challengesPageActionTypes.SUBMIT_FAIL:
            return {
                ...state,
                isSubmitting: false,
                submitError: 'general',
            };
        default:
            return state;
    }
};

export default challengesPage;
