import { TacticBoardScheme } from 'features/tactic-board/utils/pixi.utils';

export enum IllustrationTypes {
    video = 'video',
    image = 'image',
    schema = 'schema',
    pdf = 'pdf',
}

export enum DrillType {
    POS = 'POS' /* Simple Possession Games - POS */,
    POC = 'POC' /* Complex Possession Games - POC  */,
    SG = 'SG' /* Short Games - SG */,
    APLI = 'APLI' /* Applied Games - AG */,
    SP = 'SP' /* Specific Tasks - SPC */,
    CT = 'CT' /* Collective Technique - CT */,
    AR = 'AR' /* Finishing - FIN */,
    IT = 'IT' /* Individual Technique - IT */,
    OTHER = 'OTHER' /* Other - OT */,
    WARMUP = 'WARMUP' /* Warm Up - WU */,
    GPP = 'GPP' /* General Physical Preparation - GPP */,
    SPP = 'SPP' /* Specific Physical Preparation - SPP */,
    SETP = 'SETP' /* Set Pieces - SET */,
}

export const drillTypesList = [
    DrillType.POS,
    DrillType.POC,
    DrillType.SG,
    DrillType.APLI,
    DrillType.SP,
    DrillType.CT,
    DrillType.AR,
    DrillType.IT,
    DrillType.OTHER,
    DrillType.WARMUP,
    DrillType.GPP,
    DrillType.SPP,
    DrillType.SETP,
];

export type DrillIllustration = {
    id: string;
    type: IllustrationTypes;
    details: {
        src: string;
    };
    description: any;
};

type DrillLink = {
    name: string;
    href: string;
};

export enum TacticalSpecializations {
    width = 'width',
    depth = 'depth',
    mix = 'mix',
}

// enum AgeGroups {
//     u8u10 = 'u8u10',
//     u11u13 = 'u11u13',
//     u14u16 = 'u14u16',
//     u17 = 'u17',
// }

export enum DrillFocus {
    general = 'general',
    specific = 'specific',
}

export enum SpaceSizes {
    big = 'big',
    avarage = 'avarage',
    small = 'small',
}

export enum SpaceZones {
    buildUp = 'buildUp',
    develop = 'develop',
    finishing = 'finishing',
}

export enum LoadTypes {
    low = 'low',
    avarage = 'avarage',
    high = 'high',
}

export type DrillData = {
    name: string;
    drillType: DrillType;
    description: string;
    illustrations: DrillIllustration[];
    methodologyId: string;
    duration: number;
    equipment: string;
    objectives: string;
    sets: number;
    predecessors: string[];
    evolutions: string[];
    descriptionFilePath: string | null;
    instructionsFilePath: string | null;
    links: DrillLink[];
    recordings: DrillLink[];
    masterDrillId: string | null;
    variations: string[];
    previewImg: string | null;
    /* New */
    tacticalSpecialization: TacticalSpecializations | null;
    // ageGroups: AgeGroups[];
    ageGroups: number[];
    focus: DrillFocus | null;
    spaceSize: SpaceSizes[];
    spaceZone: SpaceZones[];
    load: LoadTypes[];
    volume: LoadTypes[];
    complexity: LoadTypes | null;
    tacticBoardScheme: TacticBoardScheme | null;
    prototypeDrill: null | { drillId: string; methodologyId: string };
};

type Drill = DrillData & {
    id: string;
};

export default Drill;
