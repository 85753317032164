import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import * as analytics from 'common/analytics';
import { OrganizationIcon } from 'common/icons';
import SubmitButton from 'common/forms/SubmitButton';
import { Button, Input, Typography } from 'common/components';
import { createOrganization } from 'gateways/organizationsGateway';
import { requestUserAccessRecords } from 'entities/access/access.actions';

const PREFIX = 'CreateOrganizationForm';

const classes = {
    closeButton: `${PREFIX}-closeButton`,
    row: `${PREFIX}-row`,
    field: `${PREFIX}-field`,
    createOrgForm: `${PREFIX}-createOrgForm`,
    description: `${PREFIX}-description`,
};

const Root = styled('form')(({ theme }) => ({
    [`& .${classes.closeButton}`]: {
        marginRight: 'auto',
    },

    [`& .${classes.row}`]: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        alignItems: 'center',
    },

    [`& .${classes.field}`]: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },

    [`&.${classes.createOrgForm}`]: {
        width: 260,
    },

    [`& .${classes.description}`]: {
        opacity: 0.7,
        marginBottom: theme.spacing(2),
    },
}));

type CreateOrganizationFormProps = {
    onClose: VoidFunction;
    onSuccess: ({ organizationId }: { organizationId: string }) => void;
};

const CreateOrganizationForm: React.FC<CreateOrganizationFormProps> = ({ onClose, onSuccess }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: { isSubmitting, isValid },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
        },
    });

    const handleOrgCreate = useCallback(async ({ name }: { name: string }) => {
        const {
            data: { organizationId },
        } = (await createOrganization({ organizationName: name })) as {
            data: { organizationId: string };
        };
        dispatch(requestUserAccessRecords());
        analytics.logEvent({
            event: analytics.GtmCustomEvents.createClub,
        });
        toastr.success('', t('backoffice.organizationCreatedMessage'));
        onSuccess({ organizationId });
    }, []);

    return (
        <Root onSubmit={handleSubmit(handleOrgCreate)} className={classes.createOrgForm}>
            <Typography variant="body2" className={classes.description}>
                {t('backoffice.organization.description.one')}
            </Typography>
            <Typography variant="body2" className={classes.description}>
                {t('backoffice.organization.description.two')}
            </Typography>
            <div className={classes.row}>
                <OrganizationIcon />
                <Input
                    placeholder="Organization name"
                    className={classes.field}
                    {...register('name', {
                        required: true,
                    })}
                />
            </div>
            <div className={classes.row}>
                <Button className={classes.closeButton} onClick={onClose} size="small">
                    {t('actions.close')}
                </Button>
                <SubmitButton
                    buttonText={t('backoffice.createOrganization') as string}
                    disabled={isSubmitting || !isValid}
                    isLoading={isSubmitting}
                    size="small"
                />
            </div>
        </Root>
    );
};

export default CreateOrganizationForm;
