import { downloadURI } from 'common/utils/filesUtils';
import {
    getDownloadURL,
    ref,
    deleteObject,
    UploadTask,
    uploadBytesResumable,
} from 'firebase/storage';
import { toastr } from 'react-redux-toastr';
import { storage } from '../firebase';

export const uploadFile = ({
    file,
    filePath,
    onProgress = () => {},
    onStart = () => {},
}: {
    file: File;
    filePath: string;
    onProgress?: (progress: number, uploadTask: UploadTask) => void;
    onStart?: (uploadTask: UploadTask) => void;
}) =>
    new Promise((resolve, reject) => {
        const fileRef = ref(storage, filePath);
        const uploadTask = uploadBytesResumable(fileRef, file);
        onStart(uploadTask);
        const onUploadProgress = (snapshot: any) => {
            const progress = (snapshot.bytesTransferred * 100) / snapshot.totalBytes;
            onProgress(progress, uploadTask);
        };
        const onUploadSuccess = () =>
            getDownloadURL(fileRef).then((downloadUrl) =>
                resolve({
                    downloadUrl,
                    filePath,
                }),
            );
        uploadTask.on('state_changed', onUploadProgress, reject, onUploadSuccess);
    });

export const deleteFile = async (filePath: string) => {
    const fileRef = ref(storage, filePath);
    return deleteObject(fileRef);
};

export const downloadFile = async (filePath: string, fileName: string) => {
    const starsRef = ref(storage, filePath);
    getDownloadURL(starsRef)
        .then((url) => {
            downloadURI(url, fileName);
        })
        .catch((error) => {
            switch (error.code) {
                case 'storage/object-not-found':
                    toastr.error('Failed to download file', 'File not found');
                    break;
                case 'storage/unauthorized':
                    toastr.error('Failed to download file', 'No permissions');
                    break;
                default:
                    toastr.error('Failed to download file', 'Reload the page please and try again');
            }
        });
};
