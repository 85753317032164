import { call, takeLatest, all } from 'redux-saga/effects';
import { sagas as eventsSagas } from 'entities/events';
import sessionActionTypes from './session.actionTypes';
import * as sessionActions from './session.actions';
import { calculateSessionEndTime } from './sessionUtils';

export function* submitSessionSaga({
    formData,
    teamId,
    organizationId,
    sessionId,
    onSuccess,
    onFail,
}: ReturnType<typeof sessionActions.submitSessionForm>) {
    try {
        const sessionWithUpdatedEndTime = calculateSessionEndTime(formData);
        if (sessionId === 'new') {
            const newSessionId: string = yield call([eventsSagas, eventsSagas.createEventSaga], {
                type: '',
                eventData: sessionWithUpdatedEndTime,
                organizationId,
                teamId,
            });
            yield call(onSuccess, newSessionId);
        } else {
            yield call([eventsSagas, eventsSagas.updateEventSaga], {
                type: '',
                eventData: sessionWithUpdatedEndTime,
                organizationId,
                teamId,
                eventId: sessionId,
            });
            yield call(onSuccess, sessionId);
        }
    } catch (e) {
        yield call(onFail);
    }
}

function* sessionSaga() {
    yield all([takeLatest(sessionActionTypes.SUBMIT_SESSION_FORM, submitSessionSaga)]);
}

export default sessionSaga;
