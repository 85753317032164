import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { DocsLinks } from 'common/components/DocsLink';
import { CalendarIcon } from 'common/icons';
import calendarTypesImg from './images/planningPage/calendarTypes.png';
import calendarDayImg from './images/planningPage/calendarDay.png';
import dayOffImg from './images/planningPage/dayOff.png';
import {
    Guide,
    MainBlock,
    MainIcon,
    MainText,
    Step,
    StepContent,
    StepDescription,
    StepDocsLink,
    StepEmojiIcon,
    StepsList,
} from './CommonGuideComponents';
import { VideoTutorial } from 'common/components/VideoPlayBtn/VideoTutorial';
import { TutorialName } from 'common/components/VideoPlayBtn/videoTutorial.config';

const PREFIX = 'PlanningPageGuide';

const classes = {
    calendarIcon: `${PREFIX}-calendarIcon`,
    teamIcon: `${PREFIX}-teamIcon`,
    calendarTypesImg: `${PREFIX}-calendarTypesImg`,
    calendarDayImg: `${PREFIX}-calendarDayImg`,
};

const Root = styled(Guide)(({ theme }) => ({
    [`& .${classes.calendarIcon}`]: {
        opacity: 0.7,
        width: 32,
        height: 32,
        marginRight: theme.spacing(2),
    },

    [`& .${classes.teamIcon}`]: {
        width: 64,
        flexShrink: 0,
        marginLeft: -32,
    },

    [`& .${classes.calendarTypesImg}`]: {
        maxWidth: 300,
        margin: 'auto',
        width: '100%',
    },

    [`& .${classes.calendarDayImg}`]: {
        maxWidth: 200,
        margin: 'auto',
        width: '100%',
    },
}));

type PlanningPageGuideProps = {};

const PlanningPageGuide: React.FC<PlanningPageGuideProps> = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <VideoTutorial sx={{ my: 0.5 }} tutorialName={TutorialName.appNavigation} />
            <VideoTutorial sx={{ mb: 0.5 }} tutorialName={TutorialName.planificationOverview} />
            <VideoTutorial sx={{ mb: 0.5 }} tutorialName={TutorialName.createSession} />
            <VideoTutorial sx={{ mb: 2 }} tutorialName={TutorialName.createGame} />
            <MainBlock>
                <MainIcon>🗓</MainIcon>
                <MainText>{t('help.planningPageGuide.description')}</MainText>
            </MainBlock>
            <StepsList>
                <Step>
                    <CalendarIcon className={classes.calendarIcon} />
                    <StepContent>
                        <StepDescription>
                            {t('help.planningPageGuide.calendarTypes')}
                        </StepDescription>
                        <img
                            src={calendarTypesImg}
                            alt="Calendar Types"
                            className={classes.calendarTypesImg}
                        />
                        <StepDocsLink
                            page={DocsLinks.calendarTypes}
                            title={t('help.planningPageGuide.calendarTypesDocsLink')}
                        />
                    </StepContent>
                </Step>
                <Step>
                    <StepEmojiIcon>📅</StepEmojiIcon>
                    <StepContent>
                        <StepDescription>
                            {t('help.planningPageGuide.scheduleEvents')}
                        </StepDescription>
                        <img
                            src={calendarDayImg}
                            alt="Calendar day"
                            className={classes.calendarDayImg}
                        />
                        <StepDocsLink
                            page={DocsLinks.scheduleEvents}
                            title={t('help.planningPageGuide.scheduleEventsDocsLink')}
                        />
                    </StepContent>
                </Step>
                <Step>
                    <StepEmojiIcon>😴</StepEmojiIcon>
                    <StepContent>
                        <StepDescription>{t('help.planningPageGuide.dayOff')}</StepDescription>
                        <img src={dayOffImg} alt="Day off" className={classes.calendarDayImg} />
                    </StepContent>
                </Step>
            </StepsList>
        </Root>
    );
};

export default PlanningPageGuide;
