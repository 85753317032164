import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { DocsLinks } from 'common/components/DocsLink';
import { CompareArrowsIcon, PlusOneIcon } from 'common/icons';
import {
    Guide,
    MainBlock,
    MainIcon,
    MainText,
    Step,
    StepContent,
    StepDescription,
    StepDocsLink,
    StepEmojiIcon,
    StepsList,
} from './CommonGuideComponents';
import { VideoTutorial } from 'common/components/VideoPlayBtn/VideoTutorial';
import { TutorialName } from 'common/components/VideoPlayBtn/videoTutorial.config';

const PREFIX = 'PlayersPageGuide';

const classes = {
    accessIcon: `${PREFIX}-accessIcon`,
    teamIcon: `${PREFIX}-teamIcon`,
};

const Root = styled(Guide)(({ theme }) => ({
    [`& .${classes.accessIcon}`]: {
        opacity: 0.7,
        width: 32,
        height: 32,
        marginRight: theme.spacing(2),
    },

    [`& .${classes.teamIcon}`]: {
        width: 64,
        flexShrink: 0,
        marginLeft: -32,
    },
}));

const PlayersPageGuide: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <VideoTutorial sx={{ my: 2 }} tutorialName={TutorialName.addPlayers} />
            <MainBlock>
                <MainIcon>🤜🥊</MainIcon>
                <MainText>{t('help.playersPageGuide.description')}</MainText>
            </MainBlock>
            <StepsList>
                <Step>
                    <PlusOneIcon className={classes.accessIcon} />
                    <StepContent>
                        <StepDescription>
                            {t('help.playersPageGuide.createPlayerText')}
                        </StepDescription>
                        <StepDocsLink
                            page={DocsLinks.createPlayer}
                            title={t('help.playersPageGuide.createPlayerDocs')}
                        />
                    </StepContent>
                </Step>
                <Step>
                    <CompareArrowsIcon className={classes.accessIcon} />
                    <StepContent>
                        <StepDescription>
                            {t('help.playersPageGuide.movePlayersText')}
                        </StepDescription>
                        <StepDocsLink
                            page={DocsLinks.movePlayers}
                            title={t('help.playersPageGuide.movePlayersDocs')}
                        />
                    </StepContent>
                </Step>
                <Step>
                    <StepEmojiIcon>🎯</StepEmojiIcon>
                    <StepContent>
                        <StepDescription>
                            {t('help.playersPageGuide.playersGoalsText')}
                        </StepDescription>
                        <StepDocsLink
                            page={DocsLinks.playerGoals}
                            title={t('help.playersPageGuide.playersGoalsDocs')}
                        />
                    </StepContent>
                </Step>
            </StepsList>
        </Root>
    );
};

export default PlayersPageGuide;
