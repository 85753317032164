import { Typography, Tooltip } from 'common/components';
import { HelpIcon } from 'common/icons';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { StyledSidebarButton } from 'features/assistant/AssistantButton';
import { RootState } from 'store';
import { actions as userActions, selectors as userSelectors } from 'entities/users';
import { useHelpState } from './help.utils';
import SlideDialogWithCloseBtn from 'common/components/SlideDialogWithCloseBtn';

type HelpButtonProps = {
    user: ReturnType<typeof userSelectors.userDataSelector>;
    isExpanded: boolean;
    updateUser: typeof userActions.updateUserData;
};

const HelpButton: React.FC<HelpButtonProps> = ({ user, isExpanded, updateUser }) => {
    const { t } = useTranslation();
    const { isOpen, setIsOpen, ContentComponent, prop } = useHelpState(user);

    const onAccept = useCallback(() => {
        setIsOpen(false);

        if (!prop || !user || user?.config?.[prop]) {
            return;
        }

        const { id, ...userData } = user;
        updateUser({
            userId: id,
            userData: {
                ...userData,
                config: {
                    ...userData.config,
                    [prop]: true,
                },
            },
        });
    }, [prop, user]);

    /*
     * TODO: Help is temporary hidden for mobiles because of bad mobile markup.
     * Fix mobile styles and show help
     */
    if (!ContentComponent) {
        return null;
    }

    return (
        <div>
            <Tooltip title={String(t('help.buttonHint'))}>
                <StyledSidebarButton onClick={() => setIsOpen(true)}>
                    <HelpIcon
                        sx={{ color: (theme) => (isOpen ? theme.palette.primary.main : undefined) }}
                    />
                    {isExpanded && (
                        <Typography variant="subtitle1" sx={{ ml: 3 }} noWrap>
                            {t('help.buttonHint')}
                        </Typography>
                    )}
                </StyledSidebarButton>
            </Tooltip>
            <SlideDialogWithCloseBtn isOpen={isOpen} onClose={onAccept} sx={{ maxWidth: 450 }}>
                {ContentComponent && <ContentComponent />}
            </SlideDialogWithCloseBtn>
        </div>
    );
};

const mapState = (state: RootState) => {
    return {
        user: userSelectors.userDataSelector(state),
    };
};

const mapDispatch = {
    updateUser: userActions.updateUserData,
};

export default connect(mapState, mapDispatch)(HelpButton);
