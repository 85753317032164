import _keyBy from 'lodash/keyBy';
import actionTypes from './episodes.actionTypes';
import EpisodesGroup from './Episodes';

export type EpisodesState = {
    isLoading: boolean;
    episodesGroupsMap: {
        [key: string]: EpisodesGroup;
    };
};

const initialState = {
    isLoading: false,
    episodesGroupsMap: {},
};

export default (state: EpisodesState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.EPISODES_GROUPS_LIST_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case actionTypes.EPISODES_GROUPS_LIST_RECEIVED: {
            const { eventId, episodesGroupsList } = action;
            const withEventIds = episodesGroupsList.map((episodesGroup: EpisodesGroup) => {
                return {
                    ...episodesGroup,
                    eventId,
                };
            });
            return {
                ...state,
                isLoading: false,
                episodesGroupsMap: {
                    ...state.episodesGroupsMap,
                    ..._keyBy(withEventIds, 'id'),
                },
            };
        }
        case actionTypes.EPISODES_GROUP_RECEIVED: {
            const { episodesGroup, eventId } = action;
            return {
                ...state,
                isLoading: false,
                episodesGroupsMap: {
                    ...state.episodesGroupsMap,
                    [episodesGroup.id]: {
                        ...episodesGroup,
                        eventId,
                    },
                },
            };
        }
        default:
            return state;
    }
};
