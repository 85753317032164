import { differenceInYears } from 'date-fns';

const SECONDS_IN_45_MINUTES = 45 * 60;

// value in seconds
export const formatMatchTime = (periodSeconds?: number, period: number | undefined = 1) => {
    if (!periodSeconds) {
        return '0:00';
    }
    const periodShift = (period - 1) * SECONDS_IN_45_MINUTES;
    const matchSeconds = periodSeconds + periodShift;
    const min = Math.floor(matchSeconds / 60);
    const sec = Math.floor(matchSeconds) % 60;
    return `${min}:${sec < 10 ? '0' : ''}${sec}`;
};

export const getAge = (dateOfBirth: Date) => differenceInYears(new Date(), dateOfBirth);
