import { StorageInfo } from 'entities/storage-files/StorageFile';
import actionTypes from './methodologies.actionTypes';
import Methodology from './Methodology';
type MethodologiesState = {
    methodologiesHash: {
        [key: string]: Methodology;
    };
    methodologiesStorageInfoHash: {
        [key: string]: StorageInfo;
    };
};

const initialState = {
    methodologiesHash: {},
    methodologiesStorageInfoHash: {},
};

const methodologiesReducer = (state: MethodologiesState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.METHODOLOGY_DATA_RECEIVED:
            return {
                ...state,
                methodologiesHash: {
                    ...state.methodologiesHash,
                    [action.methodologyData.id]: action.methodologyData,
                },
            };
        case actionTypes.METHODOLOGY_STORAGE_INFO_RECIEVED:
            return {
                ...state,
                methodologiesStorageInfoHash: {
                    ...state.methodologiesStorageInfoHash,
                    [action.methodologyId]: action.storageInfo,
                },
            };
        default:
            return state;
    }
};

export default methodologiesReducer;
