import { ReportParams } from 'entities/reports/Report';
import ReportProgress from './ReportProgress';
import actionTypes from './reportsProgress.actionTypes';

export const reportProgressDataReceived = ({
    reportProgressData,
}: {
    reportProgressData: ReportProgress;
}) => ({
    type: actionTypes.REPORT_PROGRESS_DATA_RECEIVED,
    reportProgressData,
});

export const reportProgressDataRequest = ({ eventId, teamId, organizationId }: ReportParams) => ({
    type: actionTypes.REPORT_PROGRESS_DATA_REQUEST,
    eventId,
    teamId,
    organizationId,
});
