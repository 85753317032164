import { createSelector } from 'reselect';
import _groupBy from 'lodash/groupBy';
import * as drillsSelectors from 'entities/drills/drills.selectors';
import { AccessTypes } from 'entities/access/Access';
import Drill, { DrillType, drillTypesList } from 'entities/drills/Drill';
import { UserAccessRecord } from 'entities/users/User';
import { userAccessRecordsSelector } from 'entities/access/access.selectors';

export const drillsGroupedByTypeListSelector = createSelector(
    drillsSelectors.drillsListSelector,
    (_: any, props: { filterText: string }) => props.filterText,
    (_: any, props: { type: 'all' | 'master' | 'variations' | 'standalone' }) => props.type,
    (drillsList, filterText = '', type) => {
        let finalDrillsList = [...drillsList];

        if (type === 'variations') {
            finalDrillsList = finalDrillsList.filter((drill) => Boolean(drill.masterDrillId));
        }

        if (type === 'master') {
            finalDrillsList = finalDrillsList.filter((drill) => !drill.masterDrillId);
        }

        if (type === 'standalone') {
            finalDrillsList = finalDrillsList.filter(
                (drill) => !drill.masterDrillId && drill.variations.length === 0,
            );
        }

        const filteredDrills =
            filterText.length > 1
                ? finalDrillsList.filter(({ name }) => name.toLowerCase().includes(filterText))
                : finalDrillsList;
        const drillsMap = _groupBy(filteredDrills, (drillData) => drillData.drillType);

        const result = Object.entries(drillsMap).map(([drillType, drills]) => {
            return {
                drillType: drillType as DrillType,
                drills,
            };
        });

        result.sort((a, b) => {
            const aIndex = drillTypesList.indexOf(a.drillType);
            const bIndex = drillTypesList.indexOf(b.drillType);
            return aIndex > bIndex ? 1 : -1;
        });

        return result;
    },
);

export const drillsListByTypeSelector = createSelector(
    drillsSelectors.drillsListSelector,
    (_: any, props: any) => props.type,
    (drillsList: Drill[], type) => {
        if (!type) {
            return [];
        }

        return drillsList.filter((drill) => drill.drillType === type);
    },
);

export const availableMethodologiesSelector = createSelector(
    userAccessRecordsSelector,
    (accessList: UserAccessRecord[]) => {
        const methodologiesAccesses = accessList.filter(
            (access) => access.accessType === AccessTypes.methodology,
        );
        return methodologiesAccesses;
    },
);
