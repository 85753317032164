import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const TeamIcon = (props: any) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <g>
            <title>Team</title>
            <path d="M12.5 10C12.5 8.35001 11.15 7.00001 9.49999 7.00001C7.84999 7.00001 6.49999 8.35001 6.49999 10C6.49999 11.65 7.84999 13 9.49999 13C11.15 13 12.5 11.65 12.5 10ZM9.49999 11C8.94999 11 8.49999 10.55 8.49999 10C8.49999 9.45001 8.94999 9.00001 9.49999 9.00001C10.05 9.00001 10.5 9.45001 10.5 10C10.5 10.55 10.05 11 9.49999 11ZM16 13C17.11 13 18 12.11 18 11C18 9.89001 17.11 9.00001 16 9.00001C14.89 9.00001 13.99 9.89001 14 11C14 12.11 14.89 13 16 13ZM11.99 2.01001C6.46999 2.01001 1.98999 6.49001 1.98999 12.01C1.98999 17.53 6.46999 22.01 11.99 22.01C17.51 22.01 21.99 17.53 21.99 12.01C21.99 6.49001 17.51 2.01001 11.99 2.01001ZM5.83999 17.12C6.51999 16.58 8.10999 16.01 9.49999 16.01C9.56999 16.01 9.64999 16.02 9.72999 16.02C9.96999 15.38 10.4 14.73 11.03 14.16C10.47 14.06 9.93999 14 9.49999 14C8.19999 14 6.10999 14.45 4.76999 15.43C4.26999 14.39 3.98999 13.23 3.98999 12C3.98999 7.59001 7.57999 4.00001 11.99 4.00001C16.4 4.00001 19.99 7.59001 19.99 12C19.99 13.2 19.72 14.34 19.24 15.37C18.24 14.78 16.88 14.5 16 14.5C14.48 14.5 11.5 15.31 11.5 17.2V19.98C9.22999 19.85 7.20999 18.77 5.83999 17.12Z" />
        </g>
    </SvgIcon>
);

export default TeamIcon;
