import React from 'react';
import { Box } from 'common/components';
import SectionTitle from './SectionTitle';

export type StatsSectionProps = {
    children: any;
    IconComponent: any;
    title: string;
    sx?: any;
};

const StatsSection: React.FC<StatsSectionProps> = ({ title, children, IconComponent, sx }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 1,
                p: 1,
                backgroundColor: (theme) => theme.custom.background.light,
                ...sx,
            }}
        >
            <SectionTitle IconComponent={<IconComponent sx={{ color: '#2196F3' }} />}>
                {title}
            </SectionTitle>
            {children}
        </Box>
    );
};

export default StatsSection;
