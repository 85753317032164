import { takeLatest, all, call, put } from 'redux-saga/effects';

import { toastr } from 'react-redux-toastr';
import * as tasksGateway from 'gateways/tasksGateway';
import tasksActionTypes from './tasks.actionTypes';
import * as tasksActions from './tasks.actions';
import { PlayerTask, TeamTask } from './Task';

export function* fetchTeamTaskSaga({
    teamId,
    organizationId,
    taskId,
}: ReturnType<typeof tasksActions.teamTaskRequest>) {
    try {
        const teamTask: TeamTask = yield call([tasksGateway, tasksGateway.fetchTeamTask], {
            teamId,
            organizationId,
            taskId,
        });
        yield put(tasksActions.teamTasksListReceived({ tasksList: [teamTask] }));
    } catch (e) {
        yield call(
            toastr.error,
            'Failed to get team task',
            'Refresh browser tab and try again. Contact support team if you continue experiencing the issue',
        );
    }
}

export function* fetchPlayerTaskSaga({
    playerId,
    taskId,
}: ReturnType<typeof tasksActions.playerTaskRequest>) {
    try {
        const playerTask: PlayerTask = yield call([tasksGateway, tasksGateway.fetchPlayerTask], {
            playerId,
            taskId,
        });
        yield put(tasksActions.playerTasksListReceived({ tasksList: [playerTask] }));
    } catch (e) {
        yield call(
            toastr.error,
            'Failed to get player task',
            'Refresh browser tab and try again. Contact support team if you continue experiencing the issue',
        );
    }
}

export function* fetchTeamTasksListSaga({
    teamId,
    organizationId,
}: ReturnType<typeof tasksActions.teamTasksListRequest>) {
    try {
        const tasksList: TeamTask[] = yield call(tasksGateway.fetchTeamTasksList, {
            teamId,
            organizationId,
        });
        if (tasksList) {
            yield put(tasksActions.teamTasksListReceived({ tasksList }));
        }
    } catch (e) {
        yield call(
            toastr.error,
            'Failed to get team tasks',
            'Refresh browser tab and try again. Contact support team if you continue experiencing the issue',
        );
    }
}

export function* fetchPlayerTasksListSaga({
    playerId,
}: ReturnType<typeof tasksActions.playerTasksListRequest>) {
    try {
        const tasksList: PlayerTask[] = yield call(tasksGateway.fetchPlayerTasksList, {
            playerId,
        });
        if (tasksList) {
            yield put(tasksActions.playerTasksListReceived({ tasksList }));
        }
    } catch (e) {
        yield call(
            toastr.error,
            'Failed to get team tasks',
            'Refresh browser tab and try again. Contact support team if you continue experiencing the issue',
        );
    }
}

export function* createTeamTaskSaga({
    taskData,
    teamId,
    organizationId,
}: ReturnType<typeof tasksActions.createTeamTask>) {
    try {
        const taskId: string = yield call([tasksGateway, tasksGateway.createTeamTask], {
            organizationId,
            teamId,
            taskData,
        });
        yield put(tasksActions.teamTaskRequest({ taskId, organizationId, teamId }));
    } catch (e) {
        yield call(
            toastr.error,
            'Failed to get player tasks',
            'Refresh browser tab and try again. Contact support team if you continue experiencing the issue',
        );
    }
}

export function* deleteTeamTaskSaga({
    taskId,
    teamId,
    organizationId,
}: ReturnType<typeof tasksActions.deleteTeamTask>) {
    try {
        yield call([tasksGateway, tasksGateway.deleteTeamTask], {
            organizationId,
            teamId,
            taskId,
        });
        yield put(tasksActions.removeTeamTask({ taskId }));
    } catch (e) {
        yield call(
            toastr.error,
            'Failed to delete team tasks',
            'Refresh browser tab and try again. Contact support team if you continue experiencing the issue',
        );
    }
}

export function* updatePlayerTaskSaga({
    taskId,
    playerId,
    playerTaskData,
}: ReturnType<typeof tasksActions.updatePlayerTask>) {
    try {
        yield call([tasksGateway, tasksGateway.updatePlayerTask], {
            playerId,
            taskId,
            playerTaskData,
        });
        yield put(tasksActions.playerTaskRequest({ taskId, playerId }));
    } catch (e) {
        yield call(
            toastr.error,
            'Failed to update player tasks',
            'Refresh browser tab and try again. Contact support team if you continue experiencing the issue',
        );
    }
}

function* tasksSaga() {
    yield all([
        takeLatest(tasksActionTypes.TEAM_TASKS_LIST_REQUEST, fetchTeamTasksListSaga),
        takeLatest(tasksActionTypes.PLAYER_TASKS_LIST_REQUEST, fetchPlayerTasksListSaga),
        takeLatest(tasksActionTypes.CREATE_TEAM_TASK, createTeamTaskSaga),
        takeLatest(tasksActionTypes.TEAM_TASK_REQUEST, fetchTeamTaskSaga),
        takeLatest(tasksActionTypes.PLAYER_TASK_REQUEST, fetchPlayerTaskSaga),
        takeLatest(tasksActionTypes.UPDATE_PLAYER_TASK, updatePlayerTaskSaga),
        takeLatest(tasksActionTypes.DELETE_TEAM_TASK, deleteTeamTaskSaga),
    ]);
}

export default tasksSaga;
