import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SkillsIcon = (props: any) => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g>
            <title>Skills</title>
            <path d="M10 8H8V12H4V14H8V18H10V14H14V12H10V8ZM14.5 6.08V7.9L17 7.4V18H19V5L14.5 6.08Z" />
        </g>
    </SvgIcon>
);

export default SkillsIcon;
