export default {
    ADD_EVENT_FITNESS_RECORDS: 'ENTITIES/FITNESS_DATA/ADD_EVENT_FITNESS_RECORDS',
    EVENT_FITNESS_RECORDS_DELETE_REQUEST:
        'ENTITIES/FITNESS_DATA/EVENT_FITNESS_RECORDS_DELETE_REQUEST',
    TEAM_FITNESS_RECORDS_LIST_REQUEST: 'ENTITIES/FITNESS_DATA/TEAM_FITNESS_RECORDS_LIST_REQUEST',
    REMOVE_EVENT_FITNESS_RECORDS: 'ENTITIES/FITNESS_DATA/REMOVE_EVENT_FITNESS_RECORDS',
    EVENT_FITNESS_RECORDS_REQUEST: 'ENTITIES/FITNESS_DATA/EVENT_FITNESS_RECORDS_REQUEST',
    EVENT_FITNESS_RECORDS_RECEIVED: 'ENTITIES/FITNESS_DATA/EVENT_FITNESS_RECORDS_RECEIVED',
    EVENT_FITNESS_RECORDS_UPDATE: 'ENTITIES/FITNESS_DATA/EVENT_FITNESS_RECORDS_UPDATE',
    PLAYER_FITNESS_RECORDS_LIST_REQUEST: 'ENTITIES/FITNESS_DATA/PLAYER_FITNESS_RECORDS_REQUEST',
    FITNESS_RECORDS_LIST_RECEIVED: 'ENTITIES/FITNESS_DATA/PLAYER_FITNESS_RECORDS_RECEIVED',
    ORG_FITNESS_DATA_METADATA_REQUEST: 'ENTITIES/FITNESS_DATA/ORG_FITNESS_DATA_METADATA_REQUEST',
    ORG_FITNESS_DATA_METADATA_RECEIVED: 'ENTITIES/FITNESS_DATA/ORG_FITNESS_DATA_METADATA_RECEIVED',
    ORG_FITNESS_DATA_METADATA_UPDATE: 'ENTITIES/FITNESS_DATA/ORG_FITNESS_DATA_METADATA_UPDATE',
};
