import _get from 'lodash/get';
import { createSelector } from 'reselect';
import Report, { Team, Player, PlayerNumbersMap, ReportStatus } from './Report';
import { RootState } from '../../store';
import { isBetween } from 'common/utils/dateTimeHelpers';

export const reportsHashSelector = (state: RootState) => state.entities.reports.reportsHash;

export const reportsListSelector = createSelector(
    reportsHashSelector,
    (_: any, { teamId }: { teamId: string }) => teamId,
    // (_: any, { organizationId }: { organizationId: string }) => organizationId,
    (_: any, { fromDate }: { fromDate: Date }) => fromDate,
    (_: any, { toDate }: { toDate: Date }) => toDate,
    (reportsHash, teamId, fromDate, toDate) => {
        const reportsList: Report[] = Object.values(reportsHash);
        let eventReportsList = reportsList.filter(
            (reportData) =>
                reportData.teamId === teamId &&
                reportData.status !== ReportStatus.removed &&
                // reportData.organizationId === organizationId &&
                isBetween(reportData.dateCreated, fromDate, toDate),
        );
        eventReportsList.sort((a: Report, b: Report) => {
            if (!a.dateCreated) {
                return 1;
            }
            if (!b.dateCreated) {
                return -1;
            }

            return b.dateCreated > a.dateCreated ? 1 : -1;
        });

        return eventReportsList;
    },
);

export const reportDataSelector = createSelector(
    reportsHashSelector,
    (_: any, { eventId }: { eventId: string }) => eventId,
    (reportsHash, eventId): Report | undefined => _get(reportsHash, eventId),
);

export const numbersMapSelector = createSelector(reportDataSelector, (reportData) => {
    if (!reportData) {
        return {};
    }
    const { teams } = reportData;
    const playersList = teams.reduce((acc: Player[], team: Team) => {
        return acc.concat(team.players);
    }, []);
    const playerShirtNumbers: PlayerNumbersMap = playersList.reduce(
        (acc: PlayerNumbersMap, player: Player) => {
            return { ...acc, [player.matchNumber || '']: player.playerNumber };
        },
        {},
    );

    return playerShirtNumbers;
});
