import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import actionTypes from './storageFiles.actionTypes';
import StorageFile from './StorageFile';

type State = {
    storageFilesHash: {
        [key: string]: StorageFile;
    };
};

const initialState: State = {
    storageFilesHash: {},
};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.STORAGE_FILE_DATA_RECEIVED: {
            return {
                ...state,
                storageFilesHash: {
                    ...state.storageFilesHash,
                    [action.storageFile.id]: action.storageFile,
                },
            };
        }
        case actionTypes.STORAGE_FILES_LIST_RECEIVED: {
            return {
                ...state,
                storageFilesHash: {
                    ...state.storageFilesHash,
                    ..._keyBy(action.storageFiles, 'id'),
                },
            };
        }
        case actionTypes.REMOVE_STORAGE_FILE_FROM_STATE: {
            return {
                ...state,
                storageFilesHash: { ..._omit(state.storageFilesHash, action.storageFileId) },
            };
        }
        default:
            return state;
    }
};
