import { db, functions } from 'common/firebase';
import { ReportParams } from 'entities/reports/Report';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { handleDocumentSnapshot } from './utils';

export const fetchReportProgressById = ({ organizationId, teamId, eventId }: ReportParams) => {
    const reportProgressRef = doc(
        db,
        `organizations/${organizationId}/teams/${teamId}/reports/${eventId}/info/progress`,
    );

    return getDoc(reportProgressRef)
        .then(handleDocumentSnapshot)
        .then((reportProgress) => {
            return {
                id: eventId,
                ...reportProgress,
            };
        });
};

const processReportFunction = httpsCallable(functions, 'processReport');
export const processReport = ({ organizationId, teamId, eventId }: ReportParams) => {
    return processReportFunction({
        organizationId,
        teamId,
        reportId: eventId,
    });
};
