import Event, { EventData } from './Event';
import { GameUpdate } from './GameUpdates';
import eventsActionTypes from './events.actionTypes';

export const eventsListRequest = ({
    from,
    to,
    teamId,
    organizationId,
}: {
    from: Date;
    to: Date;
    teamId: string;
    organizationId: string;
}) => ({
    type: eventsActionTypes.EVENTS_LIST_REQUEST,
    organizationId,
    teamId,
    from,
    to,
});

export const planificationEventsRequest = ({
    from,
    to,
    teamId,
    organizationId,
}: {
    from: Date;
    to: Date;
    teamId: string;
    organizationId: string;
}) => ({
    type: eventsActionTypes.PLANIFICATION_EVENTS_LIST_REQUEST,
    organizationId,
    teamId,
    from,
    to,
});

export const eventsListReceived = (events: Event[]) => ({
    type: eventsActionTypes.EVENTS_LIST_RECEIVED,
    events,
});

export const eventDataRequest = ({
    eventId,
    teamId,
    organizationId,
}: {
    eventId: string;
    teamId: string;
    organizationId: string;
}) => ({
    type: eventsActionTypes.EVENT_DATA_REQUEST,
    eventId,
    teamId,
    organizationId,
});

export const eventDataReceived = (eventData: Event) => ({
    type: eventsActionTypes.EVENT_DATA_RECEIVED,
    eventData,
});

export const removeEventsRequest = ({
    eventsIds,
    teamId,
    organizationId,
}: {
    eventsIds: string[];
    teamId: string;
    organizationId: string;
}) => ({
    type: eventsActionTypes.REMOVE_EVENTS_REQUEST,
    eventsIds,
    teamId,
    organizationId,
});

export const eventsRemoved = ({ eventsIds }: { eventsIds: string[] }) => ({
    type: eventsActionTypes.REMOVE_EVENTS_FROM_LIST,
    eventsIds,
});

export const createEventRequest = ({
    eventData,
    teamId,
    organizationId,
}: {
    eventData: EventData;
    teamId: string;
    organizationId: string;
}) => ({
    type: eventsActionTypes.CREATE_EVENT_REQUEST,
    eventData,
    teamId,
    organizationId,
});

export const updateEventRequest = ({
    eventData,
    eventId,
    teamId,
    organizationId,
}: {
    eventData: EventData;
    eventId: string;
    teamId: string;
    organizationId: string;
}) => ({
    type: eventsActionTypes.UPDATE_EVENT_REQUEST,
    eventData,
    eventId,
    teamId,
    organizationId,
});

export const requestEventUpdatesList = ({
    organizationId,
    teamId,
    eventId,
}: {
    organizationId: string;
    teamId: string;
    eventId: string;
}) => ({
    type: eventsActionTypes.EVENT_UPDATES_LIST_REQUEST,
    organizationId,
    teamId,
    eventId,
});

export const eventUpdatesListReceived = ({
    eventUpdatesList,
}: {
    eventUpdatesList: GameUpdate[];
}) => ({
    type: eventsActionTypes.EVENT_UPDATES_LIST_RECEIVED,
    eventUpdatesList,
});
