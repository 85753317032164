import { AppConfig, LocalConfig } from './Config';
import configActionTypes from './config.actionTypes';

export const setOffline = () => ({
    type: configActionTypes.SET_OFFLINE,
});

export const setOnline = () => ({
    type: configActionTypes.SET_ONLINE,
});

export const requestAppConfig = () => ({
    type: configActionTypes.APP_CONFIG_REQUEST,
});

export const togglePlayersVisibility = (isHidden: boolean) => ({
    type: configActionTypes.TOGGLE_PLAYERS_VISIBILITY,
    isHidden,
});

export const appConfigReceived = ({ appConfig }: { appConfig: AppConfig }) => ({
    type: configActionTypes.APP_CONFIG_RECEIVED,
    appConfig,
});

export const setLocalConfig = ({ localConfig }: { localConfig: LocalConfig }) => ({
    type: configActionTypes.SET_LOCAL_CONFIG,
    localConfig,
});

export const saveLocalConfig = ({ localConfig }: { localConfig: LocalConfig }) => ({
    type: configActionTypes.SAVE_LOCAL_CONFIG,
    localConfig,
});

export const applyLocalConfig = () => ({
    type: configActionTypes.APPLY_LOCAL_CONFIG,
});
