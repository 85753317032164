import Goal, { GoalData } from 'entities/goals/Goal';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { db } from 'common/firebase';
import { handleDocumentSnapshot, handleCollectionSnapshot } from './utils';

export const createGoal = ({ playerId, goalData }: { playerId: string; goalData: GoalData }) => {
    const goalsRef = collection(db, `players/${playerId}/goals`);

    return addDoc(goalsRef, goalData).then((doc) => doc.id);
};

export const updateGoal = ({
    playerId,
    goalId,
    goalData,
}: {
    playerId: string;
    goalId: string;
    goalData: GoalData;
}) => {
    const goalRef = doc(db, `players/${playerId}/goals/${goalId}`);

    return updateDoc(goalRef, goalData);
};

export const fetchPlayerGoals = ({ playerId }: { playerId: string }) => {
    const goalsRef = collection(db, `players/${playerId}/goals`);

    return getDocs(goalsRef)
        .then(handleCollectionSnapshot)
        .then((goalsList) => goalsList.map((goalData: Goal) => ({ ...goalData, playerId })));
};

export const fetchGoal = ({ playerId, goalId }: { playerId: string; goalId: string }) => {
    const goalRef = doc(db, `players/${playerId}/goals/${goalId}`);

    return getDoc(goalRef)
        .then(handleDocumentSnapshot)
        .then((goalData) => ({ ...goalData, playerId }));
};

export const deleteGoal = ({ playerId, goalId }: { playerId: string; goalId: string }) => {
    const goalRef = doc(db, `players/${playerId}/goals/${goalId}`);

    return deleteDoc(goalRef);
};
