export default {
    CREATE_INVITATION: 'ENTITIES/INVITATIONS/CREATE_INVITATION',
    REQUEST_INVITATIONS_LIST: 'ENTITIES/INVITATIONS/REQUEST_INVITATIONS_LIST',
    INVITATIONS_LIST_RECEIVED: 'ENTITIES/INVITATIONS/INVITATIONS_LIST_RECEIVED',
    DELETE_INVITATION: 'ENTITIES/INVITATIONS/DELETE_INVITATION',
    REMOVE_INVITATION_FROM_LIST: 'ENTITIES/INVITATIONS/REMOVE_INVITATION_FROM_LIST',
    UPDATE_INVITATION: 'ENTITIES/INVITATIONS/UPDATE_INVITATION',
    REQUEST_INVITATION_DATA: 'ENTITIES/INVITATIONS/REQUEST_INVITATION_DATA',
    INVITATION_DATA_RECEIVED: 'ENTITIES/INVITATIONS/INVITATION_DATA_RECEIVED',
    SET_PLAYER_TO_LINK: 'ENTITIES/INVITATIONS/SET_PLAYER_TO_LINK',
    FETCH_USER_INVITATIONS: 'ENTITIES/INVITATIONS/FETCH_USER_INVITATIONS',
};
