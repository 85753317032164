import _set from 'lodash/set';
import _omit from 'lodash/omit';
import _keyBy from 'lodash/keyBy';
import actionTypes from './drills.actionTypes';

const initialState = {
    drillsHash: {},
};

const drills = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.DRILL_DATA_RECEIVED:
            return {
                ...state,
                drillsHash: {
                    ..._set(state.drillsHash, action.drillData.id, action.drillData),
                },
            };
        case actionTypes.DRILLS_LIST_RECEIVED:
            return {
                ...state,
                drillsHash: {
                    ...state.drillsHash,
                    ..._keyBy(action.drillsList, 'id'),
                },
            };
        case actionTypes.REMOVE_DRILL:
            return {
                ...state,
                drillsHash: { ..._omit(state.drillsHash, action.drillId) },
            };
        default:
            return state;
    }
};

export default drills;
