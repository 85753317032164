import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as reportsGateway from 'gateways/reportsProgressGateway';
import reportsActionTypes from './reportsProgress.actionTypes';
import * as reportsActions from './reportsProgress.actions';
import ReportProgress from './ReportProgress';

export function* fetchReportProgressDataSaga({
    organizationId,
    teamId,
    eventId,
}: ReturnType<typeof reportsActions.reportProgressDataRequest>) {
    try {
        const reportProgressData: ReportProgress = yield call(
            reportsGateway.fetchReportProgressById,
            {
                organizationId,
                teamId,
                eventId,
            },
        );
        if (reportProgressData) {
            yield put(reportsActions.reportProgressDataReceived({ reportProgressData }));
        }
    } catch (e) {
        console.error({ _error: `Failed to fetch report for event ${eventId}` }, e);
    }
}

function* reportsProgressSaga() {
    yield all([
        takeLatest(reportsActionTypes.REPORT_PROGRESS_DATA_REQUEST, fetchReportProgressDataSaga),
    ]);
}

export default reportsProgressSaga;
