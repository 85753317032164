import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
// import { useTranslation } from 'react-i18next';
import { PlayerData, Typography } from 'common/components';
import Player from 'entities/players/Player';
import { PlayerStats } from 'entities/players/players.utils';

const Cell = styled(Typography)(({ theme }) => ({
    ...theme.typography.body2,
    flexShrink: 0,
    width: 36,
    padding: theme.spacing(0, 0.25),
    borderRadius: theme.shape.borderRadius,
    lineHeight: '40px',
    textAlign: 'center',
    margin: 2,
}));

const ListRow = styled('div')(() => ({
    display: 'flex',
}));

const PlayerInfo = styled(PlayerData)(({ theme }) => ({
    width: 160,
    padding: theme.spacing(0, 0.25),
}));

type PlayerStatsRowProps = {
    player: Player;
    stats?: PlayerStats;
    getColorFunc: (attr: string) => (val: number) => string;
};

const PlayerStatsRow: React.FC<PlayerStatsRowProps> = ({ player, stats, getColorFunc }) => {
    // const { t } = useTranslation();

    const getCellColor = useCallback(
        (key: string, val: number | null) => {
            const colorFunc = getColorFunc(key);

            if (val === null) {
                return undefined;
            }

            return colorFunc(val);
        },
        [getColorFunc],
    );

    if (!stats) {
        return null;
    }

    const {
        // playerId,
        attendance,
        totalSessionsTime,
        sessionsPresent,
        sessionsSkiped,
        individualSessionsCount,
        goals,
        yellowCards,
        redCards,
        gamesTime,
        subIn,
        lineup,
        newGoals,
        activeGoals,
        // avgLoad,
        // avgWellness,
        avgEvaluation,
        newInjuries,
        healedInjuries,
        activeInjuries,
    } = stats;

    return (
        <ListRow>
            <Cell key="player" sx={{ width: 160 }}>
                <PlayerInfo playerData={player} />
            </Cell>
            <Cell
                key="attendance"
                sx={{
                    backgroundColor: getCellColor('attendance', stats.attendance),
                }}
            >
                {attendance}
            </Cell>
            <Cell
                key="avgEvaluation"
                sx={{
                    backgroundColor: getCellColor('avgEvaluation', stats.avgEvaluation),
                }}
            >
                {avgEvaluation}
            </Cell>
            <Cell
                key="totalSessionsTime"
                sx={{
                    backgroundColor: getCellColor('totalSessionsTime', stats.totalSessionsTime),
                }}
            >
                {totalSessionsTime}
            </Cell>
            <Cell
                key="sessionsPresent"
                sx={{
                    backgroundColor: getCellColor('sessionsPresent', stats.sessionsPresent),
                }}
            >
                {sessionsPresent}
            </Cell>
            <Cell
                key="sessionsSkiped"
                sx={{
                    backgroundColor: getCellColor('sessionsSkiped', stats.sessionsSkiped),
                }}
            >
                {sessionsSkiped}
            </Cell>
            <Cell
                key="individualSessionsCount"
                sx={{
                    backgroundColor: getCellColor(
                        'individualSessionsCount',
                        stats.individualSessionsCount,
                    ),
                }}
            >
                {individualSessionsCount}
            </Cell>
            <Cell
                key="gamesTime"
                sx={{
                    backgroundColor: getCellColor('gamesTime', stats.gamesTime),
                }}
            >
                {gamesTime}
            </Cell>
            <Cell
                key="lineup"
                sx={{
                    backgroundColor: getCellColor('lineup', stats.lineup),
                }}
            >
                {lineup}
            </Cell>
            <Cell
                key="subIn"
                sx={{
                    backgroundColor: getCellColor('subIn', stats.subIn),
                }}
            >
                {subIn}
            </Cell>
            <Cell
                key="goals"
                sx={{
                    backgroundColor: getCellColor('goals', stats.goals),
                }}
            >
                {goals}
            </Cell>
            <Cell
                key="cards"
                sx={{
                    backgroundColor: getCellColor('cards', yellowCards + redCards),
                }}
            >
                {yellowCards + redCards}
            </Cell>
            <Cell
                key="newInjuries"
                sx={{
                    backgroundColor: getCellColor('newInjuries', stats.newInjuries),
                }}
            >
                {newInjuries}
            </Cell>
            <Cell
                key="healedInjuries"
                sx={{
                    backgroundColor: getCellColor('healedInjuries', stats.healedInjuries),
                }}
            >
                {healedInjuries}
            </Cell>
            <Cell
                key="activeInjuries"
                sx={{
                    backgroundColor: getCellColor('activeInjuries', stats.activeInjuries),
                }}
            >
                {activeInjuries}
            </Cell>
            <Cell
                key="newGoals"
                sx={{
                    backgroundColor: getCellColor('newGoals', stats.newGoals),
                }}
            >
                {newGoals}
            </Cell>
            <Cell
                key="activeGoals"
                sx={{
                    backgroundColor: getCellColor('activeGoals', stats.activeGoals),
                }}
            >
                {activeGoals}
            </Cell>
            {/* <Cell key="avgLoad">{avgLoad}</Cell>
            <Cell key="avgWellness">{avgWellness}</Cell> */}
        </ListRow>
    );
};
export default PlayerStatsRow;
