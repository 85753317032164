import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, startOfISOWeek } from 'date-fns';
import { styled } from '@mui/material';
import { AnalyticsIcon } from 'common/icons';
import TeamStats from 'entities/teams/TeamStats';
import { getTeamStats } from 'gateways/teamsGateway';
import { LoadingLine, Typography } from 'common/components';
import PeriodSelector from 'common/components/PeriodSelector';
import { StyledSidebarButton } from 'features/assistant/AssistantButton';
import SlideDialogWithCloseBtn, { DialogHeader } from 'common/components/SlideDialogWithCloseBtn';
import TeamInfo from './TeamInfo';
import TeamStatsView from './TeamStatsView';

type State =
    | {
          type: 'closed';
      }
    | {
          type: 'loading';
      }
    | {
          type: 'loaded';
          teamStats: TeamStats;
      }
    | {
          type: 'error';
      };

const StyledDialogHeader = styled(DialogHeader)(({ theme }) => ({
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
    },
}));

export type TeamStatsViewProps = {
    teamId: string;
    organizationId: string;
    isExpanded: boolean;
    sx?: any;
    hidden?: boolean;
    className?: string;
};

const TeamStatsBtn: React.FC<TeamStatsViewProps> = ({
    isExpanded,
    teamId,
    organizationId,
    hidden,
    className,
}) => {
    const { t } = useTranslation();
    const [state, setState] = useState<State>({ type: 'closed' });

    const handleLoad = useCallback(
        (fromDate: Date, toDate: Date) => {
            setState({ type: 'loading' });
            getTeamStats({
                organizationId,
                teamId,
                fromDate: fromDate.toISOString(),
                toDate: toDate.toISOString(),
            })
                .then((data) => {
                    setState({ type: 'loaded', teamStats: data });
                })
                .catch(() => {
                    setState({ type: 'error' });
                });
        },
        [organizationId, teamId],
    );

    const handleOpen = useCallback(() => {
        handleLoad(startOfISOWeek(new Date()), new Date());
    }, [handleLoad]);

    if (hidden) {
        return null;
    }

    const statsPeriod =
        state.type === 'loaded'
            ? `${format(state.teamStats.start, 'dd MMM')} - ${format(
                  state.teamStats.end,
                  'dd MMM',
              )}`
            : '';

    return (
        <>
            <StyledSidebarButton
                className={className}
                sx={{ width: isExpanded ? '100%' : 'fit-content' }}
                onClick={handleOpen}
            >
                <AnalyticsIcon />
                {isExpanded && (
                    <Typography variant="body1" sx={{ ml: 3 }} noWrap>
                        {t('sidebar.teamStats')}
                    </Typography>
                )}
            </StyledSidebarButton>
            <SlideDialogWithCloseBtn
                noPaddings
                isOpen={state.type !== 'closed'}
                onClose={() => setState({ type: 'closed' })}
                sx={{ maxWidth: 900, minWidth: 300 }}
            >
                <StyledDialogHeader>
                    <TeamInfo teamId={teamId} organizationId={organizationId} sx={{ mr: 1 }} />
                    <Typography sx={{ mx: 'auto', my: { xs: 1, sm: 0 } }}>{statsPeriod}</Typography>
                    <PeriodSelector onSelect={handleLoad} />
                </StyledDialogHeader>
                {state.type === 'loading' && <LoadingLine showText />}
                {state.type === 'loaded' && (
                    <TeamStatsView
                        teamStats={state.teamStats}
                        teamId={teamId}
                        organizationId={organizationId}
                    />
                )}
            </SlideDialogWithCloseBtn>
        </>
    );
};

export default TeamStatsBtn;
