import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { Typography, Tooltip } from 'common/components';

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',

    '&:hover': {
        color: 'rgba(0, 0, 0, 1)',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },

    [theme.breakpoints.up('sm')]: {
        width: '100%',
    },
}));

type TeamSectionWidgetProps = {
    isExpanded: boolean;
    title: string;
    hidden: boolean;
    path: string;
    IconComponent: React.FC;
    className?: string;
    isActive: boolean;
    onClose: VoidFunction;
};

const TeamSectionWidget: React.FC<TeamSectionWidgetProps> = ({
    isExpanded,
    title,
    path,
    hidden,
    IconComponent,
    className,
    isActive,
    onClose,
}) => {
    const theme = useTheme();
    if (hidden) {
        return null;
    }

    return (
        <Tooltip title={title}>
            <StyledNavLink
                sx={{
                    color: isActive ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.54)',
                    width: isExpanded ? '100%' : 'fit-content',
                }}
                className={className}
                to={path}
                onClick={onClose}
            >
                <IconComponent />
                {isExpanded && (
                    <Typography variant="body1" sx={{ ml: 3 }} noWrap>
                        {title}
                    </Typography>
                )}
            </StyledNavLink>
        </Tooltip>
    );
};

export default TeamSectionWidget;
