import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import {
    CheckCircleEmptyIcon,
    CheckCircleFilledIcon,
    CheckBoxEmptyIcon,
    CheckBoxFilledIcon,
} from 'common/icons';

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    marginLeft: 0,
    alignItems: 'flex-start',

    [`.MuiCheckbox-root`]: {
        padding: theme.spacing(0, 0.5, 0, 0),
    },

    [`.MuiFormControlLabel-label`]: {
        marginTop: 2,
    },

    [`&.MuiFormControlLabel-root`]: {
        marginRight: 4,
    },
}));

type CheckCircleProps = Pick<
    FormControlLabelProps,
    'checked' | 'disabled' | 'labelPlacement' | 'name' | 'id'
> & {
    label?: string | null;
    value?: boolean;
    className?: string;
    stroke?: string;
    fill?: string;
    circle?: boolean;
    sx?: any;
    onChange?: (value: boolean) => void;
};

const LabeledCheckbox: React.FC<CheckCircleProps> = ({
    onChange,
    value,
    label = '',
    stroke,
    fill,
    circle,
    sx,
    ...props
}) => {
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
                onChange(event.target.checked);
            }
        },
        [onChange],
    );

    const EmptyIcon = circle ? CheckCircleEmptyIcon : CheckBoxEmptyIcon;
    const FilledIcon = circle ? CheckCircleFilledIcon : CheckBoxFilledIcon;

    return (
        <StyledFormControlLabel
            label={label}
            sx={sx}
            {...props}
            control={
                <MuiCheckbox
                    checked={value}
                    onChange={handleChange}
                    icon={<EmptyIcon stroke={stroke} />}
                    checkedIcon={<FilledIcon stroke={stroke} fill={fill} />}
                />
            }
        />
    );
};

export default LabeledCheckbox;
