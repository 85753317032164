export const isOrgPage = (path: string = '') => {
    return /^\/organizations\/[a-zA-Z0-9]+$/g.test(path);
};

export const isPlayersListPage = (path: string = '') => {
    return /^\/organizations\/[a-zA-Z0-9]+\/teams\/[a-zA-Z0-9]+\/(players|goals)$/g.test(path);
};

export const isSessionPage = (path: string = '') => {
    return /^\/organizations\/[a-zA-Z0-9]+\/teams\/[a-zA-Z0-9]+\/planning\/sessions/g.test(path);
};

export const isGamePage = (path: string = '') => {
    return /^\/organizations\/[a-zA-Z0-9]+\/teams\/[a-zA-Z0-9]+\/planning\/games/g.test(path);
};

export const isConditionsPage = (path: string = '') => {
    return /^\/organizations\/[a-zA-Z0-9]+\/teams\/[a-zA-Z0-9]+\/conditions/g.test(path);
};

export const isSkillsPage = (path: string = '') => {
    return /^\/organizations\/[a-zA-Z0-9]+\/teams\/[a-zA-Z0-9]+\/players\/[a-zA-Z0-9]+\/skills$/g.test(
        path,
    );
};

export const isMethodologyPage = (path: string = '') => {
    return /^\/methodologies\/[a-zA-Z0-9]+$/g.test(path);
};

export const isPlanningPage = (path: string = '') => {
    return /^\/organizations\/[a-zA-Z0-9]+\/teams\/[a-zA-Z0-9]+\/(planning|calendar)\/\S+$/g.test(
        path,
    );
};
