import ScoutingList, { ListStatus, ScoutingListData } from 'entities/scouting-lists/ScoutingList';
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';
import { db, functions } from '../common/firebase';
import { handleDocumentSnapshot, handleCollectionSnapshot } from './utils';
import { httpsCallable } from 'firebase/functions';
import { mapListsUpdatesFromDatesStrings } from 'entities/scouting-lists/scoutingLists.utils';

export const getOrgScoutingListsPath = (organizationId: string) => {
    return `organizations/${organizationId}/lists`;
};

export const getScoutingListPath = (organizationId: string, listId: string) => {
    return `${getOrgScoutingListsPath(organizationId)}/${listId}`;
};

export const getScoutingListUpdatesPath = (organizationId: string, listId: string) => {
    return `${getScoutingListPath(organizationId, listId)}/updates`;
};

export const mapScoutingListData = (scoutingList: ScoutingList | null, organizationId: string) => {
    return scoutingList ? { ...scoutingList, organizationId } : null;
};

export const fetchScoutingList = ({
    listId,
    organizationId,
}: {
    organizationId: string;
    listId: string;
}) => {
    const docRef = doc(db, getScoutingListPath(organizationId, listId));
    return getDoc(docRef)
        .then(handleDocumentSnapshot)
        .then((scoutinglist) => mapScoutingListData(scoutinglist as ScoutingList, organizationId));
};

export const assignOrgId = (
    scoutingLists: ScoutingList[],
    organizationId: string,
): ScoutingList[] => {
    return scoutingLists
        .map((scoutinglist: ScoutingList) =>
            mapScoutingListData(scoutinglist as ScoutingList, organizationId),
        )
        .filter((scoutinglist) => scoutinglist !== null) as ScoutingList[];
};

export const fetchScoutingLists = ({
    organizationId,
    parentListId,
}: {
    organizationId: string;
    parentListId?: string;
}) => {
    const constraints = [where('status', '==', 'active')];

    if (parentListId) {
        constraints.push(where('parentListId', '==', parentListId));
    }
    const q = query(collection(db, getOrgScoutingListsPath(organizationId)), ...constraints);
    return getDocs(q)
        .then(handleCollectionSnapshot)
        .then((scoutingLists) => assignOrgId(scoutingLists, organizationId));
};

export const createScoutingList = ({
    organizationId,
    scoutingListData,
}: {
    organizationId: string;
    scoutingListData: ScoutingListData;
}) => {
    const scoutingListsRef = collection(db, getOrgScoutingListsPath(organizationId));

    return addDoc(scoutingListsRef, scoutingListData).then((doc) => doc.id);
};

export const updateScoutingList = ({
    listId,
    organizationId,
    scoutingListData,
}: {
    organizationId: string;
    listId: string;
    scoutingListData: ScoutingListData;
}) => {
    const scoutingListRef = doc(db, getScoutingListPath(organizationId, listId));

    return updateDoc(scoutingListRef, scoutingListData);
};

export const deleteScoutingList = ({
    listId,
    organizationId,
}: {
    organizationId: string;
    listId: string;
}) => {
    const scoutingListRef = doc(db, getScoutingListPath(organizationId, listId));

    return updateDoc(scoutingListRef, {
        status: ListStatus.deleted,
    });
};

export const notifyClientsForListPlayer = httpsCallable(functions, 'notifyClientsForListPlayer');

const fetchUserListsUpdatesByPeriodFunc = httpsCallable(functions, 'getUserListsUpdatesForPeriodV2');
export const fetchUserListsUpdatesByPeriod = ({
    fromDate,
    toDate,
    organizationId,
    userId,
}: {
    organizationId: string;
    userId: string;
    fromDate: Date;
    toDate: Date;
}) => {
    return fetchUserListsUpdatesByPeriodFunc({
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
        organizationId,
        userId,
    }).then((res) => (res.data as any[]).map(mapListsUpdatesFromDatesStrings));
};

export const getPlayerLonglistsIds = httpsCallable(functions, 'getPlayerLonglistsIds');

export const togglePlayerLonglist = httpsCallable(functions, 'togglePlayerLonglist');

export const fetchListUpdates = httpsCallable(functions, 'getListUpdatesV2');
