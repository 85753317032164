import { db, functions } from 'common/firebase';
import { PlayerTask, PlayerTaskData, TeamTask, TeamTaskData } from 'entities/tasks/Task';
import {
    addDoc,
    collection,
    getDoc,
    getDocs,
    query,
    where,
    doc,
    updateDoc,
    deleteDoc,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { handleCollectionSnapshot, handleDocumentSnapshot } from './utils';

const mapTeamTaskData = (teamTask: TeamTask, teamId: string) => {
    return teamTask ? { ...teamTask, teamId } : null;
};

const mapPlayerTaskData = (playerTask: PlayerTask, playerId: string) => {
    return playerTask ? { ...playerTask, playerId } : null;
};

export const fetchTeamTasksList = ({
    organizationId,
    teamId,
}: {
    organizationId: string;
    teamId: string;
}) => {
    const q = query(
        collection(db, `organizations/${organizationId}/teams/${teamId}/tasks`),
        where('destroyTime', '>=', new Date()),
    );

    return getDocs(q)
        .then(handleCollectionSnapshot)
        .then((tasksList) =>
            tasksList.map((teamTask: TeamTask) => mapTeamTaskData(teamTask, teamId)),
        );
};

export const fetchPlayerTasksList = ({ playerId }: { playerId: string }) => {
    const q = query(
        collection(db, `players/${playerId}/tasks`),
        where('destroyTime', '>=', new Date()),
    );

    return getDocs(q)
        .then(handleCollectionSnapshot)
        .then((tasksList) =>
            tasksList.map((teamTask: PlayerTask) => mapPlayerTaskData(teamTask, playerId)),
        );
};

export const createTeamTask = ({
    organizationId,
    teamId,
    taskData,
}: {
    organizationId: string;
    teamId: string;
    taskData: TeamTaskData;
}) => {
    const collectionRef = collection(db, `organizations/${organizationId}/teams/${teamId}/tasks`);

    return addDoc(collectionRef, taskData).then((doc) => doc.id);
};

export const fetchTeamTask = ({
    teamId,
    organizationId,
    taskId,
}: {
    organizationId: string;
    teamId: string;
    taskId: string;
}) => {
    const teamTaskRef = doc(db, `organizations/${organizationId}/teams/${teamId}/tasks/${taskId}`);

    return getDoc(teamTaskRef)
        .then(handleDocumentSnapshot)
        .then((teamTask) => ({ ...(teamTask as TeamTask), teamId }));
};

export const fetchPlayerTask = ({ playerId, taskId }: { playerId: string; taskId: string }) => {
    const playerTaskRef = doc(db, `players/${playerId}/tasks/${taskId}`);

    return getDoc(playerTaskRef)
        .then(handleDocumentSnapshot)
        .then((teamTask) => ({ ...(teamTask as PlayerTask), playerId }));
};

export const updatePlayerTask = ({
    taskId,
    playerId,
    playerTaskData,
}: {
    playerId: string;
    taskId: string;
    playerTaskData: PlayerTaskData;
}) => {
    const playerTaskRef = doc(db, `players/${playerId}/tasks/${taskId}`);

    return updateDoc(playerTaskRef, playerTaskData);
};

export const completePlayerTask = ({
    masterTaskId,
    playerId,
    organizationId,
    teamId,
    observationId,
}: {
    masterTaskId: string;
    organizationId: string;
    teamId: string;
    playerId: string;
    observationId: string | null;
}) => {
    return httpsCallable(
        functions,
        'completePlayerTask',
    )({
        masterTaskId,
        playerId,
        organizationId,
        teamId,
        observationId,
    });
};

export const deleteTeamTask = ({
    teamId,
    organizationId,
    taskId,
}: {
    organizationId: string;
    teamId: string;
    taskId: string;
}) => {
    const teamTaskRef = doc(db, `organizations/${organizationId}/teams/${teamId}/tasks/${taskId}`);

    return deleteDoc(teamTaskRef);
};
