import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { OrganizationIcon, SchoolIcon, TeamIcon, PlayerIcon, ListIcon } from 'common/icons';
import { Box } from 'common/components';
import { AccessTarget } from 'entities/access/Access';
import { organizationDataRequest } from 'entities/organizations/organizations.actions';
import CreateMethodologyBtn from 'features/methodology/CreateMethodologyBtn';
import AccessGroup from './AccessGroup';
import { useUserLinks } from './userLinks.utils';
import { useTranslation } from 'react-i18next';

type UserLinksProps = {
    sx?: any;
    hideIfEmpty?: boolean;
    onSelect?: VoidFunction;
};

const UserLinks: React.FC<UserLinksProps> = ({ sx, hideIfEmpty, onSelect }) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { organizationsList, teamsList, methodologiesList, playersList, longlistsList } =
        useUserLinks();
    const dispatch = useDispatch();

    useEffect(() => {
        organizationsList.forEach((record) => {
            dispatch(organizationDataRequest({ organizationId: record.target.organizationId! }));
        });
    }, [organizationsList.length]);

    const checkOrganizationMatch = useCallback(
        (target: AccessTarget) =>
            Boolean(target.organizationId && pathname.includes(target.organizationId)),
        [pathname],
    );

    const checkTeamMatch = useCallback(
        (target: AccessTarget) =>
            Boolean(
                target.teamId &&
                    pathname.includes(`${target.organizationId}/teams/${target.teamId}`),
            ),
        [pathname],
    );

    const checkPlayerMatch = useCallback(
        (target: AccessTarget) =>
            Boolean(target.playerId && pathname.includes(`players/${target.playerId}`)),
        [pathname],
    );

    const checkLonglistMatch = useCallback(
        (target: AccessTarget) =>
            Boolean(
                target.listId &&
                    pathname.includes(
                        `${target.organizationId}/scouting/longlists/${target.listId}`,
                    ),
            ),
        [pathname],
    );

    const checkMethodologyMatch = useCallback(
        (target: AccessTarget) => pathname.includes(`methodologies/${target.methodologyId}`),
        [pathname],
    );

    const isHidden =
        hideIfEmpty &&
        organizationsList.length === 0 &&
        teamsList.length === 0 &&
        methodologiesList.length === 0 &&
        playersList.length === 0 &&
        longlistsList.length === 0;

    if (isHidden) {
        return null;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
            <AccessGroup
                accessList={organizationsList}
                title={t('userLinks.clubs')}
                shouldHideIfEmpty
                IconComponent={OrganizationIcon}
                checkMatch={checkOrganizationMatch}
                onSelect={onSelect}
            />
            <AccessGroup
                accessList={teamsList}
                title={t('userLinks.teams')}
                shouldHideIfEmpty
                IconComponent={TeamIcon}
                checkMatch={checkTeamMatch}
                onSelect={onSelect}
            />
            <AccessGroup
                accessList={methodologiesList}
                title={t('userLinks.methodologies')}
                SideComponent={CreateMethodologyBtn}
                emptyPlaceholder="No methodologies"
                IconComponent={SchoolIcon}
                checkMatch={checkMethodologyMatch}
                onSelect={onSelect}
            />
            <AccessGroup
                accessList={playersList}
                title={t('userLinks.players')}
                shouldHideIfEmpty
                IconComponent={PlayerIcon}
                checkMatch={checkPlayerMatch}
            />
            <AccessGroup
                accessList={longlistsList}
                title={t('userLinks.longlists')}
                shouldHideIfEmpty
                IconComponent={ListIcon}
                checkMatch={checkLonglistMatch}
                onSelect={onSelect}
            />
        </Box>
    );
};

export default UserLinks;
