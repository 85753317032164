import _keyBy from 'lodash/keyBy';
import Evaluation from './Evaluation';
import actionTypes from './evaluations.actionTypes';

const initialState = {
    evaluationsHash: {},
};

type State = {
    evaluationsHash: {
        [key: string]: Evaluation;
    };
};

// eslint-disable-next-line default-param-last
const evaluations = (state: State = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.EVALUATIONS_LIST_RECIEVED:
            return {
                ...state,
                evaluationsHash: {
                    ...state.evaluationsHash,
                    ..._keyBy(action.evaluationsList, 'id'),
                },
            };
        case actionTypes.EVALUATION_RECIEVED:
            return {
                ...state,
                evaluationsHash: {
                    ...state.evaluationsHash,
                    [action.evaluation.id]: {
                        ...action.evaluation,
                    },
                },
            };
        default:
            return state;
    }
};

export default evaluations;
