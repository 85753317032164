import { EventTypes } from 'entities/events/Event';
import { SessionTypes } from 'entities/events/Session';

export enum ConditionTypes {
    wellness = 'wellness',
    load = 'load',
}

export enum WellnessParams {
    generalCondition = 'generalCondition',
    sleepQuality = 'sleepQuality',
    generalMuscleSoreness = 'generalMuscleSoreness',
    stressLevel = 'stressLevel',
    mood = 'mood',
}

export const wellnessParamsList = Object.values(WellnessParams);
export const eventsParamsList = ['loadLevel', 'wellnessBeforeEvent'] as (
    | 'loadLevel'
    | 'wellnessBeforeEvent'
)[];

export type ConditionsParams = {
    date?: Date;
    // TODO: check if needed
    playerId?: string;
    conditionType: ConditionTypes;
    details: {
        param?: WellnessParams;
        teamId?: string;
        eventId?: string;
        eventType?: EventTypes.session | EventTypes.game;
        startTime?: Date;
        title?: string;
        sessionType?: SessionTypes;
        opponentName?: string;
        // remove after time conditions time before 26.09.22 be migrated to events
        loadTime?: number;
    };
};

export type ConditionData = ConditionsParams & {
    playerId: string;
    teamId: string;
    referencedTeams: string[];
    value: number;
    maxValue: number;
};

export type Condition = ConditionData & {
    id: string;
};

export type ConditionsSettings = {
    conditionSize: number;
    wellnessHalves: boolean;
    loadSize: number;
    loadHalves: boolean;
    loadLevel: boolean;
    wellnessBeforeEvent: boolean;
    [WellnessParams.generalCondition]: boolean;
    [WellnessParams.generalMuscleSoreness]: boolean;
    [WellnessParams.mood]: boolean;
    [WellnessParams.sleepQuality]: boolean;
    [WellnessParams.stressLevel]: boolean;
};

export type ConditionsSchedule = {
    wellness: {
        active: boolean;
        /* UTC time to create task in format 'HH:mm' */
        utcTime: string | null;
    };
    load: {
        active: boolean;
    };
};

export const defaultConditionsSchedule = {
    wellness: {
        active: false,
        utcTime: null,
    },
    load: {
        active: false,
    },
};

export type DayConditionsReport = {
    id: string;
    date: Date;
    players: { [key: string]: ConditionData };
};
