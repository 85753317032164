export default {
    OBSERVATIONS_LIST_REQUEST: 'ENTITIES/OBSERVATIONS/OBSERVATIONS_LIST_REQUEST',
    PERIOD_PLAYER_OBSERVATIONS_LIST_REQUEST:
        'ENTITIES/OBSERVATIONS/PERIOD_PLAYER_OBSERVATIONS_LIST_REQUEST',
    EVENT_TEAM_OBSERVATIONS_LIST_REQUEST:
        'ENTITIES/OBSERVATIONS/EVENT_TEAM_OBSERVATIONS_LIST_REQUEST',
    PERIOD_TEAM_OBSERVATIONS_LIST_REQUEST:
        'ENTITIES/OBSERVATIONS/PERIOD_TEAM_OBSERVATIONS_LIST_REQUEST',
    USER_OBSERVATIONS_FOR_PERIOD_LIST_REQUEST:
        'ENTITIES/OBSERVATIONS/USER_OBSERVATIONS_FOR_PERIOD_LIST_REQUEST',
    PLAYER_EVENT_OBSERVATIONS_LIST_REQUEST:
        'ENTITIES/OBSERVATIONS/PLAYER_EVENT_OBSERVATIONS_LIST_REQUEST',
    PLAYER_GOAL_OBSERVATIONS_LIST_REQUEST:
        'ENTITIES/OBSERVATIONS/PLAYER_GOAL_OBSERVATIONS_LIST_REQUEST',
    PLAYER_INJURY_OBSERVATIONS_LIST_REQUEST:
        'ENTITIES/OBSERVATIONS/PLAYER_INJURY_OBSERVATIONS_LIST_REQUEST',
    OBSERVATIONS_LIST_RECIEVED: 'ENTITIES/OBSERVATIONS/OBSERVATIONS_LIST_RECIEVED',
    OBSERVATION_DATA_REQUEST: 'ENTITIES/OBSERVATIONS/OBSERVATION_DATA_REQUEST',
    OBSERVATION_DATA_RECIEVED: 'ENTITIES/OBSERVATIONS/OBSERVATION_DATA_RECIEVED',
    DELETE_OBSERVATION: 'ENTITIES/OBSERVATIONS/DELETE_OBSERVATION',
    UPDATE_OBSERVATION: 'ENTITIES/OBSERVATIONS/UPDATE_OBSERVATION',
    CREATE_OBSERVATION: 'ENTITIES/OBSERVATIONS/CREATE_OBSERVATION',
    REMOVE_OBSERVATION_FROM_STORE: 'ENTITIES/OBSERVATIONS/REMOVE_OBSERVATION_FROM_STORE',
};
