import _get from 'lodash/get';
import redColor from '@mui/material/colors/red';
import blueColor from '@mui/material/colors/blue';
import greenColor from '@mui/material/colors/green';
import orangeColor from '@mui/material/colors/orange';
import DeletePosition from 'img/icons/DeletePosition.svg';
import { PlayerPositions } from 'entities/players/Player';
import { getPositionsByPriority } from 'features/game/utils';

export enum Positions {
    gk = 'gk',
    lfb = 'lfb',
    lcb = 'lcb',
    cb = 'cb',
    rcb = 'rcb',
    rfb = 'rfb',
    lwb = 'lwb',
    ldm = 'ldm',
    cdm = 'cdm',
    rdm = 'rdm',
    rwb = 'rwb',
    lm = 'lm',
    lcm = 'lcm',
    cm = 'cm',
    rcm = 'rcm',
    rm = 'rm',
    lw = 'lw',
    lam = 'lam',
    cam = 'cam',
    ram = 'ram',
    rw = 'rw',
    sst = 'sst',
    lf = 'lf',
    lst = 'lst',
    st = 'st',
    rst = 'rst',
    rf = 'rf',
}

export const PLAYER_COORDINATES = {
    gk: {
        y: 92.5,
        x: 50,
    },
    // 1st line
    lfb: {
        y: 77.5,
        x: 15,
    },
    lcb: {
        y: 82.5,
        x: 30,
    },
    cb: {
        y: 77.5,
        x: 50,
    },
    rcb: {
        y: 82.5,
        x: 70,
    },
    rfb: {
        y: 77.5,
        x: 85,
    },
    // 2nd line
    lwb: {
        y: 62.5,
        x: 15,
    },
    ldm: {
        y: 67.5,
        x: 30,
    },
    cdm: {
        y: 60,
        x: 50,
    },
    rdm: {
        y: 67.5,
        x: 70,
    },
    rwb: {
        y: 62.5,
        x: 85,
    },
    // 3rd line
    lm: {
        y: 47.5,
        x: 15,
    },
    lcm: {
        y: 52.5,
        x: 30,
    },
    cm: {
        y: 45,
        x: 50,
    },
    rcm: {
        y: 52.5,
        x: 70,
    },
    rm: {
        y: 47.5,
        x: 85,
    },
    // 4th line
    lw: {
        y: 30,
        x: 15,
    },
    lam: {
        y: 35,
        x: 32.5,
    },
    cam: {
        y: 30,
        x: 50,
    },
    ram: {
        y: 35,
        x: 67.5,
    },
    rw: {
        y: 30,
        x: 85,
    },
    // 5th line
    sst: {
        y: 20,
        x: 50,
    },
    lf: {
        y: 17.5,
        x: 15,
    },
    lst: {
        y: 12.5,
        x: 35,
    },
    st: {
        y: 5,
        x: 50,
    },
    rst: {
        y: 12.5,
        x: 65,
    },
    rf: {
        y: 17.5,
        x: 85,
    },
    /* Scouting positions */
    ll: {
        y: 77.5,
        x: 15,
    },
    rl: {
        y: 77.5,
        x: 85,
    },
    dm: {
        y: 60,
        x: 50,
    },
    am: {
        y: 30,
        x: 50,
    },
    // li: {
    //     y: 35,
    //     x: 32.5,
    // },
    // ri: {
    //     y: 35,
    //     x: 67.5,
    // },
    cf: {
        y: 20,
        x: 50,
    },
};

// export const gkPositions = ['gk'];
// export const defencePositions = ['lfb', 'lcb', 'cb', 'rcb', 'rfb'];
// export const belowMidPositions = ['lwb', 'ldm', 'cdm', 'rdm', 'rwb'];
// export const midPositions = ['lm', 'lcm', 'cm', 'rcm', 'rm'];
// export const aboveMidPositions = ['lw', 'lam', 'cam', 'ram', 'rw'];
// export const attackPositions = ['sst', 'lf', 'lst', 'st', 'rst', 'rf'];
export const gkPositions = [Positions.gk];
export const defencePositions = [
    Positions.lfb,
    Positions.lcb,
    Positions.cb,
    Positions.rcb,
    Positions.rfb,
];
export const belowMidPositions = [Positions.ldm, Positions.cdm, Positions.rdm];
export const midPositions = [Positions.lcm, Positions.cm, Positions.rcm];
export const aboveMidPositions = [Positions.lam, Positions.cam, Positions.ram];
export const wingersPositions = [
    Positions.lwb,
    Positions.lm,
    Positions.lw,
    Positions.rwb,
    Positions.rm,
    Positions.rw,
];
export const attackPositions = [
    Positions.lf,
    Positions.lst,
    Positions.sst,
    Positions.st,
    Positions.rst,
    Positions.rf,
];
export const sortedPositions = [
    gkPositions,
    defencePositions,
    belowMidPositions,
    midPositions,
    aboveMidPositions,
    wingersPositions,
    attackPositions,
].reduce((acc, group) => acc.concat(group), []);

/* playersListGroups is used for different players lists: session attendance, team players ... */
export enum PlayersListGroups {
    gk = 'gk',
    defenders = 'defenders',
    midfilders = 'midfilders',
    wingers = 'wingers',
    forwards = 'forwards',
    none = 'none',
}

export const playersListGroups = new Map<PlayersListGroups, Positions[]>();
playersListGroups.set(PlayersListGroups.gk, gkPositions);
playersListGroups.set(PlayersListGroups.defenders, defencePositions);
playersListGroups.set(
    PlayersListGroups.midfilders,
    belowMidPositions.concat(midPositions).concat(aboveMidPositions),
);
playersListGroups.set(PlayersListGroups.wingers, wingersPositions);
playersListGroups.set(PlayersListGroups.forwards, attackPositions);

export const playersListGroupsList = [
    PlayersListGroups.gk,
    PlayersListGroups.defenders,
    PlayersListGroups.midfilders,
    PlayersListGroups.wingers,
    PlayersListGroups.forwards,
];

export const priorityColors: { [key: number]: any } = {
    0: blueColor,
    1: greenColor,
    2: orangeColor,
    3: redColor,
    4: redColor,
};

// @ts-ignore
export const getStylesByCoordinates = ({ position, priority, circleSize }) => {
    const { x, y } = _get(PLAYER_COORDINATES, position, { x: 0, y: 0 });

    return {
        top: `calc(${y}% - ${0.5 * circleSize}px)`,
        left: `calc(${x}% - ${0.5 * circleSize}px)`,
        backgroundColor: _get(priorityColors, `${priority}[500]`, priorityColors[0][500]),
        borderColor: _get(priorityColors, `${priority}[200]`, priorityColors[0][200]),
        width: circleSize,
        height: circleSize,
    };
};

export const getStylesByPriority = ({
    priority,
    circleSize,
}: {
    priority: number | null;
    circleSize: number;
}) => {
    if (!priority) {
        return {
            backgroundImage: `url(${DeletePosition})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderColor: 'transparent',
            width: 34,
            height: 34,
        };
    }

    return {
        backgroundColor: _get(priorityColors, `${priority}[500]`, priorityColors[0][500]),
        borderColor: _get(priorityColors, `${priority}[200]`, priorityColors[0][200]),
        width: circleSize,
        height: circleSize,
    };
};

export enum PositionsGroup {
    gk = 'gk',
    centerBack = 'centerBack',
    ll = 'll',
    rl = 'rl',
    middle = 'middle',
    cf = 'cf',
    lw = 'lw',
    rw = 'rw',
    nope = 'nope',
}

export const groupsMap = new Map<PositionsGroup, Positions[]>();
groupsMap.set(PositionsGroup.gk, [Positions.gk]);
groupsMap.set(PositionsGroup.ll, [Positions.lwb, Positions.lfb]);
groupsMap.set(PositionsGroup.centerBack, [Positions.lcb, Positions.cb, Positions.rcb]);
groupsMap.set(PositionsGroup.rl, [Positions.rwb, Positions.rfb]);
groupsMap.set(PositionsGroup.lw, [Positions.lf, Positions.lw, Positions.lm]);
groupsMap.set(PositionsGroup.middle, [
    Positions.lam,
    Positions.cam,
    Positions.ram,
    Positions.cm,
    Positions.lcm,
    Positions.rcm,
    Positions.cdm,
    Positions.ldm,
    Positions.rdm,
]);
groupsMap.set(PositionsGroup.rw, [Positions.rf, Positions.rw, Positions.rm]);
groupsMap.set(PositionsGroup.cf, [Positions.st, Positions.lst, Positions.rst, Positions.sst]);

export const positionsMap = Array.from(groupsMap.entries()).reduce((acc, [group, positions]) => {
    const subMap = positions.reduce((subAcc, pos) => {
        subAcc.set(pos, group);
        return subAcc;
    }, acc);

    return subMap;
}, new Map<Positions, PositionsGroup>());

const getIntersection = (positionsA: Positions[], positionsB: Positions[]) => {
    const intersection = positionsA.filter((pos) => positionsB.includes(pos));

    return intersection;
};

const hasIntersections = (positionsA: Positions[], positionsB: Positions[]) => {
    return getIntersection(positionsA, positionsB).length > 0;
};

export const getPositionMatch = (pitchPosition: Positions, playerPositions: PlayerPositions) => {
    const positionGroupName = positionsMap.get(pitchPosition);
    const positionGroup = groupsMap.get(positionGroupName!);
    const priority1 = getPositionsByPriority(playerPositions, 1);

    if (hasIntersections(positionGroup as Positions[], priority1)) {
        return 1;
    }

    const priority2 = getPositionsByPriority(playerPositions, 2);

    if (hasIntersections(positionGroup as Positions[], priority2)) {
        return 2;
    }

    return 3;
};

export const getHighlightColor = (pitchPosition: Positions, playerPositions: PlayerPositions) => {
    const positionMatch = getPositionMatch(pitchPosition, playerPositions);

    return priorityColors[positionMatch][500] || '#000';
};

/*
 * Positions for scouting.
 * It is important to differentiate left center back and right center back.
 * as well as left/right inside and DM
 */

export enum ScoutingPosition {
    gk = 'gk',
    ll = 'll',
    lcb = 'lcb',
    rcb = 'rcb',
    rl = 'rl',
    dm = 'dm',
    cm = 'cm',
    am = 'am',
    // li = 'li',
    // ri = 'ri',
    lw = 'lw',
    rw = 'rw',
    cf = 'cf',
    nope = 'nope',
}

export const scoutingPositionsList = [
    ScoutingPosition.gk,
    ScoutingPosition.rl,
    ScoutingPosition.rcb,
    ScoutingPosition.lcb,
    ScoutingPosition.ll,
    ScoutingPosition.dm,
    // ScoutingPosition.ri,
    // ScoutingPosition.li,
    ScoutingPosition.cm,
    ScoutingPosition.am,
    ScoutingPosition.rw,
    ScoutingPosition.lw,
    ScoutingPosition.cf,
    ScoutingPosition.nope,
];

export const scoutingGroupsMap = new Map<ScoutingPosition, Positions[]>();
scoutingGroupsMap.set(ScoutingPosition.nope, []);
scoutingGroupsMap.set(ScoutingPosition.gk, [Positions.gk]);
scoutingGroupsMap.set(ScoutingPosition.ll, [Positions.lwb, Positions.lfb]);
scoutingGroupsMap.set(ScoutingPosition.lcb, [Positions.lcb]);
scoutingGroupsMap.set(ScoutingPosition.rcb, [Positions.cb, Positions.rcb]);
scoutingGroupsMap.set(ScoutingPosition.rl, [Positions.rwb, Positions.rfb]);
scoutingGroupsMap.set(ScoutingPosition.dm, [Positions.cdm, Positions.ldm, Positions.rdm]);
scoutingGroupsMap.set(ScoutingPosition.cm, [Positions.lcm, Positions.cm, Positions.rcm]);
scoutingGroupsMap.set(ScoutingPosition.am, [Positions.lam, Positions.cam, Positions.ram]);
// scoutingGroupsMap.set(ScoutingPosition.li, [Positions.lam, Positions.lcm]);
// scoutingGroupsMap.set(ScoutingPosition.ri, [
//     Positions.cam,
//     Positions.ram,
//     Positions.cm,
//     Positions.rcm,
// ]);
scoutingGroupsMap.set(ScoutingPosition.lw, [Positions.lf, Positions.lw, Positions.lm]);
scoutingGroupsMap.set(ScoutingPosition.rw, [Positions.rf, Positions.rw, Positions.rm]);
scoutingGroupsMap.set(ScoutingPosition.cf, [
    Positions.st,
    Positions.lst,
    Positions.rst,
    Positions.sst,
]);

export const scoutingPositionsMap = Array.from(scoutingGroupsMap.entries()).reduce(
    (acc, [group, positions]) => {
        const subMap = positions.reduce((subAcc, pos) => {
            subAcc.set(pos, group);
            return subAcc;
        }, acc);

        return subMap;
    },
    new Map<Positions, ScoutingPosition>(),
);

export const scoutingToRegularPositionMap = new Map<ScoutingPosition, Positions>();
scoutingToRegularPositionMap.set(ScoutingPosition.gk, Positions.gk);
scoutingToRegularPositionMap.set(ScoutingPosition.rl, Positions.rfb);
scoutingToRegularPositionMap.set(ScoutingPosition.rcb, Positions.rcb);
scoutingToRegularPositionMap.set(ScoutingPosition.lcb, Positions.lcb);
scoutingToRegularPositionMap.set(ScoutingPosition.ll, Positions.lfb);
scoutingToRegularPositionMap.set(ScoutingPosition.dm, Positions.cdm);
scoutingToRegularPositionMap.set(ScoutingPosition.cm, Positions.cm);
scoutingToRegularPositionMap.set(ScoutingPosition.am, Positions.cam);
// scoutingToRegularPositionMap.set(ScoutingPosition.ri, Positions.ram);
// scoutingToRegularPositionMap.set(ScoutingPosition.li, Positions.lam);
scoutingToRegularPositionMap.set(ScoutingPosition.rw, Positions.rw);
scoutingToRegularPositionMap.set(ScoutingPosition.lw, Positions.lw);
scoutingToRegularPositionMap.set(ScoutingPosition.cf, Positions.st);
