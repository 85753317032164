export default {
    CREATE_STORAGE_FILE: 'ENTITIES/STORAGE_FILES/CREATE_STORAGE_FILE',
    STORAGE_FILE_DATA_RECEIVED: 'ENTITIES/STORAGE_FILES/STORAGE_FILE_DATA_RECEIVED',
    STORAGE_FILES_LIST_RECEIVED: 'ENTITIES/STORAGE_FILES/STORAGE_FILES_LIST_RECEIVED',
    FETCH_STORAGE_FILE: 'ENTITIES/STORAGE_FILES/FETCH_STORAGE_FILE',
    FETCH_ORG_STORAGE_FILES: 'ENTITIES/STORAGE_FILES/FETCH_ORG_STORAGE_FILES',
    FETCH_TEAM_STORAGE_FILES: 'ENTITIES/STORAGE_FILES/FETCH_TEAM_STORAGE_FILES',
    FETCH_METHODOLOGY_STORAGE_FILES: 'ENTITIES/STORAGE_FILES/FETCH_METHODOLOGY_STORAGE_FILES',
    FETCH_PLAYER_STORAGE_FILES: 'ENTITIES/STORAGE_FILES/FETCH_PLAYER_STORAGE_FILES',
    UPDATE_STORAGE_FILE: 'ENTITIES/STORAGE_FILES/UPDATE_STORAGE_FILE',
    REMOVE_STORAGE_FILE_FROM_STATE: 'ENTITIES/STORAGE_FILES/REMOVE_STORAGE_FILE_FROM_STATE',
};
