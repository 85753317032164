import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import EpisodesGroup, { EpisodesType } from './Episodes';

export const episodesGroupsMapSelector = (state: RootState) =>
    _get(state, 'entities.episodes.episodesGroupsMap');

export const episodesGroupsListSelector = createSelector(
    episodesGroupsMapSelector,
    (_: any, { eventId }: { eventId: string }) => eventId,
    (episodesGroupsMap, eventId) => {
        const groupsList: EpisodesGroup[] = Object.values(episodesGroupsMap);
        return groupsList.filter((group) => group.eventId === eventId);
    },
);

export const episodesGroupSelector = createSelector(
    episodesGroupsListSelector,
    (_: any, { episodesType }: { episodesType: EpisodesType }) => episodesType,
    (_: any, { period }: { period: number }) => period,
    (groupsList, episodesType, period) => {
        return groupsList.filter((group) => group.period === period && group.type === episodesType);
    },
);

export const isLoadingEpisodesSelector = (state: RootState) =>
    _get(state, 'entities.episodes.isLoading');
