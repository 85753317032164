import Player from 'entities/players/Player';
import { EventPlayerInfo, EventTypes } from './Event';
import Game, { Lineup, LineupPosition, PlayerStatus } from './Game';
import { getPlayerGameTime } from './games.utils';
import Session, { Attendance, AttendanceRecord, AttendanceStatus } from './Session';
import { getPlayerSessionTime } from './sessions.utils';
import { checkInjuryByDate } from 'entities/players/players.utils';
import _ from 'lodash';

export const playerToEventInfo = (player: Player): EventPlayerInfo => {
    const {
        id,
        lastName,
        firstName,
        enFirstName = '',
        enLastName = '',
        filePath,
        playerNumber,
        teams,
        userId,
        positions,
    } = player;
    return {
        id,
        lastName,
        firstName,
        enFirstName,
        enLastName,
        filePath,
        playerNumber,
        positions,
        teams,
        userId,
    };
};

export const mapEventToDatesStrings = (event: Game | Session) => {
    const start = event.start.toISOString();
    const end = event.end ? event.end.toISOString() : null;

    return {
        ...event,
        start,
        end,
    };
};

export const mapEventFromDatesStrings = (event: Game | Session) => {
    const start = new Date(event.start);
    const end = event.end ? new Date(event.end) : null;

    return {
        ...event,
        start,
        end,
    };
};

export const getPlayerLoadTime = (event: Game | Session, playerId: string) => {
    if (event.eventType === EventTypes.game) {
        const { substitutions, gameTime, lineup } = (event as Game).details;
        const lineupPosition = lineup[playerId];

        if (!lineupPosition) {
            return 0;
        }

        if (lineupPosition.time) {
            return Number(lineupPosition.time);
        }

        const isLineup = lineupPosition?.status === PlayerStatus.lineup;

        return getPlayerGameTime(substitutions, playerId, gameTime, isLineup, lineupPosition)
            .playerGameTime;
    }

    return getPlayerSessionTime(event as Session, playerId);
};
const isEventPlayerInfoOutdated = (
    eventPlayer: EventPlayerInfo,
    currentPlayer: EventPlayerInfo,
) => {
    return _.isEqual(eventPlayer, currentPlayer);
};

export const getEventPlayersDiff = (
    addedPlayersIds: string[],
    teamPlayersIds: string[],
    eventPlayersList: EventPlayerInfo[],
    playersList: Player[],
    setSelectedPlayers: React.Dispatch<React.SetStateAction<Set<string>>>,
) => {
    const playersIdsToAdd = playersList
        .filter((player) => !addedPlayersIds.includes(player.id))
        .map((player) => player.id);

    const playersIdsToRemove = eventPlayersList
        .filter((player) => !teamPlayersIds.includes(player.id))
        .map((player) => player.id);

    const playersDataToRemove = eventPlayersList.filter((player) =>
        playersIdsToRemove.includes(player.id),
    );
    const playersIdsToUpdate = eventPlayersList
        .filter((eventPlayer) => {
            const currentPlayer = playersList.find((player) => player.id === eventPlayer.id);
            if (currentPlayer) {
                const currentEventPlayer = playerToEventInfo(currentPlayer);
                return !isEventPlayerInfoOutdated(eventPlayer, currentEventPlayer);
            }
        })
        .map((player) => player.id);

    const allPlayersIds = playersIdsToAdd.concat(playersIdsToRemove, playersIdsToUpdate);
    setSelectedPlayers(new Set(allPlayersIds));

    return {
        playersIdsToAdd,
        playersIdsToRemove,
        playersIdsToUpdate,
        playersDataToRemove,
    };
};

export const getEventPlayersIds = (addedPlayersList: EventPlayerInfo[], playersList: Player[]) => {
    const addedPlayersIds = addedPlayersList.map((player) => player.id);
    const teamPlayersIds = playersList.map((player) => player.id);
    return { addedPlayersIds, teamPlayersIds };
};

export const getFilterdSessionPlayersToAdd = (playersToAdd: Player[], sessionDate: Date) => {
    return playersToAdd.map((player) => {
        const filteredPlayer = playerToEventInfo(player);
        const hasInjury = checkInjuryByDate(player, sessionDate);
        const newAttendanceRecord: Pick<AttendanceRecord, 'player' | 'status' | 'time'> = {
            player: filteredPlayer,
            status: hasInjury ? AttendanceStatus.injury : AttendanceStatus.present,
            time: 0,
        };

        return { [player.id]: newAttendanceRecord };
    });
};

export const getUpdatedSessionPlayers = (
    playersToUpdate: Player[],
    eventPlayersList: Attendance,
) => {
    return playersToUpdate.map((player) => {
        const newAttendanceRecord: Pick<AttendanceRecord, 'player' | 'status' | 'time'> = {
            player: playerToEventInfo(player),
            status: eventPlayersList[player.id].status,
            time: 0,
        };

        return { [player.id]: newAttendanceRecord };
    });
};

export const getFilteredAttendanceList = (
    attendanceList: Attendance,
    playersIdsToRemove: string[],
    selectedPlayers: Set<string>,
) => {
    return Object.keys(attendanceList)
        .filter((key) => !(playersIdsToRemove.includes(key) && selectedPlayers.has(key)))
        .reduce((acc: Attendance, key) => {
            acc[key] = attendanceList[key];
            return acc;
        }, {});
};

export const getUpdatedGamePlayers = (playersToUpdate: Player[], eventPlayersList: Lineup) => {
    return playersToUpdate.map((player) => {
        const newLineupPosition: LineupPosition = {
            player: playerToEventInfo(player),
            status: eventPlayersList[player.id].status,
            gamePosition: eventPlayersList[player.id].status,
            activities: eventPlayersList[player.id].activities,
            time: 0,
            gameTime: 0,
        };

        return { [player.id]: newLineupPosition };
    });
};

export const getFilterdGamePlayersToAdd = (playersToAdd: Player[]) => {
    return playersToAdd.map((player) => {
        const newLineupPosition: LineupPosition = {
            player: playerToEventInfo(player),
            status: PlayerStatus.reserve,
            gamePosition: null,
            activities: [],
            time: 0,
            gameTime: 0,
        };

        return { [player.id]: newLineupPosition };
    });
};

export const getFilteredEventPlayersList = (
    eventPlayersList: Lineup | Attendance,
    playersIdsToRemove: string[],
) => {
    return Object.keys(eventPlayersList)
        .filter((key) => !playersIdsToRemove.includes(key))
        .reduce((acc: Lineup | Attendance, key) => {
            acc[key] = eventPlayersList[key];
            return acc;
        }, {});
};

export const getIsPlayerPresent = (sessionOrGame: Session | Game, playerId: string) => {
    const isSession = sessionOrGame.eventType === EventTypes.session;

    if (isSession) {
        return (sessionOrGame as Session).attendance[playerId]?.status === AttendanceStatus.present;
    }

    return [PlayerStatus.lineup, PlayerStatus.bench].includes(
        (sessionOrGame as Game).details.lineup[playerId]?.status,
    );
};
