import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from 'common/components';
import { useTranslation } from 'react-i18next';

const PREFIX = 'AssistantPlaceholder';

const classes = {
    assistantPlaceholder: `${PREFIX}-assistantPlaceholder`,
    icon: `${PREFIX}-icon`,
    description: `${PREFIX}-description`,
    list: `${PREFIX}-list`,
    listItem: `${PREFIX}-listItem`,
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.assistantPlaceholder}`]: {
        display: 'flex',
        flexDirection: 'column',
    },

    [`& .${classes.icon}`]: {
        margin: theme.spacing(0, 'auto', 2),
        fontSize: '32px',
    },

    [`& .${classes.description}`]: {
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },

    [`& .${classes.list}`]: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        padding: theme.spacing(1),
    },

    [`& .${classes.listItem}`]: {
        marginBottom: theme.spacing(1),
        opacity: 0.5,
    },
}));



type AssistantPlaceholderProps = {
    isVisible: boolean;
};

const AssistantPlaceholder: React.FC<AssistantPlaceholderProps> = ({ isVisible }) => {
    const { t } = useTranslation();

    if (!isVisible) {
        return null;
    }

    return (
        <Root className={classes.assistantPlaceholder}>
            <span className={classes.icon}>😎</span>
            <Typography className={classes.description}>
                {t('assistant.placeholder.description')}
            </Typography>
            <div className={classes.list}>
                <Typography key="goals" className={classes.listItem}>
                    {t('assistant.placeholder.goals')}
                </Typography>
                <Typography key="events" className={classes.listItem}>
                    {t('assistant.placeholder.events')}
                </Typography>
                <Typography key="evaluation" className={classes.listItem}>
                    {t('assistant.placeholder.evaluation')}
                </Typography>
            </div>
        </Root>
    );
};

export default AssistantPlaceholder;
