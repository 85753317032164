import { useDispatch, useSelector } from 'react-redux';
import { useLocalConfig } from 'common/config/config.utils';
import { userDataSelector } from 'entities/users/users.selectors';
import { useCallback } from 'react';
import { Language } from 'entities/users/User';
import { updateUserData } from 'entities/users/users.actions';

export const useUserLang = () => {
    const user = useSelector(userDataSelector);
    const { localConfig, onChange } = useLocalConfig();
    const dispatch = useDispatch();

    const onChangeLang = useCallback(
        (lang: Language) => {
            if (user) {
                const { id, ...rest } = user;
                dispatch(updateUserData({ userId: id, userData: { ...rest, lang } }));
            }

            onChange('lang', lang);
        },
        [user, onChange],
    );

    const currentLang = user?.lang || localConfig.lang || Language.en;

    return {
        currentLang,
        onChangeLang,
    };
};
