import { StorageInfo } from 'entities/storage-files/StorageFile';
import actionTypes from './methodologies.actionTypes';
import { MethodologyData } from './Methodology';

export const requestMethodologyData = ({ methodologyId }: { methodologyId: string }) => ({
    type: actionTypes.METHODOLOGY_DATA_REQUEST,
    methodologyId,
});

export const methodologyDataReceived = ({
    methodologyData,
}: {
    methodologyData: MethodologyData;
}) => ({
    type: actionTypes.METHODOLOGY_DATA_RECEIVED,
    methodologyData,
});

export const updateMethodology = ({
    methodologyId,
    methodologyData,
}: {
    methodologyId: string;
    methodologyData: MethodologyData;
}) => ({
    type: actionTypes.METHODOLOGY_UPDATE_REQUEST,
    methodologyId,
    methodologyData,
});

export const methodologyStorageInfoRecieved = ({
    storageInfo,
    methodologyId,
}: {
    methodologyId: string;
    storageInfo: StorageInfo;
}) => ({
    type: actionTypes.METHODOLOGY_STORAGE_INFO_RECIEVED,
    storageInfo,
    methodologyId,
});
