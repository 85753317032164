import { PlayerStats } from 'entities/players/players.utils';
import { TFunction } from 'i18next';

type HeaderItem = {
    key: string;
    width: number;
    hasSorting: boolean;
};
const headerItems: HeaderItem[] = [
    { key: 'player', width: 125, hasSorting: true },
    { key: 'attendance', width: 40, hasSorting: true },
    { key: 'avgEvaluation', width: 40, hasSorting: true },
    { key: 'totalSessionsTime', width: 40, hasSorting: true },
    { key: 'sessionsPresent', width: 40, hasSorting: true },
    { key: 'sessionsSkiped', width: 40, hasSorting: true },
    { key: 'individualSessionsCount', width: 40, hasSorting: true },
    { key: 'gamesTime', width: 40, hasSorting: true },
    { key: 'lineup', width: 40, hasSorting: true },
    { key: 'subIn', width: 40, hasSorting: true },
    { key: 'goals', width: 40, hasSorting: true },
    { key: 'cards', width: 40, hasSorting: true },
    { key: 'newInjuries', width: 40, hasSorting: true },
    { key: 'healedInjuries', width: 40, hasSorting: true },
    { key: 'activeInjuries', width: 40, hasSorting: true },
    { key: 'newGoals', width: 40, hasSorting: true },
    { key: 'activeGoals', width: 40, hasSorting: true },
    // { key: 'avgLoad', width: 40, hasSorting: true },
    // { key: 'avgWellness', width: 40, hasSorting: true },
];

export const getHeaderItems = (t: TFunction) => {
    return headerItems.map((item) => ({
        ...item,
        title: t(`teamStats.playersStats.header.${item.key}.title`),
        tooltip: t(`teamStats.playersStats.header.${item.key}.tooltip`),
    }));
};

type ValueChecker = (val: number) => string;

export type ValueCheckersMap = Map<string, ValueChecker>;

// const attributes = [
//     'attendance',
//     'avgEvaluation',
//     'totalSessionsTime',
//     'sessionsPresent',
//     'sessionsSkiped',
//     'individualSessionsCount',
//     'gamesTime',
//     'lineup',
//     'subIn',
//     'goals',
//     'cards',
//     'newInjuries',
//     'healedInjuries',
//     'activeInjuries',
//     'newGoals',
//     'activeGoals',
// ];

const sortFunc = (a: number, b: number) => {
    return a > b ? -1 : 1;
};

const getTreshhod = (count: number) => {
    if (count > 20) {
        return 5;
    }

    if (count > 15) {
        return 3;
    }

    return 2;
};

const getBestWorstValues = (playersStats: PlayerStats[], key: string) => {
    const values = playersStats
        .map((playerStats) => {
            // @ts-ignore
            const value = playerStats[key];

            return Array.isArray(value) ? value.length : value;
        })
        .filter((val) => !Number.isNaN(val)) as number[];
    values.sort(sortFunc);
    const treshhold = getTreshhod(values.length);
    const goodValue = values[treshhold];
    const badValue = values[values.length - treshhold];

    return { goodValue, badValue };
};

const green = '#90ee904a';
const red = '#f0808052';

export const createColorFunc = (playersStats: PlayerStats[]) => (attr: string) => {
    if (playersStats.length <= 10) {
        return () => 'transparent';
    }

    const isNumberValue = [
        'attendance',
        'avgEvaluation',
        'totalSessionsTime',
        'sessionsPresent',
        'gamesTime',
        'lineup',
    ].includes(attr);

    if (isNumberValue) {
        const { goodValue, badValue } = getBestWorstValues(playersStats, attr);

        return (val: number) => {
            if (val >= goodValue) {
                return green;
            }

            if (val <= badValue) {
                return red;
            }

            return 'transparent';
        };
    }

    if (attr === 'sessionsSkiped') {
        return (val: number) => (val > 5 ? red : 'transparent');
    }

    if (attr === 'goals') {
        return (val: number) => (val >= 2 ? green : 'transparent');
    }

    if (attr === 'cards') {
        return (val: number) => (val > 2 ? red : 'transparent');
    }

    if (attr === 'newInjuries') {
        return (val: number) => (val > 0 ? red : 'transparent');
    }

    if (attr === 'healedInjuries') {
        return (val: number) => (val > 0 ? green : 'transparent');
    }

    if (attr === 'activeInjuries') {
        return (val: number) => (val > 0 ? red : 'transparent');
    }

    if (attr === 'newGoals') {
        return (val: number) => (val > 0 ? green : 'transparent');
    }

    if (attr === 'activeGoals') {
        return (val: number) => (val === 0 ? red : 'transparent');
    }

    return () => 'transparent';
};
