import * as medicalTestsGateway from 'gateways/medicalTestsGateway';
import { put, call, all, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as medicalTestsAction from './medicalTests.actions';
import MedicalTest from './MedicalTests';
import medicalTestsActionTypes from './medicalTests.actionTypes';
import User, { Author } from 'entities/users/User';
import { userDataSelector } from 'entities/users/users.selectors';
import { teamPlayersListSelector } from 'entities/players/players.selectors';
import Player from 'entities/players/Player';
import { createEmptyMedicalTest } from './medicalTests.utils';

export function* createMedicalTestSaga({
    organizationId,
    teamId,
    tests,
    title,
    onSuccess,
    onFail,
}: ReturnType<typeof medicalTestsAction.createMedicalTest>) {
    const user: User = yield select(userDataSelector);
    const playersList: Player[] = yield select(teamPlayersListSelector, {
        teamId,
    });
    const medicalTest = createEmptyMedicalTest({
        teamId,
        organizationId,
        playersList,
        user,
        title,
        tests,
    });
    try {
        const medicalTestId: string = yield call(medicalTestsGateway.createMedicalTest, {
            organizationId,
            teamId,
            medicalTest,
        });

        if (medicalTestId) {
            yield call(onSuccess, medicalTestId);
        }
    } catch (e) {
        yield call(onFail);
        console.error(e);
    }
}

export function* updateMedicalTestSaga({
    organizationId,
    teamId,
    medicalTestId,
    medicalTestData,
    onSuccess,
    onFail,
}: ReturnType<typeof medicalTestsAction.updateMedicalTest>) {
    try {
        yield call(medicalTestsGateway.updateMedicalTest, {
            organizationId,
            teamId,
            medicalTestId,
            medicalTest: medicalTestData,
        });

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        if (onFail) {
            yield call(onFail);
        }

        console.error(e);
    }
}

export function* fetchMedicalTestDataSaga({
    organizationId,
    teamId,
    medicalTestId,
    onSuccess,
}: ReturnType<typeof medicalTestsAction.medicalTestRequest>) {
    try {
        const medicalTest: MedicalTest = yield call(medicalTestsGateway.fetchMedicalTestById, {
            organizationId,
            teamId,
            medicalTestId,
        });
        if (medicalTest) {
            yield put(medicalTestsAction.medicalTestDataRecieved(medicalTest));
        }
        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

export function* fetchMedicalTestsListsSaga({
    organizationId,
    teamId,
    onSuccess,
}: ReturnType<typeof medicalTestsAction.medicalTestsListRequest>) {
    try {
        const medicalTestsList: MedicalTest[] = yield call(
            [medicalTestsGateway, medicalTestsGateway.fetchTeamMedicalTestsList],
            {
                organizationId,
                teamId,
            },
        );
        if (medicalTestsList) {
            yield put(medicalTestsAction.medicalTestsListRecieved(medicalTestsList));
        }

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

export function* fetchMedicalTestsAccessListSaga({
    organizationId,
    onSuccess,
}: ReturnType<typeof medicalTestsAction.medicalTestsAccessListRequest>) {
    try {
        const medicalTestsAccessList: Author[] = yield call(
            medicalTestsGateway.fetchMedicalTestsAccessList,
            { organizationId },
        );
        yield put(
            medicalTestsAction.medicalTestsAccessListRecieved(
                organizationId,
                medicalTestsAccessList,
            ),
        );

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

function* medicalTestsSagas() {
    yield all([
        takeLatest(medicalTestsActionTypes.CREATE_MEDICAL_TEST, createMedicalTestSaga),
        takeEvery(medicalTestsActionTypes.UPDATE_MEDICAL_TEST, updateMedicalTestSaga),
        takeLatest(medicalTestsActionTypes.MEDICAL_TEST_REQUEST, fetchMedicalTestDataSaga),
        takeEvery(medicalTestsActionTypes.MEDICAL_TESTS_LIST_REQUEST, fetchMedicalTestsListsSaga),
        takeLatest(
            medicalTestsActionTypes.MEDICAL_TESTS_ACCESS_LIST_REQUEST,
            fetchMedicalTestsAccessListSaga,
        ),
    ]);
}

export default medicalTestsSagas;
