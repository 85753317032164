import { Author } from 'entities/users/User';

export type StorageInfo = {
    totalSize: number;
    maxSize: number;
};

export enum StorageFileStatus {
    active = 'active',
    deleted = 'deleted',
}

export enum StorageFileTargetType {
    observation = 'observation',
    injury = 'injury',
    event = 'event',
    avatar = 'avatar',
    methodology = 'methodology',
    goal = 'goal',
    medicalTest = 'medicalTest',
    other = 'other',
}

export type StorageFileTarget = {
    organizationId?: string | null;
    methodologyId?: string | null;
    teamId?: string | null;
    playerId?: string | null;
    goalId?: string | null;
    testId?: string | null;
};

type StorageFile = {
    id: string;
    filePath: string;
    size: number;
    date: string;
    displayName: string;
    description: string;
    contentType: string | null;
    author: Author | null;
    status: StorageFileStatus;
    target: StorageFileTarget;
    targetType: StorageFileTargetType;
};

export default StorageFile;
