import { ExternalLink } from 'common/external-links/ExternalLink';
import { Author } from 'entities/users/User';

export enum MedicalRecordType {
    weight = 'weight',
    height = 'height',
    bloodPressure = 'bloodPressure',
    bodyFatPercentage = 'bodyFatPercentage',
    bodyMassIndex = 'bodyMassIndex',
    chestGirth = 'chestGirth',
    neckGirth = 'neckGirth',
    shoulderGirth = 'shoulderGirth',
    abdomenGirth = 'abdomenGirth',
    hipGirth = 'hipGirth',
    shinGirth = 'shinGirth',
    basicMetabolism = 'basicMetabolism',
    heartRate = 'heartRate',
    footAxis = 'footAxis',
    trainingRestrictions = 'trainingRestrictions',
    maxDayCalories = 'maxDayCalories',
    spinalAxis = 'spinalAxis',
    jointsCheck = 'jointsCheck',
    jumpTest = 'jumpTest',
    plxp9OWiSBZRNrGZrmtxy = 'plxp9OWiSBZRNrGZrmtxy',
}

export type MedicalRecordUpdate<T> = {
    author: Author;
    date: Date;
    result: T;
};

export enum MedicalRecordsCategory {
    singleNumber = 'singleNumber',
    twoNumbers = 'twoNumbers',
    threeNumbers = 'threeNumbers',
    leftRightNumbers = 'leftRightNumbers',
    leftRightStrings = 'leftRightStrings',
    singleCheckbox = 'singleCheckbox',
    twoCheckboxes = 'twoCheckboxes',
    threeCheckboxes = 'threeCheckboxes',
    singleString = 'singleString',
    twoStrings = 'twoStrings',
    threeStrings = 'threeStrings',
    singleOption = 'singleOption',
    custom = 'custom',
}

type MedicalRecordBase<T, R, C> = {
    organizationId: string;
    teamId: string | null;
    playerId: string;
    testId: string | null;
    date: Date;
    author: Author;
    files: string[];
    links: ExternalLink[];
    comment: string;
    isCustom: boolean;
    type: T;
    result: R | null;
    updates: Array<MedicalRecordUpdate<R>>;
    // TODO: avoid null
    category: C | null;
};

/* height */
export type HeightMedicalRecordResult = {
    value: number;
};

export type HeightMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.height,
    HeightMedicalRecordResult,
    MedicalRecordsCategory.singleNumber
>;

/* weight */
export type WeightMedicalRecordResult = {
    value: number;
};

export type WeightMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.weight,
    WeightMedicalRecordResult,
    MedicalRecordsCategory.singleNumber
>;

/* basic metabolism */
export type BasicMetabolismMedicalRecordResult = {
    value: number;
};

export type BasicMetabolismMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.basicMetabolism,
    BasicMetabolismMedicalRecordResult,
    MedicalRecordsCategory.singleNumber
>;

/* heart rate */
export type HeartRateMedicalRecordResult = {
    value: number;
};

export type HeartRateMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.heartRate,
    HeartRateMedicalRecordResult,
    MedicalRecordsCategory.singleNumber
>;

/* max day calories */
export type MaxDayCaloriesMedicalRecordResult = {
    value: number;
};

export type MaxDayCaloriesMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.maxDayCalories,
    MaxDayCaloriesMedicalRecordResult,
    MedicalRecordsCategory.singleNumber
>;

/* body fat percentage */
export type BodyFatPercentageMedicalRecordResult = {
    value: number;
};

export type BodyFatPercentageMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.bodyFatPercentage,
    BodyFatPercentageMedicalRecordResult,
    MedicalRecordsCategory.singleNumber
>;

/* body mass index */
export type BodyMassIndexMedicalRecordResult = {
    value: number;
};

export type BodyMassIndexMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.bodyMassIndex,
    BodyMassIndexMedicalRecordResult,
    MedicalRecordsCategory.singleNumber
>;

/* body mass index */
export type ChestGirthMedicalRecordResult = {
    normal: number;
    inhale: number;
    exhale: number;
};

export type ChestGirthMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.chestGirth,
    ChestGirthMedicalRecordResult,
    MedicalRecordsCategory.threeNumbers
>;

/* jump test */
export type JumpTestMedicalRecordResult = {
    left: number;
    right: number;
    both: number;
};

export type JumpTestMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.jumpTest,
    JumpTestMedicalRecordResult,
    MedicalRecordsCategory.threeNumbers
>;

/* neck girth */
export type NeckGirthMedicalRecordResult = {
    value: number;
};

export type NeckGirthMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.neckGirth,
    NeckGirthMedicalRecordResult,
    MedicalRecordsCategory.singleNumber
>;

/* abdomen girth */
export type AbdomenGirthMedicalRecordResult = {
    value: number;
};

export type AbdomenGirthMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.abdomenGirth,
    AbdomenGirthMedicalRecordResult,
    MedicalRecordsCategory.singleNumber
>;

/* Custom: KEK */
export type KekMedicalRecordResult = {
    value: number;
};

export type KekMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.plxp9OWiSBZRNrGZrmtxy,
    KekMedicalRecordResult,
    MedicalRecordsCategory.singleNumber
>;

/* training restrictions */
export type TrainingRestrictionsMedicalRecordResult = {
    value: string;
};

export type TrainingRestrictionsMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.trainingRestrictions,
    TrainingRestrictionsMedicalRecordResult,
    MedicalRecordsCategory.singleString
>;

/* foot axis */
export enum FootAxis {
    valgus = 'valgus',
    flat = 'flat',
    normal = 'normal',
}
export type FootAxisMedicalRecordResult = {
    value: FootAxis;
};

export type FootAxisMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.footAxis,
    FootAxisMedicalRecordResult,
    MedicalRecordsCategory.singleOption
>;

/* blood pressure */
export type BloodPressureMedicalRecordResult = {
    systolic: number;
    diastolic: number;
};

export type BloodPressureMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.bloodPressure,
    BloodPressureMedicalRecordResult,
    MedicalRecordsCategory.twoNumbers
>;

/* hip girth */
export type HipGirthMedicalRecordResult = {
    left: number;
    right: number;
};

export type HipGirthMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.hipGirth,
    HipGirthMedicalRecordResult,
    MedicalRecordsCategory.leftRightNumbers
>;

/* joints check */
export type JointsCheckMedicalRecordResult = {
    knee: {
        left: string;
        right: string;
    };
    foot: {
        left: string;
        right: string;
    };
    hip: {
        left: string;
        right: string;
    };
};

export type JointsCheckMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.jointsCheck,
    JointsCheckMedicalRecordResult,
    MedicalRecordsCategory.custom
>;

/* shin girth */
export type ShinGirthMedicalRecordResult = {
    left: number;
    right: number;
};

export type ShinGirthMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.shinGirth,
    ShinGirthMedicalRecordResult,
    MedicalRecordsCategory.leftRightNumbers
>;

/* shoulder girth */
export type ShoulderGirthMedicalRecordResult = {
    left: number;
    right: number;
};

export type ShoulderGirthMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.shoulderGirth,
    ShoulderGirthMedicalRecordResult,
    MedicalRecordsCategory.leftRightNumbers
>;

/* spinal axis */
export enum SpinalAxis {
    scoliosisS = 'scoliosisS',
    scoliosiC = 'scoliosiC',
    kyphosis = 'kyphosis',
    lordosis = 'lordosis',
}
export type SpinalAxisMedicalRecordResult = {
    left: number;
    right: number;
    spinalAxis: SpinalAxis | null;
};

export type SpinalAxisMedicalRecordData = MedicalRecordBase<
    MedicalRecordType.spinalAxis,
    SpinalAxisMedicalRecordResult,
    MedicalRecordsCategory.custom
>;

export type MedicalRecordData =
    | HeightMedicalRecordData
    | WeightMedicalRecordData
    | BasicMetabolismMedicalRecordData
    | MaxDayCaloriesMedicalRecordData
    | HeartRateMedicalRecordData
    | BloodPressureMedicalRecordData
    | BodyFatPercentageMedicalRecordData
    | BodyMassIndexMedicalRecordData
    | ChestGirthMedicalRecordData
    | ShoulderGirthMedicalRecordData
    | NeckGirthMedicalRecordData
    | AbdomenGirthMedicalRecordData
    | ShinGirthMedicalRecordData
    | HipGirthMedicalRecordData
    | TrainingRestrictionsMedicalRecordData
    | SpinalAxisMedicalRecordData
    | KekMedicalRecordData
    | FootAxisMedicalRecordData
    | JointsCheckMedicalRecordData
    | JumpTestMedicalRecordData;

export type SingleNumberMedicalRecord =
    | WeightMedicalRecordData
    | HeightMedicalRecordData
    | BasicMetabolismMedicalRecordData
    | HeartRateMedicalRecordData
    | BodyFatPercentageMedicalRecordData
    | BodyMassIndexMedicalRecordData
    | NeckGirthMedicalRecordData
    | AbdomenGirthMedicalRecordData;

export type ThreeNumbersMedicalRecord = ChestGirthMedicalRecordData | JumpTestMedicalRecordData;

type MedicalRecord = MedicalRecordData & {
    id: string;
};

export default MedicalRecord;

// type SingleNumberMedicalRecord = {
//     type: string;
//     category: MedicalRecordsCategory.singleNumber;
//     config: {
//         label: string;
//         units: string;
//     };
// };

// type TwoNumbersMedicalRecord = {
//     type: string;
//     category: MedicalRecordsCategory.twoNumbers;
//     config: {
//         label: string;
//         units: string;
//         labelOne: string;
//         labelTwo: string;
//     };
// };

// type ThreeNumbersMedicalRecord = {
//     type: string;
//     category: MedicalRecordsCategory.threeNumbers;
//     config: {
//         label: string;
//         units: string;
//         labelOne: string;
//         labelTwo: string;
//         labelThree: string;
//     };
// };

// type SingleStringMedicalRecord = {
//     type: string;
//     category: MedicalRecordsCategory.singleString;
//     config: {
//         label: string;
//         units: string;
//     };
// };

// type TwoStringsMedicalRecord = {
//     type: string;
//     category: MedicalRecordsCategory.twoStrings;
//     config: {
//         label: string;
//         labelOne: string;
//         labelTwo: string;
//     };
// };

// type ThreeStringsMedicalRecord = {
//     type: string;
//     category: MedicalRecordsCategory.threeStrings;
//     config: {
//         label: string;
//         labelOne: string;
//         labelTwo: string;
//         labelThree: string;
//     };
// };

// type SingleCheckboxesMedicalRecord = {
//     type: string;
//     category: MedicalRecordsCategory.singleCheckbox;
//     config: {
//         label: string;
//     };
// };

// type TwoCheckboxesMedicalRecord = {
//     type: string;
//     category: MedicalRecordsCategory.twoCheckboxes;
//     config: {
//         label: string;
//         labelOne: string;
//         labelTwo: string;
//     };
// };

// type ThreeCheckboxesMedicalRecord = {
//     type: string;
//     category: MedicalRecordsCategory.threeCheckboxes;
//     config: {
//         label: string;
//         labelOne: string;
//         labelTwo: string;
//         labelThree: string;
//     };
// };

// export type CustomMedicalRecord = {
//     type: string;
//     category: MedicalRecordsCategory,
//     config:
// };
