import { all, put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchPlayerEvaluationsForEvent,
    fetchEvaluationsForEvent,
    fetchEvaluationData,
    createEvaluation,
    editEvaluation,
    fetchEvaluationsListByRange,
} from 'gateways/evaluationsGateway';
import * as evaluationsActions from './evaluations.actions';
import actionTypes from './evaluations.actionTypes';
import Evaluation, { EvaluationData } from './Evaluation';

export function* fetchEvaluationsForEventSaga({
    eventId,
}: ReturnType<typeof evaluationsActions.fetchEvaluationsForEvent>) {
    try {
        const evaluationsList: Evaluation[] = yield call(fetchEvaluationsForEvent, eventId);
        yield put(evaluationsActions.evaluationsListRecieved(evaluationsList));
    } catch (e) {
        console.error(e);
    }
}

export function* fetchPlayerEvaluationsForEventSaga({
    eventId,
    playerId,
}: ReturnType<typeof evaluationsActions.fetchPlayerEvaluationsForEvent>) {
    try {
        const evaluationsList: Evaluation[] = yield call(fetchPlayerEvaluationsForEvent, {
            eventId,
            playerId,
        });
        yield put(evaluationsActions.evaluationsListRecieved(evaluationsList));
    } catch (e) {
        console.error(e);
    }
}

export function* fetchEvaluationDataSaga({
    organizationId,
    teamId,
    playerId,
    evaluationId,
}: {
    organizationId: string;
    teamId: string;
    playerId?: string;
    evaluationId: string;
}) {
    try {
        const evaluation: Evaluation = yield call(fetchEvaluationData, {
            organizationId,
            teamId,
            playerId,
            evaluationId,
        });
        yield put(evaluationsActions.evaluationReceived(evaluation));
    } catch (e) {
        console.error(e);
    }
}

export function* createEvaluationSaga({ evaluationData }: { evaluationData: EvaluationData }) {
    try {
        const evaluationId: string = yield call(createEvaluation, evaluationData);
        const { organizationId, teamId, playerId } = evaluationData;
        yield call(fetchEvaluationDataSaga, {
            organizationId,
            teamId,
            playerId,
            evaluationId,
        });
    } catch (e) {
        console.error(e);
    }
}

export function* editEvaluationSaga({
    evaluationData,
    evaluationId,
}: {
    evaluationData: EvaluationData;
    evaluationId: string;
}) {
    try {
        yield call(editEvaluation, {
            evaluationData,
            evaluationId,
        });
        const { organizationId, teamId, playerId } = evaluationData;
        // @ts-ignore
        yield call(fetchEvaluationDataSaga, {
            organizationId,
            teamId,
            playerId,
            evaluationId,
        });
    } catch (e) {
        console.error(e);
    }
}

export function* fetchEvaluationsListSaga({
    organizationId,
    teamId,
    playerId,
    from,
    to,
}: ReturnType<typeof evaluationsActions.fetchEvaluationsList>) {
    try {
        const evaluationsList: Evaluation[] = yield call(fetchEvaluationsListByRange, {
            organizationId,
            teamId,
            playerId,
            from,
            to,
        });
        yield put(evaluationsActions.evaluationsListRecieved(evaluationsList));
    } catch (e) {
        console.error(e);
    }
}

function* evaluationsSagas() {
    yield all([
        takeEvery(actionTypes.REQUEST_EVALUATIONS_FOR_EVENT, fetchEvaluationsForEventSaga),
        takeEvery(
            actionTypes.REQUEST_PLAYER_EVALUATIONS_FOR_EVENT,
            fetchPlayerEvaluationsForEventSaga,
        ),
        takeEvery(actionTypes.REQUEST_EVALUATIONS_LIST, fetchEvaluationsListSaga),
    ]);
}

export default evaluationsSagas;
