// @ts-ignore
window.dataLayer = window.dataLayer || [];

export enum GtmCustomEvents {
    createAccount = 'create_account',
    createClub = 'create_club',
    createTeam = 'create_team',
    planUpgradeMessage = 'plan_upgrade_message',
}

type GtmEvent = {
    event: string;
    [key: string]: string;
};

export const logEvent = (gtmEvent: GtmEvent) => {
    // @ts-ignore
    window.dataLayer.push(gtmEvent);
};
