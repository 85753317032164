import { takeLatest, all, put, call } from 'redux-saga/effects';
import * as usersGateway from 'gateways/usersGateway';
import userActionTypes from './users.actionTypes';
import { setUserData, updateUserData, requestUserData } from './users.actions';
import User from './User';

export function* fetchUserDataSaga({ userId }: ReturnType<typeof requestUserData>) {
    try {
        const userData: User = yield call([usersGateway, usersGateway.fetchUser], { userId });
        yield put(setUserData(userData));
    } catch (e: any) {
        throw new Error(e);
    }
}

export function* updateUserDataSaga({ userId, userData }: ReturnType<typeof updateUserData>) {
    try {
        yield call([usersGateway, usersGateway.updateUser], { userId, userData });
        yield put(requestUserData({ userId }));
    } catch (e: any) {
        throw new Error(e);
    }
}

function* userSaga() {
    yield all([
        takeLatest(userActionTypes.REQUEST_USER_DATA, fetchUserDataSaga),
        takeLatest(userActionTypes.UPDATE_USER_DATA, updateUserDataSaga),
    ]);
}

export default userSaga;
