import * as leaguesGateway from 'gateways/leaguesGateway';
import { put, call, all, takeLatest, takeEvery } from 'redux-saga/effects';
import * as leaguesActions from './leagues.actions';
import League from './Leagues';
import leaguesActionTypes from './leagues.actionTypes';

export function* fetchLeagueDataSaga({
    leagueId,
    onSuccess,
}: ReturnType<typeof leaguesActions.leagueDataRequest>) {
    try {
        const league: League = yield call(leaguesGateway.fetchLeagueById, {
            leagueId,
        });
        if (league) {
            yield put(leaguesActions.leagueDataRecieved(league));
        }
        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

export function* fetchLeaguesListsSaga({
    organizationId,
    onSuccess,
}: ReturnType<typeof leaguesActions.leaguesListRequest>) {
    try {
        const leaguesList: League[] = yield call(
            [leaguesGateway, leaguesGateway.fetchLeaguesList],
            {
                organizationId,
            },
        );
        if (leaguesList) {
            yield put(leaguesActions.leaguesListRecieved(leaguesList));
        }

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

function* leaguesSagas() {
    yield all([
        takeLatest(leaguesActionTypes.LEAGUE_DATA_REQUEST, fetchLeagueDataSaga),
        takeEvery(leaguesActionTypes.LEAGUES_LIST_REQUEST, fetchLeaguesListsSaga),
    ]);
}

export default leaguesSagas;
