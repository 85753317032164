import Player from 'entities/players/Player';
import Game, { GameData } from './Game';
import Session, { SessionData } from './Session';
import Holiday, { HolidayData } from './Holiday';
import RecoveryDay, { RecoveryDayData } from './RecoveryDay';
import GeneralEvent, { GeneralEventData } from './GeneralEvent';

export type EventPlayerInfo = Pick<
    Player,
    | 'id'
    | 'lastName'
    | 'firstName'
    | 'enLastName'
    | 'enFirstName'
    | 'filePath'
    | 'playerNumber'
    | 'positions'
    | 'teams'
    | 'userId'
>;

export enum EventTypes {
    session = 'session',
    game = 'game',
    holiday = 'holiday',
    general = 'general',
    recovery = 'recovery',
}

type Event = Session | Game | Holiday | GeneralEvent | RecoveryDay;

export type EventData = SessionData | GameData | HolidayData | GeneralEventData | RecoveryDayData;

export default Event;
