import { MethodologyData } from 'entities/methodologies/Methodology';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { functions, db } from '../common/firebase';
import { handleDocumentSnapshot } from './utils';

export const getMethodologyDrillFilesPath = (methodologyId: string, drillId: string) => {
    return `methodologies/${methodologyId}/drills/${drillId}`;
};

export const fetchMethodologyById = ({ methodologyId }: { methodologyId: string }) => {
    const methodologyRef = doc(db, `methodologies/${methodologyId}`);

    return getDoc(methodologyRef).then(handleDocumentSnapshot);
};

export const updateMethodology = ({
    methodologyId,
    methodologyData,
}: {
    methodologyData: MethodologyData;
    methodologyId: string;
}) => {
    const methodologyRef = doc(db, `methodologies/${methodologyId}`);

    return updateDoc(methodologyRef, methodologyData);
};

const createMethodologyFunction = httpsCallable(functions, 'createMethodology');
export const createMethodology = ({ methodologyName }: { methodologyName: string }) => {
    return createMethodologyFunction({ methodologyName });
};

const deleteMethodologyFunction = httpsCallable(functions, 'deleteMethodology');
export const deleteMethodology = ({ methodologyId }: { methodologyId: string }) => {
    return deleteMethodologyFunction({ methodologyId });
};

export const grantMethodologyAccess = httpsCallable(functions, 'grantMethodologyAccess');
