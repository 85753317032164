import React, { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import { DoneIcon, FileCopyIcon } from 'common/icons';
import { ButtonBase, Box } from 'common/components';

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    background: theme.custom.background.light,
    padding: theme.spacing(0.5, 1),
}));

type CopyButtonProps = {
    textToCopy: string;
    className?: string;
    children?: React.ReactNode;
    sx?: any;
};

const CopyButton: React.FC<CopyButtonProps> = React.forwardRef(
    ({ className, children, textToCopy, sx, ...rest }, ref: any) => {
        const [isCopied, setIsCopied] = useState<boolean>(false);
        const handleCopy = useCallback(() => {
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);

                setTimeout(() => {
                    setIsCopied(false);
                }, 1000);
            });
        }, [textToCopy]);

        return (
            <StyledButtonBase
                disabled={isCopied}
                ref={ref}
                sx={{
                    ...sx,
                    backgroundColor: (theme) =>
                        isCopied ? theme.custom.colors.green.light : undefined,
                }}
                className={className}
                onClick={handleCopy}
                {...rest}
            >
                {isCopied ? (
                    <DoneIcon
                        sx={{
                            opacity: 0.5,
                            width: 16,
                            color: (theme) => isCopied && theme.custom.colors.green.dark,
                        }}
                    />
                ) : (
                    <FileCopyIcon sx={{ opacity: 0.5, width: 16 }} />
                )}
                {children && (
                    <Box
                        component="span"
                        sx={{
                            ml: 0.5,
                            color: (theme) =>
                                isCopied ? theme.custom.colors.green.dark : 'inherit',
                        }}
                    >
                        {children}
                    </Box>
                )}
            </StyledButtonBase>
        );
    },
);

export default CopyButton;
