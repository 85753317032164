import { takeLatest, all, put, call } from 'redux-saga/effects';
import * as invitationsGateway from 'gateways/invitationsGateway';
import { EmailData } from 'entities/emails/Emails';
import { getEmailContent } from 'entities/emails/emails.utils';
import { createEmail } from 'entities/emails/emails.actions';
import invitationsActionTypes from './invitations.actionTypes';
import * as invitationsActions from './invitations.actions';
import Invitation, { UserInvitations } from './Invitation';

export function* fetchInvitationDataSaga({
    target,
    accessType,
    invitationId,
}: ReturnType<typeof invitationsActions.requestInvitationData>) {
    try {
        const invitationData: Invitation = yield call(
            [invitationsGateway, invitationsGateway.fetchInvitationData],
            { invitationId, accessType, target },
        );
        yield put(invitationsActions.invitationDataReceived({ invitationData }));
    } catch (e) {
        console.error(e);
    }
}

export function* fetchInvitationsListSaga({
    target,
    accessType,
}: ReturnType<typeof invitationsActions.requestInvitationsList>) {
    try {
        const invitationsList: Invitation[] = yield call(
            [invitationsGateway, invitationsGateway.fetchInvitationsList],
            {
                target,
                accessType,
            },
        );
        yield put(invitationsActions.invitationsListReceived({ invitationsList }));
    } catch (e) {
        console.error(e);
    }
}

export function* createInvitationSaga({
    invitationData,
    successMessage,
    t,
}: ReturnType<typeof invitationsActions.createInvitation>) {
    try {
        const invitationId: string = yield call(
            [invitationsGateway, invitationsGateway.createInvitation],
            invitationData,
        );
        const emailData: EmailData = {
            to: invitationData.userEmail,
            message: {
                subject: `You are invited to ${invitationData.targetName}`,
                html: getEmailContent(
                    t('emails.accessInvitation', {
                        targetName: invitationData.targetName,
                        email: invitationData.userEmail,
                    }),
                ),
            },
        };
        yield put(createEmail({ emailData, successMessage }));
        const { accessType, target } = invitationData;
        yield put(
            invitationsActions.requestInvitationData({
                invitationId,
                accessType,
                target,
            }),
        );
    } catch (e) {
        console.error(e);
    }
}

export function* updateInvitationSaga({
    invitationId,
    invitationData,
}: ReturnType<typeof invitationsActions.updateInvitation>) {
    try {
        yield call([invitationsGateway, invitationsGateway.updateInvitation], {
            invitationId,
            invitationData,
        });
        yield put(invitationsActions.requestInvitationData({ ...invitationData, invitationId }));
    } catch (e) {
        console.error(e);
    }
}

export function* deleteInvitationSaga({
    invitationId,
    accessType,
    target,
    onSuccess,
    onFail,
}: ReturnType<typeof invitationsActions.deleteInvitation>) {
    try {
        yield call([invitationsGateway, invitationsGateway.deleteInvitation], {
            accessType,
            target,
            invitationId,
        });
        yield put(invitationsActions.removeInvitationFromListAction({ invitationId }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        if (onFail) {
            yield call(onFail);
        }

        console.error(e);
    }
}

export function* fetchUserInvitationsSaga() {
    try {
        const { data } = yield call(invitationsGateway.getUserInvitations);
        const { playerToLink, invitationsList } = data as UserInvitations;
        yield put(invitationsActions.invitationsListReceived({ invitationsList }));

        if (playerToLink) {
            yield put(invitationsActions.setPlayerToLink(playerToLink));
        }
    } catch (e) {
        console.error('fetchUserInvitationsSaga', e);
    }
}

function* invitationsSagas() {
    yield all([
        takeLatest(invitationsActionTypes.REQUEST_INVITATIONS_LIST, fetchInvitationsListSaga),
        takeLatest(invitationsActionTypes.REQUEST_INVITATION_DATA, fetchInvitationDataSaga),
        takeLatest(invitationsActionTypes.CREATE_INVITATION, createInvitationSaga),
        takeLatest(invitationsActionTypes.DELETE_INVITATION, deleteInvitationSaga),
        takeLatest(invitationsActionTypes.UPDATE_INVITATION, updateInvitationSaga),
        takeLatest(invitationsActionTypes.FETCH_USER_INVITATIONS, fetchUserInvitationsSaga),
    ]);
}

export default invitationsSagas;
