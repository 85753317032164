import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Organization from './Organization';
import { organizationDataRequest } from './organizations.actions';
import { RootState } from 'store';
import { organizationSelector } from './organizations.selectors';

export const useOrganization = (organizationId: string) => {
    const dispatch = useDispatch();
    const organization = useSelector((state: RootState) =>
        organizationSelector(state, { organizationId }),
    ) as Organization | undefined;

    useEffect(() => {
        if (!organization) {
            dispatch(organizationDataRequest({ organizationId }));
        }
    }, [organization]);

    return organization;
};
