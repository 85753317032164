import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from 'common/components';
import { getStylesByCoordinates } from './positionsCoordinates';

const PREFIX = 'Player';

const classes = {
    pointer: `${PREFIX}-pointer`,
    caption: `${PREFIX}-caption`,
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.pointer}`]: {
        borderRadius: '50%',
        position: 'absolute',
        borderStyle: 'solid',
        borderWidth: 2,
    },

    [`& .${classes.caption}`]: {
        position: 'absolute',
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        width: '300%',
        bottom: `calc(100% + 8px)`,
        left: '-100%',
        border: `1px solid ${theme.palette.grey[300]}`,
    },
}));

type PlayerProps = {
    position: string;
    priority: number;
    name: string;
    circleSize: number;
};

const Player: React.FC<PlayerProps> = ({ position, priority, name, circleSize }) => {
    return (
        <Root
            style={getStylesByCoordinates({ position, priority, circleSize })}
            className={classes.pointer}
            key={position}
        >
            {name && (
                <Typography className={classes.caption} align="center" noWrap>
                    {name}
                </Typography>
            )}
        </Root>
    );
};

export default Player;
