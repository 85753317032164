import { createSelector } from 'reselect';
import { RootState } from 'store';
import Organization from './Organization';

export const organizationsHashSelector = (state: RootState) =>
    state.entities.organizations.organizationsHash;

export const organizationsSotrageInfoHashSelector = (state: RootState) =>
    state.entities.organizations.organizationsStorageInfoHash;

export const organizationsPlayersInfoSelector = (state: RootState) =>
    state.entities.organizations.organizationsPlayersInfoHash;

export const organizationsClubsInfoSelector = (state: RootState) =>
    state.entities.organizations.organizationsClubsInfoHash;

export const organizationsListSelector = createSelector(
    organizationsHashSelector,
    (organizationsHash) => {
        const organizationsList: Organization[] = Object.values(organizationsHash);
        organizationsList.sort((a, b) => {
            if (!a?.createDate) {
                return 1;
            }

            if (!b?.createDate) {
                return -1;
            }

            return b.createDate.getTime() > a.createDate.getTime() ? 1 : -1;
        });
        return organizationsList;
    },
);

export const organizationSelector = createSelector(
    organizationsHashSelector,
    (_: any, { organizationId }: { organizationId: string }) => organizationId,
    (organizationsHash, organizationId) => organizationsHash[organizationId],
);

export const organizationStorageInfoSelector = createSelector(
    organizationsSotrageInfoHashSelector,
    (_: any, { organizationId }: { organizationId: string }) => organizationId,
    (organizationsSotrageInfoHash, organizationId) => organizationsSotrageInfoHash[organizationId],
);

export const organizationPlayersInfoSelector = createSelector(
    organizationsPlayersInfoSelector,
    (_: any, { organizationId }: { organizationId: string }) => organizationId,
    (organizationsPlayersInfoHash, organizationId) => organizationsPlayersInfoHash[organizationId],
);

export const organizationClubsInfoSelector = createSelector(
    organizationsClubsInfoSelector,
    (_: any, { organizationId }: { organizationId: string }) => organizationId,
    (organizationsClubsInfoHash, organizationId) => organizationsClubsInfoHash[organizationId],
);
