import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import clsx from 'clsx';
import { IconButton, Slide, useMediaQuery, useTheme } from '@mui/material';

const PREFIX = 'VideoDialog';

const classes = {
    fullHeight: `${PREFIX}-fullHeight`,
    closeBtn: `${PREFIX}-closeBtn`,
    colseIcon: `${PREFIX}-colseIcon`,
};

const StyledMuiDialog = styled(MuiDialog)(({ theme }) => ({
    [`& .MuiDialog-paper`]: {
        background: '#000000',
        margin: 0,
    },
    [`& .MuiPaper-root`]: {
        margin: 0,
    },
    [`& .MuiBackdrop-root`]: {
        backgroundColor: 'rgba(32, 38, 45, 0.2)',
        backdropFilter: 'blur(2px)',
    },

    [`& .${classes.fullHeight}`]: {
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            margin: 0,
            height: '100vh',
            minHeight: '100vh',
            maxHeight: '100vh',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    [`& .${classes.closeBtn}`]: {
        position: 'fixed',
        top: 16,
        right: 16,
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        width: 64,
        height: 64,
        borderRadius: 0,
        zIndex: 9999999999,

        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
        },

        [theme.breakpoints.up('sm')]: {
            width: 48,
            height: 48,
        },
    },
    [`& .${classes.colseIcon}`]: {
        fontSize: 32,
        color: '#fff',
        transform: 'rotate(45deg)',
    },
}));

const Transition = React.forwardRef((props: any, ref) => {
    return <Slide direction="left" ref={ref} {...props} />;
});

export type VideoDialogProps = MuiDialogProps & {
    className?: string;
    dialogClassName?: string;
    fullHeight?: boolean;
    showCloseBtn?: boolean;
    mobileFullScreen?: boolean;
    onClose?: any;
};

const VideoDialog: React.FC<VideoDialogProps> = ({
    className,
    dialogClassName,
    fullHeight,
    showCloseBtn,
    open,
    children,
    mobileFullScreen,
    ...restProps
}) => {
    const theme = useTheme();
    const isFullScreen = mobileFullScreen && useMediaQuery(theme.breakpoints.only('xs'));

    return (
        <StyledMuiDialog
            classes={{
                root: dialogClassName,
                paper: clsx(className, fullHeight && classes.fullHeight),
            }}
            open={open}
            fullScreen={isFullScreen}
            TransitionComponent={Transition}
            {...restProps}
        >
            {showCloseBtn && open && (
                <IconButton className={classes.closeBtn} onClick={restProps.onClose}>
                    <span className={classes.colseIcon}>+</span>
                </IconButton>
            )}
            {children}
        </StyledMuiDialog>
    );
};

export default VideoDialog;
