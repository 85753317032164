import { Episode, EpisodesType } from 'entities/episodes/Episodes';
import { PlayerData } from 'entities/players/Player';
import { Periods } from 'features/report/ReportPageTypes';

export type ReportParams = {
    organizationId: string;
    teamId: string;
    eventId: string;
};

export type Player = Pick<
    PlayerData,
    'firstName' | 'lastName' | 'dateOfBirth' | 'foot' | 'playerNumber' | 'potential' | 'quality'
> & {
    playerId: string | null;
    providerPlayerId?: string;
    matchNumber: string | null;
    position: string | null;
    fileName: string | null;
    notes: string;
    observationId: string | null;
};

export enum TeamStatus {
    home = 'home',
    guest = 'guest',
}

export type Team = {
    providerTeamId?: string;
    name: string;
    status: TeamStatus;
    players: Player[];
};

export enum ReportStatus {
    new = 'new',
    processed = 'processed',
    processing = 'processing',
    removed = 'removed',
    error = 'error',
}

export type PlayerNumbersMap = { [key: string]: string };

export enum ReportSummaryGroups {
    defenceBlockWidth = 'defenceBlockWidth',
    defenceBlockHeight = 'defenceBlockHeight',
    defenceLineDistances = 'defenceLineDistances',
    defencePressing = 'defencePressing',
    ballLoss = 'ballLoss',
    ballGain = 'ballGain',
    progressions = 'progressions',
    optionsBetweenLines = 'optionsBetweenLines',
    finishingZonesAttack = 'finishingZonesAttack',
    finishingZonesCoverage = 'finishingZonesCoverage',
    secondBall = 'secondBall',
    // TODO: currently it manual tags are separate from tracking. Align those two
    highPressing = 'highPressing',
    noPressureAndHighDefLine = 'noPressureAndHighDefLine',
}

export type DurationSummary = {
    time: number;
    percentage: number;
    successRate?: number;
};

export type DefenceDistancesSummary = {
    [key in TeamStatus]: DurationSummary;
};

export type PressingSummary = {
    [key in
        | EpisodesType.lowPressing
        | EpisodesType.middlePressing
        | EpisodesType.highPressing]: DurationSummary;
};

export type CountSummary = {
    totalCount: number;
    activeCount: number;
};

export type BallLossSummary = {
    [key in EpisodesType.ballLossFar | EpisodesType.ballLossBallArea]: CountSummary;
};

export type BallGainSummary = {
    episodesCount: number;
    passLinesAvailable: number;
    teamSpaceIncreased: number;
    runsToDepth: number;
    deliveredToFreeSpace: number;
    passToDepthOpportunities: number;
};

export type TeamProgressionSummary = {
    [EpisodesType.progressiveRuns]: number;
    [EpisodesType.progressiveRunOpportunitiesTaken]: number;
    [EpisodesType.progressiveRunOpportunitiesMissed]: number;
};

export type PlayerProgressionSummary = TeamProgressionSummary & {
    playerNumber: string;
};

export type ProgressionsSummary = {
    team: TeamProgressionSummary;
    players: PlayerProgressionSummary[];
};

export type PeriodSummary = {
    [ReportSummaryGroups.defenceBlockWidth]: {
        [key in
            | EpisodesType.narrowDefenceBlockWidth
            | EpisodesType.normalDefenceBlockWidth
            | EpisodesType.wideDefenceBlockWidth]: DefenceDistancesSummary;
    };
    [ReportSummaryGroups.defenceBlockHeight]: {
        [key in
            | EpisodesType.flattenedDefenceBlockHeight
            | EpisodesType.normalDefenceBlockHeight
            | EpisodesType.expandedDefenceBlockHeight]: DefenceDistancesSummary;
    };
    [ReportSummaryGroups.defenceLineDistances]: {
        [key in
            | EpisodesType.corridorsBetweenDefencePlayers
            | EpisodesType.goodDefencePlayersDistances
            | EpisodesType.narrowDefenceLine]: DefenceDistancesSummary;
    };
    [ReportSummaryGroups.defencePressing]: {
        [key in TeamStatus]: PressingSummary;
    };
    [ReportSummaryGroups.ballLoss]: {
        [key in TeamStatus]: BallLossSummary;
    };
    [ReportSummaryGroups.ballGain]: {
        [key in TeamStatus]: BallGainSummary;
    };
    [ReportSummaryGroups.progressions]: {
        [key in TeamStatus]: ProgressionsSummary;
    };
};

export type ReportSummary = {
    [key: string]: PeriodSummary;
};

export type FavouriteEpisode = {
    episodeData: Episode;
    notes: string;
    period: number;
    type: EpisodesType;
};

export enum ReportProgressStep {
    parseFiles = 'parseFiles',
    preprocessP1 = 'preprocessP1',
    preprocessP2 = 'preprocessP2',
    analysisP1 = 'analysisP1',
    analysisP2 = 'analysisP2',
}

type TeamIntencity = {
    '5': number[];
    '15': number[];
    period: number[];
};

export type IntencityRecord = {
    home: TeamIntencity;
    guest: TeamIntencity;
};

export type PeriodIntencity = {
    total?: IntencityRecord;

    attack?: IntencityRecord;
    optionsBetweenLines?: IntencityRecord;
    depthAttack?: IntencityRecord;
    secondBallMidfield?: IntencityRecord;
    readyForLoss?: IntencityRecord;
    attckFz?: IntencityRecord;

    attackDefence?: IntencityRecord;
    tackle?: IntencityRecord;
    compression?: IntencityRecord;
    pressingAfterLoss?: IntencityRecord;

    defence?: IntencityRecord;
    saveCompact?: IntencityRecord;
    defenceFz?: IntencityRecord;

    defenceAttack?: IntencityRecord;
    depthAttackAfterTackle?: IntencityRecord;
    ballToFreeSpace?: IntencityRecord;
    crossTackleLine?: IntencityRecord;
};

export type Intencity = {
    [Periods.one]: PeriodIntencity;
    [Periods.two]: PeriodIntencity;
};

export type ReportData = {
    dataProvider: string | null;
    frameRate: number | null;
    providerMatchId: string | null;
    dateCreated: Date;
    creator: {
        userId: string;
        userName: string;
    };
    matchDate: Date;
    pitchName: string | null;
    notes: string;
    ball: {
        fileName: string | null;
    };
    pitchSize: {
        width: number;
        height: number;
    };
    teams: Team[];
    status: ReportStatus;
    files: string[];
    summary: ReportSummary | null;
    intencity: Intencity | null;
    favouriteEpisodes: FavouriteEpisode[];
    challengeId: string | null;
    eligibleManualTags?: EpisodesType[];
    reportType: string | null;
    competition: string | null;
    references: {
        [key: string]: string;
    };
    score: string | null;
};

type Report = ReportData & {
    id: string;
    teamId: string;
};

export default Report;

/* Example for unit tests */
export const reportExample: Report = {
    id: 'event-id-1',
    teamId: 'team-id-1',
    dataProvider: 'data-provider-1',
    frameRate: 10,
    dateCreated: new Date(),
    creator: {
        userId: 'creator-id-1',
        userName: 'creator-name-1',
    },
    matchDate: new Date(),
    notes: '',
    providerMatchId: null,
    pitchName: null,
    summary: null,
    intencity: null,
    ball: {
        fileName: 'ball-file-1',
    },
    pitchSize: {
        width: 105,
        height: 68,
    },
    favouriteEpisodes: [],
    teams: [],
    status: ReportStatus.new,
    files: ['file-1', 'file-2'],
    challengeId: null,
    reportType: null,
    competition: null,
    references: {},
    score: null,
};
