import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'common/components';
import { VideoPlayBtn } from './VideoPlayBtn';
import { TutorialConfig, TutorialName, tutorialsConfig } from './videoTutorial.config';
import { userDataSelector } from 'entities/users/users.selectors';
import { useSelector } from 'react-redux';
import { Language } from 'entities/users/User';

const StyledVideoTutorial = styled('div')(({ theme }) => ({
    display: 'flex',
    backgroundColor: theme.custom.background.light,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    width: '100%',
    maxWidth: 400,
}));

const getVideoUrl = (tutorialConfig: TutorialConfig, selectedLang: Language) => {
    const { videoUrls } = tutorialConfig;
    const videoUrl = videoUrls[selectedLang] || videoUrls[Language.en];

    if (videoUrl) {
        return videoUrl;
    }

    const availableVideoUrl = Object.values(videoUrls).find(Boolean);

    return availableVideoUrl || null;
};

type VideoTutorialProps = {
    tutorialName: TutorialName;
    sx?: any;
};

export const VideoTutorial: React.FC<VideoTutorialProps> = ({ tutorialName, sx, ...rest }) => {
    const { t } = useTranslation();
    const user = useSelector(userDataSelector);
    const selectedLang: Language = user?.lang || Language.en;
    const tutorialConfig = tutorialsConfig[tutorialName];
    const { titleTranslationKey, descriptionTranslationKey } = tutorialConfig;
    const videoUrl = getVideoUrl(tutorialConfig, selectedLang);

    if (!videoUrl) {
        return null;
    }

    return (
        <StyledVideoTutorial sx={sx} {...rest}>
            <VideoPlayBtn videoUrl={videoUrl} size={48} sx={{ mr: 2, my: 'auto' }} />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {titleTranslationKey && (
                    <Typography variant="body1">{t(titleTranslationKey)}</Typography>
                )}
                {descriptionTranslationKey && (
                    <Typography variant="body2">{t(descriptionTranslationKey)}</Typography>
                )}
            </Box>
        </StyledVideoTutorial>
    );
};
