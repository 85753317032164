import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Event, { EventTypes } from 'entities/events/Event';
import Session from 'entities/events/Session';
import EventsList from './EventsList';
import AssistantBase from './AssistantBase';

export const getNoDrillsSessions = (eventsList: Event[]) => {
    const sessionsList = eventsList.filter(
        ({ eventType }) => eventType === EventTypes.session,
    ) as Session[];
    return sessionsList.filter((session) => session.details.drills.length === 0);
};

type NoDrillsProps = {
    teamId: string;
    organizationId: string;
    eventsList: Event[];
    onClose: VoidFunction;
};

const NoDrills: React.FC<NoDrillsProps> = ({ organizationId, teamId, eventsList, onClose }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onSelectEvent = useCallback(
        (event: Event) => {
            onClose();
            navigate(
                `/organizations/${organizationId}/teams/${teamId}/planning/sessions/${event.id}`,
            );
        },
        [teamId, organizationId, navigate],
    );

    if (eventsList.length === 0) {
        return null;
    }

    return (
        <AssistantBase
            description={t('assistant.noDrills.description')}
            content={<EventsList onSelect={onSelectEvent} eventsList={eventsList} />}
            icon="💪"
        >
            {/* <HelpLink href="https://undersports.club/ru/session">
                {t('assistant.noDrills.helpBtn')}
            </HelpLink> */}
        </AssistantBase>
    );
};

export default NoDrills;
