export default {
    SET_EPISODES_TYPE: 'UI/REPORT/SET_EPISODES_TYPE',
    SET_EPISODES_FILTER_NAME: 'UI/REPORT/SET_EPISODES_FILTER_NAME',
    SET_TEAM: 'UI/REPORT/SET_TEAM',
    SET_PLAYER: 'UI/REPORT/SET_PLAYER',
    SET_PITCH_AREA: 'UI/REPORT/SET_PITCH_AREA',
    SET_PERIOD: 'UI/REPORT/SET_PERIOD',
    SET_TIME: 'UI/REPORT/SET_TIME',
    CLEAR_FAVOURITES: 'UI/REPORT/CLEAR_FAVOURITES',
    TOGGLE_FAVOURITE: 'UI/REPORT/TOGGLE_FAVOURITE',
    UPDATE_FAVOURITE_EPISODE: 'UI/REPORT/UPDATE_FAVOURITE_EPISODE',
};
