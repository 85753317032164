import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import _set from 'lodash/set';
import eventsActionTypes from './events.actionTypes';
import { GameUpdate } from './GameUpdates';

const initialState = {
    eventsHash: {},
    eventsUpdatesHash: {},
};

type State = {
    eventsHash: Record<string, Event>;
    eventsUpdatesHash: Record<string, GameUpdate>;
};

const events = (state: State = initialState, action: any) => {
    switch (action.type) {
        case eventsActionTypes.EVENT_DATA_RECEIVED:
            return {
                ...state,
                eventsHash: {
                    ..._set(state.eventsHash, action.eventData.id, action.eventData),
                },
            };
        case eventsActionTypes.REMOVE_EVENTS_FROM_LIST:
            return {
                ...state,
                eventsHash: { ..._omit(state.eventsHash, action.eventsIds) },
            };
        case eventsActionTypes.EVENTS_LIST_RECEIVED:
            return {
                ...state,
                eventsHash: {
                    ...state.eventsHash,
                    ..._keyBy(action.events, 'id'),
                },
            };
        case eventsActionTypes.EVENT_UPDATES_LIST_RECEIVED:
            return {
                ...state,
                eventsUpdatesHash: {
                    ...state.eventsUpdatesHash,
                    ..._keyBy(action.eventUpdatesList, 'id'),
                },
            };
        default:
            return state;
    }
};

export default events;
