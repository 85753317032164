import React from 'react';
import { Typography } from 'common/components';
import { useSelector } from 'react-redux';
import { Language } from 'entities/users/User';
import { userDataSelector } from 'entities/users/users.selectors';

export enum DocsLinks {
    howToStart = 'howToStart',
    howToCreateTeam = 'howToCreateTeam',
    howToCreateSession = 'howToCreateSession',
    playerGoals = 'playerGoals',
    movePlayers = 'movePlayers',
    createPlayer = 'createPlayer',
    challenge = 'challenge',
    accessManagement = 'accessManagement',
    calendarTypes = 'calendarTypes',
    scheduleEvents = 'scheduleEvents',
}

type DocsLinkProps = {
    page: DocsLinks;
    title: string;
    className?: string;
};

const docsMap = {
    [DocsLinks.howToStart]: {
        [Language.en]: 'https://blog.undersports.club/how-to-start-with-undersports-49c95003a855',
    },
    [DocsLinks.howToCreateTeam]: {
        [Language.en]: 'https://blog.undersports.club/how-to-create-a-team-86a1ce984e05',
    },
    [DocsLinks.challenge]: {
        [Language.en]: 'https://blog.undersports.club/undersports-challenge-1ee9f2b4b851',
    },
};

const DocsLink: React.FC<DocsLinkProps> = ({ page, title, className }) => {
    const user = useSelector(userDataSelector);

    const lang = user?.lang || Language.en;
    // @ts-ignore
    const enLink = docsMap[page]?.[Language.en];
    // @ts-ignore
    const link = docsMap[page]?.[lang] || enLink;

    if (!link) {
        return null;
    }

    return (
        <Typography
            sx={{
                opacity: 0.5,
                textDecoration: 'underline',
                textAlign: 'center',

                '&:hover': {
                    opacity: 1,
                },
            }}
            component="a"
            href={link}
            target="_blank"
            className={className}
        >
            {title}
        </Typography>
    );
};

export default DocsLink;
