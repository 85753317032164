import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'common/components';
import { InjuryIcon } from 'common/icons';
import TeamStats from 'entities/teams/TeamStats';
import StatsSection from './StatsSection';

export type InjuriesInfoProps = {
    teamStats: TeamStats;
};

const InjuriesInfo: React.FC<InjuriesInfoProps> = ({ teamStats }) => {
    const { t } = useTranslation();
    const { newInjuries, healedInjuries, activeInjuries } = teamStats.injuries;
    const contentText = [
        `${t('teamPage.teamStats.injuries.newInjuries')}: ${newInjuries}`,
        `${t('teamPage.teamStats.injuries.healedInjuries')}: ${healedInjuries}`,
        `${t('teamPage.teamStats.injuries.activeInjuries')}: ${activeInjuries}`,
    ].join(' | ');

    return (
        <StatsSection title={t('teamPage.teamStats.injuries.title')} IconComponent={InjuryIcon}>
            <Typography variant="body2">{contentText}</Typography>
        </StatsSection>
    );
};

export default InjuriesInfo;
