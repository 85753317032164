import challengesPageActionTypes from './challengesPage.actionTypes';

export const submitSolution = ({ challengeId }: { challengeId: string }) => ({
    type: challengesPageActionTypes.SUBMIT_SOLUTION,
    challengeId,
});

export const submitSolutionSuccess = () => {
    return {
        type: challengesPageActionTypes.SUBMIT_SUCCESS,
    };
};

export const submitSolutionFail = () => {
    return {
        type: challengesPageActionTypes.SUBMIT_FAIL,
    };
};

export const downloadResultsCsv = ({
    challengeId,
    failMessage,
}: {
    challengeId: string;
    failMessage: string;
}) => {
    return {
        type: challengesPageActionTypes.DOWNLOAD_RESULTS_CSV,
        challengeId,
        failMessage,
    };
};
