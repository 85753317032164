import { takeLatest, all, takeEvery, call, put } from 'redux-saga/effects';

import * as snackbarActions from 'common/snackbar/snackbar.actions';
import * as solutionsGateway from 'gateways/solutionsGateway';
import solutionsActionTypes from './solutions.actionTypes';
import * as solutionsActions from './solutions.actions';
import Solution from './Solution';

export function* fetchSolutionDataSaga({
    solutionId,
    challengeId,
    failMessage,
    onFinish,
}: ReturnType<typeof solutionsActions.solutionDataRequest>) {
    try {
        const solutionData: Solution = yield call(solutionsGateway.fetchSolutionById, {
            solutionId,
            challengeId,
        });
        if (solutionData) {
            yield put(solutionsActions.solutionDataReceived({ solutionData }));
        }
    } catch (e) {
        if (failMessage) {
            yield put(snackbarActions.showError({ message: failMessage }));
        }
    }

    if (onFinish) {
        yield call(onFinish);
    }
}

export function* fetchSolutionsListSaga({
    challengeId,
}: ReturnType<typeof solutionsActions.solutionsListRequest>) {
    try {
        const solutionsList: Solution[] = yield call(solutionsGateway.fetchSolutionsList, {
            challengeId,
        });
        yield put(solutionsActions.solutionsListReceived({ solutionsList }));
    } catch (e) {
        console.error({
            e,
            _error: 'Failed to fetch solutions',
        });
    }
}

function* solutionsSaga() {
    yield all([
        takeEvery(solutionsActionTypes.SOLUTION_DATA_REQUEST, fetchSolutionDataSaga),
        takeLatest(solutionsActionTypes.SOLUTIONS_LIST_REQUEST, fetchSolutionsListSaga),
    ]);
}

export default solutionsSaga;
