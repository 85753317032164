import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { PrintIcon, SkillsIcon, ListIcon, InfoIcon } from 'common/icons';
import { Typography } from 'common/components';
import {
    Guide,
    MainBlock,
    MainText,
    Step,
    StepContent,
    StepDescription,
    StepsList,
} from './CommonGuideComponents';

const PREFIX = 'SkillsPageGuide';

const classes = {
    skillsIcon: `${PREFIX}-skillsIcon`,
    icon: `${PREFIX}-icon`,
    grades: `${PREFIX}-grades`,
    grade: `${PREFIX}-grade`,
    value: `${PREFIX}-value`,
};

const Root = styled(Guide)(({ theme }) => ({
    [`& .${classes.skillsIcon}`]: {
        fill: theme.palette.primary.main,
        fontSize: '72px',
        textAlign: 'center',
        margin: 'auto',
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.icon}`]: {
        opacity: 0.5,
        width: 32,
        height: 32,
        marginRight: theme.spacing(2),
    },

    [`& .${classes.grades}`]: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },

    [`& .${classes.grade}`]: {
        marginBottom: theme.spacing(0.5),
    },

    [`& .${classes.value}`]: {
        display: 'inline-flex',

        backgroundColor: theme.custom.background.light,
        padding: theme.spacing(0, 0.5),
        marginRight: theme.spacing(1),
        width: 32,
        flexShrink: 0,
        justifyContent: 'center',
    },
}));

const SkillsPageGuide: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Root>
            <MainBlock>
                <SkillsIcon className={classes.skillsIcon} />
                <MainText>{t('help.skillsPageGuide.description')}</MainText>
            </MainBlock>
            <StepsList>
                <Step>
                    <InfoIcon className={classes.icon} />
                    <StepContent>
                        <StepDescription>
                            {t('help.skillsPageGuide.valuesDescription')}
                        </StepDescription>
                        <div className={classes.grades}>
                            <Typography className={classes.grade}>
                                <span className={classes.value}>1-4</span>
                                {t('help.skillsPageGuide.value.low')}
                            </Typography>
                            <Typography className={classes.grade}>
                                <span className={classes.value}>5-7</span>
                                {t('help.skillsPageGuide.value.middle')}
                            </Typography>
                            <Typography className={classes.grade}>
                                <span className={classes.value}>8-9</span>
                                {t('help.skillsPageGuide.value.high')}
                            </Typography>
                            <Typography className={classes.grade}>
                                <span className={classes.value}>10</span>
                                {t('help.skillsPageGuide.value.extra')}
                            </Typography>
                        </div>
                    </StepContent>
                </Step>
                <Step>
                    <PrintIcon className={classes.icon} />
                    <StepContent>
                        <StepDescription>
                            {t('help.skillsPageGuide.printDescription')}
                        </StepDescription>
                    </StepContent>
                </Step>
                <Step>
                    <ListIcon className={classes.icon} />
                    <StepContent>
                        <StepDescription>
                            {t('help.skillsPageGuide.historyDescription')}
                        </StepDescription>
                    </StepContent>
                </Step>
            </StepsList>
        </Root>
    );
};

export default SkillsPageGuide;
