import { IconButton, Dialog, DialogContent, Tooltip } from 'common/components';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AddIcon } from 'common/icons';
import MethodologyForm from './MethodologyCreateBox';

const CreateMethodologyBtn: React.FC = () => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSuccess = useCallback(
        (methodologyId: string) => {
            navigate(`/methodologies/${methodologyId}`);
            setIsDialogOpen(false);
        },
        [navigate],
    );

    return (
        <>
            <Tooltip title={String(t('methodologyForm.tooltipText'))}>
                <IconButton size="small" sx={{ ml: 1 }} onClick={() => setIsDialogOpen(true)}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogContent>
                    <MethodologyForm
                        onSuccess={handleSuccess}
                        onClose={() => setIsDialogOpen(false)}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CreateMethodologyBtn;
