import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const YellowCardIcon = (props: any) => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g>
            <title>YellowCard</title>
            <rect
                x="4.25"
                y="1.25"
                width="15.5"
                height="21.5"
                rx="3.75"
                fill="#FFE600"
                stroke="#E4B12C"
                strokeWidth="0.5"
            />
        </g>
    </SvgIcon>
);

export default YellowCardIcon;
