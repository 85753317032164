import { takeLatest, all, put, call, takeEvery } from 'redux-saga/effects';
import * as goalsGateway from 'gateways/goalsGateway';
import actionTypes from './goals.actionTypes';
import * as goalsActions from './goals.actions';
import Goal from './Goal';

export function* fetchGoalDataSaga({
    playerId,
    goalId,
}: ReturnType<typeof goalsActions.goalDataRequest>) {
    try {
        const goal: Goal = yield goalsGateway.fetchGoal({ playerId, goalId });
        if (goal) {
            yield put(goalsActions.goalsListRecieved([goal]));
        }
    } catch (e) {
        console.error(
            {
                _error: `Failed to fetch goal ${goalId} for ${playerId}`,
            },
            e,
        );
    }
}

export function* fetchPlayerGoalsSaga({
    playerId,
}: ReturnType<typeof goalsActions.goalDataRequest>) {
    try {
        const goalsList: Goal[] = yield goalsGateway.fetchPlayerGoals({
            playerId,
        });
        if (goalsList) {
            yield put(goalsActions.goalsListRecieved(goalsList));
        }
    } catch (e) {
        console.error(
            {
                _error: `Failed to fetch goals for Player: ${playerId}`,
            },
            e,
        );
    }
}

export function* updateGoalSaga({
    playerId,
    goalId,
    goalData,
    onSuccess,
    onFail,
}: ReturnType<typeof goalsActions.updateGoalRequest>) {
    try {
        yield call(goalsGateway.updateGoal, { playerId, goalId, goalData });
        yield put(goalsActions.goalDataRequest({ playerId, goalId }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(`Failed to fetch goal: ${goalId}`, e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* createGoalSaga({
    playerId,
    goalData,
    onSuccess,
    onFail,
}: ReturnType<typeof goalsActions.addGoalRequest>) {
    try {
        const createdGoalId: string = yield goalsGateway.createGoal({
            playerId,
            goalData,
        });

        yield put(goalsActions.goalDataRequest({ playerId, goalId: createdGoalId }));
        yield call(onSuccess);
    } catch (e) {
        console.error(`Failed to create goal for player: ${playerId}`, e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* deleteGoalSaga({
    playerId,
    goalId,
}: ReturnType<typeof goalsActions.deleteGoalRequest>) {
    try {
        yield call([goalsGateway, goalsGateway.deleteGoal], { playerId, goalId });
        yield put(goalsActions.deleteGoalFromState({ goalId }));
    } catch (e) {
        console.error(
            {
                _error: `Failed to fetch goal: ${goalId}`,
            },
            e,
        );
    }
}

function* goalsSagas() {
    yield all([
        takeEvery(actionTypes.GOAL_DATA_REQUEST, fetchGoalDataSaga),
        takeEvery(actionTypes.PLAYER_GOALS_LIST_REQUEST, fetchPlayerGoalsSaga),
        takeLatest(actionTypes.ADD_GOAL_REQUEST, createGoalSaga),
        takeLatest(actionTypes.UPDATE_GOAL_REQUEST, updateGoalSaga),
        takeLatest(actionTypes.DELETE_GOAL_REQUEST, deleteGoalSaga),
    ]);
}

export default goalsSagas;
