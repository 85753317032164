import Notification from 'entities/notifications/Notification';
import {
    fetchNotification,
    fetchUserNotifications,
    updateNotification,
} from 'gateways/notificationsGateway';
import { toastr } from 'react-redux-toastr';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as notificationsActions from './notifications.actions';
import actionTypes from './notifications.actionTypes';

export function* fetchUserNotificationsSaga({
    userId,
}: ReturnType<typeof notificationsActions.notificationsListRequest>) {
    try {
        const notificationsList: Notification[] = yield call(fetchUserNotifications, {
            userId,
        });

        if (notificationsList) {
            yield put(notificationsActions.notificationsListRecieved({ notificationsList }));
        }
    } catch (e) {
        console.error(
            {
                _error: `Failed to fetch notifications for the user: ${userId}`,
            },
            e,
        );
    }
}

export function* fetchNotificationSaga({
    notificationId,
    userId,
}: ReturnType<typeof notificationsActions.notificationDataRequest>) {
    try {
        const notification: Notification = yield call(fetchNotification, {
            notificationId,
            userId,
        });

        if (notification) {
            yield put(notificationsActions.notificationDataRecieved({ notification }));
        }
    } catch (e) {
        console.error(
            {
                _error: `Failed to fetch notification: ${notificationId}`,
            },
            e,
        );
    }
}

export function* updateNotificationSaga({
    userId,
    notificationId,
    notificationData,
    onSuccess,
}: ReturnType<typeof notificationsActions.updateNotification>) {
    try {
        yield call(updateNotification, {
            notificationId,
            userId,
            notificationData,
        });
        yield put(notificationsActions.notificationDataRequest({ notificationId, userId }));
        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(`Failed to update notification ${notificationId} for Player: ${userId}`, e);
        yield call(toastr.error, 'Failed to update notification', '');
    }
}

export default function* notificationsSagas() {
    yield all([
        takeLatest(actionTypes.NOTIFICATIONS_LIST_REQUEST, fetchUserNotificationsSaga),
        takeEvery(actionTypes.NOTIFICATION_DATA_REQUEST, fetchNotificationSaga),
        takeLatest(actionTypes.UPDATE_NOTIFICATION, updateNotificationSaga),
    ]);
}
