import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { LocalConfig } from './Config';
import { applyLocalConfig, saveLocalConfig } from './config.actions';
import { localConfigSelector } from './config.selectors';

export const useLocalConfig = () => {
    const dispatch = useDispatch();
    const localConfig = useSelector(localConfigSelector) as LocalConfig;

    useEffect(() => {
        dispatch(applyLocalConfig());
    }, []);

    const onChange = useCallback(
        (key: keyof LocalConfig, value: any) => {
            dispatch(
                saveLocalConfig({
                    localConfig: {
                        ...localConfig,
                        [key]: value,
                    },
                }),
            );
        },
        [localConfig],
    );

    return { localConfig, onChange };
};
