import StorageFile, { StorageFileTarget, StorageFileTargetType } from './StorageFile';
import actionTypes from './storageFiles.actionTypes';

export const createStorageFile = ({
    storageFileId,
    file,
    filePath,
    target,
    targetType,
    onSuccess,
    onFail,
}: {
    file: File;
    storageFileId: string;
    filePath: string;
    target: StorageFileTarget;
    targetType: StorageFileTargetType;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.CREATE_STORAGE_FILE,
    storageFileId,
    file,
    filePath,
    target,
    targetType,
    onSuccess,
    onFail,
});

export const storageFileReceived = ({ storageFile }: { storageFile: StorageFile }) => ({
    type: actionTypes.STORAGE_FILE_DATA_RECEIVED,
    storageFile,
});

export const storageFilesListReceived = ({ storageFiles }: { storageFiles: StorageFile[] }) => ({
    type: actionTypes.STORAGE_FILES_LIST_RECEIVED,
    storageFiles,
});

export const updateStorageFile = ({
    storageFileId,
    storageFile,
    onSuccess,
    onFail,
}: {
    storageFileId: string;
    storageFile: StorageFile;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.UPDATE_STORAGE_FILE,
    storageFileId,
    storageFile,
    onSuccess,
    onFail,
});

export const fetchStorageFile = ({
    storageFileId,
    onSuccess,
    onFail,
}: {
    storageFileId: string;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.FETCH_STORAGE_FILE,
    storageFileId,
    onSuccess,
    onFail,
});

export const fetchOrgStorageFiles = ({
    organizationId,
    fromDate,
    onSuccess,
    onFail,
}: {
    organizationId: string;
    fromDate?: Date;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.FETCH_ORG_STORAGE_FILES,
    fromDate,
    organizationId,
    onSuccess,
    onFail,
});

export const fetchTeamStorageFiles = ({
    teamId,
    fromDate,
    onSuccess,
    onFail,
}: {
    teamId: string;
    fromDate?: Date;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.FETCH_TEAM_STORAGE_FILES,
    fromDate,
    teamId,
    onSuccess,
    onFail,
});

export const fetchMethodologyStorageFiles = ({
    methodologyId,
    fromDate,
    onSuccess,
    onFail,
}: {
    methodologyId: string;
    fromDate?: Date;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.FETCH_METHODOLOGY_STORAGE_FILES,
    fromDate,
    methodologyId,
    onSuccess,
    onFail,
});

export const fetchPlayerStorageFiles = ({
    playerId,
    fromDate,
    onSuccess,
    onFail,
}: {
    playerId: string;
    fromDate?: Date;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.FETCH_PLAYER_STORAGE_FILES,
    fromDate,
    playerId,
    onSuccess,
    onFail,
});

export const removeStorageFileFromState = ({
    storageFileId,
    onSuccess,
    onFail,
}: {
    storageFileId: string;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.REMOVE_STORAGE_FILE_FROM_STATE,
    storageFileId,
    onSuccess,
    onFail,
});
