import { ReportParams } from 'entities/reports/Report';
import actionTypes from './tracking.actionTypes';

export const trackingDataRequest = ({
    eventId,
    organizationId,
    teamId,
    periodNumber,
    target,
}: ReportParams & {
    eventId: string;
    periodNumber: number;
    target: string;
}) => ({
    type: actionTypes.DOWNLOAD_TRACKING_DATA,
    eventId,
    organizationId,
    teamId,
    periodNumber,
    target,
});

export const trackingDataReceived = ({
    eventId,
    periodNumber,
    target,
    trackingData,
}: {
    eventId: string;
    periodNumber: number;
    target: string;
    trackingData: string;
}) => ({
    type: actionTypes.TRACKING_DATA_RECEIVED,
    eventId,
    periodNumber,
    target,
    trackingData,
});
