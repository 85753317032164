import { playerToEventInfo } from 'entities/events/events.utils';
import MedicalTest, { MedicalTestData } from './MedicalTests';
import User from 'entities/users/User';
import { AttendanceStatus } from 'entities/events/Session';
import Player from 'entities/players/Player';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
    isLoadingTestsAccessSelector,
    medicalTestSelector,
    medicalTestsAccessListSelector,
} from './medicalTests.selectors';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { medicalTestDataRecieved, medicalTestRequest } from './medicalTests.actions';
import { doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from 'common/firebase';
import { getMedicalTestPath } from 'gateways/medicalTestsGateway';
import { handleDocumentSnapshot } from 'gateways/utils';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import { MedicalRecordType } from 'entities/medical-records/MedicalRecords';

export const createEmptyMedicalTest = ({
    organizationId,
    teamId,
    user,
    playersList,
    tests,
    title = '',
}: {
    organizationId: string;
    teamId: string;
    user: User;
    title?: string;
    playersList: Player[];
    tests: MedicalRecordType[];
}) => {
    const attendance = playersList.reduce((acc, player) => {
        return {
            ...acc,
            [player.id]: {
                status: AttendanceStatus.present,
                time: 0,
                player: playerToEventInfo(player),
            },
        };
    }, {});
    const testAuthor = {
        userId: user.id,
        name: user.name || '',
        email: user.email,
        filePath: null,
        role: null,
    };
    const newTest: MedicalTestData = {
        title,
        organizationId,
        teamId,
        date: new Date(),
        author: testAuthor,
        links: [],
        tests,
        attendance,
        files: [],
        lastUpdateAuthorUid: user.id,
    };

    return newTest;
};

export const useMedicalTest = ({
    organizationId,
    teamId,
    medicalTestId,
    subscribeUpdates,
}: {
    organizationId: string;
    teamId: string;
    medicalTestId: string;
    subscribeUpdates: boolean;
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const medicalTest: MedicalTest | undefined = useSelector((state: RootState) =>
        medicalTestSelector(state, { medicalTestId }),
    );

    useEffect(() => {
        if (!medicalTest) {
            dispatch(medicalTestRequest({ organizationId, teamId, medicalTestId }));
        }
    }, [teamId, organizationId, medicalTest]);

    useEffect(() => {
        if (!subscribeUpdates) return;
        let isInitialLoad = true;

        const medicalTestRef = doc(db, getMedicalTestPath(organizationId, teamId, medicalTestId));

        const unsubscribeMedicalTestUpdates = onSnapshot(medicalTestRef, (doc) => {
            const medicalTestSnapData = handleDocumentSnapshot(doc) as MedicalTest;

            if (medicalTestSnapData) {
                dispatch(medicalTestDataRecieved(medicalTestSnapData));
            }

            if (isInitialLoad) {
                isInitialLoad = false;
            } else if (medicalTestSnapData) {
                toastr.info(t('medicalTests.updates.successUpdateMessage'), '');
            } else {
                toastr.info(t('medicalTests.updates.successDeleteMessage'), '');
            }
        });

        return unsubscribeMedicalTestUpdates;
    }, [organizationId, teamId, medicalTestId, subscribeUpdates]);

    return medicalTest;
};

export const useHasMedicalTestsAccess = (organizationId: string) => {
    const mediacalTestsAccessList = useSelector((state: RootState) =>
        medicalTestsAccessListSelector(state, { organizationId }),
    );
    const isLoadingTestsAccess = useSelector(isLoadingTestsAccessSelector);
    const usersIdsWithAccess = mediacalTestsAccessList.map((userInfo) => userInfo.userId);
    const currentUserUid = auth.currentUser?.uid;

    return {
        hasMedicalTestsAccess: currentUserUid && usersIdsWithAccess.includes(currentUserUid),
        isLoadingTestsAccess,
    };
};
