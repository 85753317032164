import _keyBy from 'lodash/keyBy';
import actionTypes from './medicalTests.actionTypes';
import MedicalTest from './MedicalTests';
import { Author } from 'entities/users/User';

type MedicalTestsState = {
    medicalTestsHash: Record<string, MedicalTest>;
    medicalTestsAccessHash: Record<string, Author[]>;
    isLoadingTestsAccess: boolean;
};
const initialState = {
    medicalTestsHash: {},
    medicalTestsAccessHash: {},
    isLoadingTestsAccess: false,
};

const medicalTests = (state: MedicalTestsState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.MEDICAL_TEST_RECEIVED:
            return {
                ...state,
                medicalTestsHash: {
                    ...state.medicalTestsHash,
                    [action.medicalTest.id]: {
                        ...action.medicalTest,
                    },
                },
            };
        case actionTypes.MEDICAL_TESTS_LIST_RECEIVED:
            return {
                ...state,
                medicalTestsHash: {
                    ...state.medicalTestsHash,
                    ..._keyBy(action.medicalTestsList, 'id'),
                },
            };
        case actionTypes.MEDICAL_TESTS_ACCESS_LIST_RECEIVED:
            return {
                ...state,
                medicalTestsAccessHash: {
                    ...state.medicalTestsAccessHash,
                    [action.organizationId]: action.usersInfo,
                },
                isLoadingTestsAccess: false,
            };
        case actionTypes.MEDICAL_TESTS_ACCESS_LIST_REQUEST:
            return {
                ...state,
                isLoadingTestsAccess: true,
            };
        default:
            return state;
    }
};

export default medicalTests;
