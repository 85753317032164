import { Language } from 'entities/users/User';

export enum TutorialName {
    createAccaunt,
    profileSetup,
    createTeam,
    appNavigation,
    addPlayers,
    planificationOverview,
    planMicrocycle,
    linkPlayers,
    addEventToCalendar,
    createSession,
    evaluateSession,
    createMethodology,
    createDrill,
    createGame,
    evaluateGame,
    accessControl,
    observations,
    individualGoals,
    individualGoalsEvaluation,
    playerInjuries,
    teamDashboard,
    conditionsOverview,
    morningWellness,
    morningWellnessSetup,
    loadConditions,
    loadConditionsSetup,
    conditionsSetup,
}

export type TutorialConfig = {
    titleTranslationKey: string;
    descriptionTranslationKey: string;
    videoUrls: { [key in Language]: string | null };
};

type TutorialsConfigs = {
    [key in TutorialName]: TutorialConfig;
};

export const tutorialsConfig: TutorialsConfigs = {
    [TutorialName.createAccaunt]: {
        titleTranslationKey: 'videoTutorial.profileSetup.title',
        descriptionTranslationKey: 'videoTutorial.profileSetup.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/JnXZ1rlkn0s',
            [Language.ua]: 'https://www.youtube.com/embed/_7s46oY7WwM',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.profileSetup]: {
        titleTranslationKey: 'videoTutorial.profileSetup.title',
        descriptionTranslationKey: 'videoTutorial.profileSetup.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/lAgay3A3sjo',
            [Language.ua]: 'https://www.youtube.com/embed/BL-GO57X3DU',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.createTeam]: {
        titleTranslationKey: 'videoTutorial.createTeam.title',
        descriptionTranslationKey: 'videoTutorial.createTeam.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/q_C9cZAMVSg',
            [Language.ua]: 'https://www.youtube.com/embed/xhk1BT8eQck',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.appNavigation]: {
        titleTranslationKey: 'videoTutorial.appNavigation.title',
        descriptionTranslationKey: 'videoTutorial.appNavigation.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/q_C9cZAMVSg',
            [Language.ua]: 'https://www.youtube.com/embed/xhk1BT8eQck',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.addPlayers]: {
        titleTranslationKey: 'videoTutorial.addPlayers.title',
        descriptionTranslationKey: 'videoTutorial.addPlayers.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/eKswgcMJubI',
            [Language.ua]: 'https://www.youtube.com/embed/RnfcPFCrBLA',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.planificationOverview]: {
        titleTranslationKey: 'videoTutorial.planificationOverview.title',
        descriptionTranslationKey: 'videoTutorial.planificationOverview.description',
        videoUrls: {
            [Language.en]: null,
            [Language.ua]: 'https://www.youtube.com/embed/X84I-lovgN8',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.planMicrocycle]: {
        titleTranslationKey: 'videoTutorial.planMicrocycle.title',
        descriptionTranslationKey: 'videoTutorial.planMicrocycle.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/mfRKJVqXgGo',
            [Language.ua]: 'https://www.youtube.com/embed/752O4Xc6nNQ',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.linkPlayers]: {
        titleTranslationKey: 'videoTutorial.linkPlayers.title',
        descriptionTranslationKey: 'videoTutorial.linkPlayers.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/aYC2a3qnj40',
            [Language.ua]: 'https://www.youtube.com/embed/HpPMJWdosoM',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.addEventToCalendar]: {
        titleTranslationKey: 'videoTutorial.addEventToCalendar.title',
        descriptionTranslationKey: 'videoTutorial.addEventToCalendar.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/1O8x6BFnh5M',
            [Language.ua]: 'https://www.youtube.com/embed/pMWsr6HLoSc',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.createSession]: {
        titleTranslationKey: 'videoTutorial.createSession.title',
        descriptionTranslationKey: 'videoTutorial.createSession.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/59HmsoTfjow',
            [Language.ua]: 'https://www.youtube.com/embed/NYye8h_eWAs',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.evaluateSession]: {
        titleTranslationKey: 'videoTutorial.evaluateSession.title',
        descriptionTranslationKey: 'videoTutorial.evaluateSession.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/kqj_yU59lq8',
            [Language.ua]: 'https://www.youtube.com/embed/KsUyEVuQdu0',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.createMethodology]: {
        titleTranslationKey: 'videoTutorial.createMethodology.title',
        descriptionTranslationKey: 'videoTutorial.createMethodology.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/SbPQhzCLQFE',
            [Language.ua]: 'https://www.youtube.com/embed/mbD6dRKxTVk',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.createDrill]: {
        titleTranslationKey: 'videoTutorial.createDrill.title',
        descriptionTranslationKey: 'videoTutorial.createDrill.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/x3gFJpL9WyA',
            [Language.ua]: 'https://www.youtube.com/embed/MT7hqXAvlC0',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.createGame]: {
        titleTranslationKey: 'videoTutorial.createGame.title',
        descriptionTranslationKey: 'videoTutorial.createGame.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/poJhdUdUwtI',
            [Language.ua]: 'https://www.youtube.com/embed/x3NLP8k0kI0',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.evaluateGame]: {
        titleTranslationKey: 'videoTutorial.evaluateGame.title',
        descriptionTranslationKey: 'videoTutorial.evaluateGame.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/7or09WL6CA8',
            [Language.ua]: 'https://www.youtube.com/embed/enAfVEr5xfQ',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.accessControl]: {
        titleTranslationKey: 'videoTutorial.accessControl.title',
        descriptionTranslationKey: 'videoTutorial.accessControl.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/WSjpB14E0go',
            [Language.ua]: 'https://www.youtube.com/embed/UvhUsTV5BTk',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.observations]: {
        titleTranslationKey: 'videoTutorial.observations.title',
        descriptionTranslationKey: 'videoTutorial.observations.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/-6hyQArghhU',
            [Language.ua]: 'https://www.youtube.com/embed/XYOJP2ZfJX8',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.individualGoals]: {
        titleTranslationKey: 'videoTutorial.individualGoals.title',
        descriptionTranslationKey: 'videoTutorial.individualGoals.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/Gkk2ZSK7Rx4',
            [Language.ua]: 'https://www.youtube.com/embed/-DNQFi2rcbQ',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.individualGoalsEvaluation]: {
        titleTranslationKey: 'videoTutorial.individualGoalsEvaluation.title',
        descriptionTranslationKey: 'videoTutorial.individualGoalsEvaluation.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/iQG0PNrXcek',
            [Language.ua]: 'https://www.youtube.com/embed/ZCsG0PhBdVE',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.playerInjuries]: {
        titleTranslationKey: 'videoTutorial.playerInjuries.title',
        descriptionTranslationKey: 'videoTutorial.playerInjuries.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/9HEfdQheQkI',
            [Language.ua]: 'https://www.youtube.com/embed/Vn5rTY82G4o',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.teamDashboard]: {
        titleTranslationKey: 'videoTutorial.teamDashboard.title',
        descriptionTranslationKey: 'videoTutorial.teamDashboard.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/5PpDGOHS7fE',
            [Language.ua]: 'https://www.youtube.com/embed/HaHkB_Bsluo',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.conditionsOverview]: {
        titleTranslationKey: 'videoTutorial.conditionsOverview.title',
        descriptionTranslationKey: 'videoTutorial.conditionsOverview.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/Fk1ukM6M88M',
            [Language.ua]: 'https://www.youtube.com/embed/bNVylDnNxnc',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.morningWellness]: {
        titleTranslationKey: 'videoTutorial.morningWellness.title',
        descriptionTranslationKey: 'videoTutorial.morningWellness.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/rbiOVG-cAMQ',
            [Language.ua]: 'https://www.youtube.com/embed/0HfqDoAlSzY',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.morningWellnessSetup]: {
        titleTranslationKey: 'videoTutorial.morningWellnessSetup.title',
        descriptionTranslationKey: 'videoTutorial.morningWellnessSetup.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/FCTuDl22hLM',
            [Language.ua]: 'https://www.youtube.com/embed/iHGxTKz7w98',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.loadConditions]: {
        titleTranslationKey: 'videoTutorial.loadConditions.title',
        descriptionTranslationKey: 'videoTutorial.loadConditions.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/G1V8MzgTCzk',
            [Language.ua]: 'https://www.youtube.com/embed/gNLaTRE-XjA',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.loadConditionsSetup]: {
        titleTranslationKey: 'videoTutorial.loadConditionsSetup.title',
        descriptionTranslationKey: 'videoTutorial.loadConditionsSetup.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/NBVb4dEoj0Y',
            [Language.ua]: 'https://www.youtube.com/embed/tm6rHHtifqs',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
    [TutorialName.conditionsSetup]: {
        titleTranslationKey: 'videoTutorial.conditionsSetup.title',
        descriptionTranslationKey: 'videoTutorial.conditionsSetup.description',
        videoUrls: {
            [Language.en]: 'https://www.youtube.com/embed/ca4l3m8IpPI',
            [Language.ua]: 'https://www.youtube.com/embed/RvOFfdxHe9s',
            [Language.pl]: null,
            [Language.es]: null,
            [Language.ru]: null,
        },
    },
};
