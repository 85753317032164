import { createSelector } from 'reselect';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { RootState } from 'store';
import Event, { EventTypes } from './Event';
import Game from './Game';
import Session from './Session';

export const eventsHashSelector = (state: RootState) => state.entities.events.eventsHash;

export const teamEventsListSelector = createSelector(
    eventsHashSelector,
    (_: any, { teamId }: { teamId: string }) => teamId,
    (eventsHash, teamId: string) =>
        Object.values(eventsHash).filter((event: Event) => event.teamId === teamId),
);

export const orgEventsListSelector = createSelector(
    eventsHashSelector,
    (_: any, { organizationId }: { organizationId: string }) => organizationId,
    (eventsHash, organizationId: string) =>
        Object.values(eventsHash).filter((event: Event) => event.organizationId === organizationId),
);

export const eventDataSelector = createSelector(
    eventsHashSelector,
    (_: any, { eventId }: { eventId: string }) => eventId,
    (eventsHash, eventId) => eventsHash[eventId] as Event | undefined,
);

export const dayEventsSelector = createSelector(
    teamEventsListSelector,
    (_: any, props: { selectedDate: Date }) => props.selectedDate,
    (eventsList: Event[], date) => {
        return eventsList.filter(({ start }) => isSameDay(start, date));
    },
);

export const dayGamesAndSessionsSelector = createSelector(
    dayEventsSelector,
    (eventsList: Event[]) => {
        const gamesAndSessionsList = eventsList.filter(
            ({ eventType }) => eventType === EventTypes.game || eventType === EventTypes.session,
        ) as (Game | Session)[];
        return gamesAndSessionsList;
    },
);

export const periodGamesAndSessionsSelector = createSelector(
    teamEventsListSelector,
    (_: any, { from }: { from: Date }) => from,
    (_: any, { to }: { to: Date }) => to,
    (eventsList, from, to) => {
        const gamesAndSessionsList = eventsList.filter(
            ({ eventType, start }) =>
                [EventTypes.game, EventTypes.session].includes(eventType) &&
                isAfter(start, from) &&
                isBefore(start, to),
        ) as (Game | Session)[];
        return gamesAndSessionsList;
    },
);

export const orgDayPlanificationEventsSelector = createSelector(
    orgEventsListSelector,
    (_: any, { selectedDate }: { selectedDate: Date }) => selectedDate,
    (eventsList, selectedDate) => {
        return eventsList.filter(
            ({ start, eventType }) =>
                isSameDay(start, selectedDate) &&
                [EventTypes.game, EventTypes.session].includes(eventType),
        );
    },
);

export const eventsByIdsSelector = createSelector(
    eventsHashSelector,
    (_: any, { eventsIds }: { eventsIds: string[] }) => eventsIds,
    (eventsHash, eventsIds) => {
        return eventsIds
            .map((eventsId) => {
                return eventsHash[eventsId];
            })
            .filter((event) => event) as Event[];
    },
);

export const eventsUpdatesHashSelector = (state: RootState) =>
    state.entities.events.eventsUpdatesHash;

export const eventsUpdatesListSelector = createSelector(
    eventsUpdatesHashSelector,
    (eventsUpdatesHash) => {
        return Object.values(eventsUpdatesHash);
    },
);

export const eventUpdatesListSelector = createSelector(
    eventsUpdatesListSelector,
    (_: any, { eventId }: { eventId: string }) => eventId,
    (eventsUpdatesList, eventId) => {
        const result = eventsUpdatesList.filter(
            (playerUpdateRecord) => playerUpdateRecord.eventId === eventId,
        );
        result.sort((a, b) => (isBefore(a.date, b.date) ? 1 : -1));

        return result;
    },
);
