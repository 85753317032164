import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SubstitutionInIcon = (props: any) => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
        <g>
            <title>SubstitutionIn</title>
            <path
                fill="green"
                d="M9.21 11H11v9c0 .55.45 1 1 1s1-.45 1-1v-9h1.79c.45 0 .67-.54.35-.85l-2.79-2.79c-.2-.2-.51-.2-.71 0l-2.79 2.79c-.31.31-.09.85.36.85zM4 4c0 .55.45 1 1 1z"
            />
        </g>
    </SvgIcon>
);

export default SubstitutionInIcon;
