import { ScoutingPosition } from 'common/components/pitch/positionsCoordinates';
import { Author } from 'entities/users/User';

export enum ListType {
    longlist = 'longlist',
    shadowTeam = 'shadowTeam',
}

export enum ListStatus {
    active = 'active',
    deleted = 'deleted',
}

export type ListsFolder = {
    id: string;
    name: string;
    groups: string[];
};

type NotificationReceiver = {
    name: string;
    email: string;
};

export type PlayerNotification = {
    receivers: NotificationReceiver[];
    date: string;
    text: string;
};

export type ScoutingListPlayerPositions = {
    /* Number means order in a list for given position */
    [key in ScoutingPosition]?: number;
};

export type PlayerRecord = {
    playerId: string;
    listPositions: ScoutingListPlayerPositions;
    notifications: PlayerNotification[];
    transferProbability: number;
    // order: null | number;
    addedDate: Date | null;
};

export enum UpdateType {
    remove = 'remove',
    add = 'add',
}

export type ListUpdateRecord = {
    id: string;
    playerId: string;
    action: UpdateType;
    author: Author;
    date: Date;
    listId: string;
    organizationId: string;
};

export type ScoutingListData = {
    name: string;
    creatorId: string;
    type: ListType;
    deletedDate: Date | null;
    createDate: Date;
    editDate: Date;
    logoPath: string | null;
    groups: string[];
    leagues: string[];
    players: Record<string, PlayerRecord>;
    status: ListStatus;
    regions: string[];
    parentListId: null | string;
    frozenPlayersIds: string[];
    withShadowTeams: boolean;
    showOnlyPrimaryPosition: boolean;
};

type ScoutingList = ScoutingListData & {
    id: string;
    organizationId: string;
};

export default ScoutingList;
