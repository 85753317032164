import { styled } from '@mui/material/styles';
import MuiTooltip from '@mui/material/Tooltip';

export default styled(MuiTooltip)(({ theme }) => ({
    [`& .MuiTooltip-popper`]: {
        background: theme.custom.background.gradient,
        boxShadow: theme.custom.boxShadow.main,
        color: theme.palette.common.black,
    },
}));
