import MuiInputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';

const StyledInputLabel = styled(MuiInputLabel)(({ theme }) => ({
    transform: 'none',
    position: 'static',
    paddingLeft: theme.spacing(1),
    ...theme.typography.body2,
}));

export default StyledInputLabel;
