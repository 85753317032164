import snackbarActionTypes from './snackbar.actionTypes';

const initialState = {
    active: null,
};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case snackbarActionTypes.SHOW_SNACKBAR:
            return {
                ...state,
                active: {
                    variant: action.variant,
                    message: action.message,
                    title: action.title,
                },
            };
        case snackbarActionTypes.CLOSE_SNACKBAR:
            return {
                ...state,
                active: null,
            };
        default:
            return state;
    }
};
