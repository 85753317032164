import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import AccessRecord from './Access';
import accessActionTypes from './access.actionTypes';

type AccessState = {
    accessHash: Record<string, AccessRecord>;
    defaultPermissions: Record<string, Record<string, string>> | null;
};
const initialState = {
    accessHash: {},
    defaultPermissions: null,
};

const accessReducer = (state: AccessState = initialState, action: any) => {
    switch (action.type) {
        case accessActionTypes.ACCESS_LIST_RECEIVED: {
            return {
                ...state,
                accessHash: {
                    ...state.accessHash,
                    ..._keyBy(action.accessList, 'gid'),
                },
            };
        }
        case accessActionTypes.ACCESS_DATA_RECEIVED: {
            return {
                ...state,
                accessHash: {
                    ...state.accessHash,
                    [action.accessRecord.gid]: action.accessRecord,
                },
            };
        }
        case accessActionTypes.REMOVE_ACCESS_FROM_LIST: {
            return {
                ...state,
                accessHash: { ..._omit(state.accessHash, action.gid) },
            };
        }
        case accessActionTypes.DEFAULT_PERMISSIONS_RECEIVED: {
            return {
                ...state,
                defaultPermissions: action.defaultPermissions,
            };
        }
        default:
            return state;
    }
};

export default accessReducer;
