import { AccessTarget } from 'entities/access/Access';
import { userDataSelector } from 'entities/users/users.selectors';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { createSelector } from 'reselect';
import { RootState } from 'store';

const invitationsHashSelector = (state: RootState) => state.entities.invitations.invitationsHash;

export const invitationsListSelector = createSelector(invitationsHashSelector, (invitationsHash) =>
    Object.values(invitationsHash),
);

export const targetInvitationsListSelector = createSelector(
    invitationsListSelector,
    (_: any, { target }: { target: AccessTarget }) => target,
    (invitationsList, target) => {
        return invitationsList.filter((access) => _isEqual(access.target, target));
    },
);

export const invitationDataSelector = createSelector(
    invitationsHashSelector,
    (_: any, { invitationId }: { invitationId: string }) => invitationId,
    (invitationsHash, invitationId) => _get(invitationsHash, `${invitationId}`, {}),
);

export const userInvitationsListSelector = createSelector(
    userDataSelector,
    invitationsListSelector,
    (user, invitationsList) => {
        if (!user) {
            return [];
        }

        return invitationsList.filter(({ userEmail }) => userEmail === user.email);
    },
);

export const playerToLinkSelector = (state: RootState) => state.entities.invitations.playerToLink;
