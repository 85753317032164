import { takeLatest, all, put, call, select } from 'redux-saga/effects';
import { addDays, format } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import { sagas as eventsSagas, actions as eventsActions } from 'entities/events';
import GeneralEvent from 'entities/events/GeneralEvent';
import { PlanningPeriods, ScheduleViewTypes } from 'features/calendar/Calendar';
import calendarActionTypes from './calendar.actionTypes';
import * as calendarActions from './calendar.actions';
import { weekDaysList } from './calendar.constants';
import { teamPlayersListSelector } from 'entities/players/players.selectors';
import Player from 'entities/players/Player';
import { EventTypes } from 'entities/events/Event';
import Session from 'entities/events/Session';
import Game from 'entities/events/Game';
import { copyGameData, copySessionData } from './calendar.utils';
import { createEventSaga } from 'entities/events/events.sagas';
import Holiday from 'entities/events/Holiday';
import RecoveryDay from 'entities/events/RecoveryDay';

export function* deleteCalendarEventSaga({
    eventId,
    teamId,
    organizationId,
    navigate,
}: ReturnType<typeof calendarActions.deleteCalendarEvent>) {
    try {
        yield call(eventsSagas.deleteEventsSaga, {
            type: '',
            eventsIds: [eventId],
            teamId,
            organizationId,
        });
        yield call(
            navigate,
            `/organizations/${organizationId}/teams/${teamId}/${ScheduleViewTypes.planning}/${PlanningPeriods.week}`,
        );
    } catch (e) {
        yield call(console.error, 'Failed to add drill to session', e);
    }
}
export function* createGameSaga({
    teamId,
    organizationId,
    formData,
    onSuccess,
    onFail,
}: ReturnType<typeof calendarActions.createGameRequest>) {
    try {
        const gameId: string = yield call([eventsSagas, eventsSagas.createEventSaga], {
            type: '',
            eventData: formData,
            teamId,
            organizationId,
        });
        yield call(onSuccess, gameId);
    } catch (e) {
        console.error(e);

        if (onFail) {
            yield call(onFail);
        }
    }
}

export function* editGameSaga({
    teamId,
    formData,
    gameId,
    organizationId,
    onSuccess,
}: ReturnType<typeof calendarActions.editGameRequest>) {
    try {
        yield call([eventsSagas, eventsSagas.updateEventSaga], {
            type: '',
            eventId: gameId,
            eventData: formData,
            teamId,
            organizationId,
        });
        yield call(onSuccess);
    } catch (e) {
        yield call(toastr.error, 'Failes to save game', '');
    }
}

export function* createEventsSaga({
    eventData,
    teamId,
    organizationId,
    repeat,
}: ReturnType<typeof calendarActions.createEventsRequest>) {
    try {
        const repeatDays = Object.entries(repeat)
            .filter((deySetup) => deySetup[1])
            .map(([day]) => day);
        const originalWeekDay = format(eventData.start, 'EEEEEE').toLowerCase();
        const originalWeekDayIndex = weekDaysList.findIndex(
            (weekDay) => weekDay === originalWeekDay,
        );
        const newEventsActions = repeatDays.map((day) => {
            const repeatDayIndex = weekDaysList.findIndex((weekDay) => weekDay === day);
            const daysDiff = repeatDayIndex - originalWeekDayIndex;
            const eventStart = addDays(eventData.start, daysDiff);
            const eventEnd = addDays((eventData as GeneralEvent).end, daysDiff);
            const eventWithUpdatedTime = {
                ...eventData,
                start: eventStart,
                end: eventEnd,
            };
            return put(
                eventsActions.createEventRequest({
                    eventData: eventWithUpdatedTime,
                    organizationId,
                    teamId,
                }),
            );
        });
        yield all(newEventsActions);
    } catch (e) {
        yield call(console.error, e, 'Failed to create events');
    }
}

export function* copyEventsSaga({
    events,
    daysShift,
    teamId,
    organizationId,
    onSuccess,
}: ReturnType<typeof calendarActions.copyEvents>) {
    const playersList: Player[] = yield select(teamPlayersListSelector, { teamId, organizationId });
    try {
        /* Copy sessions */
        const newSessions = events
            .filter((event) => event.eventType === EventTypes.session)
            .map((session) =>
                copySessionData(session as Session, playersList, addDays(session.start, daysShift)),
            );

        const newSessionsActions = newSessions.map((sessionData) => {
            return call(createEventSaga, {
                type: '',
                eventData: sessionData,
                organizationId,
                teamId,
            });
        });

        yield all(newSessionsActions);

        /* Copy games */
        const newGames = events
            .filter((event) => event.eventType === EventTypes.game)
            .map((game) => copyGameData(game as Game, playersList, addDays(game.start, daysShift)));

        const newGamesActions = newGames.map((gameData) => {
            return call(createEventSaga, {
                type: '',
                eventData: gameData,
                organizationId,
                teamId,
            });
        });
        yield all(newGamesActions);

        /* Copy general events */
        const generalEvents = events.filter(
            (event) => event.eventType === EventTypes.general,
        ) as GeneralEvent[];
        const newGenetralEvents = generalEvents.map(({ id, start, end, ...rest }) => ({
            ...rest,
            start: addDays(start, daysShift),
            end: addDays(end, daysShift),
        }));

        const newGenetralEventsActions = newGenetralEvents.map((generalEventData) => {
            return call(createEventSaga, {
                type: '',
                eventData: generalEventData,
                organizationId,
                teamId,
            });
        });
        yield all(newGenetralEventsActions);

        /* Copy holidays and recovery events */
        const holidaysRecoveryEvents = events.filter((event) =>
            [EventTypes.holiday, EventTypes.recovery].includes(event.eventType),
        ) as (Holiday | RecoveryDay)[];
        const newHolidaysRecoveryEvents = holidaysRecoveryEvents.map(({ id, start, ...rest }) => ({
            ...rest,
            start: addDays(start, daysShift),
        }));

        const newHolidaysRecoveryEventsActions = newHolidaysRecoveryEvents.map(
            (generalEventData) => {
                return call(createEventSaga, {
                    type: '',
                    eventData: generalEventData,
                    organizationId,
                    teamId,
                });
            },
        );
        yield all(newHolidaysRecoveryEventsActions);

        yield call(onSuccess);
    } catch (e) {
        yield call(console.error, e, 'Failed to copy events');
    }
}

function* calendarSaga() {
    yield all([
        takeLatest(calendarActionTypes.CREATE_GAME_REQUEST, createGameSaga),
        takeLatest(calendarActionTypes.EDIT_GAME_REQUEST, editGameSaga),
        takeLatest(calendarActionTypes.DELETE_CALENDAR_EVENT, deleteCalendarEventSaga),
        takeLatest(calendarActionTypes.CREATE_EVENTS_REQUEST, createEventsSaga),
        takeLatest(calendarActionTypes.COPY_EVENTS, copyEventsSaga),
    ]);
}

export default calendarSaga;
