import { auth, db, functions } from 'common/firebase';
import { MedicalTestData, MedicalTestsMetadata } from 'entities/medical-tests/MedicalTests';
import { collection, doc, getDoc, getDocs, addDoc, updateDoc } from 'firebase/firestore';
import { HttpsCallableResult, httpsCallable } from 'firebase/functions';
import { handleCollectionSnapshot, handleDocumentSnapshot } from 'gateways/utils';

export const getMedicalTestsAccessesPath = (organizationId: string) => {
    return `organizations/${organizationId}/metadata/medicalTests`;
};

export const getTeamMedicalTestsPath = (organizationId: string, teamId: string) => {
    return `organizations/${organizationId}/teams/${teamId}/medicalTests`;
};

export const getMedicalTestPath = (
    organizationId: string,
    teamId: string,
    medicalTestId: string,
) => {
    return `${getTeamMedicalTestsPath(organizationId, teamId)}/${medicalTestId}`;
};

export const getMedicalTestFilesPath = getMedicalTestPath;

export const createMedicalTest = ({
    organizationId,
    teamId,
    medicalTest,
}: {
    organizationId: string;
    teamId: string;
    medicalTest: MedicalTestData;
}) => {
    const medicalTestsRef = collection(db, getTeamMedicalTestsPath(organizationId, teamId));

    return addDoc(medicalTestsRef, medicalTest).then((doc) => doc.id);
};

export const updateMedicalTest = ({
    organizationId,
    teamId,
    medicalTestId,
    medicalTest,
}: {
    organizationId: string;
    teamId: string;
    medicalTestId: string;
    medicalTest: MedicalTestData;
}) => {
    const medicalTestRef = doc(db, getMedicalTestPath(organizationId, teamId, medicalTestId));
    const lastUpdateAuthorUid = auth.currentUser?.uid || null;
    Object.assign(medicalTest, { lastUpdateAuthorUid });

    return updateDoc(medicalTestRef, medicalTest);
};

export const fetchMedicalTestById = ({
    organizationId,
    teamId,
    medicalTestId,
}: {
    organizationId: string;
    teamId: string;
    medicalTestId: string;
}) => {
    const medicalTestRef = doc(db, getMedicalTestPath(organizationId, teamId, medicalTestId));

    return getDoc(medicalTestRef).then(handleDocumentSnapshot);
};

export const fetchTeamMedicalTestsList = ({
    organizationId,
    teamId,
}: {
    organizationId: string;
    teamId: string;
}) => {
    const medicalTestsRef = collection(db, getTeamMedicalTestsPath(organizationId, teamId));

    return getDocs(medicalTestsRef).then(handleCollectionSnapshot);
};

const getMedicalRecordsAccessListFunction = httpsCallable(
    functions,
    'getMedicalRecordsAccessListV2',
);
export const fetchMedicalTestsAccessList = async ({
    organizationId,
}: {
    organizationId: string;
}) => {
    const { data } = (await getMedicalRecordsAccessListFunction({
        organizationId,
    })) as HttpsCallableResult<MedicalTestsMetadata>;
    return data.usersWithAccess;
};
