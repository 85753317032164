import { db } from 'common/firebase';
import Notification, { NotificationData } from 'entities/notifications/Notification';
import {
    collection,
    doc,
    getDoc,
    getDocs,
    orderBy,
    query,
    updateDoc,
    limit,
} from 'firebase/firestore';
import { handleCollectionSnapshot, handleDocumentSnapshot } from './utils';

export const fetchUserNotifications = ({
    userId,
    count = 10,
}: {
    userId: string;
    count?: number;
}) => {
    const notificationsRef = collection(db, `users/${userId}/notifications`);
    const q = query(notificationsRef, orderBy('date', 'desc'), limit(count));
    return getDocs(q)
        .then(handleCollectionSnapshot)
        .then((notifications) => notifications.map((obj: Notification) => ({ ...obj, userId })));
};

export const fetchNotification = ({
    notificationId,
    userId,
}: {
    userId: string;
    notificationId: string;
}) => {
    const notificationsRef = doc(db, `users/${userId}/notifications/${notificationId}`);

    return getDoc(notificationsRef)
        .then(handleDocumentSnapshot)
        .then((obj) => ({ ...obj, userId }));
};

export const updateNotification = ({
    notificationId,
    userId,
    notificationData,
}: {
    userId: string;
    notificationId: string;
    notificationData: NotificationData;
}) => {
    const notificationsRef = doc(db, `users/${userId}/notifications/${notificationId}`);

    return updateDoc(notificationsRef, notificationData);
};
