import authActionTypes from './auth.actionTypes';
import { AuthState } from './Auth';

type InitialStateType = {
    isVerifyingEmail: boolean;
    authState: AuthState | null;
    emailVerificationError: string | null;
};

const initialState: InitialStateType = {
    isVerifyingEmail: false,
    authState: null,
    emailVerificationError: null,
};

const auth = (state = initialState, action: any) => {
    switch (action.type) {
        case authActionTypes.START_EMAIL_VERIFICATION:
            return {
                ...state,
                isVerifyingEmail: true,
                emailVerificationError: null,
            };
        case authActionTypes.SET_EMAIL_VERIFICATION_ERROR:
            return {
                ...state,
                isVerifyingEmail: false,
                emailVerificationError: action.errorMessage,
            };
        case authActionTypes.SET_EMAIL_VERIFIED:
            return {
                ...state,
                isVerifyingEmail: false,
                emailVerificationError: null,
            };
        case authActionTypes.SET_AUTH_STATE:
            return {
                ...state,
                authState: action.authState,
            };
        default:
            return state;
    }
};

export default auth;
