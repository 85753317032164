import { takeLatest, all, put, call } from 'redux-saga/effects';

import { auth } from 'common/firebase';
import { format } from 'date-fns';
import * as snackbarAcrions from 'common/snackbar/snackbar.actions';
import * as solutionsGateway from 'gateways/solutionsGateway';
import * as solutionsActions from 'entities/solutions/solutions.actions';
import Solution from 'entities/solutions/Solution';
import { downloadFile } from 'common/utils/filesUtils';
import challengesPageActionTypes from './challengesPage.actionTypes';
import * as challengesPageActions from './challengesPage.actions';

export function* submitSolutionSaga({
    challengeId,
}: ReturnType<typeof challengesPageActions.submitSolution>) {
    try {
        yield call([solutionsGateway, solutionsGateway.submitSolution], {
            challengeId,
        });
        yield put(
            solutionsActions.solutionDataRequest({
                challengeId,
                solutionId: auth.currentUser?.uid || '',
            }),
        );
        yield put(challengesPageActions.submitSolutionSuccess());
    } catch (e) {
        yield put(challengesPageActions.submitSolutionFail());
    }
}

const generateSolutionsTable = (solutionsList: Solution[]) => {
    const titleString = ['User Name', 'Email', 'score', 'Submit date'].join(',').concat('\n');

    const solutionsStr = solutionsList
        .map((solution) => {
            const { userName, userEmail, score, submitDate } = solution;
            return [
                userName,
                userEmail,
                score === null ? '-' : Math.round(score * 100) / 100,
                submitDate ? format(submitDate, 'dd MMM') : '-',
            ].join(',');
        })
        .join('\n');
    return titleString.concat(solutionsStr);
};

export function* downloadResultsCsvSaga({
    challengeId,
    failMessage,
}: ReturnType<typeof challengesPageActions.downloadResultsCsv>) {
    try {
        const solutionsList: Solution[] = yield call(
            [solutionsGateway, solutionsGateway.fetchSolutionsList],
            {
                challengeId,
            },
        );
        const csvStr = generateSolutionsTable(solutionsList);
        yield call(downloadFile, { content: csvStr, fileName: 'challenge-results.csv' });
    } catch (e) {
        yield put(
            snackbarAcrions.showError({
                message: failMessage,
            }),
        );
    }
}

function* challengesPageSagas() {
    yield all([
        takeLatest(challengesPageActionTypes.SUBMIT_SOLUTION, submitSolutionSaga),
        takeLatest(challengesPageActionTypes.DOWNLOAD_RESULTS_CSV, downloadResultsCsvSaga),
    ]);
}

export default challengesPageSagas;
