import { takeLatest, all, takeEvery, call, put } from 'redux-saga/effects';

import * as challengesGateway from 'gateways/challengesGateway';
import { actions as snackbarActions } from 'common/snackbar';
import challengesActionTypes from './challenges.actionTypes';
import * as challengesActions from './challenges.actions';
import Challenge, { ChallengeParams } from './Challenge';

export function* fetchChallengeDataSaga({
    challengeId,
}: ReturnType<typeof challengesActions.challengeDataRequest>) {
    try {
        const challengeData: Challenge = yield call(challengesGateway.fetchChallengeById, {
            challengeId,
        });
        if (challengeData) {
            yield put(challengesActions.challengeDataReceived({ challengeData }));
        }
    } catch (e) {
        console.error({ _error: `Failed to fetch challenge for event ${challengeId}` }, e);
    }
}

export function* fetchChallengesListSaga() {
    try {
        const challengesList: Challenge[] = yield call(challengesGateway.fetchChallengesList);
        yield put(challengesActions.challengesListReceived({ challengesList }));
    } catch (e) {
        console.error({
            e,
            _error: 'Failed to fetch challenges',
        });
    }
}

export function* createChallengeSaga({
    challengeData,
    successMessage,
}: ReturnType<typeof challengesActions.challengeCreateRequest>) {
    try {
        const id: string = yield call(challengesGateway.createChallenge, {
            challengeData,
        });
        yield put(
            snackbarActions.showSuccess({
                message: successMessage,
            }),
        );
        yield put(challengesActions.challengeDataRequest({ challengeId: id }));
        return id;
    } catch (e) {
        yield put(
            snackbarActions.showError({
                message:
                    '🤕 Failed to create the challenge. Reload page and try one more time please',
            }),
        );
        throw e;
    }
}

export function* updateChallengeSaga({
    challengeId,
    challengeData,
    successMessage,
    failMessage,
}: ReturnType<typeof challengesActions.challengeDataUpdate>) {
    try {
        yield call(challengesGateway.updateChallengeData, {
            challengeId,
            challengeData,
        });
        yield put(challengesActions.challengeDataRequest({ challengeId }));
        yield put(
            snackbarActions.showSuccess({
                message: successMessage,
            }),
        );
    } catch (e) {
        yield put(
            snackbarActions.showError({
                message: failMessage,
            }),
        );
        yield call(console.error, e);
    }
}

export function* removeChallengesSaga({ challengeId }: ChallengeParams) {
    try {
        yield call(challengesGateway.removeChallenge, { challengeId });
        yield put(challengesActions.removeChallengeFromList({ challengeId }));
    } catch (e) {
        yield put(
            snackbarActions.showError({
                message: '🤕 Failed to delete challenge. Reload page and try one more time please',
            }),
        );
        throw e;
    }
}

function* challengesSaga() {
    yield all([
        takeLatest(challengesActionTypes.CHALLENGE_UPDATE_REQUEST, updateChallengeSaga),
        takeLatest(challengesActionTypes.CHALLENGE_CREATE_REQUEST, createChallengeSaga),
        takeEvery(challengesActionTypes.CHALLENGE_DATA_REQUEST, fetchChallengeDataSaga),
        takeLatest(challengesActionTypes.CHALLENGES_LIST_REQUEST, fetchChallengesListSaga),
    ]);
}

export default challengesSaga;
