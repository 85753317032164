import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import actionTypes from './tasks.actionTypes';
import { PlayerTask, TeamTask } from './Task';

type SolutionState = {
    teamTasksHash: {
        [key: string]: TeamTask;
    };
    playerTasksHash: {
        [key: string]: PlayerTask;
    };
};

const initialState: SolutionState = {
    teamTasksHash: {},
    playerTasksHash: {},
};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.TEAM_TASKS_LIST_RECEIVED: {
            return {
                ...state,
                teamTasksHash: {
                    ...state.teamTasksHash,
                    ..._keyBy(action.tasksList, 'id'),
                },
            };
        }
        case actionTypes.PLAYER_TASKS_LIST_RECEIVED: {
            return {
                ...state,
                playerTasksHash: {
                    ...state.playerTasksHash,
                    ..._keyBy(action.tasksList, 'id'),
                },
            };
        }
        case actionTypes.REMOVE_TEAM_TASK:
            return {
                ...state,
                teamTasksHash: { ..._omit(state.teamTasksHash, action.taskId) },
            };
        default:
            return state;
    }
};
