import React from 'react';
import { ClearIcon, DoneIcon } from 'common/icons';
import blueGrayColor from '@mui/material/colors/blueGrey';
import { Typography, Button, CircularProgress, Box } from 'common/components';

const ButtonIcon = ({
    isLoading = false,
    isError = false,
    isSuccess = false,
}: {
    /* eslint-disable react/require-default-props */
    isLoading?: boolean;
    isError?: boolean;
    isSuccess?: boolean;
    /* eslint-enable react/require-default-props */
}) => {
    if (isLoading) {
        return <CircularProgress size={16} style={{ color: blueGrayColor[100] }} />;
    }
    if (isError) {
        return <ClearIcon />;
    }
    if (isSuccess) {
        return <DoneIcon />;
    }

    return null;
};

export type SubmitButtonProps = {
    isLoading?: boolean;
    isError?: boolean;
    isSuccess?: boolean;
    buttonText?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    size?: string;
    className?: string;
    onClick?: Function;
    sx?: any;
};

const SubmitButton: React.FC<SubmitButtonProps> = ({
    fullWidth,
    disabled,
    buttonText = 'Submit',
    size,
    className,
    onClick,
    sx,
    ...restProps
}) => {
    const { isLoading = false, isError = false, isSuccess = false, ...rest } = restProps;
    const isTextHidden = isError || isLoading || isSuccess;

    return (
        <Button
            sx={sx}
            type="submit"
            variant="contained"
            color="primary"
            className={className}
            disabled={disabled}
            fullWidth={fullWidth}
            size={size}
            onClick={onClick}
            {...rest}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                }}
            >
                <ButtonIcon {...restProps} />
            </Box>
            <Typography color="inherit" style={{ opacity: isTextHidden ? 0 : 1 }}>
                {buttonText}
            </Typography>
        </Button>
    );
};

export default SubmitButton;
