export default {
    TEAM_DATA_RECEIVED: 'TEAMS/TEAM_DATA_RECEIVED',
    TEAMS_LIST_REQUEST: 'TEAMS/TEAMS_LIST_REQUEST',
    TEAMS_LIST_RECIEVED: 'TEAMS/TEAMS_LIST_RECIEVED',
    DELETE_TEAM: 'ENTITIES/TEAMS/DELETE_TEAM',
    SET_PLAYER_ID: 'ENTITIES/TEAMS/SET_PLAYER_ID',
    TEAM_DATA_REQUEST: 'ENTITIES/TEAMS/TEAM_DATA_REQUEST',
    TEAM_DATA_UPDATE: 'ENTITIES/TEAMS/TEAM_DATA_UPDATE',
    REMOVE_TEAM: 'ENTITIES/TEAMS/REMOVE_TEAM',
    TEAM_UPDATES_LIST_RECEIVED: 'ENTITIES/TEAMS/TEAM_UPDATES_LIST_RECEIVED',
    TEAM_UPDATES_LIST_REQUEST: 'ENTITIES/TEAMS/TEAM_UPDATES_LIST_REQUEST',
    PLAYERS_LABELS_DATA_REQUEST: 'ENTITIES/TEAMS/PLAYERS_LABELS/PLAYERS_LABELS_DATA_REQUEST',
    PLAYERS_LABELS_DATA_RECEIVED: 'ENTITIES/TEAMS/PLAYERS_LABELS/PLAYERS_LABELS_DATA_RECEIVED',
    UPDATE_PLAYERS_LABELS: 'ENTITIES/TEAMS/PLAYERS_LABELS/UPDATE_PLAYERS_LABELS',
};
