import Agency from './Agencies';
import agenciesActionTypes from './agencies.actionTypes';
import * as agenciesActions from './agencies.actions';
import * as agenciesGateway from 'gateways/agenciesGateway';
import { put, call, all, takeEvery } from 'redux-saga/effects';

export function* updateAgencySaga({
    agencyId,
    agencyData,
    onSuccess,
}: ReturnType<typeof agenciesActions.agencyDataUpdate>) {
    try {
        yield call([agenciesGateway, agenciesGateway.updateAgency], {
            agencyId,
            agencyData,
        });
        yield put(agenciesActions.agencyDataRequest({ agencyId }));

        if (onSuccess) {
            onSuccess(agencyData.name);
        }
    } catch (e) {
        console.error(e);
    }
}

export function* createAgencySaga({
    agencyData,
    onSuccess,
}: ReturnType<typeof agenciesActions.agencyCreate>) {
    try {
        const agencyId: string = yield call([agenciesGateway, agenciesGateway.createAgency], {
            agencyData,
        });
        yield put(agenciesActions.agencyDataRequest({ agencyId }));
        if (onSuccess) {
            onSuccess(agencyId);
        }
    } catch (e) {
        console.error(e);
    }
}

export function* fetchAgencyDataSaga({
    agencyId,
    onSuccess,
}: ReturnType<typeof agenciesActions.agencyDataRequest>) {
    try {
        const agency: Agency = yield call(agenciesGateway.fetchAgencyById, {
            agencyId,
        });
        if (agency) {
            yield put(agenciesActions.agencyDataRecieved(agency));
        }
        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

export function* fetchAgenciesListsSaga({
    organizationId,
}: ReturnType<typeof agenciesActions.agenciesListRequest>) {
    try {
        const agenciesList: Agency[] = yield call(
            [agenciesGateway, agenciesGateway.fetchAgenciesList],
            {
                organizationId,
            },
        );
        if (agenciesList) {
            yield put(agenciesActions.agenciesListRecieved(agenciesList));
        }
    } catch (e) {
        console.error(e);
    }
}

function* agenciesSagas() {
    yield all([takeEvery(agenciesActionTypes.AGENCIES_LIST_REQUEST, fetchAgenciesListsSaga)]);
    yield all([takeEvery(agenciesActionTypes.AGENCY_CREATE, createAgencySaga)]);
    yield all([takeEvery(agenciesActionTypes.AGENCY_DATA_REQUEST, fetchAgencyDataSaga)]);
    yield all([takeEvery(agenciesActionTypes.AGENCY_DATA_UPDATE, updateAgencySaga)]);
}

export default agenciesSagas;
