import { createSelector } from 'reselect';
import { RootState } from 'store';
import Goal from './Goal';

export const playerIdSelector = (_: any, { playerId }: { playerId: string }) => playerId;

export const goalsListSelector = (state: RootState): Goal[] => {
    const goals = state.entities.goals.goalsHash;

    return Object.values(goals);
};

export const teamPlayersGoalsListSelector = createSelector(
    goalsListSelector,
    (_: RootState, { teamId }: { teamId: string }) => teamId,
    (goalsList: Goal[], teamId: string) => {
        return goalsList.filter((goal) => goal.teamId === teamId);
    },
);

export const playerGoalsListSelector = createSelector(
    goalsListSelector,
    playerIdSelector,
    (goalsList: Goal[], playerId: string) => goalsList.filter((goal) => goal.playerId === playerId),
);
