import Club from './Clubs';
import actionTypes from './clubs.actionTypes';

export const clubDataRequest = ({
    clubId,
    onSuccess,
}: {
    clubId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.CLUB_DATA_REQUEST,
    clubId,
    onSuccess,
});

export const clubDataRecieved = (club: Club) => ({
    type: actionTypes.CLUB_DATA_RECEIVED,
    club,
});

export const clubsListRequest = ({ organizationId }: { organizationId: string }) => ({
    type: actionTypes.CLUBS_LIST_REQUEST,
    organizationId,
});

export const leagueClubsListRequest = ({
    organizationId,
    leagueId,
}: {
    organizationId: string;
    leagueId: string;
}) => ({
    type: actionTypes.LEAGUE_CLUBS_LIST_REQUEST,
    organizationId,
    leagueId,
});

export const clubsListRecieved = (clubsList: Club[]) => ({
    type: actionTypes.CLUBS_LIST_RECEIVED,
    clubsList,
});
