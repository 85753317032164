import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import Observation from './Observation';
import actionTypes from './observations.actionTypes';

const initialState = {
    observationsHash: {},
};

type State = {
    observationsHash: {
        [key: string]: Observation;
    };
};

const observations = (state: State = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.OBSERVATIONS_LIST_RECIEVED:
            return {
                ...state,
                observationsHash: {
                    ...state.observationsHash,
                    ..._keyBy(action.observationsList, 'id'),
                },
            };
        case actionTypes.OBSERVATION_DATA_RECIEVED:
            return {
                ...state,
                observationsHash: {
                    ...state.observationsHash,
                    [action.observationData.id]: {
                        ...action.observationData,
                    },
                },
            };
        case actionTypes.REMOVE_OBSERVATION_FROM_STORE:
            return {
                ...state,
                observationsHash: {
                    ..._omit(state.observationsHash, action.observationId),
                },
            };
        default:
            return state;
    }
};

export default observations;
