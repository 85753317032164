import { auth } from 'common/firebase';
import {
    confirmPasswordReset,
    createUserWithEmailAndPassword as createWithEmailPassword,
    reauthenticateWithCredential,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    updatePassword as updateUserPassword,
    applyActionCode as applyActionCodeFirebase,
    sendEmailVerification,
    EmailAuthProvider,
} from 'firebase/auth';

export const signOut = () => auth.signOut();

export const loginUser = ({ email, password }: { email: string; password: string }) => {
    return signInWithEmailAndPassword(auth, email, password);
};

export const createUserWithEmailAndPassword = ({
    email,
    password,
}: {
    email: string;
    password: string;
}) => {
    return createWithEmailPassword(auth, email, password);
};

export const resetUserPassword = ({ email }: { email: string }) => {
    return sendPasswordResetEmail(auth, email);
};

export const userCredential = async ({ oldPassword }: { oldPassword: string }) => {
    return EmailAuthProvider.credential(auth.currentUser?.email!, oldPassword);
};

export const reAuthenticateUser = async (oldPassword: string) => {
    const credential = await userCredential({ oldPassword });
    reauthenticateWithCredential(auth.currentUser!, credential);
};

export const setNewPassword = ({
    confirmationCode,
    newPassword,
}: {
    confirmationCode: string;
    newPassword: string;
}) => {
    return confirmPasswordReset(auth, confirmationCode, newPassword);
};

export const updatePassword = ({ newPassword }: { newPassword: string }) => {
    if (auth.currentUser) {
        return updateUserPassword(auth.currentUser, newPassword);
    }
    return null;
};

export const applyActionCode = ({ code }: { code: string }) => {
    return applyActionCodeFirebase(auth, code);
};

export const sendVerificationEmail = () => {
    if (auth.currentUser) {
        return sendEmailVerification(auth.currentUser);
    }

    return null;
};
