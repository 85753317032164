import invitationPageActionTypes from './invitationPage.actionTypes';

const initialState = {
    isSubmitting: false,
};

const invitationPage = (state = initialState, action: any) => {
    switch (action.type) {
        case invitationPageActionTypes.START_SUBMIT:
            return {
                ...state,
                isSubmitting: true,
            };
        case invitationPageActionTypes.STOP_SUBMIT:
            return {
                ...state,
                isSubmitting: false,
            };
        default:
            return state;
    }
};

export default invitationPage;
