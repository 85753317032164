import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import Notification from './Notification';
import actionTypes from './notifications.actionTypes';

const initialState = {
    notificationsHash: {},
};

type State = {
    notificationsHash: {
        [key: string]: Notification;
    };
};

const notifications = (state: State = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.NOTIFICATIONS_LIST_RECIEVED:
            return {
                ...state,
                notificationsHash: {
                    ...state.notificationsHash,
                    ..._keyBy(action.notificationsList, 'id'),
                },
            };
        case actionTypes.NOTIFICATION_DATA_RECIEVED:
            return {
                ...state,
                notificationsHash: {
                    ...state.notificationsHash,
                    [action.notification.id]: {
                        ...action.notification,
                    },
                },
            };
        default:
            return state;
    }
};

export default notifications;
