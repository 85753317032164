import actionTypes from './tracking.actionTypes';

export type Position = {
    x: string;
    y: string;
    number: string;
};

type TrackingSateType = {
    trackingDataHash: {
        [key: string]: Position[][];
    };
};

const initialState: TrackingSateType = {
    trackingDataHash: {},
};

const trackingCsvToList = (trackingData: string) => {
    const rows = trackingData.split('\n');
    const numbers = rows[0].split(',').slice(1);
    const trackingDataArray = rows.slice(1).map((frame) => {
        const positions = frame.split(',').slice(1);
        const result = [];
        for (let i = 0; i < positions.length / 2; i += 1) {
            const playerPosition = {
                x: positions[2 * i],
                y: positions[2 * i + 1],
                number: numbers[2 * i].split('_')[1],
            };
            result.push(playerPosition);
        }

        return result.filter((player) => player.x && player.y);
    });

    return trackingDataArray;
};

export default (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.TRACKING_DATA_RECEIVED: {
            const { eventId, target, periodNumber, trackingData } = action;
            const teamDataId = `${eventId}_${target}_${periodNumber}`;
            const trackingDataArray = trackingCsvToList(trackingData);

            return {
                ...state,
                trackingDataHash: {
                    ...state.trackingDataHash,
                    [teamDataId]: trackingDataArray,
                },
            };
        }
        default:
            return state;
    }
};
