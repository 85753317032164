import { ExternalLink } from 'common/external-links/ExternalLink';
import { EventPlayerInfo, EventTypes } from './Event';

export enum PlayerStatus {
    lineup = 'lineup',
    bench = 'bench',
    reserve = 'reserve',
    ill = 'ill',
    skip = 'skip',
    reason = 'reason',
    injury = 'injury',
    promotion = 'promotion',
}

export enum ActivityType {
    redCard = 'redCard',
    yellowCard = 'yellowCard',
    injury = 'injury',
    substitution = 'substitution',
    outSubstitution = 'outSubstitution',
    inSubstitution = 'inSubstitution',
    ball = 'ball',
    assist = 'assist',
    yellowAndRedCard = 'yellowAndRedCard',
}

export type GameActivity = {
    action: ActivityType;
    minute: number;
};

export type LineupPosition = {
    player: EventPlayerInfo;
    status: PlayerStatus;
    gamePosition: string | null;
    activities: GameActivity[];
    // observations: string[];
    time: number; /* Load time used for RPE */
    gameTime: number; /* Clean game time */
};

export type Lineup = Record<string, LineupPosition>;

export type Substitution = {
    in: string;
    out: string;
    time: number;
};

export enum GameStatus {
    home = 'home',
    away = 'away',
}

export type GamesSettings = {
    showPlayerEvaluations: boolean;
    showGameStats: boolean;
    showLineup: number;
    showGameFiles: boolean;
    showGameLinks: boolean;
};


export type GameOpponent = {
    name: string;
};

export type GameScore = {
    team: number | null;
    opponent: number | null;
};

export type GameDetails = {
    gameTime: number;
    opponent: GameOpponent;
    formation: string;
    substitutions: Substitution[];
    lineup: Lineup;
    homeAway: GameStatus;
    location: string | null;
    description: string;
    gameScore: GameScore;
    playerNotes: string;
    recordings: ExternalLink[];
    files: string[];
};

export type GameData = {
    eventType: EventTypes;
    start: Date;
    end: Date;
    details: GameDetails;
    settings: GamesSettings;
    createDate: Date | null;
    lastUpdateAuthorUid: string | null;
};

type Game = GameData & {
    id: string;
    teamId: string;
    organizationId: string;
};

export type GameParams = {
    gameId: string;
    organizationId: string;
    teamId: string;
};

export default Game;
