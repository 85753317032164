import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    [`.MuiInputBase-root`]: {
        backgroundColor: 'rgba(9, 30, 66, 0.04)',
        border: '2px solid transparent',
        paddingRight: theme.spacing(1),
    },
    [`.MuiInputBase-root.Mui-focused`]: {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
    },
    [`.MuiInputBase-inputSizeSmall`]: {
        padding: theme.spacing(0.5, 1),
    },
}));

export default StyledInputBase;
