import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Box, Button, Drawer } from 'common/components';

const StyledDrawer = styled(Drawer)(({theme}) => ({
    ['& .MuiDrawer-paper']: {
        background: theme.custom.background.gradient,
    },
    [`& .MuiBackdrop-root`]: {
        backgroundColor: 'rgba(32, 38, 45, 0.2)',
        backdropFilter: 'blur(2px)',
    },
}));

type RightDrawerWithCloseBtnProps = {
    isOpen: boolean;
    children: any;
    noPaddings?: boolean;
    onClose: VoidFunction;
};

const RightDrawerWithCloseBtn: React.FC<RightDrawerWithCloseBtnProps> = ({
    isOpen,
    children,
    noPaddings = false,
    onClose,
    ...rest
}) => {
    const { t } = useTranslation();

    return (
        <StyledDrawer anchor="right" open={isOpen} onClose={onClose} {...rest}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    px: noPaddings ? 0 : 1,
                    py: noPaddings ? 0 : 2,
                    flexGrow: 1,
                    overflowY: 'auto',
                }}
            >
                {children}
            </Box>
            <Box
                sx={{
                    py: 1,
                    px: 3,
                    boxShadow: (theme) => theme.custom.boxShadow.main,
                }}
            >
                <Button color="inherit" onClick={onClose}>
                    {t('actions.close')}
                </Button>
            </Box>
        </StyledDrawer>
    );
};

export default RightDrawerWithCloseBtn;
