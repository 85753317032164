import { PlanningPeriods } from 'features/calendar/Calendar';
import {
    addDays,
    addMinutes,
    differenceInCalendarDays,
    differenceInDays,
    endOfDay,
    endOfISOWeek,
    endOfMonth,
    format,
    getDate,
    getMonth,
    isAfter,
    isBefore,
    isSameDay,
    isSameMonth,
    isSameYear,
    parse,
    startOfDay,
    startOfISOWeek,
    startOfMonth,
    subMinutes,
} from 'date-fns';

export const getDaysForCalendarMonthView = (date: Date) => {
    const rangeStart = startOfISOWeek(startOfMonth(date));
    const rangeEnd = endOfISOWeek(endOfMonth(date));
    const totalDays = differenceInDays(rangeEnd, rangeStart) + 1;

    return {
        days: Array(totalDays)
            .fill('')
            .map((_, i) => addDays(rangeStart, i)),
        rangeEnd,
        rangeStart,
    };
};

export const getDaysWeek = (date: Date) => {
    const rangeStart = startOfISOWeek(date);
    const rangeEnd = endOfISOWeek(date);
    const totalDays = differenceInDays(rangeEnd, rangeStart) + 1;
    return {
        days: Array(totalDays)
            .fill('')
            .map((_, i) => addDays(rangeStart, i)),
        rangeEnd,
        rangeStart,
    };
};

export const getDays = (from: Date, to: Date) => {
    const periodLength = differenceInCalendarDays(startOfDay(to), endOfDay(from)) + 1;
    const result = Array(periodLength)
        .fill('')
        .map((_, index) => addDays(from, index));

    return result;
};

export const isBetween = (date?: Date, from?: Date, to?: Date) => {
    if (!date || !from || !to) {
        return false;
    }

    return isAfter(date, from) && isBefore(date, to);
};

export const getCurrentDateRange = (currentDate: Date, period = PlanningPeriods.month) => {
    let rangeStart: any;
    let rangeEnd: any;

    if (period === PlanningPeriods.day) {
        rangeStart = startOfDay(currentDate);
        rangeEnd = endOfDay(currentDate);
    } else if (period === PlanningPeriods.week) {
        rangeStart = startOfISOWeek(currentDate);
        rangeEnd = endOfISOWeek(currentDate);
    } else if (period === PlanningPeriods.month) {
        rangeStart = startOfMonth(currentDate);
        rangeEnd = endOfMonth(currentDate);
    }

    return {
        rangeStart,
        rangeEnd,
        days: getDays(rangeStart, rangeEnd),
    };
};

export const getDaysRange = (fromDate: Date, toDate: Date) => {
    const daysDifference = differenceInDays(toDate, fromDate);
    const result: Date[] = [];

    for (let i = 0; i <= daysDifference; i += 1) {
        const nextDay = addDays(fromDate, i);

        result.push(nextDay);
    }

    return result;
};

export const checkBirthdayToday = (date?: Date) => {
    if (!date) {
        return false;
    }

    const today = new Date();
    return getDate(today) === getDate(date) && getMonth(today) === getMonth(date);
};

export const checkBirthdayTomorrow = (date?: Date) => {
    if (!date) {
        return false;
    }
    const tomorrow = addDays(new Date(), 1);
    return getDate(tomorrow) === getDate(date) && getMonth(tomorrow) === getMonth(date);
};

export const getDaysPeriodString = (startDate: Date, endDate: Date) => {
    if (isSameDay(startDate, endDate)) {
        return format(startDate, 'dd MMM');
    }

    if (isSameMonth(startDate, endDate)) {
        return `${format(startDate, 'dd')} - ${format(endDate, 'dd MMM')}`;
    }

    const startDateStr = format(startDate, 'dd MMM');
    const endDateStr = format(endDate, 'dd MMM');

    return `${startDateStr} - ${endDateStr}`;
};

export const getPeriodString = (startDate: Date, endDate: Date) => {
    if (isSameMonth(startDate, endDate)) {
        return format(startDate, 'MMM yyyy');
    }

    if (isSameYear(startDate, endDate)) {
        const year = format(startDate, 'yyyy');
        const startMonth = format(startDate, 'MMM');
        const endMonth = format(endDate, 'MMM');

        return `${startMonth} - ${endMonth} ${year}`;
    }

    const startDateStr = format(startDate, 'MMM yyyy');
    const endDateStr = format(endDate, 'MMM yyyy');

    return `${startDateStr} - ${endDateStr}`;
};

export const fromUtcTimeStr = (utcTimeStr: string) => {
    const timezoneShift = new Date().getTimezoneOffset();
    const localTime = parse(utcTimeStr, 'HH:mm', new Date());
    const utcTime = subMinutes(localTime, timezoneShift);

    return format(utcTime, 'HH:mm');
};

export const toUtcTimeStr = (localTimeStr: string) => {
    const timezoneShift = new Date().getTimezoneOffset();
    const localTime = parse(localTimeStr, 'HH:mm', new Date());
    const utcTime = addMinutes(localTime, timezoneShift);

    return format(utcTime, 'HH:mm');
};
