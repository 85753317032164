import {
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    where,
} from 'firebase/firestore';
import { db, functions } from 'common/firebase';
import { handleDocumentSnapshot, handleCollectionSnapshot } from './utils';
import StorageFile from 'entities/storage-files/StorageFile';
import { httpsCallable } from 'firebase/functions';

export const createStorageFileRequest = ({
    storageFileId,
    storageFile,
}: {
    storageFileId: string;
    storageFile: StorageFile;
}) => {
    const cycleRef = doc(db, `files/${storageFileId}`);

    return setDoc(cycleRef, storageFile);
};

export const updateStorageFileRequest = ({
    storageFileId,
    storageFile,
}: {
    storageFileId: string;
    storageFile: StorageFile;
}) => {
    const cycleRef = doc(db, `files/${storageFileId}`);

    return updateDoc(cycleRef, storageFile);
};

export const fetchOrgStorageFilesRequest = ({
    organizationId,
    fromDate,
}: {
    organizationId: string;
    fromDate?: Date;
}) => {
    const filesCollectionRef = collection(db, 'files');
    let conditions = [
        where('target.organizationId', '==', organizationId),
        where('status', '==', 'active'),
    ];

    if (fromDate) {
        conditions.push(where('date', '>=', fromDate));
    }

    const q = query(filesCollectionRef, ...conditions);

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchTeamStorageFilesRequest = ({
    teamId,
    fromDate,
}: {
    teamId: string;
    fromDate?: Date;
}) => {
    const filesCollectionRef = collection(db, 'files');
    let conditions = [where('target.teamId', '==', teamId), where('status', '==', 'active')];

    if (fromDate) {
        conditions.push(where('date', '>=', fromDate));
    }

    const q = query(filesCollectionRef, ...conditions);

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchMethodologyStorageFilesRequest = ({
    methodologyId,
    fromDate,
}: {
    methodologyId: string;
    fromDate?: Date;
}) => {
    const filesCollectionRef = collection(db, 'files');
    let conditions = [
        where('target.methodologyId', '==', methodologyId),
        where('status', '==', 'active'),
    ];

    if (fromDate) {
        conditions.push(where('date', '>=', fromDate));
    }

    const q = query(filesCollectionRef, ...conditions);

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchPlayerStorageFilesRequest = ({
    playerId,
    fromDate,
}: {
    playerId: string;
    fromDate?: Date;
}) => {
    const filesCollectionRef = collection(db, 'files');
    let conditions = [where('target.playerId', '==', playerId), where('status', '==', 'active')];

    if (fromDate) {
        conditions.push(where('date', '>=', fromDate));
    }

    const q = query(filesCollectionRef, ...conditions);

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchStorageFileRequest = ({ storageFileId }: { storageFileId: string }) => {
    const cycleRef = doc(db, `files/${storageFileId}`);

    return getDoc(cycleRef).then(handleDocumentSnapshot);
};

export const convertVideoToGif = httpsCallable(functions, 'convertVideoToGifV2');
