import { useLocation, useNavigate } from 'react-router-dom';
import { getParam } from 'common/utils/urlUtils';
import { RedirectConfig } from './Auth';
import { useCallback } from 'react';

export const getRedirectPath = (config: RedirectConfig | null): string => {
    if (config?.targetPage === 'linkPlayer') {
        return `/link-player/${config.playerId}`;
    }

    return '/';
};

export const useSuccessLogin = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const redirectPath = getParam({ location, param: 'targetUrl' });
    const redirectSearchParams = getParam({ location, param: 'searchParams' });
    const redirectUrl = redirectSearchParams
        ? `${redirectPath}?${redirectSearchParams}`
        : redirectPath;

    const onSuccess = useCallback(() => {
        navigate(redirectPath ? String(redirectUrl) : '/');
    }, [redirectPath, redirectUrl]);

    return { onSuccess };
};

export const allowedDomains = [
    'gmail.com',
    'icloud.com',
    'me.com',
    'mac.com',
    'yahoo.com',
    'ymail.com',
    'hotmail.com',
    'aol.com',
    'outlook.com',
    'mail.com',
    'ukr.net',
    'outlook.fr',
    'i.ua',
];
