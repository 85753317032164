import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoalIcon } from 'common/icons';
// import GoalsDetails from 'features/organization/goals/GoalsDetails';
import TeamStats from 'entities/teams/TeamStats';
import StatsSection from './StatsSection';
import { Typography } from '@mui/material';

export type GoalsInfoProps = {
    teamStats: TeamStats;
};

const GoalsInfo: React.FC<GoalsInfoProps> = ({ teamStats }) => {
    const { t } = useTranslation();
    const { finished, created } = teamStats.goals;
    const contentText = [
        `${t('organization.teamStats.finishedGoals.short')}: ${finished}`,
        `${t('organization.teamStats.createdGoals.short')}: ${created}`,
    ].join(' | ');

    return (
        <StatsSection
            sx={{ mb: 1 }}
            title={t('teamPage.teamStats.goalsInfo.title')}
            IconComponent={GoalIcon}
        >
            <Typography variant="body2">{contentText}</Typography>
        </StatsSection>
    );
};

export default GoalsInfo;
