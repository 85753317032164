import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    Typography,
    Box,
    Button,
    CircularProgress,
    useTheme,
    useMediaQuery,
} from 'common/components';
import { userDataSelector } from 'entities/users/users.selectors';
import { sendVerifictionEmail } from '../auth.actions';

type State =
    | {
          type: 'closed';
      }
    | {
          type: 'open';
          isLoading: boolean;
      };

const VerificationSuggestions: React.FC = () => {
    const [state, setState] = useState<State>({ type: 'closed' });
    const dispatch = useDispatch();
    const user = useSelector(userDataSelector);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const { t } = useTranslation();

    const handleSendAgain = useCallback(() => {
        setState({ type: 'open', isLoading: true });
        dispatch(
            sendVerifictionEmail({
                t,
                onSuccess: () => {
                    setState({ type: 'open', isLoading: false });
                },
                onFail: () => {
                    setState({ type: 'open', isLoading: false });
                },
            }),
        );
    }, []);

    if (!user) {
        return null;
    }

    const { email } = user;
    const isOpen = state.type === 'open';
    const isLoading = isOpen && state.isLoading;

    return (
        <Box
            sx={{
                m: 'auto',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: 300,
                mt: 2,
                p: 1,
                borderRadius: 1,
                cursor: isOpen ? 'default' : 'pointer',
                backgroundColor: (theme) => theme.custom.background.light,
            }}
            onClick={() => setState({ type: 'open', isLoading: false })}
        >
            <Typography gutterBottom sx={{ opacity: 0.5 }}>
                {t('auth.emailVerification.suggestions.title')}
            </Typography>
            {!isOpen && (
                <Typography variant="caption" sx={{ opacity: 0.5, textDecoration: 'underline' }}>
                    {t('auth.emailVerification.suggestions.btn')}
                </Typography>
            )}
            {isOpen && (
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Typography variant="body2" gutterBottom>
                        {t('auth.emailVerification.suggestions.wait')}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        {t('auth.emailVerification.suggestions.spam')}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        {t('auth.emailVerification.suggestions.typos', { email })}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        {t('auth.emailVerification.suggestions.fullInbox')}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        {t('auth.emailVerification.suggestions.lastEmail')}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        {t('auth.emailVerification.suggestions.popularProviders')}
                    </Typography>
                    {isMobile && (
                        <Typography variant="body2" gutterBottom>
                            {t('auth.emailVerification.suggestions.useDesktop')}
                        </Typography>
                    )}
                </Box>
            )}
            {isOpen && (
                <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isLoading}
                    onClick={handleSendAgain}
                >
                    {isLoading ? <CircularProgress size={16} /> : t('auth.resendVerificationEmail')}
                </Button>
            )}
        </Box>
    );
};

export default VerificationSuggestions;
