import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'common/components';
import { TelegramIcon } from 'common/icons';

type TelegramBtnProps = {
    size?: 'small';
    className?: string;
    sx?: any;
};

const TelegramBtn: React.FC<TelegramBtnProps> = ({ size, className, sx }) => {
    const { t } = useTranslation();

    return (
        <Button
            component="a"
            target="_blank"
            variant="contained"
            color="primary"
            size={size}
            sx={sx}
            startIcon={<TelegramIcon />}
            href="https://t.me/undersports_support"
            className={className}
        >
            {t('support.openChat.telegram')}
        </Button>
    );
};

export default TelegramBtn;
